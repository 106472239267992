import {palette} from './palette';

export const getDesignTokens = (mode: any) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        ...palette.light
      }
      : {})
  },
  typography: {
    fontFamily: [
      'Readex Pro'
    ].join(','),
    body1: {
      fontFamily: 'Readex Pro'
    }
  }
});
