import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';
import { TCreateRestaurantCategory } from '../d';


const useCreateRestaurantCategoryMutation = () => {
    const mutation = useMutation({
        mutationFn: async (data: TCreateRestaurantCategory) => {
            const response = await axiosClient.post(createUrl({
                url: '/dispatcher/restaurant/category',
                version: 'v1',
            }), data, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
        }
    });
    return mutation;
};

export default useCreateRestaurantCategoryMutation;
