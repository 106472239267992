import React, { ComponentType, ElementType, PropsWithChildren } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { StyledComponent } from 'styled-components';
import { useTranslation } from 'react-i18next';

export type TTranslateProps = {
    use: string,
    as?: ElementType | ComponentType | StyledComponent<any, any>,
    params?: any
} & PropsWithChildren<any>

export const Translation = ({ use, as: Element = 'span', params = undefined, ...rest }: TTranslateProps) => {
    const { t } = useTranslation();

    return (
        <Element {...rest}>
            {t(use, params) || use}
        </Element>
    );

};

export default Translation;
