import {HomeButton, NotFoundContainer, NotFoundText, NotFoundTitle} from './style';
import Translation from '../../components/Translation';
import {Translate} from '../../internationalization/translate';
import {useNavigate} from 'react-router-dom';
import {useCallback} from 'react';

export default function NotFound() {

    const navigate = useNavigate();

    const handlerOnClick = useCallback((e: any) => {
        e.preventDefault();
        navigate('/', {replace: false});
    }, [navigate]);

    return (
        <NotFoundContainer>
            <svg width="237" height="93" viewBox="0 0 237 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M45.306 91V18.808L48.506 19.576L19.066 55.544L18.554 54.008H74.234V66.68H9.978L4.09 55.16L47.994 1.39999H58.618V91H45.306ZM118.409 92.28C111.582 92.28 105.694 90.4027 100.745 86.648C95.881 82.8933 92.1263 77.6027 89.481 70.776C86.8357 63.864 85.513 55.672 85.513 46.2C85.513 36.728 86.8357 28.5787 89.481 21.752C92.1263 14.84 95.881 9.50666 100.745 5.752C105.694 1.99733 111.582 0.119995 118.409 0.119995C125.236 0.119995 131.124 1.99733 136.073 5.752C141.022 9.50666 144.82 14.84 147.465 21.752C150.11 28.5787 151.433 36.728 151.433 46.2C151.433 55.672 150.11 63.864 147.465 70.776C144.82 77.6027 141.022 82.8933 136.073 86.648C131.124 90.4027 125.236 92.28 118.409 92.28ZM118.409 79.736C124.638 79.736 129.46 76.8347 132.873 71.032C136.286 65.144 137.993 56.8667 137.993 46.2C137.993 35.5333 136.286 27.2987 132.873 21.496C129.46 15.608 124.638 12.664 118.409 12.664C112.18 12.664 107.358 15.608 103.945 21.496C100.532 27.2987 98.825 35.5333 98.825 46.2C98.825 56.8667 100.532 65.144 103.945 71.032C107.358 76.8347 112.18 79.736 118.409 79.736ZM202.431 91V18.808L205.631 19.576L176.191 55.544L175.679 54.008H231.359V66.68H167.103L161.215 55.16L205.119 1.39999H215.743V91H202.431Z"
                    fill="white"/>
            </svg>
            <Translation use={Translate.notFoundTitle} as={NotFoundTitle}/>
            <Translation use={Translate.notFoundText} as={NotFoundText}/>
            <HomeButton variant={'outlined'} onClick={handlerOnClick}>
                <Translation use={Translate.notFoundButtonLabel}/>
            </HomeButton>
        </NotFoundContainer>
    );
}
