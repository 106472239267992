import { useCallback, useContext, useEffect, useMemo } from 'react';
import PeriodPickerContext from '../../../../../../components/PeriodPicker/context/context';
import useShiftSchedulingQuery from '../../../../../../core/api/hooks/query/shiftScheduling/useShiftSchedulingQuery';
import {
    SchedulingBoardTableBodyHeaderItem,
    SchedulingBoardTableHeaderItem, SchedulingBoardTableViewBody, SchedulingBoardTableViewBodyRow,
    SchedulingBoardTableViewContainer,
    SchedulingBoardTableViewRow
} from './style';
import Translation from '../../../../../../components/Translation';
import { Translate } from '../../../../../../internationalization/translate';
import SchedulingBoardViewShift from './SchedulingBoardViewShift';
import { SHIFT_TYPES } from '../../../../components/Dialogs/DefineShiftsForDay/context/d';
import moment from 'moment';
import { TUseOptimizeEventData, useOptimizeEventClick } from '../../../../../../hooks/useOptimizeEventClick';


import { SHIFT_SCHEDULING_TABLE_EVENTS } from '../../d';
import { useModal } from '../../../../../../hooks/useModal';
import DefineUpdateShift from '../../../../components/Dialogs/DefineUpdateShift';
import { useSelector } from 'react-redux';
import { selectSettingsShifts } from '../../../../../../store/app/appSlice';
import PreviewShiftModal from '../../../../components/Dialogs/PreviewShift';
import { getDatesBetweenDates } from '../../../../../../utils';


const SchedulingBoardView = () => {
    const settingsShift = useSelector(selectSettingsShifts);
    const { dateRange } = useContext(PeriodPickerContext) as any;


    const [isDisabledThirdShift] = useMemo(()=>[
        !settingsShift?.[`${SHIFT_TYPES.THIRD_SHIFT}`]
    ] ,[settingsShift]);
    
    const { data, refetch } = useShiftSchedulingQuery(dateRange?.startDate, dateRange?.endDate);

    const tableData = useMemo(()=> {
        return (data || getDatesBetweenDates(dateRange?.startDate, dateRange?.endDate)).map((x: any)=> ({
            ...x,
            [`${SHIFT_TYPES.FIRST_SHIFT}`]: x[`${SHIFT_TYPES.FIRST_SHIFT}`] || [],
            [`${SHIFT_TYPES.SECOND_SHIFT}`]:x[`${SHIFT_TYPES.SECOND_SHIFT}`] || [],
            [`${SHIFT_TYPES.THIRD_SHIFT}`]:x[`${SHIFT_TYPES.THIRD_SHIFT}`] || [],
        }));
    },[data, dateRange]);

    useEffect(() => {
        if(!data) return;
        refetch().then();
    }, [dateRange]);

    const { openModal: openDialogAddNewShift, closeModal: closeAddNewShiftModal } = useModal({
        modal: DefineUpdateShift,
        isActionsButtonsVisible: false,
        isCloseAction: false,
        noPadding: false
    });

    const { openModal: openDialogPreviewShift, closeModal: closeAddPreviewShiftModal } = useModal({
        modal: PreviewShiftModal,
        isActionsButtonsVisible: false,
        isCloseAction: true,
        noPadding: false
    });

    const handlerOpenDialogAddNewShift = useCallback((data: any) => {
        openDialogAddNewShift({
            ...data,
            closeModal: closeAddNewShiftModal
        });
    }, [closeAddNewShiftModal, openDialogAddNewShift]);

    const handlerOpenDialogPreviewShift = useCallback((data: any) => {
        openDialogPreviewShift({
            ...data,
            closeModal: closeAddNewShiftModal
        });
    }, [closeAddPreviewShiftModal, openDialogPreviewShift]);


    const handlerOpenDialogEditShift = useCallback((data: any) => {
        openDialogAddNewShift({
            ...data,
            isUpdate: true,
            closeModal: closeAddNewShiftModal
        });
    }, [closeAddNewShiftModal, openDialogAddNewShift]);


    
    const { onClickHandler } = useOptimizeEventClick({
        eventHandler(data: TUseOptimizeEventData) {
            if(data.action === SHIFT_SCHEDULING_TABLE_EVENTS.EDIT_SHIFT) {
                if(data.param) handlerOpenDialogEditShift(JSON.parse(data.param));
                return;
            }

            if(data.action === SHIFT_SCHEDULING_TABLE_EVENTS.ADD_NEW_SHIFT_ITEM) {
                if(data.param) handlerOpenDialogAddNewShift(JSON.parse(data.param));
                return;
            }

            if(data.action === SHIFT_SCHEDULING_TABLE_EVENTS.PREVIEW_SHIFT) {
                if(data.param) handlerOpenDialogPreviewShift(JSON.parse(data.param));
                return;
            }
        }
    });

    return (
        <SchedulingBoardTableViewContainer>
            <SchedulingBoardTableViewRow>
                <Translation use={Translate.schedulingBoardTableHeaderWeekDays} as={SchedulingBoardTableHeaderItem} />
                <Translation use={Translate.schedulingBoardTableHeaderFirstShift} as={SchedulingBoardTableHeaderItem} />
                <Translation use={Translate.schedulingBoardTableHeaderSecondShift}
                             as={SchedulingBoardTableHeaderItem} />
                <Translation use={Translate.schedulingBoardTableHeaderThirdShift} as={SchedulingBoardTableHeaderItem} />
            </SchedulingBoardTableViewRow>
            <SchedulingBoardTableViewBody onClick={onClickHandler} data-action-root>
                {tableData.map((row: any, index: number) => {
                    return (
                        <SchedulingBoardTableViewBodyRow key={index}>
                            <SchedulingBoardTableBodyHeaderItem>{moment(row?.date).format('DD.MM.YYYY')}</SchedulingBoardTableBodyHeaderItem>
                            <SchedulingBoardViewShift row={row} shiftType={SHIFT_TYPES.FIRST_SHIFT}/>
                            <SchedulingBoardViewShift row={row} shiftType={SHIFT_TYPES.SECOND_SHIFT}/>
                            <SchedulingBoardViewShift row={row} shiftType={SHIFT_TYPES.THIRD_SHIFT} isDisabled={isDisabledThirdShift}/>
                        </SchedulingBoardTableViewBodyRow>
                    );
                })}
            </SchedulingBoardTableViewBody>
        </SchedulingBoardTableViewContainer>
    );
};

export default SchedulingBoardView;