import React, { useCallback, useContext, useMemo } from 'react';
import useModalFormData from 'hooks/useModalFormData';
import { UserBlockDialogContainer, UserBlockDialogHeader, UserBlockDialogText } from 'views/Users/Dialogs/Block/style';
import UiDataContext from 'context/UiContext/context';
import Translation from 'components/Translation';
import { Translate } from 'internationalization/translate';
import useDriverDeleteMutation from 'core/api/hooks/mutations/driver/useDriverDeleteMutation';
import useDriverReactivateMutation from 'core/api/hooks/mutations/driver/useDriverReactivateMutation';
import { errorToast, successToast } from 'components/Utils/Toast';
import { useTranslation } from 'react-i18next';

const DriverDialogActiveInActive = ({ row, setRefetch, closeModal }: any) => {

  const {t} = useTranslation();
  const useDriverDelete= useDriverDeleteMutation();
  const useDriverReactivate= useDriverReactivateMutation();
  const { setLoading, resetLoading } = useContext(UiDataContext);
  const [id, nickname, isActive, title, text] = useMemo(() => [
    row.id,
    `${row?.firstName} ${row?.lastName}`,
    row.active,
    row.active ? Translate.driverInactiveModalTitle : Translate.driverActiveModalTitle,
    row.active ? Translate.driverInactiveModalText : Translate.driverActiveModalText
  ], [row]);

  const handlerOnSubmit = useCallback(async () => {
    if (!id) return false;
    try {
      setLoading();
      const result = isActive ? await useDriverDelete.mutateAsync({ id }) : await useDriverReactivate.mutateAsync({ id });
      if (result?.status === 200) {
        successToast(t(result.message));
        setRefetch();
        closeModal();
      }
    } catch (e: any) {
      console.log(e);
      errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
      return false;
    } finally {
      resetLoading();
    }
    return true;
  }, [id, setLoading, isActive, useDriverDelete, useDriverReactivate, t, setRefetch, closeModal, resetLoading]);

  useModalFormData({
    handlerOnSubmit
  });


  return (
    <UserBlockDialogContainer>
      <Translation use={title} as={UserBlockDialogHeader} />
      <Translation use={text} as={UserBlockDialogText} params={{nickname}} />
    </UserBlockDialogContainer>
  );
};


export default DriverDialogActiveInActive;
