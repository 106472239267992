import { CustomColumnDef } from '../../../../components/Table/d';
import Translation from '../../../../components/Translation';
import React from 'react';
import { formatPriceSymbol } from '../../../../utils';
import RestaurantMenuViewListActions from './component/TableComponent/RestaurantMenuViewListActions';
import RestaurantMenuViewListCategory from './component/TableComponent/RestaurantMenuViewListCategory';
import { dishesTypeOpts } from './component/RestaurantMenuAddManuallyForm';
import RestaurantItemImage from '../../component/TableComponents/ItemImage';

export const MENU_ITEMS_TABLE_EVENTS = {
    REMOVE_ITEM: 'MENU_ITEMS_TABLE_EVENTS_REMOVE_ITEM',
    EDIT_ITEM: 'MENU_ITEMS_TABLE_EVENTS_EDIT_ITEM'
};

export const RestaurantMenuManuallyColumns: CustomColumnDef[] = [
    {
        accessorKey: 'index',
        header: '',
        align: 'center',
        style: {
            minWidth: 45,
            width: '45px'
        },
        cell: (props) =>  props.row.original.position || '1'
    },
    {
        accessorKey: 'avatar',
        header: '',
        align: 'center',
        style: {
            minWidth: 65,
            width: '65px'
        },
        cell: (props) => <RestaurantItemImage row={props.row.original} />
    },
    {
        accessorKey: 'type',
        header: 'restaurantMenuItemType',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '120px',
            width: '120px'
        },
        cell: (props) => <Translation
            use={dishesTypeOpts.find(x => x.value === props.row.original?.type)?.label || ''} />
    },
    {
        accessorKey: 'categoryName',
        header: 'category',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '25%',
            width: '25%'
        },
        cell: (props) => <RestaurantMenuViewListCategory id={props.row.original?.restaurantCategoryId} />
    },
    {
        accessorKey: 'name',
        header: 'name',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '15%',
            width: '15%'
        },
        cell: (props) => `${props.row.original?.name || ''}`
    },
    {
        accessorKey: 'description',
        header: 'description',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '25%',
            width: '25%'
        },
        cell: (props) => `${props.row.original?.description || ''}`
    },
    {
        accessorKey: 'quantity',
        header: 'restaurantMenuItemAmount',
        align: 'left',
        alignHeader: 'right',
        style: {
            minWidth: '10%',
            width: '10%'
        }
    },
    {
        accessorKey: 'price',
        header: 'price',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '10%',
            width: '10%'
        },
        cell: (props) => formatPriceSymbol(props.row.original?.price)
    },
    {
        accessorKey: 'free',
        header: 'free',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '10%',
            width: '10%'
        },
        cell: (props) => <Translation use={props.row?.original?.free ? 'yes' : 'no'} />
    },
    {
        accessorKey: 'actions',
        header: '',
        align: 'center',
        style: {
            minWidth: 90,
            width: 90
        },
        cell: (user: any) => <RestaurantMenuViewListActions row={user.row.original} />
    }
];