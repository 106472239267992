import React from 'react';
import { OrdersViewPartContainer } from './style';
import OrderViewHeader from './OrderViewHeader';
import OrderViewContextContainer from './context';
import OrderViewList from './OrderViewList';


const OrdersViewPart = () => {

    
    return (
        <OrderViewContextContainer>
            <OrdersViewPartContainer>
                <OrderViewHeader />
                <OrderViewList />
            </OrdersViewPartContainer>
        </OrderViewContextContainer>
    );
};

export default OrdersViewPart;