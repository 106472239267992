import axios from 'axios';
import {CONTEXT_TYPES} from 'context/d';

const axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}`
});

export const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem(CONTEXT_TYPES.REFRESH_TOKEN);
    const result = await  axiosClient.post('/refresh-token',{
        token: refreshToken
    });
    return result.data;
};


axiosClient.interceptors.request.use((config) => {
    const token = localStorage.getItem(CONTEXT_TYPES.ACCESS_TOKEN);
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['Access-Control-Expose-Headers'] = 'version-app';
    return config;
});

axiosClient.interceptors.response.use((response) => {
    const version = response.headers['version-app'];
    return {
        ...response,
        version
    } as any;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const { token } = await refreshAccessToken();
        localStorage.setItem(CONTEXT_TYPES.ACCESS_TOKEN, token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return axiosClient(originalRequest);
    }
    return Promise.reject(error);
});

export const transformRequest =  [function (data: any, headers: any) {
    if (headers['Content-Type'] && (headers['Content-Type'] as string).startsWith('multipart/form-data')) {
        const form = new FormData();
        for (const key in data) {
            const value = data[key];
            if(!value) continue;
            if (Array.isArray(value)) {
                const arrayKey = `${key}[]`;
                value.forEach((v) => {
                    form.append(arrayKey, v);
                });
            } else{
                form.append(key, value);
            }
        }
        return form;
    }
    return { data, headers };
}];

export default axiosClient;
