import React, { useMemo } from 'react';
import Translation from 'components/Translation';
import { Translate } from 'internationalization/translate';
import { CategoryStatusBadge, CategoryStatusContainer } from './style';

const CategoryStatus = ({ row }: { row: any }) => {

    const [statusText, isActive] = useMemo(() => [
        row.active !== 1 ? Translate.inactiveCategory : Translate.activeCategory,
        row.active ? 1 : 0
    ], [row]);

    return (
        <CategoryStatusContainer>
            <CategoryStatusBadge active={isActive}>
                <Translation use={statusText} />
            </CategoryStatusBadge>
        </CategoryStatusContainer>
    );
};

export default CategoryStatus;
