
import {
    IconButton,
    IconButtonProps,
    DialogProps,
    Dialog,
    Button, DialogContent
} from '@mui/material';
import { styled } from '@mui/material/styles';
import _styled, {css} from 'styled-components';

import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import { Flex } from 'assets/style';
import {Colors} from '../../theme/palette';

export const DialogContainer = styled((props: DialogProps) => (
    <Dialog  {...props} />
))(() => ({
    '& 	.MuiDialog-paper': {
        maxWidth: '100%',
        background: '#000000',
        borderRadius: 20,
        border: '2px solid #FFD700'
    },
    '& .MuiDialogActions-root': {
        justifyContent: 'center'
    }
}));

export const DialogHeaderTitle = _styled(DialogTitle)`
    &&& {
    font: normal 400 28px/36px 'Readex Pro';
    color: white;
    padding: 15px;
    text-align: center;
    }
`;

export const DialogContentContainer = _styled(DialogContent)<{isPadding?: number}>`
    &&& {
        padding: 0;
        
        ${props => props.isPadding && css`
             padding: 20px 0;
        `}
    }
`;

export const DialogCloseIcon = styled((props: IconButtonProps) => (
    <IconButton  {...props} />
))(() => ({
    '&.MuiIconButton-root': {
        position: 'absolute',
        top: 5,
        right: 5,
        color: 'white',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '30px'
    }
}));



export const DialogButton = _styled(Button)<{primary?: number}>`
    &&& {
          min-width: 120px;
          margin-right: 10px;
          margin-left: 10px;
          border: 1px solid #000000;
          color: #000000;
          font: normal normal normal 24px/30px 'Readex Pro';
          border-radius: 20px;
          background: #FFFFFF;
          padding: 15px 40px;
          text-transform: capitalize;
          
          ${props => props.primary && css`
            background: linear-gradient(180deg, ${Colors.gradient.yellowLight} 0%, ${Colors.gradient.yellowDark} 100%);
          `}
    }
`;


export const DialogButtonsPart = _styled(Flex)`
    justify-content: space-between;
    align-items: center;
    min-width: 450px;
    padding-bottom: 30px;
`;
