import styled from 'styled-components';
import {Box, Button} from '@mui/material';
import {Flex} from '../../assets/style';


export const NotFoundContainer = styled(Box)`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;


export const NotFoundTitle = styled(Flex)`
    padding: 40px 0;
    font: normal 400 40px/50px 'Readex Pro';
`;

export const NotFoundText = styled(Flex)`
    padding-bottom: 80px;
    font: normal 400 30px/37.5px 'Readex Pro';
`;

export const HomeButton = styled(Button)`
    &&& {
        border-radius: 20px;
        padding: 20px;
        background: #FFFFFF;
        border: 1px solid #000000;
        font: normal  400 18px/22.5px 'Readex Pro';
        color: #000000;
    }
`;