import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

export const RestaurantMenuInsertTypeFormTitle = styled(Flex)`
  font: normal 400 36px/46px 'Readex Pro';
  color: #FFFFFF;
  text-align: center;
`;

export const RestaurantMenuInsertTypeExcelIcon = styled(BackupTableIcon)`
  &&& {
    font-size: 48px;
    color: #000000;
  }
`;

export const RestaurantMenuInsertTypeManuallyIcon = styled(NoteAltIcon)`
  &&& {
    font-size: 48px;
    color: #000000;
  }
`;