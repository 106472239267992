import React from 'react';
import { useField } from 'formik';
import Select from 'components/select';



const FormikSelectField = ({ name,...rest } :any)=> {


  const [field, meta] = useField(name);

  const newProps = {
    ...field,
    ...rest,
    error: Boolean(meta.error) && meta.touched ? meta.error : false,
    helperText: Boolean(meta.touched) && meta.error ? meta.error : ''
  };

  const onBlur = (e: any) => {
    field.onBlur(e);
  };

  return (
    <Select
      {...newProps}
      value={newProps.value || ''}
      onBlur={onBlur}
    />
  );
};


export default FormikSelectField;
