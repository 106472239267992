import {AppToolBarRightPart} from 'components/appbar/style';
import UserAvatar from 'components/appbar/appbarRight/UserAvatar';
import ApprovalRequests from './ApprovalRequests';


const AppbarRight = ()=> {


    return (
        <AppToolBarRightPart>
            <ApprovalRequests />
            <UserAvatar/>
        </AppToolBarRightPart>
    );
};

export default AppbarRight;
