

export enum ORDERS_VIEW_ASSIGN_EVENTS {
    SET_SELECTED_DRIVER = 'SET_SELECTED_DRIVER',
    SET_SELECTED_ORDER = 'SET_SELECTED_ORDER',
    RESET_STATE= 'RESET_STATE'
}

export type TOrderAssignViewActions = {
    type: ORDERS_VIEW_ASSIGN_EVENTS,
    payload?: any
}


export type TOrderViewAssignReducer = {
    selectedDriver?: string
    selectedOrders?: string[]
}