import styled              from 'styled-components';
import {TablePagination}            from '@mui/material';
import { Flex } from 'assets/style';


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const TablePaginationContainer = styled(TablePagination).attrs({component: 'div'})`

    &&& {
        min-width: 380px;
        max-height: 40px;
        color: black;

        .MuiTablePagination-actions {
            .MuiButtonBase-root {
                color: black;
            }
        }
        
        .MuiTablePagination-selectLabel {
            display: none;
        }
        .MuiInputBase-root {
            display: none;
        }


        > div {
            
            max-height: 40px;
            min-height: 40px;

            .MuiSelect-select {
                min-height: auto;
            }
        }

    }
`;


export const TablePaginationEmptyContainer = styled(Flex)`
  min-width: 380px;
`;
