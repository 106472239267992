import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  MutableRefObject
} from 'react';

import {
  guid,
  NOOP
} from 'utils';

const DATA = {
  modals: [] as TModalsData[],
  setRenderState: NOOP
};

export type TModalsData = {
  guid: string,
  ModalComponent: React.FC,
  isOpen: boolean,
  onAccept: (a?: any) => void
  onReject: (a?: any) => void
  modalTitle: string,
  setIsOpen: (value: boolean) => void,
  closeModal: () => void,
  setModalTitle: (value: string) => void,
  refProps: MutableRefObject<any>
  refSubmit: MutableRefObject<any>
  isActionsButtonsVisible?: boolean
  isCloseAction?: boolean
  noPadding?: boolean
  buttonCancelLabel?: string,
  buttonSubmitLabel?: string,
}

export const useModalRender = () => {

  const [modelsToRender, setModelsToRender] = useState<TModalsData[]>([]);

  useEffect(() => {
    DATA.setRenderState = (a: any) => setModelsToRender([...a]);
    return () => {
      DATA.setRenderState = NOOP;
    };
  }, [setModelsToRender]);

  return modelsToRender;

};

export const useModal = ({
                           onAccept = NOOP,
                           onReject = NOOP,
                           modal: ModalComponent,
                           isActionsButtonsVisible,
                           isCloseAction,
                           noPadding = true,
                           buttonCancelLabel,
                           buttonSubmitLabel,
                         }: {
  onAccept?: (a: any) => void,
  onReject?: (a: any) => void,
  modal: React.FC<any>,
  isActionsButtonsVisible?: boolean,
  isCloseAction?: boolean,
  noPadding?: boolean,
  buttonCancelLabel?: string,
  buttonSubmitLabel?: string,
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const refGuid = useRef(guid());
  const refProps = useRef({});
  const refSubmit = useRef({});

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const _onReject = useCallback((a?: any) => {
    closeModal();
    onReject(a);
  }, [onReject, closeModal]);

  const setModalTitle = useCallback((modalTitle: string) => {
    const modalIndex = DATA.modals.findIndex(m => m.guid === refGuid.current);
    if (modalIndex === -1) return;
    const modal = DATA.modals[modalIndex];
    DATA.modals.splice(modalIndex, 1, {
      ...modal,
      modalTitle
    });
    DATA.setRenderState(DATA.modals);
  }, [refGuid]);

  useEffect(() => {

    DATA.modals = [...DATA.modals, {
      guid: refGuid.current,
      setIsOpen,
      modalTitle: '',
      refProps: refProps,
      ModalComponent,
      onAccept,
      onReject: _onReject,
      closeModal,
      refSubmit,
      setModalTitle,
      isOpen: false,
      isActionsButtonsVisible,
      isCloseAction,
      noPadding,
      buttonCancelLabel,
      buttonSubmitLabel
    }];

    DATA.setRenderState(DATA.modals);

    return () => {
      DATA.modals = DATA.modals.filter(s => s.setIsOpen !== setIsOpen);
      DATA.setRenderState(DATA.modals);
    };

  }, [isActionsButtonsVisible, isCloseAction, refSubmit, setIsOpen, ModalComponent, refGuid, refProps, onAccept, _onReject, closeModal, setModalTitle, noPadding]);

  useEffect(() => {
    const modalIndex = DATA.modals.findIndex(m => m.guid === refGuid.current);
    if (modalIndex === -1) return;
    const modal = DATA.modals[modalIndex];
    if (modal.isOpen === isOpen) return;
    DATA.modals.splice(modalIndex, 1, {
      ...modal,
      isOpen
    });
    DATA.setRenderState(DATA.modals);
  }, [isOpen, refGuid]);

  const openModal = useCallback((dataProps = {}) => {
    refProps.current = dataProps || {};
    setIsOpen(true);
  }, [setIsOpen, refProps]);

  return useMemo(() => ({
    openModal,
    closeModal
  }), [openModal, closeModal]);

};
