

export enum ORDER_DRIVER_VIEW_EVENTS {
    CLEAR_ALL_FILTER = 'CLEAR_ALL_FILTER',
    ADD_FILTER = 'ADD_FILTER',
    CLEAR_FILTER = 'CLEAR_FILTER',
    CLEAR_NEED_REFETCH = 'CLEAR_NEED_REFETCH',
    SET_NEED_REFETCH = 'SET_NEED_REFETCH'
}

export type TOrderDriverViewActions = {
    type: ORDER_DRIVER_VIEW_EVENTS,
    payload?: any
}

export const initOrderDriverViewState = {
    filter: {}
};

export type TOrderDriverViewReducer = {
    filter?: any
    isNeedRefetch?: number
}