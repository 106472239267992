import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { Flex } from '../../assets/style';
import Button from '../../components/button';


/**
 *
 * DEV COMMENT
 * @author Aleksandar Djordjevic
 * @date 29.03.2024
 *
 * This style for views with table can be a one style what can use on every this view
 *
 */
export const VehiclesContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 5px 10px;
`;

export const VehiclesHeader = styled(Flex)`
    padding: 15px 25px;
    justify-content: space-between;
    align-items: center;
    min-height: 76px;
`;

export const VehicleHeaderAddButton = styled(Button)`
    &&& {
        display: flex;
        justify-content: space-between;
        min-width: 85px;
        max-width: 140px;
        gap: 15px;
        padding: 10px 25px;
        color: black;
        border-radius: 10px;
        font-family: Readex Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        .MuiButton-startIcon {
            margin-right: 0;
            width: 24px;
            height: 24px;
        }
    }
`;

export const VehiclesHeaderType = styled(Typography).attrs({
    variant: 'h4'
})`
    color: #FFF;
    text-align: center;
    font: normal 400 40px/normal 'Readex Pro';
`;

export const VehiclesTableContainer = styled(Flex)`
    flex: 2;
    width: 100%;
    padding: 15px 20px;


    @media (max-width: 1921px) {
        min-width: 1800px;
        max-width: 1800px;
    }

    @media (max-width: 1800px) {
        min-width: 1500px;
        max-width: 1700px;
    }
`;
