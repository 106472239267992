import { IS_TEST } from '../../../../config';
import * as yup from 'yup';
import { Translate } from '../../../../internationalization/translate';
export enum MENU_IMPORT_TYPE {
    EXCEL= 0x10,
    MANUAL= 0x20
}
export const initialValuesMenuInsertForm = IS_TEST ? {
    menuImportType: 0,
} : {
    menuImportType: 0,
};

export const validationRestaurantMenuInsertTypeForm =   yup.object({
    menuImportType: yup.number()
        .required(Translate.menuImportTypeRequired)
        .test('menu-import-type-allows-values',
            Translate.notAllowedValues,
            value => !![MENU_IMPORT_TYPE.EXCEL,MENU_IMPORT_TYPE.MANUAL].includes(+value))
});