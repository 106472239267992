
export const Colors = {
  gradient: {
    yellowLight: '#FFF200',
    yellowDark: '#dbb902',
    yellowLighter: '#FDF783'
  },
  black: '#000000',
  red: '#DD3533',
  orange: '#ff9100',
  green: '#59E24D',
  green70: '#59E24DB2',
  green90: 'rgba(33,124,25,0.7)',
  blue: '#0F52FF',
  blue70: '#0F52FFB2',
  white: '#FFFFFF',
  grey: '#666666',
  grey60: '#666666',
  grey70: '#666666B2',
  lightGrey: '#D9D9D9',
  greenDark: '#59E24DB2',
  redLight: 'rgba(221,53,51,0.5)',
};


export const palette = {
  light: {
    primary: {
      light: '#FFF200',
      dark: '#FFD700',
      main: '#FFF200',
    /*  yellow: 'linear-gradient(to right, #FFF200, #FFD700)',
      grey: '#666666',
      lightGrey: '#D9D9D9',
      red: '#DD3533',
      white: '#FFFFFF',*/
    },
    success: {
      main: '#11D600',
      light: '#11D600',
      dark: '#11D600',
    },
    secondary: {
      light: '#FFFFFF',
      dark: '#FFFFFF',
      main: '#FFF200',
    },
    error: {
      main: '#DD3533',
      light: '#DD3533',
      dark: '#DD3533',
    },
    divider: '#ffffff',
    background: {
      default: '#000000',
      paper: '#000000'
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
      disabled: '#D9D9D9'
    }
  }
};
