import styled from 'styled-components';
import { Flex } from '../../../../assets/style';
import { Form } from 'formik';

export const DefineVehicleContainer = styled(Flex)`
    min-width: 700px;
    max-width: 700px;
    min-height: 680px;
    max-height: 700px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 
`;

export const VehicleFormContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 2;
`;

export const VehicleFormTitle = styled(Flex)`
  font: normal 400 36px/46px 'Readex Pro';
  color: #FFFFFF;
`;

export const VehicleFormTitleContainer = styled(Flex)`
  margin: 20px 
`;

export const VehicleFormInputsContainer = styled(Flex)`
    padding: 0 125px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

export const VehicleFormikContainer = styled(Form)`
    display: flex;
    padding-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
`;