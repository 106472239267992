import React, { useContext } from 'react';
import Pagination from 'components/Table/Pagination';
import TableDataContext from 'components/Table/context/context';
import { LeftPart, RightPart, TableFooterContainer, TableMiniProgress } from 'components/Table/TableFooter/style';

const TableFooter = ({ totalRecords, rowsPerPageOptions, FooterChildren, isMiniLoading }: {
  csvFileName?: string,
  title?: string,
  totalRecords: number,
  rowsPerPageOptions?: Array<number | { value: number; label: string }>,
  modelName?: string,
  FooterChildren?: any
  isMiniLoading?: boolean
}) => {

  const { page, perPage, setPage, setPerPage } = useContext(TableDataContext);

  return (
    <TableFooterContainer>
      <LeftPart>
      </LeftPart>
      {FooterChildren ? FooterChildren : null}
      <RightPart>
        {isMiniLoading ? <TableMiniProgress size={'20px'} /> : null}
        <Pagination
          totalRecords={totalRecords}
          page={page}
          perPage={perPage}
          setPerPage={setPerPage}
          setPage={setPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </RightPart>
    </TableFooterContainer>
  );
};


export default TableFooter;
