import Panel from '../../../../components/Panel';
import {
    RestaurantMenuAddManuallyAddPartContainer,
    RestaurantMenuAddManuallyContainer,
    RestaurantMenuAddManuallyContentContainer, RestaurantMenuAddManuallyViewPartContainer
} from './style';
import RestaurantMenuAddManuallyForm from './component/RestaurantMenuAddManuallyForm';
import RestaurantMenuViewManuallyForm from './component/RestaurantMenuViewManuallyForm';
import RestaurantContextContainer from '../context';
import { useParams } from 'react-router-dom';

const DefineRestaurantMenuManually = () => {
    const { id } = useParams();

    return (
        <RestaurantMenuAddManuallyContainer>
            <Panel>
                <RestaurantContextContainer>
                    <RestaurantMenuAddManuallyContentContainer>
                        <RestaurantMenuAddManuallyAddPartContainer>
                            <RestaurantMenuAddManuallyForm id={id}  />
                        </RestaurantMenuAddManuallyAddPartContainer>
                        <RestaurantMenuAddManuallyViewPartContainer>
                            <RestaurantMenuViewManuallyForm id={id} />
                        </RestaurantMenuAddManuallyViewPartContainer>
                    </RestaurantMenuAddManuallyContentContainer>
                </RestaurantContextContainer>
            </Panel>
        </RestaurantMenuAddManuallyContainer>
    );
};

export default DefineRestaurantMenuManually;