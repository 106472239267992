import React, { useCallback, useState } from 'react';
import { CustomerPhoneFieldContainer, CustomerPhoneFieldInvalid } from './style';
import FormikTextField from 'components/Formik/FormikTextField';
import { Translate } from 'internationalization/translate';
import useOrderCheckPhoneMutation from '../../../../../../../core/api/hooks/mutations/order/useOrderCheckPhoneMutation';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';


const CustomerPhoneField = () => {
    const {t} = useTranslation();
    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const checkPhoneMutation = useOrderCheckPhoneMutation();
    const checkPhone = useCallback(async (phone: string)=> {
        try {
            if(!phone) return;
            const result = await checkPhoneMutation.mutateAsync({customerPhone: phone});
            setIsInvalid(!!result?.data);
        }catch (e) {
            console.log(e);
        }
    },[checkPhoneMutation, setIsInvalid]);


    return (
        <CustomerPhoneFieldContainer>
            {isInvalid ?
                <Tooltip title={t(Translate.defineOrderCustomerPhoneInvalid)} placement={'top'}>
                    <CustomerPhoneFieldInvalid />
                </Tooltip>: <></>}
            <FormikTextField
                name={'customerPhone'}
                variant={'outlined'}
                label={Translate.defineOrderCustomerPhoneLabel}
                withCbFn
                cbFn={checkPhone}
            />
        </CustomerPhoneFieldContainer>
    );
};


export default CustomerPhoneField;