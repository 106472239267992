import React, {useCallback,useMemo, useContext} from 'react';
import useModalFormData from 'hooks/useModalFormData';
import UiDataContext from 'context/UiContext/context';
import Translation from 'components/Translation';
import {useTranslation} from 'react-i18next';
import {errorToast, successToast} from 'components/Utils/Toast';
import { useSelector } from 'react-redux';
import { selectGetDriver, selectGetVehicle } from '../../../../../store/app/appSlice';
import { Translate } from '../../../../../internationalization/translate';
import { SHIFT_SCHEDULING_STATUS } from '../../../view/SchedulingBoard/d';
import useTemporaryStopShiftsMutation
    from '../../../../../core/api/hooks/mutations/shiftScheduling/useTemporaryStopShiftsMutation';
import {
    ShiftSchedulingTempStopResumeDialogContainer,
    ShiftSchedulingTempStopResumeDialogHeader,
    ShiftSchedulingTempStopResumeDialogSubText,
    ShiftSchedulingTempStopResumeDialogText
} from './style';
const ShiftSchedulingTemporaryStopDialog = ({ guid, id, status, vehicleId, driverId, setRefetch, closeModal}: any) => {

    const {t} = useTranslation();
    const shiftTemporaryStopMutation = useTemporaryStopShiftsMutation();
    const {setLoading, resetLoading} = useContext(UiDataContext);
    const driver = useSelector(selectGetDriver(driverId));
    const vehicle = useSelector(selectGetVehicle(vehicleId));
    const [driverName, vehicleName, isTempCanStop] = useMemo(() => [
        driver?.fullName,
        vehicle?.name,
        status !== SHIFT_SCHEDULING_STATUS.TEMP_STOP
    ], [driver, vehicle, status]);

    const handlerOnSubmit = useCallback(async () => {
        if (!id || !isTempCanStop) return false;
        try {
            setLoading();
            const result = await shiftTemporaryStopMutation.mutateAsync({
                id
            });
            if (result?.status === 200) {
                setRefetch();
                successToast(t(result.message));
                closeModal();
            }
        } catch (e: any) {
            console.log(e);
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
        return true;
    }, [id, setLoading, shiftTemporaryStopMutation, t, guid, isTempCanStop, setRefetch, closeModal, resetLoading]);

    useModalFormData({
        handlerOnSubmit
    });


    return (
        <ShiftSchedulingTempStopResumeDialogContainer>
            <Translation use={Translate.shiftSchedulingTempStopTitle} as={ShiftSchedulingTempStopResumeDialogHeader}/>
            <Translation use={Translate.shiftSchedulingTempStopText} as={ShiftSchedulingTempStopResumeDialogText}/>
            <Translation use={Translate.shiftSchedulingTempStopDriverText} as={ShiftSchedulingTempStopResumeDialogSubText} params={{name: driverName}}/>
            <Translation use={Translate.shiftSchedulingTempStopVehicleText} as={ShiftSchedulingTempStopResumeDialogSubText} params={{name: vehicleName}}/>
        </ShiftSchedulingTempStopResumeDialogContainer>
    );
};


export default ShiftSchedulingTemporaryStopDialog;
