import { EmptyShiftsContentContainer, EmptyShiftsContentText } from './style';
import Translation from '../../../../../../../components/Translation';
import { Translate } from '../../../../../../../internationalization/translate';


const EmptyShiftsContent = () => {


    return (
        <EmptyShiftsContentContainer>
           <Translation use={Translate.defineShiftsEmptyItems} as={EmptyShiftsContentText} />
        </EmptyShiftsContentContainer>
    );
};

export default EmptyShiftsContent;