import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';
import Panel from '../../../../../components/Panel';


export const CategorySectionContainer = styled(Flex)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-width: 90vw;
    min-height: 85vh;
    height: 85vh;
`;


export const CategorySectionTitle = styled(Flex)`
    font: normal 400 36px/46px 'Readex Pro';
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 30px;
`;

export const CategorySectionDefineUpdateForm = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    height: 100%;
    border-right: 1px solid #FFFFFF;
    flex: 1;
`;

export const CategorySectionTablePanel = styled(Panel)`
    &&& {
        flex: 2;
        width: 100%;
    }
`;

export const CategorySectionTableView = styled(Flex)`
    flex: 1.5;
    padding: 25px;
    display: flex;
    flex-direction: column;
    height: 100%;
    
    .MuiTable-root {
        max-height: 100%;
    }
`;

