import styled, { css } from 'styled-components';
import { Box, Tabs, Tab } from '@mui/material';
import { Colors } from 'theme/palette';




export const TabHeaderContainer = styled(Box)`
  &&& {
    background-color: inherit;
    display: inline-flex;
    margin-top: 0;
    min-height: 40px;
    width: 100%;
    z-index: 1300;
  }
`;
export const TabsContainer = styled.div<{ istopheader?: number, isabsolute?: number, isopen?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  ${props => props.istopheader && css`
    flex-direction: column-reverse;
  `}
  
  ${props => props.isabsolute && css`
    
    ${TabHeaderContainer}{
        transition: linear 0.3s;
        position: absolute;
        top: 15px;
        left:  180px;
        width: auto !important;
    }
  `}

  ${props => props.isabsolute &&  props.isopen && css`
    
    ${TabHeaderContainer}{
        position: absolute;
        top: 15px;
        left: 310px;
        width: auto !important;
    }
  `}
  
`;


export const MaterialTabs = styled(Tabs).attrs({ variant: 'scrollable', indicatorColor: undefined })`
  &&& {
    background-color: inherit;
    padding: 0 5px;
    
    .MuiTabs-indicator {
      display: none;
    }
  }
`;

export const TabHeaderTab = styled(Tab)`
  &&& {
    border-bottom: none;
    border-radius: 0;
    border-top: none;
    flex: initial;
    font-size: 13px;
    font-weight: 500;
    height: 39px;
    min-width: 90px;
    outline: 3px solid #0000;
    text-transform: none;
    width: auto;
    color: #FFFFFF;
    border: none;

    &:hover {
      box-shadow: 0px -4px 5px -4px black;
      color: ${Colors.gradient.yellowDark};
      opacity: 0.6;
    }

    &.Mui-selected {
      color: ${Colors.gradient.yellowDark};
      text-decoration: underline;
      box-shadow: 0 1px 3px 1px #3c404326;
    }
  }
`;

export const TabContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 5px 10px;
`;


export const TabPanelContainer = styled.div`
  flex: 2;
  height: 100%;
  width: 100%;
`;
