import { SettingsNavigationList, SettingsNavigationContainer } from './style';
import SettingsNavigationItem from './SettingsNavigationItem';
import { Translate } from '../../../../internationalization/translate';





const SettingsNavigation = () => {
    
    
    return (
        <SettingsNavigationContainer>
            <SettingsNavigationList>
                <SettingsNavigationItem path={'/settings/shifts'} text={Translate.settingsShiftSchedulingLabel}/>
            </SettingsNavigationList>
        </SettingsNavigationContainer>
    );
};

export default SettingsNavigation;