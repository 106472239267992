import { toast } from 'react-toastify';
export const errorToast = (message: string) => {
    return toast(message, {
        type: 'error',
        theme: 'colored',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
    });
};

export const successToast = (message?: string) => {
    return toast(message || 'Success', {
        type: 'success',
        theme: 'colored',
        position: 'top-center',
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
    });
};

export const warningToast = (message?: string) => {
    return toast(message || 'Warning', {
        type: 'warning',
        theme: 'colored',
        position: 'top-center',
        autoClose: 3500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
    });
};
