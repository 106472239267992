import styled   from 'styled-components';
import {
    Box,
    Popover
}               from '@mui/material';
import { Flex } from 'assets/style';
import ButtonIcon from '../button/ButtonIcon';


export const PopoverHoverBox = styled(Flex)`
    width: 100%;
    height: 100%;
`;

export const PopoverHoverChildBox = styled(Box)`
  width: 100%;
  height: 100%;
`;

export const StyledPopover = styled(Popover) `
  &&& {
    font-size: 12px;
  }
`;

export const PopoverButtonStyled = styled(ButtonIcon)`
    &&& {
        width: 30px;
        height: 30px;
        
        svg {
            color: white;
        }
        
    }
`;
