import styled from 'styled-components';
import { Flex } from '../../../../../../../assets/style';


export const ResetMenuDialogContainer = styled(Flex)`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 450px;
    max-width: 450px;
`;

export const ResetMenuDialogHeader = styled(Flex)`
    text-align: center;
    font: normal normal 400 40px/50px 'Readex Pro';
`;

export const ResetMenuDialogText = styled(Flex)`
    max-width: 90%;
    padding-top: 30px;
    text-align: center;
    font: normal normal normal 24px/30px 'Readex Pro';

`;
