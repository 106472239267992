import { CustomColumnDef } from '../../components/Table/d';
import Avatar from 'components/Avatar';
import { vehicleOwnership, vehicleType } from '../../utils';
import Translation from '../../components/Translation';
import React from 'react';
import VehicleAction from './component/TableComponent/Action';
import VehicleStatus from './component/TableComponent/VehicleStatus';

export const extendedVehiclesColumns = [
    { accessorKey: 'blocked' },
    { accessorKey: 'active' }
];

export const VehiclesColumns: CustomColumnDef[] = [
    {
        accessorKey: 'avatar',
        header: '',
        align: 'center',
        style: {
            minWidth: 65,
            width: '65px'
        },
        cell: (props) => <Avatar row={props.row.original} />
    },
    {
        accessorKey: 'name',
        header: 'Ime',
        align: 'left',
        style: {
            minWidth: '25%',
            width: '25%'
        },
        cell: (props) => `${props.row.original?.name}`
    },
    {
        accessorKey: 'year',
        header: 'Godina',
        align: 'left',
        style: {
            minWidth: '15%',
            width: '15%'
        },
        cell: (props) => `${props.row.original?.year || ''}`
    },
    {
        accessorKey: 'registrationPlate',
        header: 'Reg. oznaka',
        align: 'left',
        style: {
            minWidth: '25%',
            width: '25%'
        },
        cell: (props) => `${props.row.original?.registrationPlate || ''}`
    },
    {
        accessorKey: 'vehicleNumber',
        header: 'Broj vozila',
        align: 'left',
        style: {
            minWidth: '10%',
            width: '10%'
        },
        cell: (props) => `${props.row.original?.vehicleNumber}`
    },
    {
        accessorKey: 'vehicleType',
        header: 'Tip',
        align: 'left',
        style: {
            minWidth: '10%',
            width: '10%'
        },
        cell: (props) => <Translation use={vehicleType(props.row.original?.vehicleType)} />
    },
    {
        accessorKey: 'vehicleOwner',
        header: 'Vlasnistvo',
        align: 'left',
        style: {
            minWidth: '10%',
            width: '10%'
        },
        cell: (props) => <Translation use={vehicleOwnership(props.row.original?.vehicleOwner)} />
    },
    {
        header: 'Status',
        style: {
            minWidth: 120,
            width: 120
        },
        cell: (user: any) => <VehicleStatus row={user.row.original} />
    },
    {
        accessorKey: 'actions',
        header: '',
        style: {
            minWidth: 90,
            width: 90
        },
        cell: (user: any) => <VehicleAction row={user.row.original} />
    }
];