import React, { useContext, useEffect } from 'react';
import {
  SpinnerContainer,
  SpinnerText,
  SpinnerProgress, SpinnerDingDong
} from 'components/Spinner/style';
import Translation from 'components/Translation';
import { Translate } from 'internationalization/translate';
import UiDataContext from 'context/UiContext/context';
import loadingGif from 'assets/images/loadergif.gif';

export const Spinner = ({ text }: { text?: string }) => {

  return (
    <SpinnerContainer>
      <SpinnerProgress size={'4rem'} />
      <Translation as={SpinnerText} use={text ? text : Translate.loadingMessage} />
    </SpinnerContainer>
  );
};

export const MiniSpinner = ({className}: {className?: string}) => {
  return <SpinnerProgress  className={className} size={'24px'} />;
};



export const SpinnerDing = () => {
  return(
    <SpinnerContainer>
      <SpinnerDingDong src={loadingGif} alt={'Loading'} />
    </SpinnerContainer>
  );
};


const SpinnerCenter = () => {

  const { isLoading, timer, resetLoading} = useContext(UiDataContext);

  useEffect(() => {
    let th = 0;
    if (timer) {
      th = setTimeout(() => resetLoading(), timer) as any;
    }
    return () => {
      clearTimeout(th);
    };
  }, [timer, resetLoading]);

  return isLoading ? <SpinnerDing /> : <></>;

};

export default SpinnerCenter;
