import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePickerContainer } from './style';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';


const Timepicker = ({
                        format = 'HH:mm',
                        label,
                        ampm = false,
                        ...rest
                    }: any) => {
    const {t} = useTranslation();
    const _label = useMemo(()=> label ? t(label) : '' ,[label]);
    return (
        <DatePickerContainer>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker format={format} ampm={ampm} label={_label} {...rest} />
            </LocalizationProvider>
        </DatePickerContainer>

    );
};


export default Timepicker;