import styled from 'styled-components';
import {IconButton} from '@mui/material';
import { Colors } from '../../../../theme/palette';

export const SliderButton = styled(IconButton)`
    &&& {
        background: linear-gradient(180deg, ${Colors.gradient.yellowLight} 0%, ${Colors.gradient.yellowDark} 100%);
        border: 1px solid #000;
        border-radius: 20px;
        color: #000;
        padding: 10px;
        text-transform: none;
        font-family: "Readex Pro";
        font-size: 24px;
        line-height: 30px;
        font-style: normal;
        font-weight: 400;

        &:hover {
            border-width: 1px;
        }
    }
`;

export const SliderInput = styled.input`
    height: 7px;
    
    &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }
    
    &::-webkit-slider-thumb {
     
    }

`;