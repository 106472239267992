import React from 'react';
import { SettingsContainer, SettingsHeader, SettingsHeaderType, SettingsViewContainer } from './style';
import Translation from '../../components/Translation';
import { Translate } from '../../internationalization/translate';
import Panel from '../../components/Panel';
import SettingsLayout from './view/SettingsLayout';

const Settings = () => {
    return (
        <SettingsContainer>
            <SettingsHeader>
                <Translation use={Translate.settingsPageTitle} as={SettingsHeaderType} />
            </SettingsHeader>
            <SettingsViewContainer>
                <Panel>
                    <SettingsLayout />
                </Panel>
            </SettingsViewContainer>
        </SettingsContainer>
    );
};


export default Settings;
