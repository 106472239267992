import {Navigate, Outlet} from 'react-router-dom';
import { useStateContext } from 'context/ContextProvider';
import {GuestLayoutContainer, GuestLayoutContent, GuestLayoutHeader, GuestLayoutImage} from 'layouts/style';
import pattern from 'assets/images/pattern.png';
import {isMobile} from 'react-device-detect';
import React from 'react';

const GuestLayout =() =>{
    const { token } = useStateContext();

    if(isMobile) {
        return <Navigate to="/only-desktop"/>;
    }

    if (token) {
        return <Navigate to="/" />;
    }

    return (
        <GuestLayoutContainer id="guestLayout">
            <GuestLayoutImage src={pattern}/>
            <GuestLayoutHeader>
                Dispičer app
            </GuestLayoutHeader>
            <GuestLayoutContent>
                <Outlet />
            </GuestLayoutContent>
        </GuestLayoutContainer>
    );
};



export default GuestLayout;
