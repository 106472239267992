import styled, { css } from 'styled-components';
import {
    TableCell
}                      from '@mui/material';
import { blueGrey }    from '@mui/material/colors';
import FilterListIcon  from '@mui/icons-material/FilterList';
import MenuIcon        from '@mui/icons-material/Menu';
import TuneIcon        from '@mui/icons-material/Tune';
import ButtonIcon      from 'components/button/ButtonIcon';
import { Flex } from 'assets/style';
import { Colors } from 'theme/palette';



export const THCellContainer = styled(TableCell)<{ index: number}>`
    position: relative;

    &.MuiTableCell-root {
        position: sticky;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        cursor: pointer;
        background: #FFFFFF;
        font: normal normal 400 24px/30px 'Readex Pro';
        color: black;
        padding: 30px 0;
        border-bottom: 2px solid ${Colors.lightGrey};
    }
`;
export const THCellLabelContainer = styled(Flex)<{align?: string}>`
  flex: 1;
  justify-content: ${props => props.align  || 'flex-start'};
  align-items: center;
  position: relative;
    
    
`;

export const ThCellLabel = styled.div`
  
`;

export const THCellLabelSortContainer = styled(Flex)<{ isFilterSort?: boolean }>`
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  ${props  => props.isFilterSort && css`
    padding-right: 15px;
  `}
`;

export const ThCellFilterButtonSortIcon = styled(FilterListIcon)`
  &&& {
    font-size: inherit;
  }
`;

export const ThCellFilterButton = styled(ButtonIcon)<{ isActive?: boolean, _asc?: boolean }>`
  &&& {
    font-size: 12px;
    padding: 1px;
    color: ${blueGrey['300']};

    ${props => props?.isActive && css`
      color: white;
      transform: scale(1.4);
      border: 0.5px solid rgba(255, 255, 255, 0.3);
    `}
  }

  ${ThCellFilterButtonSortIcon} {
    &&& {
      font-size: inherit;
    }

    ${props => props?._asc && css`
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    `}
  }
`;
export const ThCellFilterButtonIcon = styled(MenuIcon)`
  &&& {
    font-size: inherit;
  }
`;

export const ThCellFilterButtonFilterIcon = styled(TuneIcon)`
  &&& {
    font-size: inherit;
  }
`;

