import React, { useCallback } from 'react';
import { OrdersContainer, OrdersHeader, OrdersHeaderAddButton, OrdersHeaderType, OrdersViewsContainer } from '../style';
import Translation from '../../../components/Translation';
import { Translate } from '../../../internationalization/translate';
import AddIcon from '@mui/icons-material/Add';
import OrdersViewPart from './views/OrdersViewPart';
import OrdersDriverPart from './views/OrdersDriverPart';
import OrderViewAssignContextContainer from './views/context';


const OrdersView = ({ setShowForm }: any) => {

    const handlerOpenDialogDefineOrder = useCallback(() => {
        setShowForm(true);
    }, [setShowForm]);


    return (
        <OrdersContainer>
            <OrdersHeader>
                <Translation use={Translate.homePageTitle} as={OrdersHeaderType} />
                <OrdersHeaderAddButton
                    onClick={handlerOpenDialogDefineOrder}
                    startIcon={<AddIcon />}
                >
                    <Translation use={Translate.homeOrderAddButtonTitle} />
                </OrdersHeaderAddButton>
            </OrdersHeader>
            <OrdersViewsContainer>
                <OrderViewAssignContextContainer>
                    <OrdersViewPart />
                    <OrdersDriverPart />
                </OrderViewAssignContextContainer>
            </OrdersViewsContainer>
        </OrdersContainer>
    );
};

export default OrdersView;