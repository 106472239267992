import styled, { css } from 'styled-components';
import { Flex } from 'assets/style';
import ButtonIcon from 'components/button/ButtonIcon';
import { Colors } from 'theme/palette';
import { backgroundYellowGradient, colorBlack } from 'theme/base';



export const PreviewShiftContainer = styled(Flex)<{isEnded?: number}>`
    flex-direction: column;
    gap: 10px;
    padding: 10px 15px;  
    background: transparent;
    border-radius: 10px;
    ${props => props.isEnded && css`
        ${colorBlack};
        ${backgroundYellowGradient};
        svg {
            ${colorBlack};
        }
    `}
`;

export const PreviewShiftRowContainer = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    font: normal normal 400 20px/26px "Readex Pro";
    letter-spacing: 0.4px;
`;

export const PreviewShiftCollapseContainer = styled(Flex)`
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font: normal normal 400 16px/22px "Readex Pro";
    letter-spacing: 0.4px;
`;

export const PreviewShiftRowItem = styled(Flex)`
    flex: 2;
`;

export const PreviewShiftRowStatus = styled(Flex)`
    flex: 1;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 25px;
`;

export const PreviewShiftRowStatusText = styled(Flex)`
    flex: 1;
`;

export const PreviewShiftRowButtonAction = styled(Flex)`
    &&& {
        gap: 15px;
    }
`;

export const PreviewShiftRowCollapseButton = styled(ButtonIcon)`
    &&& {
        width: 30px;
        height: 30px;
        padding: 4px;
        
        svg {
            font-size: 30px;
            color: ${Colors.white};
        }
    }
`;


