import axiosClient from 'core/api/config/axios';
import {useQuery} from '@tanstack/react-query';
import {createUrl} from 'core/api/utils/createUrl';


const useRestaurantWholeCategoriesQuery =  (restaurantId: number)=> {
    const { data } = useQuery({
        queryKey: [`restaurant--whole-categories-${restaurantId}`],
        queryFn: async () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const res: any = await axiosClient.get(createUrl({url: `/dispatcher/restaurant-categories/${restaurantId}`, version: 'v1'}));
            return res.data?.data  || [];
        },
    });
    return data;
};

export default useRestaurantWholeCategoriesQuery;
