import * as yup from 'yup';
import { Translate } from 'internationalization/translate';
import { IS_TEST } from '../../../../../config';


export const initialValuesEditForm = IS_TEST ?  {
  firstName: 'Stefan',
  lastName: 'Milutinovic',
  email: 'stefan.milutinovic.rs+driver4@gmail.com',
  phoneCode: '',
  phone: ''
}: {
  firstName: '',
  lastName: '',
  email: '',
  phoneCode: '',
  phone: ''
};
export const validationEditDriverForm =  yup.object({
  firstName: yup.string()
      .required(Translate.firstNameRequired)
      .min(1,Translate.firstNameMinLength)
      .max(40, Translate.firstNameMaxLength)
      .matches(/^[A-ZČĆĐŠŽ].*$/, Translate.firstNameCapitalize),
  lastName: yup.string()
      .required(Translate.lastNameRequired)
      .min(1,Translate.lastNameRequired)
      .max(40, Translate.lastNameMaxLength)
      .matches(/^[A-ZČĆĐŠŽ].*$/, Translate.lastNameCapitalize),
  email: yup.string()
    .email(Translate.invalidEmail)
    .required(Translate.emailRequired)
});


