import styled, { css } from 'styled-components';
import { Flex } from '../../../../../assets/style';
import { Colors } from '../../../../../theme/palette';
import { Box } from '@mui/material';


export const OrdersViewDriverPartContainer = styled(Flex)`
    flex: 1;
    flex-direction: column;
    gap: 20px;
    height: 100%;
`;

export const OrderViewHeaderContainer = styled(Flex)`
    min-height: 40px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid ${Colors.gradient.yellowDark};
`;

export const OrderViewHeaderItem = styled(Flex)`
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
`;
export const OrderViewHeaderItemCircle = styled(Flex)<{color?: string, active?: number}>`
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: relative;
    border: 2px solid ${props => props?.color || 'transparent'};
    cursor: pointer;
    
    ${props => props.active && css`
        background: ${props?.color || 'transparent'};
    `}
`;


export const OrderViewHeaderItemText = styled(Flex)`
    font: normal 400 18px/24px 'Readex Pro';
`;



export const OrderViewListContainer = styled(Box)`
    flex: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: 1px solid ${Colors.gradient.yellowDark};
    padding: 20px;
    gap: 15px;
`;

export const OrderViewDriverMapContainer = styled(Flex)`
    flex: 2;
    width: 100%;  
`;
