import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';


const useLoginMutation = () => {
    const mutation = useMutation({
        mutationFn: async (data: { email: string; password: string }) => {
            const {email, password} = data;
            const response = await axiosClient.post(createUrl({url: '/sign-in'}), {
                    email,
                    password,
            }, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-account-type': '1'
                },
            });
            return response.data;
        }
    });
    return mutation;
};

export default useLoginMutation;
