import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import DefaultLayout from 'layouts/DefaultLayout';
import NotFound from 'views/NotFound';
import GuestLayout from 'layouts/GuestLayout';
import Login from 'views/Login';
import Vehicles from 'views/Vehicles';
import Schedule from 'views/Schedule';
import Settings from 'views/Settings';
import Accounts from 'views/Accounts';
import OnlyDesktop from '../views/OnlyDesktop';
import RestaurantMenu from 'views/RestaurantMenu';
import DefineRestaurantMenuManually from '../views/RestaurantMenu/view/DefineRestaurantMenuManually';
import SettingsShifts from '../views/Settings/view/Shifts';
import Orders from '../views/Orders';

const router = createBrowserRouter([
    {
        path: '/',
        element: <DefaultLayout />,
        children: [
            {
                path: '/',
                element: <Navigate to="/home" />
            },
            {
                path: '/accounts',
                element: <Accounts />
            },
            {
                path: '/vehicles',
                element: <Vehicles />
            },
            {
                path: '/schedule',
                element: <Schedule />
            },
            {
                path: '/settings/shifts',
                element: <Settings />,
                children: [
                    {
                        path: '/settings/shifts',
                        element: <SettingsShifts />
                    }
                ]
            },
            {
                path: '/restaurant-menu',
                element: <RestaurantMenu />
            },
            {
                path: '/restaurant-menu-add-manually/:id',
                element: <DefineRestaurantMenuManually />
            },
            {
                path: '/home',
                element: <Orders />
            },
        ]
    },
    {
        path: '/',
        element: <GuestLayout />,
        children: [
            {
                path: '/login',
                element: <Login />
            }
        ]
    },
    {
        path: '*',
        element: <NotFound />
    },
    {
        path: '/only-desktop',
        element: <OnlyDesktop />
    }
]);


export default router;
