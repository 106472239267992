import { DefineShiftForDayContent, DefineShiftsForDayContainer } from './style';
import ShiftSchedulingForDateContextContainer from './context';
import ShiftsForDateTabs from './components/ShiftsForDateTabs';
import ShiftForDayShiftsContent from './components/ShiftForDayShiftsContent';
import ShiftForDayHeader from './components/ShiftForDayHeader';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Translate } from '../../../../../internationalization/translate';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ShiftSchedulingForDayContext from './context/context';
import { format } from 'date-fns';
import useCreateShiftsByDateMutation
    from '../../../../../core/api/hooks/mutations/shiftScheduling/useCreateShiftsByDateMutation';
import UiDataContext from '../../../../../context/UiContext/context';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

const DefineShiftsForDayForm = ({ closeModal }: any) => {

    const {t} = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const createShiftsByDate = useCreateShiftsByDateMutation();
    const { shifts } = useContext(ShiftSchedulingForDayContext);
    const queryClient = useQueryClient();

    const shiftSchema = yup.object().shape({
        shiftDate: yup.string().required(Translate.requiredField)
    });
    const [newValidationSchema, setNewValidationSchema] = useState(shiftSchema);
    const [initValues, setInitValues] = useState({
        shiftDate: ''
    });

    useEffect(() => {
        if (!shifts) return;
        let obj = {};
        const shiftsValidations = Object.values(shifts).map(x => x).flat().reduce((acc: any, x: any) => {
            const dd = {
                [`${x.shiftId}_vehicleId_${x.id}`]: yup.string().required(Translate.requiredField),
                [`${x.shiftId}_driverId_${x.id}`]: yup.string().required(Translate.requiredField),
                [`${x.shiftId}_amount_${x.id}`]: yup.string().required(Translate.requiredField)
            };
            obj = {
                ...obj,
                ...dd
            };
            return acc.concat(
                yup.object().shape(dd));
        }, yup.object().shape({}));
        setNewValidationSchema(shiftSchema.concat(shiftsValidations as any));
        setInitValues({
            shiftDate: ''
        });
    }, [shifts]);

    const onSubmit =  useCallback(async (values: any) => {
        const newMap = new Map();
        Object.keys(values).forEach(x=> {
            if(x !== 'shiftDate') {
                const [shiftType, field, id] = (() => {
                    const arr = x.split('_');
                    return [Number(arr[0]), arr[1], arr[2]];
                })();
                const [_field, value] = (()=> {
                   const isAmount = field === 'amount';
                   const value = values[x];
                    return [
                        !isAmount ? field : 'shiftStartAmount',
                        !isAmount ? value : Number(value?.replace(/\./gmi, '').replace(/,/gi, '.'))
                    ];
                })();
                
                const valueMap = newMap.get(id);

                newMap.set(id, Object.assign({
                    [_field]: value,
                    shiftType
                }, valueMap ? {...valueMap} : {}));
            }
        });
        const payload = {
            shiftDate: format(values.shiftDate, 'yyyy-MM-dd'),
            shifts: Array.from(newMap.values())
        };
        try {
            const results = await createShiftsByDate.mutateAsync(payload);
            if (results?.status === 200) {
                successToast(t(results.message));
                await queryClient.invalidateQueries({queryKey: ['shift-scheduling']});
                closeModal();
            }
        }catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        }finally {
            resetLoading();
        }
    }, [t, closeModal, queryClient, setLoading, resetLoading, createShiftsByDate]);


    return (
        <Formik
            initialValues={initValues}
            validationSchema={newValidationSchema}
            onSubmit={onSubmit}
        >
            <Form>
                <DefineShiftsForDayContainer>
                    <ShiftForDayHeader />
                    <DefineShiftForDayContent>
                        <ShiftsForDateTabs />
                        <ShiftForDayShiftsContent closeModal={closeModal} />
                    </DefineShiftForDayContent>
                </DefineShiftsForDayContainer>
            </Form>
        </Formik>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DefineShiftsForDay = ({ closeModal }: any) => {


    return (
        <ShiftSchedulingForDateContextContainer>
            <DefineShiftsForDayForm closeModal={closeModal} />
        </ShiftSchedulingForDateContextContainer>
    );
};


export default DefineShiftsForDay;