import { SelectProps }      from '@mui/material/Select/Select';
import { SvgIconComponent } from '@mui/icons-material';

export const selectDropdownMaxHeight = 350;

export type TSelectOptionIcon = {
    icon: SvgIconComponent,
    color?: string
    isComponent?: boolean
}

export type TSelectOptions = {
    value: string|number
    label?: string
    icon?: TSelectOptionIcon
    component?: any
    disabled?: boolean
}


export type TSelectProps = {
    options: TSelectOptions[],
    required?: boolean
    label?: string,
    fullWidth?: boolean
    onClear?: ()=> void
    useHelperText?: boolean
    error?: any
    labelIcon?: boolean
    loading?: boolean
    helperText?: any
    isDark?: boolean
} & SelectProps



export type TSelectPhoneCodeProps = {
    required?: boolean
    fullWidth?: boolean
    onClear?: ()=> void
    helperText?: string
    useHelperText?: boolean
    error?: string|boolean
    labelIcon?: boolean
    loading?: boolean
} & SelectProps
