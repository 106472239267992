import React, { useCallback, useContext } from 'react';
import { ResetMenuDialogContainer, ResetMenuDialogHeader, ResetMenuDialogText } from './style';
import Translation from '../../../../../../../components/Translation';
import { Translate } from '../../../../../../../internationalization/translate';
import useModalFormData from '../../../../../../../hooks/useModalFormData';
import { errorToast, successToast } from '../../../../../../../components/Utils/Toast';
import UiDataContext from '../../../../../../../context/UiContext/context';
import { useTranslation } from 'react-i18next';
import useCreateRestaurantMenuMutation
    from '../../../../../../../core/api/hooks/mutations/restaurantMenu/useCreateRestaurantMenuMutation';
import { useNavigate } from 'react-router-dom';
import { omit } from 'lodash';
import { getFileBase64 } from '../../../../../../../utils';


const MenuFinishForm = ({closeModal, tableList, id, resetState}: any) => {
    const {t} = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const createRestaurantMenu = useCreateRestaurantMenuMutation();
    const navigate = useNavigate();
    

    const handlerOnSubmit = useCallback(async () => {
        try {

            const list = [] as any;
            for (const x of tableList) {
                const file = x.file ? await getFileBase64(x.file) : undefined;
                const obj = Object.assign({
                    ...omit(x, ['id', 'type'])
                }, file ? {
                    file,
                    fileName: x.file.name,
                    mimetype: x.file.type,
                } : {});
                list.push(obj);
            }
            setLoading();
            const result = await createRestaurantMenu.mutateAsync({
                id,
                items: list
            });
            if (result?.status === 200) {
                successToast(t(result.message));
                resetState();
                closeModal();
                navigate(-1);
            }
        }catch (e: any) {
            console.log(e);
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
        return true;
    }, [setLoading, tableList, t, resetState, navigate, closeModal, resetLoading]);

    useModalFormData({
        handlerOnSubmit
    });


    return (
        <ResetMenuDialogContainer>
            <Translation use={Translate.restaurantMenuFinishDialogTitle} as={ResetMenuDialogHeader} />
            <Translation use={Translate.restaurantMenuFinishDialogText} as={ResetMenuDialogText} />
        </ResetMenuDialogContainer>
    );
};

export default MenuFinishForm;