import React from 'react';
import { VersionCheckerButton, VersionCheckerFormContainer, VersionCheckerText } from './style';
import { useTranslation } from 'react-i18next';
import { Translate } from '../../internationalization/translate';
import Translation from '../Translation';
import useModalFormData from '../../hooks/useModalFormData';


const VersionCheckerForm = ()=> {
    const {t} = useTranslation();

    useModalFormData({
        modalTitle: t(Translate.versionCheckerTitle)
    });


    const handlerOnClick = () => window.location.reload();

    return (
        <VersionCheckerFormContainer>
            <Translation use={Translate.versionCheckerText} as={VersionCheckerText} />
            <VersionCheckerButton onClick={handlerOnClick}>{t(Translate.reload)}</VersionCheckerButton>
        </VersionCheckerFormContainer>
    );
};


export default VersionCheckerForm;