import {Navigate, Outlet} from 'react-router-dom';
import {useStateContext} from 'context/ContextProvider';
import React, {useEffect} from 'react';
import useAuthUserQuery from 'core/api/hooks/query/useAccountQuery';
import AppBar from 'components/appbar';
import Drawer from 'components/drawer';
import {DefaultLayoutContainer, DefaultLayoutContent} from 'layouts/style';
import {isMobile} from 'react-device-detect';
import { useAppDispatch } from '../store/hooks';
import { setVersion, setDrivers, setVehicles, setSettings } from '../store/app/appSlice';
import ModalContainer from '../components/Dialog/ModalContainer';
import useGetDriversQuery from '../core/api/hooks/query/useGetDriversQuery';
import useGetVehiclesQuery from '../core/api/hooks/query/useGetVehiclesQuery';
import useGetSettingsQuery from '../core/api/hooks/query/useGetSettingsQuery';


const Header = () => {
    const {open, setOpen} = useStateContext();

    const toggleDrawer = () => {
        setOpen(v => !v);
    };

    return (
        <>
            <AppBar open={open} toggleDrawer={toggleDrawer}/>
            <Drawer open={open} toggleDrawer={toggleDrawer}/>
        </>
    );

};


const DefaultLayout = () => {
    const dispatch = useAppDispatch();
    const {token, user, setUser, open} = useStateContext();

    const userAuth = useAuthUserQuery();
    const useDrivers = useGetDriversQuery();
    const useVehicles = useGetVehiclesQuery();
    const useSettings = useGetSettingsQuery();

    useEffect(() => {
        if (!useDrivers || !(useDrivers as any)?.data?.rows?.length) return;
        dispatch(setDrivers(useDrivers.data?.rows));
    }, [useDrivers, dispatch]);

    useEffect(() => {
        if (!useSettings) return;
        dispatch(setSettings(useSettings.data));
    }, [useSettings, dispatch]);


    useEffect(() => {
        if (!useVehicles || !(useVehicles as any)?.data?.rows?.length) return;
        dispatch(setVehicles(useVehicles.data?.rows));
    }, [useVehicles, dispatch]);

    useEffect(() => {
        if (!userAuth?.id || (user as any)?.id) return;
        dispatch(setVersion(userAuth.version));
        setUser(userAuth);
    }, [userAuth, setUser, user, dispatch]);



    if(isMobile) {
        return <Navigate to="/only-desktop"/>;
    }

    if (!token) {
        return <Navigate to="/login"/>;
    }

    return (
        <DefaultLayoutContainer id="defaultLayout">
            <Header />
            <DefaultLayoutContent open={open}>
                <Outlet/>
            </DefaultLayoutContent>
            <ModalContainer/>
        </DefaultLayoutContainer>
    );
};


export default DefaultLayout;
