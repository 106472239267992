import React from 'react';
import { THCellContainer, ThCellLabel, THCellLabelContainer, THCellLabelSortContainer } from './style';
import { omit } from 'lodash';
import Translation from 'components/Translation';
import { flexRender } from '@tanstack/react-table';

const ThCell = ({
                    column,
                    align = 'left',
                    index
                }: any) => {

    return (
        <THCellContainer variant={'head'} align={column?.column?.columnDef?.alignHeader || align} style={{
            ...omit(column.column.columnDef?.style || {}, ['padding', 'paddingTop', 'paddingBottom'])
        }} index={index}>
            <THCellLabelContainer align={column?.column?.columnDef?.alignHeader || align}>
                <THCellLabelSortContainer>
                    <Translation as={ThCellLabel} use={flexRender(
                        column.column.columnDef.header,
                        column.getContext()
                    )} />
                </THCellLabelSortContainer>
            </THCellLabelContainer>
        </THCellContainer>
    );
};

export default ThCell;
