import { Flex } from 'assets/style';
import styled from 'styled-components';
import Button from 'components/button';


export const ShiftFinishPopoverContainer = styled(Flex)`
    padding: 10px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ShiftFinishPopoverText = styled(Flex)`
    margin: 15px 0;
    font: normal normal normal 16px/22px "Readex Pro";
`;

export const ShiftFinishPopoverButtons = styled(Flex)`
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    padding: 15px 0;
`;


export const ShiftFinishPopoverButton = styled(Button)`
    &&& {
        min-width: 100px;
        max-width: 120px;
        font-size: 14px;
        line-height: 18px;
        padding: 8px 16px;
    }
`;