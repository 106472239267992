import {ButtonIconContainer} from 'components/button/style';
import {PropsWithChildren} from 'react';
import { useTranslation } from 'react-i18next';


const ButtonIcon = ({children, title, ...rest}: PropsWithChildren<any>) => {
    const {t} = useTranslation();
    
    return (
        <ButtonIconContainer
            title={t(title)}
            {...rest}
        >
            {children}
        </ButtonIconContainer>
    );
};

export default ButtonIcon;
