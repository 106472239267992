import {createContext, useCallback, useContext, useState} from 'react';
import {CONTEXT_TYPES} from 'context/d';
import { APP_VERSION } from '../config';


const StateContext = createContext({
    user: null,
    token: null,
    open: false,
    refreshToken: null,
    appVersion: '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setUser: (_user: any) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setToken: (_token: string) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setRefreshToken: (_token: string) => {},
    clearData: () => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setOpen: (_open: (v: boolean) => boolean) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setAppVersion: (_value: string) => {},
});

export const ContextProvider = ({children}: any) => {
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState({} as any);
    const [appVersion ,setAppVersion] = useState(APP_VERSION);
    const [token, _setToken] = useState(localStorage.getItem(CONTEXT_TYPES.ACCESS_TOKEN));
    const [refreshToken, _setRefershToken] = useState(localStorage.getItem(CONTEXT_TYPES.REFRESH_TOKEN));

    const setToken =  useCallback((token: string|null) => {
        _setToken(token);
        if (token) {
            localStorage.setItem(CONTEXT_TYPES.ACCESS_TOKEN, token);
        } else {
            localStorage.removeItem(CONTEXT_TYPES.ACCESS_TOKEN);
        }
    },[_setToken]);

    const setRefreshToken =  useCallback((token: string|null) => {
        _setRefershToken(token);
        if (token) {
            localStorage.setItem(CONTEXT_TYPES.REFRESH_TOKEN, token);
        } else {
            localStorage.removeItem(CONTEXT_TYPES.REFRESH_TOKEN);
        }
    },[_setRefershToken]);


    const clearData = useCallback(()=> {
        setToken(null);
        setRefreshToken(null);
        setUser(null);
    },[setToken, setRefreshToken, setUser]);

    return (
        <StateContext.Provider value={{
            user,
            open,
            setUser,
            token,
            setToken,
            refreshToken,
            setRefreshToken,
            clearData,
            setOpen,
            appVersion,
            setAppVersion
        } as any}>
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);
