import React from 'react';
import { useField } from 'formik';
import AddressSearchGoogle from './AddressSearchGoogle';


const FormikGoogleSearchAddress = ({name, changeData, ...rest}: any) => {

    const [field, meta] = useField(name);

    const newProps = {
        ...field,
        ...rest,
        error: Boolean(meta.error) && meta.touched ? meta.error : false,
        helperText: Boolean(meta.touched) && meta.error ? meta.error : ''
    };

    const onBlur = (e: any) => {
        field.onBlur(e);
    };

    const onChange = (value: any) => {
        field.onChange({
            target: {
                value: value?.description
            }
        });
        changeData && changeData({
            [name || 'address']: value?.description,
            [name ? `${name}Object` : 'addressObject']: value
        });
    };


    return (
        <AddressSearchGoogle
            {...newProps}
            value={newProps.value || ''}
            onBlur={onBlur}
            onChange={onChange}
        />
    );
};

export default FormikGoogleSearchAddress;