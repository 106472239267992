import styled from 'styled-components';
import { Flex } from '../../../../../../assets/style';
import SearchInput from '../../../../../../components/input/SearchInput';


export const OrderViewListHeaderContainer = styled(Flex)`
    min-height: 40px;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
`;


export const OrderViewListHeaderTitle = styled(Flex)`
    
`;

export const OrderViewListHeaderSearch = styled(SearchInput)`
    
`;