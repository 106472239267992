import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';


const useReactivateRestaurantCategoryMutation = () => {
    const mutation = useMutation({
        mutationFn: async ({ id }: { id: number }) => {
            const response = await axiosClient.patch(createUrl({
                url: `/dispatcher/restaurant/category/reactivate/${id}`,
                version: 'v1',
            }), {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
        }
    });
    return mutation;
};

export default useReactivateRestaurantCategoryMutation;
