import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';
import { Form } from 'formik';

export const ChangeSettingsShiftsContainer = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 54px;
    min-width: 450px;
    max-width: 450px;
`;


export const ChangeSettingsShiftsForm = styled(Form)`
  min-width: 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 45px;
`;

export const ChangeSettingsShiftPickersContainer = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    
    > div {
        flex: 1;
    }
`;