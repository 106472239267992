import {
    PreviewShiftRowContainerHeader,
    PreviewShiftRowItem,
    PreviewShiftRowStatus,
    PreviewShiftRowStatusText
} from './style';
import { Translate } from '../../../../../../../internationalization/translate';
import Translation from '../../../../../../../components/Translation';


const PreviewShiftRowHeader = () => {

    
    return (
        <PreviewShiftRowContainerHeader>
            <Translation use={Translate.driver} as={PreviewShiftRowItem} />
            <PreviewShiftRowStatus>
                <Translation use={Translate.status} as={PreviewShiftRowStatusText} />
            </PreviewShiftRowStatus>
        </PreviewShiftRowContainerHeader>
    );
};

export default PreviewShiftRowHeader;