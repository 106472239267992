import React, { ChangeEvent, MouseEvent, useCallback, useEffect } from 'react';
import { TTablePaginationProps } from 'components/Table/d';
import { TablePaginationContainer } from 'components/Table/Pagination/style';

const Pagination = ({
                        perPage,
                        page,
                        setPage,
                        setPerPage,
                        totalRecords,
                        rowsPerPageOptions = [1, 10, 15, 20, 25, 50, 100]
                    }: TTablePaginationProps) => {

    const onPageChange = useCallback((event: MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
    }, [setPage]);

    const onRowsPerPageChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setPerPage(Number(event.target.value) || 10);
    }, [setPerPage]);

    const maxPage = totalRecords && perPage !== -1 ? (Math.ceil(totalRecords / perPage) - 1) : 0;
    const isPaginationNotToRender = !totalRecords || maxPage < page;

    useEffect(() => {
        if (maxPage < page) setPage(maxPage);
    }, [setPage, maxPage, page]);

    if (isPaginationNotToRender) return <></>;

    return (
        <TablePaginationContainer
            count={totalRecords || 0}
            page={totalRecords ? page : 0}
            onPageChange={onPageChange}
            rowsPerPage={perPage}
            onRowsPerPageChange={onRowsPerPageChange}
            rowsPerPageOptions={rowsPerPageOptions}
        />
    );
};

export default Pagination;
