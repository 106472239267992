
export enum OrderViewActionsListEvents {
    CHANGE_EDIT_PRICE = 'CHANGE_EDIT_PRICE',
    EDIT_ORDER_INFO = 'EDIT_ORDER_INFO',
    ADD_STOP = 'ADD_STOP',
    PREVIEW_ORDER = 'PREVIEW_ORDER',
    ABORT_ORDER = 'ABORT_ORDER',
    CUSTOMER_NOT_SHOW_UP = 'CUSTOEMR_NOT_SHOW_UP',
    SET_TO_PENDING = 'SET_TO_PENDING',
}

export enum OrderDriverViewActionsListEvents {
    ASSIGNED_DRIVER = 'ASSIGNED_DRIVER'
}