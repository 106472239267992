import React, { useCallback, useContext, useMemo } from 'react';
import TableDataContext from '../../../../../components/Table/context/context';
import { useModal } from '../../../../../hooks/useModal';
import ButtonIcon from '../../../../../components/button/ButtonIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    UserActionMenu,
    UserActionMenuItem,
    UserActionMenuItemIcon
} from '../../../../Users/TableComponents/Actions/style';
import ConditionalRendering from '../../../../../components/Utils/ConditionalRendering';
import Translation from '../../../../../components/Translation';
import { Translate } from '../../../../../internationalization/translate';
import { ListItemText } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import VehicleDialogActiveInActive from '../../Dialogs/ActiveInActive';
import VehicleEditForm from '../../Dialogs/Edit';
import VehiclesDialogEnableDisable from '../../Dialogs/EnableDisabled';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
const VehicleAction = ({row}: {row: any}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
    const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    }, [setAnchorEl]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const { setRefetch } = useContext(TableDataContext);

    const [isEnabled, isActive] = useMemo(() => [
        row.enabled,
        row.active
    ], [row]);

    const { openModal: openDialogEnableDisable, closeModal: closeModalEnableDisable } = useModal({
        modal: VehiclesDialogEnableDisable,
        isActionsButtonsVisible: true,
        isCloseAction: false
    });

    const handlerOpenDialogEnableDisableVehicle = useCallback(() => {
        openDialogEnableDisable({
            row,
            setRefetch,
            closeModal: closeModalEnableDisable
        });
        handleClose();
    }, [row, setRefetch, openDialogEnableDisable, handleClose, closeModalEnableDisable]);


    const { openModal: openDialogDeleteVehicle, closeModal: closeModalDelete } = useModal({
        modal: VehicleDialogActiveInActive,
        isActionsButtonsVisible: true,
        isCloseAction: false
    });

    const handlerOpenDialogDeleteVehicle = useCallback(() => {
        openDialogDeleteVehicle({
            row,
            setRefetch,
            closeModal: closeModalDelete
        });
        handleClose();
    }, [row, setRefetch, openDialogDeleteVehicle, handleClose, closeModalDelete]);

    const { openModal: openDialogEditVehicle, closeModal: closeModalEdit } = useModal({
        modal: VehicleEditForm,
        isActionsButtonsVisible: false,
        isCloseAction: true
    });

    const handlerOpenDialogEditVehicle = useCallback(() => {
        openDialogEditVehicle({
            row,
            setRefetch,
            closeModal: closeModalEdit
        });
        handleClose();
    }, [openDialogEditVehicle, row, setRefetch, closeModalEdit, handleClose]);

    return(
        <>
            <ButtonIcon
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </ButtonIcon>
            <UserActionMenu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <ConditionalRendering condition={isActive}>
                    <UserActionMenuItem
                        disableRipple
                        onClick={handlerOpenDialogEnableDisableVehicle}
                        divider
                    >
                        <UserActionMenuItemIcon>
                            {isEnabled ? <RemoveCircleOutlineIcon/> : <CheckCircleOutlineIcon/>}
                        </UserActionMenuItemIcon>
                        <Translation use={isEnabled ? Translate.unavailable : Translate.available} as={ListItemText}/>
                    </UserActionMenuItem>
                </ConditionalRendering>
                <ConditionalRendering condition={isActive}>
                    <UserActionMenuItem
                        disableRipple
                        onClick={handlerOpenDialogEditVehicle}
                        divider
                    >
                        <UserActionMenuItemIcon>
                            <EditIcon/>
                        </UserActionMenuItemIcon>
                        <Translation use={Translate.editText} as={ListItemText}/>
                    </UserActionMenuItem>
                </ConditionalRendering>
                <UserActionMenuItem
                    disableRipple
                    onClick={handlerOpenDialogDeleteVehicle}
                >
                    <UserActionMenuItemIcon>
                        {isActive ? <DeleteIcon/> : <AutorenewIcon/>}
                    </UserActionMenuItemIcon>
                    <Translation use={isActive ? Translate.deleteText : Translate.activateText} as={ListItemText}/>
                </UserActionMenuItem>
            </UserActionMenu>
        </>
    );
};

export default VehicleAction;