import React from 'react';
import { useField } from 'formik';
import InputNumber from 'components/input/InputNumber';


const FormikInputNumber = ({ name, maxLength, ...rest }: any) => {

    const [field, meta] = useField(name);

    const newProps = {
        ...field,
        ...rest,
        error: Boolean(meta.error) && meta.touched ? meta.error : false,
        helperText: Boolean(meta.touched) && meta.error ? meta.error : ''
    };

    const onBlur = (e: any) => {
        field.onBlur && field.onBlur(e);
    };

    return <InputNumber
        type={'text'}
        {...newProps}
        value={newProps.value || ''}
        onBlur={onBlur}
        maxLength={maxLength}
    />;
};


export default FormikInputNumber;
