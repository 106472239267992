import React, { FC, useCallback, useContext } from 'react';
import { SetToPendingContainer, SetToPendingText } from './style';
import useModalFormData from '../../../../../hooks/useModalFormData';
import { Translate } from '../../../../../internationalization/translate';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import { queryClient } from '../../../../../core/root/ReactQueryProvider';
import UiDataContext from '../../../../../context/UiContext/context';
import { useTranslation } from 'react-i18next';
import Translation from '../../../../../components/Translation';
import useSetToPendingOrderMutation from '../../../../../core/api/hooks/mutations/order/useSetToPendingOrderMutation';

type Props = {
    id: number|string
    closeModal: () => void,
    order: any
}

const OrderSetToPending: FC<Props> = ({
   id,
   closeModal,
   order
}) => {

    const { setLoading, resetLoading } = useContext(UiDataContext);
    const {t} = useTranslation();
    const setToPendingOrderMutation = useSetToPendingOrderMutation();
    
    const handlerOnSubmit = useCallback(async () => {
        if (!id) return false;
        try {
            setLoading();
            const result = await setToPendingOrderMutation.mutateAsync({
                id
            });
            if (result?.status === 200) {
                successToast(t(result.message));
                await queryClient.invalidateQueries({queryKey: ['get-orders']});
                await queryClient.invalidateQueries({queryKey: ['get-current-drivers']});
                closeModal();
            }
        }catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
            return false;
        }finally {
            resetLoading();
        }
        return true;
    },[id, setLoading, resetLoading, setToPendingOrderMutation]);

    useModalFormData({
        modalTitle: t(Translate.orderSetToPendingTitle, {dailyNumber: order?.dailyNumber}),
        handlerOnSubmit
    });

    return (
        <SetToPendingContainer>
            <Translation use={'orderSetToPendingText'} as={SetToPendingText}/>
        </SetToPendingContainer>
    );
};

export default OrderSetToPending;