import { CustomColumnDef } from '../../components/Table/d';
import Translation from '../../components/Translation';
import { dishesTypeOpts } from './view/DefineRestaurantMenuManually/component/RestaurantMenuAddManuallyForm';

import { formatPriceSymbol } from '../../utils';
import React from 'react';
import RestaurantItemImage from './component/TableComponents/ItemImage';
import RestaurantItemStatus from './component/TableComponents/Status';
import RestaurantMenuItemActions from './component/TableComponents/Actions';

export const RestaurantMenuColumns: CustomColumnDef[] = [
    {
        accessorKey: 'index',
        header: '',
        align: 'center',
        style: {
            minWidth: 45,
            width: '45px'
        },
        cell: (props) => props.row.original.posNumber || '1'
    },
    {
        accessorKey: 'avatar',
        header: '',
        align: 'center',
        style: {
            minWidth: 65,
            width: '65px'
        },
        cell: (props) => <RestaurantItemImage row={props.row.original} />
    },
    {
        accessorKey: 'name',
        header: 'name',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '15%',
            width: '15%'
        },
        cell: (props) => `${props.row.original?.name || ''}`
    },
    {
        accessorKey: 'categoryName',
        header: 'category',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '25%',
            width: '25%'
        },
        cell: (props) => props.row.original.categoryName
    },
    {
        accessorKey: 'position',
        header: 'restaurantMenuItemPosition',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '100px',
            width: '100px'
        },
        cell: (props) => props.row.original.position
    },
    {
        accessorKey: 'type',
        header: 'restaurantMenuItemType',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '120px',
            width: '120px'
        },
        cell: (props) => <Translation
            use={dishesTypeOpts.find(x => x.value === `${props.row.original?.type}`)?.label || ''} />
    },
    {
        accessorKey: 'description',
        header: 'description',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '25%',
            width: '25%'
        },
        cell: (props) => `${props.row.original?.description || ''}`
    },
    {
        accessorKey: 'quantity',
        header: 'restaurantMenuItemAmount',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '150px',
            width: '10%'
        },
        cell: (props) => props.row.original?.quantity
    },
    {
        accessorKey: 'price',
        header: 'price',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '10%',
            width: '10%'
        },
        cell: (props) => formatPriceSymbol(props.row.original?.price)
    },
    {
        accessorKey: 'free',
        header: 'free',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '150px',
            width: '150px'
        },
        cell: (props) => <Translation use={props.row?.original?.free ? 'yes' : 'no'} />
    },
    {
        accessorKey: 'is18Plus',
        header: 'is18Plus',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '60px',
            width: '60px'
        },
        cell: (props) => <Translation use={props.row?.original?.is18Plus ? 'yes' : 'no'} />
    },
    {
        accessorKey: 'active',
        header: 'status',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '120px',
            width: '120px',
        },
        cell: (props) => <RestaurantItemStatus row={props.row.original}/>
    },
    {
        accessorKey: 'actions',
        header: '',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '90px',
            width: '90px',
        },
        cell: (props) => <RestaurantMenuItemActions row={props.row.original}/>
    },
];