import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import placeholder from 'assets/images/img-placeholder.png';
import { readFile } from './helpers/cropImage';
import ImageCropModalContent from './ImageCropModalContent';
import { useImageCropContext } from './context';
import { OnlyDialog } from '../../Dialog';
import {
    CameraIcon,
    ImageCropPreview,
    ImageCropPreviewButton,
    ImageCropPreviewContainer,
    ImageCropPreviewImage,
    InputHidden
} from './style';

const ImageCrop = ({ image, handlerSave, isNeedReset }: any) => {
    const imgInputRef = useRef(null);
    const [openModal, setOpenModal] = useState(false);
    const [preview, setPreview] = useState(placeholder);

    const { file, getProcessedImage, setImage, setFile, resetStates } = useImageCropContext();

    const imagePreviewSrc = useMemo(() => preview, [preview]);

    const handleDone = async () => {
        const avatar = await getProcessedImage();
        const obj = window.URL.createObjectURL(avatar);
        setPreview(obj);
        resetStates();
        setOpenModal(false);
        file && handlerSave(file);
    };

    const handleFileChange = async ({ target: { files } }: any) => {
        const file = files && files[0];
        const imageDataUrl = await readFile(file);
        setImage(imageDataUrl);
        setFile(file);
        if(imgInputRef.current) {
            if (imgInputRef.current) {
                (imgInputRef.current as any).type = 'text';
                (imgInputRef.current as any).value = '';
                (imgInputRef.current as any).type = 'file';
            }
        }
        setOpenModal(true);
    };

    const cropPreviewPhotoButtonClick = () => {
        const imgInput = imgInputRef?.current as any;
        if (!imgInput) return;
        imgInput.click();
    };
    
    const handleClose = useCallback(()=> {
        setPreview(placeholder);
        resetStates();
        setOpenModal(false);
    },[]);

    useEffect(() => {
        if(!isNeedReset) return;
        setPreview(placeholder);
    }, [isNeedReset, setPreview]);


    useEffect(() => {
        if (!image) return;
        setPreview(image);
    }, [image]);

    return (
        <ImageCropPreviewContainer>
            <InputHidden
                type="file"
                onChange={handleFileChange}
                ref={imgInputRef}
                id="avatarInput"
                accept="image/*"
            />
            <ImageCropPreview>
                <ImageCropPreviewImage
                    onClick={()=> (imgInputRef as any)?.current?.click()}
                    src={imagePreviewSrc}
                    className="object-cover rounded-full h-48 w-48"
                    alt=""
                />
                <ImageCropPreviewButton onClick={cropPreviewPhotoButtonClick}><CameraIcon /></ImageCropPreviewButton>
            </ImageCropPreview>
            <OnlyDialog
                Context={ImageCropModalContent}
                contextProps={{
                    handleDone,
                    handleClose
                }}
                isOpen={openModal}
                onCloseDialog={() => setOpenModal(false)}
                handlerClose={handleClose}
            />
        </ImageCropPreviewContainer>
    );
};

export default ImageCrop;