import React, { PropsWithChildren, useCallback } from 'react';
import { AccordionSlots } from '@mui/material/Accordion';
import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Translation from '../Translation';
import Fade from '@mui/material/Fade';
import { CollapseContainer, CollapseDetails, CollapseSummary } from './style';

const Collapse = ({label, children}: PropsWithChildren<any>) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpansion = useCallback(() => {
        setExpanded((prevExpanded) => !prevExpanded);
    },[setExpanded]);
    
    return (
        <CollapseContainer
            expanded={expanded}
            onChange={handleExpansion}
            slots={{ transition: Fade as AccordionSlots['transition'] }}
            slotProps={{ transition: { timeout: 400 } }}
            sx={{
                '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
            }}
        >
            <CollapseSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Translation use={label} as={Typography} />
            </CollapseSummary>
            <CollapseDetails>
                {children}
            </CollapseDetails>
        </CollapseContainer>
    );
};

export default Collapse;