import React from 'react';
import {
    RestaurantMenuListButton,
    RestaurantMenuListDeleteIcon,
    RestaurantMenuListEditIcon,
    RestaurantMenuListRow
} from './style';
import { MENU_ITEMS_TABLE_EVENTS } from '../../../def';

const RestaurantMenuViewListActions = ({ row }: any) => {

    return (
        <RestaurantMenuListRow>
            <RestaurantMenuListButton
                data-action={MENU_ITEMS_TABLE_EVENTS.EDIT_ITEM}
                data-action-id={row.id}
            >
                <RestaurantMenuListEditIcon />
            </RestaurantMenuListButton>
            <RestaurantMenuListButton
                data-action={MENU_ITEMS_TABLE_EVENTS.REMOVE_ITEM}
                data-action-id={row.id}
            >
                <RestaurantMenuListDeleteIcon />
            </RestaurantMenuListButton>
        </RestaurantMenuListRow>
    );
};
export default RestaurantMenuViewListActions;