import styled, { css } from 'styled-components';
import { Flex } from '../../../../../../../assets/style';
import Button from 'components/button';
import { Typography } from '@mui/material';

export const DefineShiftForDayTabsContent = styled(Flex)<{isNeedMaxWidth?: number}>`
    flex: 2;
    flex-direction: column;
    padding: 0 20px;
    
    ${props => props.isNeedMaxWidth && css`
        max-width: 900px;
    `}
`;

export const DefineShiftTabsContentHeader = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
`;

export const DefineShiftTabsContentHeaderText = styled(Typography).attrs({
    variant: 'h4'
})`
    color: #FFF;
    text-align: center;
    font: normal normal 400 16px/24px 'Readex Pro';
`;


export const DefineShiftTabsContentAddNewRecordButton = styled(Button)`
    &&& {
        display: flex;
        justify-content: space-between;
        min-width: 85px;
        max-width: 140px;
        gap: 15px;
        padding: 10px 25px;
        color: black;
        border-radius: 10px;
        font-family: Readex Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        .MuiButton-startIcon {
            margin-right: 0;
            width: 24px;
            height: 24px;
        }
    }
`;

export const DefineShiftsTabsContentContainer = styled.div`
    flex: 2;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const DefineShiftsTabsFooter = styled(Flex)`
    justify-content: center;
    align-items: center;
    gap: 200px;
    min-height: 40px;
    padding: 5px 0 5px;
`;

export const DefineShiftsButton = styled(Button)`
  &&& {   
    min-width: 200px;
    max-width: 200px;
    font-size: 18px;
    line-height: 22.5px;
  }
`;

export const DefineShiftTabsContentItems = styled(Flex)`
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 0;
    max-height: 595px;
    overflow-y: scroll;
`;

export const EmptyShiftsContentContainer = styled(Flex)`
    flex: 2;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
`;


export const EmptyShiftsContentText = styled(Typography).attrs({
    variant: 'h4'
})`
    color: #FFF;
    text-align: center;
    font: normal 400 40px/normal 'Readex Pro';
`;

