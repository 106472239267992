import { Flex } from 'assets/style';
import styled, { css } from 'styled-components';


export const UserStatusContainer = styled(Flex)`
  flex: 1;
  height: 100%;
  min-width: 120px;
  justify-content: start;
  align-items: center;
`;

export const UserStatusBadge = styled(Flex)<{active: number}>`
  background: rgba(221, 53, 51, 0.8);
  padding: 5px;
  min-width: 120px;
  border-radius: 10px;
  font: normal normal 400 15px/18px 'Readex Pro', serif;
  color: #FFFFFF;
  display: flex;
  justify-content: center;

  ${props => props.active === 1 && css`
    background: #11D600B2;
  `}

  ${props => props.active === 2 && css`
    background: #FFE00F;
  `}

`;
