
import styled    from 'styled-components';
import {
    CircularProgress,

}                 from '@mui/material';
import { Colors } from 'theme/palette';

export const SpinnerContainer = styled.div`
      position: absolute;
      background: rgba(192, 192, 192, 0.6);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 20000;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
`;

export const SpinnerDingDong = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const SpinnerProgress = styled(CircularProgress)`
    &&& {
        color: ${Colors.gradient.yellowDark};
    }
`;

export const SpinnerText = styled.div`
    margin-top: 15px;
    color: #fefefe;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-shadow: 0.8px 1px 0px #646464;
`;
