import React, {
  PropsWithChildren
} from 'react';
import TableDataContextContainer from 'components/Table/context';
import { TTableDataProps } from 'components/Table/d';
import TableComponent from 'components/Table/TableComponent';

const TableData = ({
  columns,
  title,
  fnFilters,
  needRefetch,
  refetchDone,
  tableBordered,
  hideLoading,
  preventFetch,
  usePagination = true,
  className,
  emitFetchSuccess,
  csvFileName,
  isResetPagination,
  rowsPerPageOptions,
  children,
  useHeader = false,
  FooterChildren,
  useCustomChildren,
  queryKey,
  url,
  ...rest
}: PropsWithChildren<TTableDataProps>) => {


  return (
    <TableDataContextContainer columns={columns} fnFilters={fnFilters} {...rest} >
      <TableComponent
        csvFileName={csvFileName}
        preventFetch={preventFetch}
        queryKey={queryKey}
        url={url}
        hideLoading={hideLoading}
        className={className}
        emitFetchSuccess={emitFetchSuccess}
        title={title}
        needRefetch={needRefetch}
        refetchDone={refetchDone}
        tableBordered={tableBordered}
        usePagination={usePagination}
        isResetPagination={isResetPagination}
        rowsPerPageOptions={rowsPerPageOptions}
        useHeader={useHeader}
        FooterChildren={FooterChildren}
      >
        {!useCustomChildren && children}
      </TableComponent>
      {useCustomChildren && children}
    </TableDataContextContainer>
  );
};

export default TableData;
