import React, { useMemo } from 'react';
import { useField } from 'formik';
import Select from 'components/select';


const FormikSelectYear = ({ name, label, variant, fullWidth, ...rest }: any) => {

    const [field, meta] = useField({
        name,
        ...rest
    });

    const newProps = {
        ...field,
        error: Boolean(meta.error) && meta.touched,
        helperText: Boolean(meta.touched) && meta.error
    };

    const onBlur = (e: any) => {
        field.onBlur(e);
    };

    const options = useMemo(() => {
        const year = new Date().getFullYear();
        return Array.from({ length: 60 }, (_, i) => ({
            label: year - i,
            value: `${year - i}`
        })) as any;
    }, []);

    return (
        <Select
            {...newProps}
            variant={variant}
            label={label}
            fullWidth={fullWidth}
            options={options}
            value={newProps.value || ''}
            onBlur={onBlur}
            disabled={rest.disabled}
        />
    );
};


export default FormikSelectYear;
