import React from 'react';
import { EmptyOrderFreeContentContainer, EmptyOrderFreeContentText } from './style';
import { Translate } from '../../../../../../internationalization/translate';
import Translation from '../../../../../../components/Translation';

const EmptyOrderFreeContent = () => (
    <EmptyOrderFreeContentContainer>
         <Translation use={Translate.defineOrderSectionItemsEmpty} as={EmptyOrderFreeContentText} />
    </EmptyOrderFreeContentContainer>
);

export default EmptyOrderFreeContent;
