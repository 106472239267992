import styled, { css } from 'styled-components';
import { Flex } from '../../../../../../../assets/style';
import { backgroundYellowGradient } from 'theme/base';


export const SchedulingBoardViewShiftContainer = styled(Flex)<{status: number}>`
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: black;
    border-left: 1px solid black;
    border-top: 1px solid black;
    background: transparent;
    
    &:last-child {
        border-right: 1px solid black;
    }
    
    ${props => (props.status === 1 || props.status === 3) && css`
        background: #59E24D;
    `}

    ${props => props.status === 0 && css`
        ${backgroundYellowGradient};
    `}
    
`;


export const SchedulingBoardViewShiftText = styled(Flex)`
    flex: 2;
    padding: 14px 10px;
    justify-content: flex-start;    
    align-items: flex-start;
    height: 100%;
    color: black;
    
    p {
        font: normal normal 400 16px/24px 'Readex Pro', serif;
        margin: 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const SchedulingBoardViewShiftEndedData = styled(Flex)`
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const SchedulingBoardViewShiftFristRow = styled(Flex)`
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const SchedulingBoardViewShiftSecondRow = styled(Flex)`
    width: 100%;
    justify-content: center;
    align-items: center;
`;



export const SchedulingBoardViewShiftAction = styled(Flex)`
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: 0 0 5px 10px;
    color: black;
    
    button {
        width: 32px !important;
        height: 32px !important;

        svg {
            font-size: 24px;
        }
    }
    
   
`;