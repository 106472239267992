import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Flex } from '../../../../../../../../assets/style';
import ButtonIcon from '../../../../../../../../components/button/ButtonIcon';

export const RestaurantMenuListRow = styled(Flex)`
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
`;

export const RestaurantMenuListButton = styled(ButtonIcon)`
    
`;
export const RestaurantMenuListEditIcon = styled(EditIcon)`
  &&& {
    color: #000000;
  }
`;

export const RestaurantMenuListDeleteIcon = styled(DeleteIcon)`
  &&& {
    color: #F2645A;
  }
`;

export const RestaurantMenuListAutorenewIcon = styled(AutorenewIcon)`
  &&& {
    color: #000000;
  }
`;
