
import {AppBarComponent, AppToolbar, AppBarProps} from 'components/appbar/style';
import {DrawerButton} from 'components/drawer/style';
import AppbarRight from 'components/appbar/appbarRight';
import MenuIcon from '@mui/icons-material/Menu';

const AppBar = ({ open, toggleDrawer }: AppBarProps)=> {

    return (
        <>
            <AppBarComponent position="fixed" open={open}>
                <AppToolbar>
                    <DrawerButton
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                    >
                       <MenuIcon />
                    </DrawerButton>
                    <AppbarRight />
                </AppToolbar>
            </AppBarComponent>
        </>

    );
};


export default AppBar;
