import React, { useState } from 'react';
import OrdersView from './components/OrdersView';
import ConditionalRendering from '../../components/Utils/ConditionalRendering';
import DefineOrderFreeForm from './components/modals/DefineOrderFreeForm';
import OrderFreeContextContainer from './components/modals/DefineOrderFreeForm/context';


const Orders = () => {
    const [showForm, setShowForm] = useState<boolean>(false);
    
    return (
        <>
            <ConditionalRendering condition={!showForm} useTransition>
                <OrdersView setShowForm={setShowForm} />
            </ConditionalRendering>
            <ConditionalRendering condition={showForm} useTransition>
                <OrderFreeContextContainer>
                    <DefineOrderFreeForm setShowForm={setShowForm} />
                </OrderFreeContextContainer>
            </ConditionalRendering>
        </>
    );
};

export default Orders;