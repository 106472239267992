import { PropsWithChildren, useCallback, useMemo, useReducer, useRef } from 'react';
import { ORDERS_VIEW_ASSIGN_EVENTS, TOrderAssignViewActions, TOrderViewAssignReducer } from './d';
import OrderViewAssignContext from './context';

const reducer = (state: TOrderViewAssignReducer, action: TOrderAssignViewActions) => {
    switch (action.type) {
        default:
            return state;

        case ORDERS_VIEW_ASSIGN_EVENTS.SET_SELECTED_DRIVER: {
            return {
                ...state,
                selectedDriver: state.selectedDriver === action.payload ? undefined : action.payload
            };
        }

        case ORDERS_VIEW_ASSIGN_EVENTS.SET_SELECTED_ORDER: {
            const arr = [...(state.selectedOrders || [])];
            const index = arr.findIndex(x => x === action.payload);
            if (index !== -1) {
                arr.splice(index, 1);
            } else {
                arr.push(action.payload);
            }
            return {
                ...state,
                selectedOrders: [...arr]
            };
        }
        case ORDERS_VIEW_ASSIGN_EVENTS.RESET_STATE: {
            return {
                selectedDriver: undefined,
                selectedOrders: []
            };
        }


    }
};

export const useOrderAssignViewContext = (entryData = {}) => {

    const dataReduceState = useRef({
        ...entryData
    });

    const [state, dispatch] = useReducer(reducer, dataReduceState.current as TOrderViewAssignReducer);

    const setDriver = useCallback((id: number) => {
        dispatch({
            type: ORDERS_VIEW_ASSIGN_EVENTS.SET_SELECTED_DRIVER,
            payload: id
        });
    }, [dispatch]);

    const setOrder = useCallback((id: number) => {
        dispatch({
            type: ORDERS_VIEW_ASSIGN_EVENTS.SET_SELECTED_ORDER,
            payload: id
        });
    }, [dispatch]);
    
    const resetState = useCallback(()=> {
        dispatch({
            type: ORDERS_VIEW_ASSIGN_EVENTS.RESET_STATE
        });
    },[dispatch]);


    return useMemo(() => ({
        ...state,
        setDriver,
        setOrder,
        resetState
    }), [state, setDriver, setOrder, resetState]);
};

export type TOrderViewAssignContextType = ReturnType<typeof useOrderAssignViewContext>;

const OrderViewAssignContextContainer = ({
                                             children,
                                             ...rest
                                         }: PropsWithChildren<any>) => {

    const providerData = useOrderAssignViewContext(rest);


    return (
        <OrderViewAssignContext.Provider value={providerData}>
            {children}
        </OrderViewAssignContext.Provider>
    );
};

export default OrderViewAssignContextContainer;