import React from 'react';
import { useField } from 'formik';
import SelectPhoneCode from 'components/select/SelectPhoneCode';


const FormikSelectPhoneCode = ({ name,...rest } :any)=> {

  const [field, meta] = useField(name);

  const newProps = {
    ...field,
    ...rest,
    error: meta.error
  };

  const onBlur = (e: any) => {
    field.onBlur && field.onBlur(e);
  };

  return (
    <SelectPhoneCode
      {...newProps}
      value={newProps.value || ''}
      onBlur={onBlur}
    />
  );
};


export default FormikSelectPhoneCode;
