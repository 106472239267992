import React, { useCallback, useContext } from 'react';
import { DialogActions } from '@mui/material';
import { TDialogProps } from 'components/Dialog/d';
import { DialogCloseIcon, DialogContainer, DialogContentContainer, DialogHeaderTitle } from 'components/Dialog/style';
import { Clear } from '@mui/icons-material';
import ModalContext from 'components/Dialog/context';
import Buttons from 'components/Dialog/Buttons';
import Translation from 'components/Translation';

export const OnlyDialog = ({
                               handlerClose,
                               isOpen,
                               onCloseDialog,
                               title,
                               isCloseAction = true,
                               Context,
                               text,
                               isActionsButtonsVisible = false,
                               onSubmitActions = ()=> {},
                               contextProps = {},
                               buttonCancelLabel,
                               buttonSubmitLabel,
                               noPadding
                           }: any) => {
    return (
        <DialogContainer
            disableEscapeKeyDown
            open={isOpen}
            onClose={onCloseDialog}
        >
            {title || isCloseAction ? (<DialogHeaderTitle>
                <>
                    <Translation use={title || ''} as={'div'} />
                    {isCloseAction && <DialogCloseIcon onClick={handlerClose}>
                        <Clear />
                    </DialogCloseIcon>}
                </>
            </DialogHeaderTitle>) : null}
            <DialogContentContainer isPadding={Number(noPadding)}>
                {
                    Context ? (
                            <Context
                                {...contextProps}
                            />
                        ) :
                        text ? text : null
                }
            </DialogContentContainer>
            {isActionsButtonsVisible && (<DialogActions>
                <Buttons onSubmitAction={onSubmitActions} buttonSubmitLabel={buttonSubmitLabel} buttonCancelLabel={buttonCancelLabel}  />
            </DialogActions>)
            }
        </DialogContainer>
    );

};
const Dialog = ({ Context, text }: TDialogProps) => {

    const {
        isOpen,
        onReject,
        onAccept,
        refProps,
        modalTitle,
        refSubmit,
        isActionsButtonsVisible,
        isCloseAction,
        noPadding,
        buttonSubmitLabel,
        buttonCancelLabel
    } = useContext(ModalContext);

    const handlerClose = useCallback(() => {
        onReject && onReject(false);
    }, [onReject]);

    const onCloseDialog = useCallback((event: any, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason !== 'backdropClick') handlerClose();
    }, [handlerClose]);

    const onSubmitActions = useCallback(async () => {
        // setLoading();
        try {
            const exeVal = (refSubmit.current as any)?.submit && await (refSubmit.current as any).submit();
            if (exeVal) {
                onReject();
                onAccept();
            }
        } finally {
            //resetLoading();
        }

    }, [onAccept, onReject, refSubmit]);

    const _title = refProps.current?.title;
    const title = _title || modalTitle;

    return (
        <OnlyDialog
            isOpen={isOpen}
            onCloseDialog={onCloseDialog}
            title={title}
            isCloseAction={isCloseAction}
            handlerClose={handlerClose}
            Context={Context}
            contextProps={refProps.current}
            isActionsButtonsVisible={isActionsButtonsVisible}
            onSubmitActions={onSubmitActions}
            text={text}
            noPadding={noPadding}
            buttonSubmitLabel={buttonSubmitLabel}
            buttonCancelLabel={buttonCancelLabel}
        />
    );
};

export default Dialog;
