import { ChangeShiftStartAmountContainer, ChangeShiftStartAmountForm, ChangeShiftStartAmountTitle } from './style';
import Translation from '../../../../../components/Translation';
import { Translate } from '../../../../../internationalization/translate';
import { Formik } from 'formik';
import * as yup from 'yup';
import FormikInputCurrency from '../../../../../components/Formik/FormikInputCurrency';
import { SettingsDialogButton, SettingsDialogButtonsForm } from '../../../view/style';
import { Colors } from '../../../../../theme/palette';
import useUpdateSettingsMutation from '../../../../../core/api/hooks/mutations/settings/useUpdateSettingsMutation';
import { useSelector } from 'react-redux';
import { selectSettings, setSettings } from '../../../../../store/app/appSlice';
import { useCallback, useContext, useMemo } from 'react';
import { SETTINGS_KEYS } from '../../../../../store/app/d';
import UiDataContext from '../../../../../context/UiContext/context';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../store/hooks';

const validationSchema = yup.object({
    shiftStartAmount: yup.string().required('required')
});

const ChangeShiftStartAmount = ({ closeModal }: any) => {
    const {t} = useTranslation();
    const settings = useSelector(selectSettings);
    const dispatch = useAppDispatch();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const [id, startShiftAmount]= useMemo(()=> {
        const sett = settings?.[SETTINGS_KEYS.SHIFT_START_AMOUNT];
        return [
            sett.id,
            sett?.value
        ];
    },[settings]);
    const updateSettingsMutation = useUpdateSettingsMutation(id);
    
    
    const onSubmit = useCallback(async (values: any) => {
        if(!values?.shiftStartAmount) return;
        try {
            setLoading();
            const result = await updateSettingsMutation.mutateAsync({
                key: SETTINGS_KEYS.SHIFT_START_AMOUNT,
                value: values.shiftStartAmount
            });
            if (result?.status === 200) {
                if(result?.data) {
                    dispatch(setSettings({
                        [SETTINGS_KEYS.SHIFT_START_AMOUNT]: {...(result?.data || {})}
                    }));
                }
                successToast(t(result.message));
                closeModal();
            }
        }catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
            return false;
        }finally {
            resetLoading();
        }
    },[resetLoading, setLoading, updateSettingsMutation, closeModal, dispatch]);


    const onCancel = () => {
        closeModal();
    };

    return (
        <ChangeShiftStartAmountContainer>
            <Formik
                initialValues={{
                    shiftStartAmount: startShiftAmount
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                <ChangeShiftStartAmountForm>
                    <Translation use={Translate.settingsShiftChangeShiftStartTitle} as={ChangeShiftStartAmountTitle} />
                    <FormikInputCurrency
                        name={'shiftStartAmount'}
                        variant={'outlined'}
                        label={Translate.amount}
                    />
                    <SettingsDialogButtonsForm isColumn={1}>
                        <SettingsDialogButton type={'submit'}>
                            <Translation use={Translate.save} />
                        </SettingsDialogButton>
                        <SettingsDialogButton background={Colors.white} onClick={onCancel}>
                            <Translation use={Translate.cancel} />
                        </SettingsDialogButton>
                    </SettingsDialogButtonsForm>
                </ChangeShiftStartAmountForm>
            </Formik>

        </ChangeShiftStartAmountContainer>
    );
};

export default ChangeShiftStartAmount;