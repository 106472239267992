import { Flex } from 'assets/style';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../../../theme/palette';
import ButtonIcon from '../../../../../../components/button/ButtonIcon';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';


export const OrderItemLeftActions =  styled(Flex)`
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 10px 5px; 
`;

export const OrderItemIcons = styled(Flex)<{align?: string, opacity?: number}>`
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    color: ${Colors.white};
    
    ${props => props?.align && css`
        align-items: ${props.align};
    `}

    ${props => props?.opacity && css`
        opacity: 0.7;
    `}
    
    svg {
        filter: drop-shadow(1px 2px 3px rgb(0 0 0 / 0.4));
    }
`;

export const OrderItemLeftCheckArea = styled(Flex)<{allowed?: number, disabled?: boolean}>`
    width: 20px;
    height: 20px;
    background: ${Colors.black};
    border-radius: 100%;
    padding: 5px;
    cursor: pointer;
    position: relative;

    ${props => !!props?.disabled && css`
        cursor: not-allowed;
        opacity: 0.5;
    `}
    
    ${props => !!props?.allowed && css`
        cursor: not-allowed;
    `}
    
`;

export const OrderItemCheckedIcon = styled(CheckOutlinedIcon)`
   &&& {
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
   }
`;

export const OrderItemRightActionButton = styled(ButtonIcon)<{disabled?: boolean}>`
   &&& {
       width: 30px;
       height: 30px;
       padding: 5px;
       border-radius: 100%;
       color: ${Colors.white};
       
       ${props => props.disabled && css`
            cursor: not-allowed;
           pointer-events: auto;
       `}
       
       svg {
           font-size: 30px;
       }
   }
`;

export const OrderItemContent = styled(Flex)`
    flex: 2;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    height: 100%;
    gap: 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
`;

export const OrderItemRow = styled(Flex)<{alignItems?: string, justify?: string}>`
    gap: 10px;
    font-size: 125%;
    
    align-items: ${props => props?.alignItems || 'flex-start'};
    justify-content: ${props => props?.justify || 'space-between'};
`;

export const OrderItemColumn = styled(Flex)`
    flex-direction: column;
    gap: 4px;
`;

export const OrderItemRightActions = styled(Flex)`
    flex-direction: column;
    height: 100%;
    gap: 15px;
    padding: 10px 5px;
`;

export const OrderItemTitle = styled(Flex)<{isCompleted?: number}>`
    ${props => !!props.isCompleted && css`
        text-decoration: underline;
    `}
`;

export const OrderItemPickUpTimeContainer = styled(Flex)<{isOrange?: number, isRed?: number}>`
    flex-direction: column;
    
   ${props => !!props.isOrange && css`
     color: ${Colors.orange};
   `}
   ${props => !!props.isRed && css`
     color: ${Colors.red};
   `}
`;

export const OrderItemPickUpTimeText =styled(Flex)<{isOrange?: number, isRed?: number}>`
    font: normal 400 16px/125% "Readex Pro";
    padding: 0 3px;
    background: rgba(0, 0, 0, 1);
    justify-content: center;
    ${props => !!props.isOrange && css`
        color: ${Colors.orange};
    `}
    ${props => !!props.isRed && css`
        color: ${Colors.red};
    `}
    text-shadow: 0.5px 0.5px 0.5px #494949;
`;


export const OrderItemPickUpLateTime =styled(Flex)`
    font: normal 400 14px/100% "Readex Pro";
    color: ${Colors.red};
    text-shadow: 0.5px 0.5px 0.5px #494949;
`;


export const OrderItemContainer = styled(Flex)<{color?:  string, selected?: number, allowed?: number, isAborted?: number}>`
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    border: 3px solid ${props => !props.isAborted ? Colors.gradient.yellowDark : Colors.red};
    padding: 0;
    
    ${props => !!props.selected && css`
        background-image: linear-gradient(180deg, ${Colors.gradient.yellowLight} 100%, ${Colors.gradient.yellowLighter} 100%, ${Colors.gradient.yellowLight} 100%) !important;
        color: ${Colors.black};
        background: transparent;

        ${OrderItemIcons}{
            color: ${Colors.black} !important;
        }
        
        ${OrderItemRightActionButton}{
            color: ${Colors.black} !important;
        }
        ${OrderItemLeftCheckArea}{
            background: transparent;
            border: 1px solid ${Colors.black};
        }
    `}

    ${props => !!props?.allowed && css`
        opacity: 0.6;
    `}

    ${props => !props.selected && props?.color === 'orange' && css`
        background: ${Colors.orange};
        color: ${Colors.white} !important;
        
        ${OrderItemPickUpTimeText}{
            color: ${Colors.white} !important;
        }
    `}
    
    
    ${props => !props.selected && props?.color === 'red' && css`
        background: ${Colors.red};
        color: ${Colors.white} !important;
        
        ${OrderItemPickUpTimeText}{
            color: ${Colors.white} !important;
        }
    `}
    
    ${props => !props.selected && props?.color === 'blue' && css`
        background: ${Colors.blue70};
        color: ${Colors.white};
    `}

    ${props => !props.selected && props?.color === 'green' && css`
        background: ${Colors.green70};
        color: ${Colors.white};
    `}

    ${props => !props.selected && props?.color === 'grey' && css`
        background: ${Colors.grey70};
        color: ${Colors.white};
    `}
    
    
`;
