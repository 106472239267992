import React, { useCallback, useContext } from 'react';
import { OrderFreeItemContainer, ClearItemButton } from './style';
import { Translate } from 'internationalization/translate';
import FormikTextField from 'components/Formik/FormikTextField';
import FormikInputCurrency from 'components/Formik/FormikInputCurrency';
import ClearIcon from '@mui/icons-material/Clear';

import { useFormikContext } from 'formik';
import OrderFreeContext from '../../context/context';
import AddressSearch from '../../../../../../../components/Autocomplete/AddressSearch';
import PickUpValue from './PickUpValue';

const OrderFreeItem = ({ item }: any) => {

    const { removeItem } = useContext(OrderFreeContext);
    const { unregisterField, setFieldValue } = useFormikContext() as any;


    const handlerRemoveItem = useCallback(() => {
        if (!item?.id) return;
        removeItem(item.id);
        unregisterField(`${item.id}_locationName`);
        unregisterField(`${item.id}_description`);
        unregisterField(`${item.id}_address`);
        unregisterField(`${item.id}_pickUpValue`);
        unregisterField(`${item.id}_amount`);
        setFieldValue(`${item.id}_locationName`, undefined as any);
        setFieldValue(`${item.id}_description`, undefined as any);
        setFieldValue(`${item.id}_address`, undefined as any);
        setFieldValue(`${item.id}_pickUpValue`, undefined as any);
        setFieldValue(`${item.id}_amount`, undefined as any);
    }, [removeItem, item, unregisterField, setFieldValue]);

    return (
        <OrderFreeItemContainer>
            <ClearItemButton onClick={handlerRemoveItem}>
                <ClearIcon />
            </ClearItemButton>
            <AddressSearch
                isFormik
                name={`${item.id}_address`}
                label={Translate.defineOrderItemAddress}
                showSwitch={false}
            />
            <FormikTextField
                name={`${item.id}_description`}
                variant={'outlined'}
                label={Translate.defineOrderItemDescription}
                rows={3}
                multiline
            />
            <PickUpValue id={item.id}/>
            <FormikInputCurrency
                name={`${item.id}_amount`}
                variant={'outlined'}
                label={Translate.defineOrderItemAmount}
            />
        </OrderFreeItemContainer>
    );
};

export default OrderFreeItem;