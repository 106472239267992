import axiosClient from 'core/api/config/axios';
import {useQuery} from '@tanstack/react-query';
import {createUrl} from 'core/api/utils/createUrl';


const useRestaurantMenuQuery = (id: number)=> {
    const { data } = useQuery({
        queryKey: ['restaurant-menu'],
        queryFn: async () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            try {
                const res: any = await axiosClient.get(createUrl({
                    url: `/dispatcher/restaurant/menu/${id}`,
                    version: 'v1'
                }));
                return res.data?.data  || {};
            } catch (e) {
                return {};
            }
        },
        enabled: !!id
    });
    return data;
};

export default useRestaurantMenuQuery;
