import styled from 'styled-components';
import { Flex } from '../../../../assets/style';

export const RestaurantMenuAddManuallyContainer = styled(Flex)`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 25px 35px;
`;

export const RestaurantMenuAddManuallyContentContainer = styled(Flex)`
    flex-direction: row;
    width: 100%;
    height: 100%;
`;

export const RestaurantMenuAddManuallyAddPartContainer = styled(Flex)`
    flex: 1;
    border-right: 2px solid black;
    min-width: 520px;
`;

export const RestaurantMenuAddManuallyViewPartContainer = styled(Flex)`
    flex: 2;
`;