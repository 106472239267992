import styled, { css } from 'styled-components';
import { Flex } from '../../../../../assets/style';
import { Form } from 'formik';
import Button from '../../../../../components/button';


export const AddMenuItemDialogContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 2;
  width: 750px;  
`;

export const AddMenuItemFormTitle = styled(Flex)`
  font: normal 400 36px/46px 'Readex Pro';
  color: #FFFFFF;
  text-align: center;
`;

export const AddMenuItemFormContent = styled(Flex)`
  flex: 2;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 75px;  
`;

export const AddMenuItemForm = styled(Form)`
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
`;



export const AddMenuItemFormButtonsColumn = styled(Flex)`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 15px 0;
`;


export const AddMenuItemFormButton = styled(Button)<{white?: boolean}>`
    &&& {
        min-width: 250px;
        font-size: 18px;
        line-height: 22.5px;
        ${props => props.white &&  css`
            background: white;
        `}
    }
`;