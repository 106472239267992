import styled from 'styled-components';
import { Flex } from 'assets/style';
import Button from '../../../../../components/button';
import { Form } from 'formik';


export const OrderAbortContainer = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;


export const OrderAbortForm = styled(Form)`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;


export const OrderAbortFooter = styled(Flex)`
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-height: 40px;
    padding: 5px 0 5px;
`;

export const OrderAbortButton = styled(Button)`
  &&& {   
    min-width: 150px;
    max-width: 150px;
    font-size: 16px;
    line-height: 20px;
    padding: 15px 0;
  }
`;