import {styled as _styled} from '@mui/material/styles';
import {
    Drawer,
    DrawerProps,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar
} from '@mui/material';
import {drawerWidth, miniDrawerWidth} from 'components/appbar/style';
import styled, {css} from 'styled-components';
import {Colors} from 'theme/palette';
import {Flex} from '../../assets/style';

export const DrawerContainer = _styled(Drawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})<DrawerProps>(({open}) => ({
    width: open ? drawerWidth : miniDrawerWidth,
    ml: open ? drawerWidth : miniDrawerWidth,
    flexShrink: 0,
    overflowX: 'hidden',
    '& .MuiDrawer-paper': {
        width: open ? drawerWidth : miniDrawerWidth,
        transition: 'ease-in-out 0.3s',
        boxSizing: 'border-box',
        overflowX: 'hidden',
    }
}));


export const DrawerToolbar = styled(Toolbar)<{ open?: boolean }>`
    &&& {
        padding: 16px 60px;

        ${props => !props.open && css`
            padding: 30px 10px;
        `}
    }
`;

export const DrawerLogo = styled.img<{ open?: boolean }>`
    width: ${props => props?.open ? 119 : 80}px;
    height: ${props => props?.open ? 89 : 59}px;
    flex-shrink: 0;
    transition: linear 0.6s;
    cursor: pointer;
`;

export const DrawerButton = styled(IconButton)`
    &&& {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        color: white;
        //border: 1px solid white; 
        background: linear-gradient(180deg, ${Colors.gradient.yellowLight} 0%, ${Colors.gradient.yellowDark} 100%);
        z-index: 20000;
        border-radius: 8px;

        > svg {
            width: 24px;
            height: 24px;
        }
    }
`;


export const DrawerListItemIcon = styled(ListItemIcon)`
    &&& {
        color: #fff;
    }
`;
export const DrawerList = styled(List)<{ open?: boolean }>`
    flex: 2;

    ${props => !props.open && css`
        ${DrawerListItemButton} {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            ${DrawerListItemIcon} {
                display: flex;
                justify-content: center;
            }
        }
    `}

`;

export const DrawerListItemText = styled(ListItemText)`
    &&& {
        .MuiTypography-root {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
`;

export const DrawerListItem = styled(ListItem)`
    &&& {
        &.Mui-selected {
            background: linear-gradient(180deg, ${Colors.gradient.yellowLight} 0%, ${Colors.gradient.yellowDark} 100%);
            color: #000;

            ${DrawerListItemIcon} {
                color: #000;
            }

            .MuiTypography-root {
                color: #000;
            }
        }

    }
`;


export const DrawerListItemButton = styled(ListItemButton)`
    &&& {
        padding: 18px 30px;
    }
`;

export const DrawerVersionBox = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 30px;
`;

export const DriverVersionApp = styled.sub`
 // position: absolute;
 // bottom: 5px;
 // left: 5px;
  text-align: center;
  color: white;
  letter-spacing: 0.3px;
  line-height: 1;
`;
