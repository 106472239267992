import React, { useCallback, useMemo } from 'react';
import { CheckBoxContainer, CheckboxFormControl } from './style';
import { useTranslation } from 'react-i18next';

const CheckBox = ({
                      label,
                      error,
                      onChange,
                      value,
                      labelPlacement = 'end',
                      className,
                      isDark = true,
                      ...rest
                  }: any) => {
    const { t } = useTranslation();
    const Component = () => {

        const handlerOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            const event = {
                persist: () => { /** */
                },
                target: {
                    checked
                }
            } as any;
            onChange && onChange(event);
        }, [onChange]);

        return <CheckBoxContainer
            {...rest}
            checked={!!value}
            onChange={handlerOnChange}
            inputProps={{
                'aria-label': 'controlled'
            }}
        />;
    };

    const _label = useMemo(() => label ? t(label) : '', [t]);

    if (!label) return <Component />;

    return <CheckboxFormControl control={<Component />} error={error} className={className} label={_label} isDark={isDark}
                                labelPlacement={labelPlacement} />;
};

export default CheckBox;
