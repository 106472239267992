import axiosClient from 'core/api/config/axios';
import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'core/api/utils/createUrl';


const useGetOrderQuery = (id: number|string) => {
  const { data, isLoading, isFetched, isFetching, error } = useQuery({
    queryKey: [`get-order-${id}`],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await axiosClient.get(createUrl({
        url: `/dispatcher/order-free/${id}`,
        version: 'v1'
      }));
      return res?.data?.data || [];
    }
  });
  return { data, isLoading, isFetched, isFetching, error };
};

export default useGetOrderQuery;
