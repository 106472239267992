import styled from 'styled-components';
import { Flex } from '../../../../assets/style';

export const DefineRestaurantMenuContainer = styled(Flex)`
  min-width: 700px;
  max-width: 700px;
  min-height: 680px;
  max-height: 700px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DefineRestaurantMenuStepsContainer = styled(Flex)`
  &&& {
    width: 320px;
    justify-content: space-between;
    padding: 25px 0;
  }
`;