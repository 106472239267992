import React from 'react';
import ImageCropProvider from './context';
import ImageCrop from './ImageCrop';

const CropImage = ({imageUrl, handlerSave, isNeedReset}: any) => {


    
    return (
        <ImageCropProvider>
            <ImageCrop image={imageUrl} handlerSave={handlerSave} isNeedReset={isNeedReset}  />
        </ImageCropProvider>
    );
};


export default CropImage;