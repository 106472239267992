import React, {
    forwardRef,
    ForwardedRef
} from 'react';
import {
    Tooltip,
    IconButtonProps
} from '@mui/material';
import { ButtonPrevNextContainer } from 'components/PeriodPicker/ButtonPrevNext/style';

type TButtonPrevNextProps = IconButtonProps & {
    className?: string
    tooltip?: string
    children?: any
}

const ButtonPrevNext = forwardRef(({children, tooltip = '', ...rest}: TButtonPrevNextProps , ref: ForwardedRef<any>) => {

    return (
        <Tooltip title={tooltip}>
            <ButtonPrevNextContainer
                ref={ref}
                {...rest}
            >
                {children}
            </ButtonPrevNextContainer>
        </Tooltip>
    );
});

export default ButtonPrevNext;
