export enum UI_EVENTS {
  UI_SET_LOADING='UI_SET_LOADING',
  UI_RESET_LOADING='UI_RESET_LOADING'
}

export type TUiReducerAction = {
  type: UI_EVENTS;
  payload?: number | string | boolean | null | any;
}

export type TUiReducer = {
  isLoading?: boolean,
  text?: string
  timer?: number
  isSkeleton?: boolean
  isMini?: boolean
}
