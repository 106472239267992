import styled from 'styled-components';
import { Flex } from '../../../../../../../assets/style';
import { Colors } from '../../../../../../../theme/palette';

export const CustomerPhoneFieldContainer = styled(Flex)`
   position: relative; 
`;


export const CustomerPhoneFieldInvalid = styled(Flex)`
    position: absolute;
    top: -20px;
    right: 0;
    border-radius: 100%;
    height: 15px;
    width: 15px;
    background: ${Colors.red};
    cursor: pointer;
    background: linear-gradient(107.2deg, rgb(150, 15, 15) 10.6%, rgb(247, 0, 0) 91.1%);
`;