import styled from 'styled-components';
import { Flex } from 'assets/style';


export const UserBlockDialogContainer = styled(Flex)`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const UserBlockDialogHeader = styled(Flex)`
    text-align: center;
    font: normal normal 400 40px/50px 'Readex Pro';
`;

export const UserBlockDialogText = styled(Flex)`
    max-width: 60%;
    padding-top: 30px;
    text-align: center;
    font: normal normal normal 24px/30px 'Readex Pro';

`;
