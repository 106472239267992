import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';


const useRestaurantMenuItemReactivateMutation = () => {
    const mutation = useMutation({
        mutationFn: async (data: {id: string|number}) => {
            const {id} = data;
            const response = await axiosClient.patch(createUrl({
                url: `/dispatcher/restaurant/menu/item/reactivate/${id}`,
                version: 'v1'
            }), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        }
    });
    return mutation as any;
};

export default useRestaurantMenuItemReactivateMutation;
