import React, { useCallback, useContext } from 'react';
import { ResetMenuDialogContainer, ResetMenuDialogHeader, ResetMenuDialogText } from './style';
import Translation from '../../../../../../../components/Translation';
import { Translate } from '../../../../../../../internationalization/translate';
import useModalFormData from '../../../../../../../hooks/useModalFormData';
import { errorToast } from '../../../../../../../components/Utils/Toast';
import UiDataContext from '../../../../../../../context/UiContext/context';
import { useTranslation } from 'react-i18next';


const MenuResetForm = ({closeModal, resetState}: any) => {
    const {t} = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);

    const handlerOnSubmit = useCallback(async () => {
        try {
            setLoading();
            resetState && resetState();
            closeModal();
        }catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
        return true;
    }, [setLoading, resetState, t, closeModal, resetLoading]);

    useModalFormData({
        handlerOnSubmit
    });


    return (
        <ResetMenuDialogContainer>
            <Translation use={Translate.restaurantMenuResetDialogTitle} as={ResetMenuDialogHeader} />
            <Translation use={Translate.restaurantMenuResetDialogText} as={ResetMenuDialogText} />
        </ResetMenuDialogContainer>
    );


};

export default MenuResetForm;