import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import {
    AddMenuItemDialogContainer,
    AddMenuItemForm,
    AddMenuItemFormButton,
    AddMenuItemFormButtonsColumn,
    AddMenuItemFormContent,
    AddMenuItemFormTitle
} from './style';
import Translation from '../../../../../components/Translation';
import { Translate } from '../../../../../internationalization/translate';
import { Grid } from '@mui/material';
import CropImage from '../../../../../components/Image/Crop';
import FormikSelectField from '../../../../../components/Formik/FormikSelectField';
import FormikTextField from '../../../../../components/Formik/FormikTextField';
import FormikInputCurrency from '../../../../../components/Formik/FormikInputCurrency';
import FormikInputNumber from '../../../../../components/Formik/FormikInputNumber';
import FormikCheckBox from '../../../../../components/Formik/FormikCheckBox';
import { dishesTypeOpts } from '../../../view/DefineRestaurantMenuManually/component/RestaurantMenuAddManuallyForm';


import { Formik, useFormikContext } from 'formik';
import {
    initialValuesMenuManuallyInsertForm,
    restaurantMenuValidation
} from '../../../view/DefineRestaurantMenuManually/component/RestaurantMenuAddManuallyForm/validation';
import useRestaurantCategoriesByTypeQuery
    from '../../../../../core/api/hooks/query/restaurantMenu/useRestaurantCategoriesByTypeQuery';
import useCreateRestaurantMenuItemMutation
    from '../../../../../core/api/hooks/mutations/restaurantMenu/useCreateRestaurantMenuItemMutation';
import useUpdateRestaurantMenuItemMutation
    from '../../../../../core/api/hooks/mutations/restaurantMenu/useUpdateRestaurantMenuItemMutation';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import { useTranslation } from 'react-i18next';
import UiDataContext from '../../../../../context/UiContext/context';
import useRestaurantMenuItemQuery from '../../../../../core/api/hooks/query/restaurantMenu/useRestaurantMenuItemQuery';
import { omit } from 'lodash';
import { formatPriceSymbol } from '../../../../../utils';

import useRestaurantMenuLastPositionQuery
    from '../../../../../core/api/hooks/query/restaurantMenu/useRestaurantMenuLastPositionQuery';

const AddMenuItemDialogForm = ({ id, categoriesByType, closeModal }: any) => {

    const { values, resetForm, setFieldValue, setValues } = useFormikContext() as any;

    const { data: menuItem, isFetched, isLoading } = useRestaurantMenuItemQuery(id);
    const { data: lastPosition, isFetched: isFetchedLastPosition, isLoading: isLoadingLastPosition} = useRestaurantMenuLastPositionQuery(menuItem?.restaurantCategoryId || values?.restaurantCategoryId);


    const categoriesOptions = useMemo(() => {
        const type = (values as any)?.type;
        if (!categoriesByType) return [];
        const categs = categoriesByType[`${type}`];
        if (!categs) return [];
        return (categs || []).map((category: any) => ({
            label: category.name,
            value: category.id
        }));
    }, [categoriesByType, values]);

    const handlerSaveImage = (data: any) => {
        if(!data) return;
        setFieldValue('file', data, false).then();
    };

    const handlerResetForm = useCallback(() => {
        if(!id) {
            resetForm();
        }
        closeModal();
    }, [resetForm, id, closeModal]);


    useEffect(() => {
        if(!lastPosition || !isFetchedLastPosition || isLoadingLastPosition) return;
        setValues({
            ...values,
            position: lastPosition
        });
    }, [lastPosition, isFetchedLastPosition, isLoadingLastPosition]);

    useEffect(() => {
        if (!menuItem || !isFetched || isLoading) return;
        setValues({
            ...(values as any),
            restaurantMenuId: id,
            restaurantCategoryId: menuItem.restaurantCategoryId,
            name: menuItem.name,
            description: menuItem.name,
            quantity: menuItem.quantity,
            price: formatPriceSymbol(menuItem.price),
            position: menuItem.position,
            imageUrl: menuItem.imageUrl,
            free: !!menuItem?.free,
            type: menuItem.category?.type,
            is18Plus: !!menuItem?.is18Plus
        }).then();
    }, [menuItem, isFetched, isLoading]);

    const handlerFreeChange = useCallback((free: boolean)=> {
        if(free) {
            setFieldValue('lastPrice', values.price, false);
            setFieldValue('price', '0', false);
        } else {
            setFieldValue('lastPrice', '', false);
            setFieldValue('price', values.lastPrice || '', true);
        }
    },[setFieldValue, values]);

    const [imageUrl, isFree] = useMemo(() => [
        (values as any).imageUrl,
        !!(values as any)?.free
    ], [values]);

    
    useEffect(()=> {
       if(!isFree) return;
        setValues({
            ...(values as any),
            price: '0',
        }).then();
    },[isFree]);
    

    return (
        <AddMenuItemForm>
            <AddMenuItemFormContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <CropImage imageUrl={imageUrl} handlerSave={handlerSaveImage} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikSelectField
                            options={dishesTypeOpts}
                            variant="outlined"
                            name={'type'}
                            label={Translate.restaurantMenuItemType}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikSelectField
                            options={categoriesOptions}
                            variant="outlined"
                            disabled={!categoriesOptions.length}
                            name={'restaurantCategoryId'}
                            label={Translate.restaurantMenuItemCategory}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormikTextField
                            variant="outlined"
                            label={Translate.restaurantMenuItemName}
                            name={'name'}
                            fullWidth
                            type={'text'}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormikTextField
                            variant="outlined"
                            label={Translate.restaurantMenuItemDescription}
                            name={'description'}
                            fullWidth
                            type={'text'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikTextField
                            variant="outlined"
                            label={Translate.restaurantMenuItemAmount}
                            name={'quantity'}
                            fullWidth
                            type={'text'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikInputCurrency
                            variant="outlined"
                            label={Translate.restaurantMenuItemPrice}
                            name={'price'}
                            fullWidth
                            disabled={isFree}
                            type={'text'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikInputNumber
                            name={'position'}
                            variant="outlined"
                            label={Translate.restaurantMenuItemPosition}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikCheckBox
                            name={'free'}
                            label={Translate.restaurantMenuItemIsFree}
                            handlerAfterChange={handlerFreeChange}
                            type={'checkbox'}
                            fullWidth
                        />
                        <FormikCheckBox
                            name={'is18Plus'}
                            label={Translate.restaurantMenuItemIs18Plus}
                            type={'checkbox'}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </AddMenuItemFormContent>
            <AddMenuItemFormButtonsColumn>
                <AddMenuItemFormButton white onClick={handlerResetForm}>
                    <Translation use={Translate.cancel} />
                </AddMenuItemFormButton>
                <AddMenuItemFormButton type={'submit'}>
                    <Translation use={Translate.save} />
                </AddMenuItemFormButton>
            </AddMenuItemFormButtonsColumn>
        </AddMenuItemForm>
    );
};


const AddMenuItemDialog = ({ lastPosition, id, restaurantId, menuId, setRefetch, closeModal }: any) => {

    const { t } = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const addRestaurnatItem = useCreateRestaurantMenuItemMutation(menuId);
    const updateRestaurantItem = useUpdateRestaurantMenuItemMutation(menuId);
    const categoriesByType = useRestaurantCategoriesByTypeQuery(restaurantId);


    const onSubmit = useCallback(async (values: any, { resetForm }: any) => {
        if (!values.price) return;
        try {
            setLoading();
            const obj = id ? Object.assign({
                    id,
                    ...omit(values as any, ['imageUrl', 'type', 'file', 'restaurantMenuId', 'lastPosition']),
                    price: values.price.replace(/\./gm, '').replace(/,/gm, '.'),
                    free: values?.free ?  1 : 0,
                    is18Plus: values?.is18Plus ?  1 : 0
                } as any,
                values.file ? { file: values.file } : {}) : Object.assign({
                    ...omit(values as any, ['imageUrl', 'type', 'file', 'restaurantMenuId', 'lastPosition']),
                    price: values.price.replace(/\./gm, '').replace(/,/gm, '.'),
                    free: values?.free ?  1 : 0,
                    is18Plus: values?.is18Plus ?  1 : 0
                }, values.file ? { file: values.file } : {});
            const result = id ? await updateRestaurantItem.mutateAsync(obj) : await addRestaurnatItem.mutateAsync(obj);
            if (result?.status === 200) {
                successToast(t(result.message));
                setRefetch();
                resetForm();
                closeModal();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
    }, [id, setRefetch, closeModal, t, addRestaurnatItem, updateRestaurantItem, setLoading, resetLoading]);

    return (
        <AddMenuItemDialogContainer>
            <Translation use={Translate.restaurantMenuItemInsertTitle} as={AddMenuItemFormTitle} />
            <Formik
                initialValues={{
                    ...initialValuesMenuManuallyInsertForm,
                    lastPosition
                }}
                validationSchema={restaurantMenuValidation}
                onSubmit={onSubmit}
            >
                <AddMenuItemDialogForm id={id} categoriesByType={categoriesByType} closeModal={closeModal} />
            </Formik>
        </AddMenuItemDialogContainer>
    );
};

export default AddMenuItemDialog;