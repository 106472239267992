import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { Popover } from '@mui/material';
import { PopoverButtonStyled } from './style';



const PopoverButton = ({Icon, children}: PropsWithChildren<any>)=> {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = useMemo(()=> Boolean(anchorEl) ,[anchorEl]);
  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  },[setAnchorEl]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  },[setAnchorEl]);

  return (
    <>
      <PopoverButtonStyled
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Icon />
      </PopoverButtonStyled>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
      >
          {children(handleClose)}
      </Popover>

    </>
  );
};

export default PopoverButton;
