import { useMutation } from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import { createUrl } from 'core/api/utils/createUrl';


const useDriverDeleteMutation = () => {
    const mutation = useMutation({
        mutationFn: async (data: { id: string | number, isBlocked?: boolean }) => {
            const { id } = data;
            const response = await axiosClient.delete(createUrl({
                url: `/dispatcher/driver/${id}`,
                version: 'v1'
            }), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        }
    });
    return mutation as any;
};

export default useDriverDeleteMutation;
