import {useMemo} from 'react';
import {
    PreviewShiftRowContainer,
    PreviewShiftRowItem,
    PreviewShiftRowStatus,
    PreviewShiftRowStatusIndicator, PreviewShiftRowStatusText
} from './style';
import { SHIFT_SCHEDULING_STATUS } from '../../../../../view/SchedulingBoard/d';
import { Translate } from '../../../../../../../internationalization/translate';
import Translation from '../../../../../../../components/Translation';


const PreviewShiftRow = ({
    driver,
    status
}: any) => {
    
    const statusText = useMemo(()=> {
        switch(status) {
            case SHIFT_SCHEDULING_STATUS.END: return Translate.driverEnd;
            case SHIFT_SCHEDULING_STATUS.PAUSE: return Translate._pause;
            case SHIFT_SCHEDULING_STATUS.TEMP_STOP:
                return Translate.driverTempEnd;
            case SHIFT_SCHEDULING_STATUS.TEMP_CAN_RESUME:
                return Translate.driverTempCanResume;
            case SHIFT_SCHEDULING_STATUS.START:
                return Translate.driverStart;
            default: return Translate.driverNotStarted;
        }
    },[status]);
    
    
    return (
        <PreviewShiftRowContainer>
            <PreviewShiftRowItem>{driver}</PreviewShiftRowItem>
            <PreviewShiftRowStatus>
                <Translation use={statusText} as={PreviewShiftRowStatusText} />
                <PreviewShiftRowStatusIndicator status={status} />
            </PreviewShiftRowStatus>
        </PreviewShiftRowContainer>
    );
};

export default PreviewShiftRow;