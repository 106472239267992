import { SettingsNavigationListItem, SettingsNavigationListItemButton, SettingsNavigationListItemText } from './style';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';


const SettingsNavigationItem = ({
    text,
    path
                                }: any) => {
    const {t} = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const handlerClick = useCallback(() => {
        navigate(path, { replace: false });
    }, [path, navigate]);

    const selected = useMemo(() => !!(path === pathname), [path, pathname]);


    return (
        <SettingsNavigationListItem key={text} selected={selected} disablePadding>
            <SettingsNavigationListItemButton onClick={handlerClick}>
                <SettingsNavigationListItemText primary={t(text)} />
            </SettingsNavigationListItemButton>
        </SettingsNavigationListItem>
    );
};

export default SettingsNavigationItem;