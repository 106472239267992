import {
    RestaurantMenuBackIcon, RestaurantMenuViewContentContainer,
    RestaurantMenuViewHeaderButtonBack,
    RestaurantMenuViewHeaderButtonReset,
    RestaurantMenuViewHeaderButtonSave,
    RestaurantMenuViewHeaderContainer, RestaurantMenuViewHeaderRightPart, RestaurantMenuViewHeaderSearchPart,
    RestaurantMenuViewManuallyContainer
} from './style';
import { Translate } from '../../../../../../internationalization/translate';
import Translation from '../../../../../../components/Translation';
import React, { useCallback, useContext, useMemo } from 'react';
import SearchInput from '../../../../../../components/input/SearchInput';
import { StaticTable } from '../../../../../../components/Table/TableComponent';
import { MENU_ITEMS_TABLE_EVENTS, RestaurantMenuManuallyColumns } from '../../def';
import RestaurantMenuContext from '../../../context/context';
import { TUseOptimizeEventData, useOptimizeEventClick } from '../../../../../../hooks/useOptimizeEventClick';
import { useModal } from '../../../../../../hooks/useModal';
import MenuResetForm from '../Dialogs/MenuResetForm';
import MenuFinishForm from '../Dialogs/MenuFinishForm';
import MenuGoBackForm from '../Dialogs/MenuGoBackForm';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const data = [
    {
        category: 'Dodatak',
        name: 'Kečap',
        description: 'Kečap description',
        price: 15.00,
        free: true
    },
    {
        category: 'Hrana',
        name: 'Hamburger',
        description: 'Hamburger description',
        price: 350.00,
        free: false
    },
    {
        category: 'Piće',
        name: 'Coca cola',
        description: 'Coca cola description',
        price: 80.00,
        free: false
    },
    {
        category: 'Dodatak',
        name: 'Majonez',
        description: 'Majonez description',
        price: 15.00,
        free: true
    },
];

const RestaurantMenuViewManuallyForm = ({id} : any) => {
    
    const {tableListFiltered, tableList,resetState, removeItem, setEditItem, setSearch, clearSearch} = useContext(RestaurantMenuContext);
    
    const isDisabled = useMemo(() => !tableListFiltered?.length,[tableListFiltered]);

    const { openModal: openDialogResetMenuForm, closeModal: closeModalReset } = useModal({
        modal: MenuResetForm,
        isActionsButtonsVisible: true,
        isCloseAction: true
    });

    const { openModal: openDialogFinishMenuForm, closeModal: closeModalFinish } = useModal({
        modal: MenuFinishForm,
        isActionsButtonsVisible: true,
        isCloseAction: true
    });

    const { openModal: openDialogGoBackMenuForm, closeModal: closeModalGoBack } = useModal({
        modal: MenuGoBackForm,
        isActionsButtonsVisible: true,
        isCloseAction: true
    });


    const handlerSearch = useCallback((value: string) => {
        setSearch(value);
    },[setSearch]);

    const handlerClear = useCallback(()=> {
        clearSearch();
    },[clearSearch]);


    const handlerBack = useCallback(()=> {
        openDialogGoBackMenuForm({
            closeModal: closeModalGoBack,
            resetState
        });
    },[openDialogGoBackMenuForm, resetState, closeModalGoBack]);
    
    const handlerResetState = useCallback(()=> {
        if(isDisabled) return;
        openDialogResetMenuForm({
            closeModal: closeModalReset,
            resetState,
        });
    } , [resetState, openDialogResetMenuForm, closeModalReset, isDisabled]);
    
    const handlerSave = useCallback(()=> {
        if(isDisabled) return;
        openDialogFinishMenuForm({
            id,
            tableList,
            closeModal: closeModalFinish,
            resetState
        });
    },[openDialogFinishMenuForm, isDisabled, id, tableList, resetState, closeModalFinish]);

    const { onClickHandler } = useOptimizeEventClick({
        eventHandler(data: TUseOptimizeEventData) {
            
            if(data.action === MENU_ITEMS_TABLE_EVENTS.EDIT_ITEM) {
                if(data.id) {
                    setEditItem(data.id);
                    return;
                }
            }
            
            if(data.action === MENU_ITEMS_TABLE_EVENTS.REMOVE_ITEM){
                if(data.id) {
                    removeItem(data.id);
                    return;
                }
            }
        }
    });
    
    
    return (
        <RestaurantMenuViewManuallyContainer>
            <RestaurantMenuViewHeaderContainer>
                <RestaurantMenuViewHeaderButtonBack onClick={handlerBack}>
                    <RestaurantMenuBackIcon />
                    <Translation use={Translate.back} />
                </RestaurantMenuViewHeaderButtonBack>
                <RestaurantMenuViewHeaderSearchPart>
                    <SearchInput
                        handlerSearch={handlerSearch}
                        clearSearch={handlerClear}
                        isDark={false}
                        placeholder={'search'}
                    />
                </RestaurantMenuViewHeaderSearchPart>
                <RestaurantMenuViewHeaderRightPart>
                    <RestaurantMenuViewHeaderButtonReset disabled={isDisabled} onClick={handlerResetState}>
                        <Translation use={Translate.reset} />
                    </RestaurantMenuViewHeaderButtonReset>
                    <RestaurantMenuViewHeaderButtonSave disabled={isDisabled} onClick={handlerSave}>
                        <Translation use={Translate.save} />
                    </RestaurantMenuViewHeaderButtonSave>
                </RestaurantMenuViewHeaderRightPart>
            </RestaurantMenuViewHeaderContainer>
            <RestaurantMenuViewContentContainer onClick={onClickHandler} data-action-root>
                <StaticTable
                    visibleColumns={RestaurantMenuManuallyColumns}
                    tableData={tableListFiltered}
                    totalRecords={tableListFiltered?.length || 0}
                />
            </RestaurantMenuViewContentContainer>
        </RestaurantMenuViewManuallyContainer>
    );
};

export default RestaurantMenuViewManuallyForm;