import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';


const useHandoverShiftsMutation = () => {
    const mutation = useMutation({
        mutationFn: async (data: { id: number }) => {
            const response = await axiosClient.patch(createUrl({
                url: `/dispatcher/shift-scheduling/handover/${data.id}`,
                version: 'v1',
            }), {}, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        }
    });
    return mutation;
};

export default useHandoverShiftsMutation;
