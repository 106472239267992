import React from 'react';
import { useField } from 'formik';
import AddressSearchOpenStreet from './AddressSearchOpenStreet';


const FormikOpenStreetSearchAddress = ({name, changeData, ...rest}: any) => {

    const [field, meta] = useField(name);

    const newProps = {
        ...field,
        ...rest,
        error: Boolean(meta.error) && meta.touched ? meta.error : false,
        helperText: Boolean(meta.touched) && meta.error ? meta.error : ''
    };

    const onBlur = (e: any) => {
        field.onBlur(e);
    };
    
    const onChange = (value: any) => {
        field.onChange(value?.formatted_address);
        changeData && changeData({
            address: value?.formatted_address,
            addressObject: value
        });
    };
    
    
    return (
        <AddressSearchOpenStreet
            {...newProps}
            value={newProps.value || ''}
            onBlur={onBlur}
            onChange={onChange}
        />
    );
};

export default FormikOpenStreetSearchAddress;