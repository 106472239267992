import React, { useMemo } from 'react';
import { AvatarFirstLetterContainer, StyledAvatar, StyleLazyLoadImage } from 'components/Avatar/style';


const Avatar = ({ row }: { row: any }) => {

    const [isAvatar, userFirstLetter, url, nickname] = useMemo(() => [
        row?.avatarUrl || row?.imageUrl,
        (row?.nickname || row?.firstName || row?.businessName)?.charAt(0)?.toUpperCase(),
        row?.avatarUrl ? row?.avatarUrl : row?.imageUrl,
        row?.nickname || row?.firstName || row?.businessName,
    ], [row]);

    return (
        <AvatarFirstLetterContainer>
            <StyledAvatar>
                {isAvatar ?
                    <StyleLazyLoadImage
                        alt={nickname}
                        effect="blur"
                        src={url} />
                    : userFirstLetter}
            </StyledAvatar>
        </AvatarFirstLetterContainer>
    );
};

export default Avatar;
