import React, { useMemo } from 'react';
import {
    PreviewItemDescription,
    PreviewItemRow,
    PreviewOrderItemAddress,
    PreviewOrderItemBox,
    PreviewOrderItemCompletedBox
} from './style';
import moment from 'moment';
import { formatCurrency } from '../../../../../../utils';
import Collapse from '../../../../../../components/Collapse';
import { Translate } from '../../../../../../internationalization/translate';
import Translation from '../../../../../../components/Translation';
import {
    PreviewOrderColumn,
    PreviewOrderFinishImage,
    PreviewOrderFinishNoteText, PreviewOrderFinishText,
    PreviewOrderFinishTimeText, PreviewOrderItemPickUpTimeText
} from '../style';


const PreviewOrderItem = ({ item, index }: any) => {

    const [time, amount, completedTime, isOrange, isRed, completedText, isCompleted] = useMemo(() => {
        const diff = item?.pickUpTime ? moment.duration(moment(item?.pickUpTime).diff(moment()))?.asMinutes() : '';
        let diffCompleted = item?.completedTime ? moment.duration(moment(item?.pickUpTime).diff(moment(item?.completedTime)))?.asMinutes() : 0;
        if(diffCompleted && diffCompleted < 0) {
            diffCompleted *= - 1;
        }
        return [
            item?.pickUpTime ? moment(item?.pickUpTime).format('YYYY-MM-DD HH:mm') : '',
            item?.amount ? formatCurrency(item?.amount, false) : '',
            item?.completedTime ? moment(item?.completedTime).format('YYYY-MM-DD HH:mm') : '',
            Number(item?.pickUpTime && (diff >= 15 || (diff <= 15 && diff >= 11))),
            Number(item?.pickUpTime && diff <= 10),
            item?.completedTime ? Math.floor(diffCompleted) : '',
            item?.isCompleted
        ];
    }, [item]);

    return (
        <PreviewOrderItemBox isCompleted={item?.isCompleted}>
            <PreviewItemRow>
                <PreviewItemRow gap={'15'}>
                    <PreviewOrderItemCompletedBox
                        isCompleted={item.isCompleted}
                    />
                    <div>#{index}</div>
                </PreviewItemRow>
                {time ? <Translation
                    use={Translate.orderPreviewItemsPickUpTime}
                    as={PreviewOrderItemPickUpTimeText}
                    params={{ time, completed: isCompleted ? 'o': 'i'}}
                    isOrange={isOrange}
                    isRed={isRed}
                />: null}
            </PreviewItemRow>
            <PreviewItemRow>
                <PreviewOrderItemAddress isCompleted={item?.isCompleted}>{item.address}</PreviewOrderItemAddress>
                {amount ? <Translation use={Translate.orderPreviewItemsAmount} as={'div'} params={{ amount }} /> : null}
            </PreviewItemRow>
            <PreviewItemDescription>
                {item.description}
            </PreviewItemDescription>
            {item?.isCompleted ? <Collapse label={Translate.orderPreviewItemsFinishInformation}>
                <PreviewItemRow gap={'20'} alignItems={'flex-start'}>
                    <PreviewOrderFinishImage
                        width={100}
                        height={100}
                        alt={'Finish Image'}
                        src={item.image}
                    />
                    <PreviewOrderColumn flex={2}>
                        <PreviewItemRow>
                            <Translation
                                use={Translate.orderPreviewItemsCompletedText}
                                as={PreviewOrderFinishText}
                                isOrange={isOrange}
                                isRed={isRed}
                                params={{ time: completedText }}
                            />
                            <Translation
                                use={Translate.orderPreviewItemsCompletedTime}
                                as={PreviewOrderFinishTimeText}
                                params={{ time: completedTime }}
                            />
                        </PreviewItemRow>
                        <PreviewOrderFinishNoteText>
                            {item?.note}
                        </PreviewOrderFinishNoteText>
                    </PreviewOrderColumn>
                </PreviewItemRow>
            </Collapse> : null}
        </PreviewOrderItemBox>
    );
};

export default PreviewOrderItem;