import styled from 'styled-components';
import { Flex } from '../../../assets/style';
import Button from '../../button';
import { Colors } from '../../../theme/palette';
import ButtonIcon from '../../button/ButtonIcon';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';


export const ImageCropModalContainer = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 450px;
    min-width: 300px;
    position: relative;
`;

export const ImageCropContainer = styled.div`
    position: relative;
    width: 236px;
    height: 236px;
    background: linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 0,
    linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 100%,
    linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 0,
    linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 100%,
    linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 0 0,
    linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 100% 0,
    linear-gradient(to top, #cbd4e1 8px, transparent 8px) 0 100%,
    linear-gradient(to top, #cbd4e1 8px, transparent 8px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 70px 70px;
    margin-bottom: 30px;
`;

export const ImageCropPreviewContainer = styled(Flex)`
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px; 
    margin-bottom: 20px;
`;

export const ImageCropPreviewButton = styled(ButtonIcon)`
    &&& {
        max-width: 30px;
        max-height: 30px;
        background: #666666;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        right: 0;
    }
`;

export const CameraIcon = styled(PhotoCameraIcon)`
    &&& {
        color: white;
        height: 18px;
        width: 18px;
    }
`;

export const ImageCropPreview = styled(Flex)`
    cursor: pointer;
    position: relative;
    border-radius: 100%;
    border: 3px solid ${Colors.gradient.yellowDark};
    width: 104px;
    height: 104px;
    justify-content: center;
    align-items: center;
`;

export const ImageCropPreviewImage = styled.img`
    object-fit: cover;
    border-radius: 100%;
    width: 100px;
    height: 100px;
`;

export const InputHidden = styled.input`
    display: none;
`;

export const CropImageModalButtonsPart = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;
`;


export const CropImageActionButtons = styled(Button)`
    &&& {
        min-width: 100px;
    }
`;