import styled, { css } from 'styled-components';
import { Flex } from '../../../../../../../assets/style';
import { Form } from 'formik';
import Button from '../../../../../../../components/button';
import { Grid } from '@mui/material';


export const DefineUpdateCategoryFormContainer = styled(Flex)`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;



export const DefineUpdateCategoryFormFormContainer = styled(Form)`
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const DefineUpdateCategoryFormGrid = styled(Grid)`
    &&& {
            max-width: 650px;
    }
`;

export const DefineUpdateCategoryFormButtonsColumn = styled(Flex)`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 15px 0;
`;

export const DefineUpdateCategoryFormButton = styled(Button)<{white?: boolean}>`
    &&& {
        min-width: 250px;
        font-size: 18px;
        line-height: 22.5px;
        ${props => props.white &&  css`
            background: white;
        `}
    }
`;

