import { SettingsLayoutContainer, SettingsLayoutContent } from './style';
import SettingsNavigation from '../components/SettingsNavigation';
import { Outlet } from 'react-router-dom';


const SettingsLayout = () => {


    return (
        <SettingsLayoutContainer>
            <SettingsNavigation />
            <SettingsLayoutContent>
                <Outlet />
            </SettingsLayoutContent>
        </SettingsLayoutContainer>
    );
};

export default SettingsLayout;