import { useCallback, useState, useMemo } from 'react';
import Collapse from '@mui/material/Collapse';
import {
    PreviewShiftCollapseContainer, PreviewShiftContainer, PreviewShiftRowButtonAction,
    PreviewShiftRowCollapseButton,
    PreviewShiftRowContainer,
    PreviewShiftRowItem,
    PreviewShiftRowStatus, PreviewShiftRowStatusText
} from './style';
import { TPreviewEndedShiftRow } from '../index';
import { formatPrice } from '../../../../../../../../utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { SHIFT_SCHEDULING_STATUS } from '../../../../../../view/SchedulingBoard/d';
import ConditionalRendering from '../../../../../../../../components/Utils/ConditionalRendering';
import PreviewShiftFinishPopoverButton from '../PreviewShiftFinishPopoverButton';
const PreviewShiftRow = ({
    id,
    driver,
    status,
    shiftEarnings,
    shiftExpenses,
    shiftStartAmount,
    shiftEndAmount,
    shiftSchedulingExpenses,
    shiftDate,
    shiftType
}: TPreviewEndedShiftRow) => {
    const [open, setOpen] = useState<boolean>(false);

    const onClickOpen = useCallback(()=> {
        setOpen(v=> !v);
    },[setOpen]);
    
    const [isEnded, isNeedDispEnd] = useMemo(()=> [
        Number(status === SHIFT_SCHEDULING_STATUS.DISP_END),
        status === SHIFT_SCHEDULING_STATUS.END
    ],[status]);
    
    const shiftExpensesText = useMemo(()=> (shiftSchedulingExpenses || [])?.map((x:any) => `${x.description} - ${x.value}`).join(', '),[shiftSchedulingExpenses]);

    
    return (
        <PreviewShiftContainer isEnded={isEnded}>
            <PreviewShiftRowContainer>
                <PreviewShiftRowItem>{driver}</PreviewShiftRowItem>
                <PreviewShiftRowStatusText>{formatPrice(shiftStartAmount)}</PreviewShiftRowStatusText>
                <PreviewShiftRowStatusText>{formatPrice(shiftExpenses)}</PreviewShiftRowStatusText>
                <PreviewShiftRowStatusText>{formatPrice(shiftEarnings)}</PreviewShiftRowStatusText>
                <PreviewShiftRowStatus>
                    <PreviewShiftRowStatusText>{formatPrice(shiftEndAmount)}</PreviewShiftRowStatusText>
                    <PreviewShiftRowButtonAction>
                        <PreviewShiftRowCollapseButton onClick={onClickOpen}>
                            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </PreviewShiftRowCollapseButton>
                        <ConditionalRendering condition={isNeedDispEnd}>
                            <PreviewShiftFinishPopoverButton id={id} shiftDate={shiftDate} shiftType={shiftType} />
                        </ConditionalRendering>
                    </PreviewShiftRowButtonAction>
                </PreviewShiftRowStatus>
            </PreviewShiftRowContainer>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <PreviewShiftCollapseContainer>
                    <div>Troškovi:</div>
                    <div>{shiftExpensesText}</div>
                </PreviewShiftCollapseContainer>
            </Collapse>
        </PreviewShiftContainer>

    );
};

export default PreviewShiftRow;