import axiosClient from 'core/api/config/axios';
import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'core/api/utils/createUrl';


const useGetDriverQuery = (id: number) => {
  const { data, isLoading, isFetched, isFetching } = useQuery({
    queryKey: ['get-driver'],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await axiosClient.get(createUrl({
        url: `/dispatcher/driver/${id}`,
        version: 'v1'
      }));
      return res.data?.data || {};
    },
    enabled: !!id
  });
  return { data, isLoading, isFetched, isFetching };
};

export default useGetDriverQuery;
