import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';

const useSetToPendingOrderMutation = () => {
    const mutation = useMutation({
        mutationFn: async (data: {id: number|string}) => {
            const response = await axiosClient.patch(createUrl({
                url: `/dispatcher/order-free/set-to-pending/${data.id}`,
                version: 'v1',
            }));
            return response.data;
        }
    });
    return mutation;
};

export default useSetToPendingOrderMutation;
