import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextFieldContainer } from 'components/input/style';
import ClearIcon from '@mui/icons-material/Clear';
import {
    InputAdornment,
    Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const InputTime = ({
                         fullWidth,
                         label,
                         icon,
                         iconRight,
                         value,
                         onChange,
                         variant = 'standard',
                         size = 'small',
                         error,
                         helperText,
                         useHelperText = true,
                         readOnly = false,
                         // eslint-disable-next-line @typescript-eslint/no-unused-vars
                         loading = false,
                         textAlign = 'left',
                         selectOnFocus,
                         onFocus,
                         inputRef,
                         placeholder,
                         isClearable,
                         onClear,
                         maxLength,
                         isDark = true,
                         InputProps,
                         ...rest
                     }: any) => {

    const [selectionOnFocus, setSelectionOnFocus] = useState<boolean>(false);
    const { t } = useTranslation();

    const onHandlerChange = useCallback((e: any) => {
        e?.persist && e?.persist();
        e.target.value = (e.target.value || '').replace(/[^0-9:]/g, '');
        onChange && onChange(e);
    }, [onChange]);

    const handlerClear = useCallback(() => {
        if (isClearable) {
            if (!value.length) return;
            onClear && onClear();
        }
    }, [isClearable, onClear, value]);

    const IconRight = useMemo(() => {
        if (isClearable) {
            return {
                icon: ClearIcon,
                handler: handlerClear,
                tooltip: {
                    title: 'Clear'
                }
            } as any;
        }
        return iconRight;
    }, [iconRight, isClearable, handlerClear]);


    const [_placeholder, _label] = useMemo(() => [
        placeholder ? t(placeholder) : undefined,
        label ? t(label) : undefined
    ], [t, placeholder, label]);

    const iconsProps = useMemo(() => Object.assign({}, icon ? {
        startAdornment: icon?.tooltip ? (
            <Tooltip title={t(icon.tooltip.title) || ''} placement={icon.tooltip?.placement || 'top'}>
                <InputAdornment title={icon.tooltip.title} style={{ cursor: 'pointer' }} position="start" onClick={icon.handler}>
                    <icon.icon color={icon.color || 'inherit'} style={{ cursor: 'pointer' }} />
                </InputAdornment>
            </Tooltip>
        ) : (<InputAdornment position="start" style={{ cursor: 'pointer' }} onClick={icon.handler}>
            <icon.icon color={icon.color || 'inherit'} />
        </InputAdornment>)
    } : {}, IconRight ? {
        endAdornment: IconRight?.tooltip ? (
            <Tooltip title={t(IconRight?.tooltip?.title) || ''} placement={IconRight.tooltip?.placement || 'top'}>
                <InputAdornment position="start" style={{ cursor: 'pointer' }} onClick={IconRight.handler}>
                    <IconRight.icon color={IconRight.color || 'inherit'} />
                </InputAdornment>
            </Tooltip>
        ) : (
            <InputAdornment position="end" style={{ cursor: 'pointer' }} onClick={IconRight.handler}>
                <IconRight.icon color={IconRight.color || 'inherit'} />
            </InputAdornment>
        )
    } : {}), [icon, IconRight]);


    const _onFocusHandler = React.useCallback((e: any) => {
        if (selectOnFocus && inputRef && (inputRef as any).current) {
            setSelectionOnFocus(true);
        }
        onFocus && onFocus(e);
    }, [onFocus, selectOnFocus, setSelectionOnFocus, inputRef]);

    const _helperText = useMemo(() => useHelperText ? error && typeof error === 'string' ? t(error) : helperText ? t(helperText) : <>&nbsp;</> : null, [t, error, helperText, useHelperText]);


    useEffect(() => {
        if (!selectionOnFocus || !selectOnFocus || !inputRef) {
            return;
        }
        if ((inputRef as any).current) {
            const input = (inputRef as any).current.getElementsByTagName('input');
            if (input?.length) input[0].select();
        }
    }, [selectionOnFocus, setSelectionOnFocus, selectOnFocus, inputRef]);


    return (
        <TextFieldContainer
            fullWidth={fullWidth}
            ref={inputRef}
            label={_label}
            autoComplete="off"
            InputProps={{
                ...InputProps,
                ...iconsProps,
                readOnly
            }}
            inputProps={{
                style: {
                    textAlign
                },
                maxLength
            }}
            error={!!error}
            value={value || ''}
            onChange={onHandlerChange}
            onFocus={_onFocusHandler}
            variant={variant}
            FormHelperTextProps={{
                style: {
                    display: 'block',
                    minHeight: '20px'
                }
            }}
            size={size}
            isDark={isDark}
            helperText={_helperText}
            placeholder={_placeholder}
            {...rest}
        />
    );
};


export default InputTime;
