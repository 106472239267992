import axiosClient from 'core/api/config/axios';
import {useQuery} from '@tanstack/react-query';
import {createUrl} from 'core/api/utils/createUrl';
import moment from 'moment';


const useShiftSchedulingQuery = (from?: string|Date, to?: string|Date)=> {
    const { data, isFetched, isLoading, refetch } = useQuery({
        queryKey: ['shift-scheduling'],
        queryFn: async () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const res: any = await axiosClient.get(createUrl({
                url: '/dispatcher/shift-scheduling',
                query: {
                    from: moment(from).format('YYYY-MM-DD'),
                    to: moment(to).format('YYYY-MM-DD')
                },
                version: 'v1'
            }));
            return res.data?.data  || {};
        },
        enabled: !!(from && to)
    });
    return { data, isFetched, isLoading, refetch };
};

export default useShiftSchedulingQuery;
