import React, { useCallback, useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Input from './Input';

export type TTableSearchProps = {
    handlerSearch: (value: string) => void
    clearSearch?: () => void
    timeOutTrigger?: number
    isDark?: boolean
    placeholder?: string
}


const SearchInput = ({
                         timeOutTrigger = 400,
                         handlerSearch,
                         clearSearch,
                         isDark = true,
                         placeholder,
                         ...rest
                     }: TTableSearchProps) => {

    const ref = useRef();
    const timer = useRef(0);
    const [search, setSearch] = useState<string>('');


    const triggerSearch = useCallback((value: string, time?: number) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            handlerSearch(value);
        }, time || timeOutTrigger) as any;
    }, [handlerSearch, timer, timeOutTrigger]);

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setSearch(e.target.value);
        triggerSearch(e.target.value);
    }, [setSearch, triggerSearch]);

    const setFocus = useCallback(() => {
        if (!ref || !ref.current) return;
        (ref.current as any)?.focus();
    }, [ref]);

    const handlerClear = useCallback(() => {
        if (clearSearch) clearSearch();
        else handlerSearch('');
        setSearch('');
        setFocus();
    }, [setSearch, clearSearch, setFocus, handlerSearch]);

    useEffect(() => {
        if (search?.length) return;
        if (!search?.length) handlerClear();
    }, [search, onChange, handlerClear]);

    useEffect(() => {
        return () => {
            timer.current && clearTimeout(timer.current);
        };
    }, []);

    return (
        <Input
            {...rest}
            inputRef={ref}
            icon={{
                icon: SearchIcon,
                color: '#666666'
            }}
            placeholder={placeholder}
            value={search}
            onChange={onChange}
            onClear={handlerClear}
            variant={'outlined'}
            useHelperText={false}
            isDark={isDark}
            isClearable
        />
    );
};


export default SearchInput;
