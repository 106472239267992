import styled from 'styled-components';
import { Flex } from '../../../../../../assets/style';


export const SchedulingBoardTableViewContainer = styled(Flex)`
  flex: 2;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const SchedulingBoardTableViewRow = styled(Flex)`
    justify-content: start;
    align-items: center;
    width: 100%;
`;

export const SchedulingBoardTableHeaderItem = styled(Flex)`
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 18px 0;
    color: black;
    font: normal normal 400 24px/30px 'Readex Pro', serif;
`;

export const SchedulingBoardTableViewBody = styled(Flex)`
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    width: 100%;
`;

export const SchedulingBoardTableViewBodyRow = styled(Flex)`
    width: 100%;
    justify-content: start;
    align-items: center;
    min-height: 80px;
    
    &:last-child {
        border-bottom: 1px solid black;
    }
`;

export const SchedulingBoardTableBodyHeaderItem = styled(Flex)`
    flex: 1;
    justify-content: center;
    align-items: center;
    color: black;
    height: 100%;
    font: normal normal 400 24px/30px 'Readex Pro', serif;
    border-left: 1px solid black;
    border-top: 1px solid black;
`;

