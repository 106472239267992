import {blue, common, grey} from '@mui/material/colors';
import {palette} from './palette';

export const getThemedComponents = (mode: any) => ({
    components: {
        ...(mode === 'light'
            ? {
                MuiAppBar: {
                    styleOverrides: {
                        colorPrimary: {
                            backgroundColor: grey[800]
                        }
                    }
                },
                MuiButton: {
                    variants: [
                        {
                            props: {variant: 'contained'},
                            style: {
                                fontFamily: 'Readex Pro'
                            }
                        },
                        {
                            props: {variant: 'outlined'},
                            style: {
                                color: palette.light.primary.main
                            }
                        },
                        {
                            props: {variant: 'primary', color: 'primary'},
                            style: {
                                border: '4px dashed blue'
                            }
                        }
                    ]
                },
                MuiList: {
                    styleOverrides: {
                        root: {}
                    }
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            color: common.white,
                            alignItems: 'stretch',
                            fontFamily: 'Readex Pro'
                        }
                    }
                },
                MuiAccordion: {
                    styleOverrides: {
                        root: {
                            color: common.white,
                            fontFamily: 'Readex Pro'
                        }
                    }
                },
                MuiFormLabel: {
                    styleOverrides: {
                        root: {
                            color: '#ffffff',
                            backgroundColor: common.black,
                            '&$focused $notchedOutline': {
                                color: '#ffffff',
                            },
                        }
                    }
                },
                MuiFormControl: {
                    styleOverrides: {
                        root: {
                            color: '#ffffff',
                            borderRadius: 20,
                            borderColor: '#fff',
                            '&$focused $notchedOutline': {
                                borderColor: '#fff',
                            },
                        },
                        input: {
                            color: '#ffffff',
                            borderColor: '#fff'
                        }
                    }
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            color: '#ffffff',
                            borderRadius: 20,
                            minHeight: 50,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: '#fff',
                            input: {
                                '&:-webkit-autofill': {
                                    WebkitBoxShadow: '0 0 0 1000px transparent inset'
                                },
                                color: '#ffffff',
                            },
                            '&$focused $notchedOutline': {
                                borderColor: '#fff',
                            },
                        }
                    }
                },
                MuiSelect: {
                    styleOverrides: {
                        select: {
                            borderRadius: 20,
                            minHeight: 50,
                            input: {
                                '&:-webkit-autofill': {
                                    WebkitBoxShadow: '0 0 0 1000px transparent inset'
                                }
                            }
                        }
                    }
                }
            }
            : {
                MuiAppBar: {
                    styleOverrides: {
                        colorPrimary: {
                            backgroundColor: blue[800]
                        }
                    }
                },
                MuiFormLabel: {
                    styleOverrides: {
                        root: {
                            color: '#ffffff',
                            backgroundColor: common.black,
                            '&$focused $notchedOutline': {
                                color: '#ffffff',
                            },
                        }
                    }
                },
                MuiFormControl: {
                    styleOverrides: {
                        root: {
                            color: '#ffffff',
                            borderRadius: 20,
                            borderColor: '#fff',
                            '&$focused $notchedOutline': {
                                borderColor: '#fff',
                            },
                        },
                        input: {
                            color: '#ffffff',
                            borderColor: '#fff'
                        }
                    }
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            color: '#ffffff',
                            borderRadius: 20,
                            minHeight: 50,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: '#fff',
                            input: {
                                '&:-webkit-autofill': {
                                    WebkitBoxShadow: '0 0 0 1000px transparent inset'
                                },
                                color: '#ffffff',
                            },
                            '&$focused $notchedOutline': {
                                borderColor: '#fff',
                            },
                        }
                    }
                },
                MuiSelect: {
                    styleOverrides: {
                        select: {
                            borderRadius: 20,
                            minHeight: 50,
                            input: {
                                '&:-webkit-autofill': {
                                    WebkitBoxShadow: '0 0 0 1000px transparent inset'
                                }
                            }
                        }
                    }
                }

            })
    }
});
