import React from 'react';
import { TStepsProps } from 'components/Steps/d';
import { StepContainer, StepLabelContainer, StepperContainer, StepperIconDiv } from 'components/Steps/style';
import { StepIconProps } from '@mui/material';


const IconComponent = (props: StepIconProps) => {
  const { active, completed, icon } = props;

  return (
    <StepperIconDiv active={active ? 1 : 0} completed={completed ? 1 : 0}>
      {icon}
    </StepperIconDiv>
  );
};

const Steps = ({ steps, activeStep }: TStepsProps) => {


  return (
    <StepperContainer activeStep={activeStep}>
      {steps.map((step, index) => (
        <StepContainer key={index} visible={Number(steps.length > 1)}>
          <StepLabelContainer  StepIconComponent={IconComponent}/>
        </StepContainer>
      ))}
    </StepperContainer>
  );
};

export default Steps;
