import styled from 'styled-components';
import {Box} from '@mui/material';
import {Flex} from 'assets/style';

export const LoginContainer = styled(Box)`
    &&& {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
`;

export const LoginBox = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 520px;  
`;

export const LoginImage = styled.img`
  &&& {
    margin-bottom: 50px;
    max-width: 400px;
    max-height: 400px;
  }
`;

export const LoginForm = styled(Box)`
  &&& {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 50px;
  }
`;
