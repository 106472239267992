import {
    DefineVehicleContainer,
    VehicleFormContainer,
    VehicleFormikContainer,
    VehicleFormInputsContainer,
    VehicleFormTitle,
    VehicleFormTitleContainer
} from './style';
import Translation from '../../../../components/Translation';
import { Translate } from '../../../../internationalization/translate';
import React, { useCallback, useContext, useMemo } from 'react';
import { Grid } from '@mui/material';
import FormikTextField from '../../../../components/Formik/FormikTextField';
import { Formik, useFormikContext } from 'formik';
import { initalValuesVehicleAddForm, validationVehicleAddForm } from './validation';
import FormikSelectField from '../../../../components/Formik/FormikSelectField';
import { RegisterButton } from '../../../Drivers/components/DefineDriverForm/style';
import FormikCheckBox from '../../../../components/Formik/FormikCheckBox';
import CropImage from '../../../../components/Image/Crop';
import { useTranslation } from 'react-i18next';
import UiDataContext from '../../../../context/UiContext/context';
import useCreateVehicleMutation from '../../../../core/api/hooks/mutations/vehicle/useCreateVehicleMutation';
import { errorToast, successToast } from '../../../../components/Utils/Toast';
import FormikSelectYear from '../../../../components/Formik/FormikSelectYear';

/**
 *
 * DEV COMMENT
 *
 * @author Aleksandar Djordjevic
 * @date 04.04.2024
 *
 * TODO:Add this all form style in one file
 *
 */

export const vehicleTypesOpts = [
    { label: 'vehicleTypeCar', value: '1' },
    { label: 'vehicleTypeMotor', value: '2' },
    { label: 'vehicleTypeBicycle', value: '4' }
];


const VehicleForm = () => {
    const { values, setFieldValue } = useFormikContext() as any;

    const imageUrl = useMemo(() => values?.imageUrl, [values]);

    const handlerSaveImage = useCallback((data: any) => {
        setFieldValue('file', data, false);
    }, [setFieldValue]);

    const isTypeBike = useMemo(() => values?.vehicleType === '4', [values]);

    return (
        <VehicleFormikContainer>
            <VehicleFormInputsContainer>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <CropImage imageUrl={imageUrl} handlerSave={handlerSaveImage} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikSelectField
                            options={vehicleTypesOpts}
                            variant="outlined"
                            name={'vehicleType'}
                            label={Translate.vehicleType}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikTextField
                            variant="outlined"
                            name={'vehicleNumber'}
                            label={Translate.vehicleNumber}
                            fullWidth
                            type={'text'}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormikTextField
                            variant="outlined"
                            name={'name'}
                            label={Translate.vehicleName}
                            fullWidth
                            type={'text'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikSelectYear
                            variant="outlined"
                            name={'year'}
                            label={Translate.vehicleYear}
                            fullWidth
                            disabled={isTypeBike}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikTextField
                            variant="outlined"
                            name={'registrationPlate'}
                            label={Translate.vehicleRegistrationPlate}
                            fullWidth
                            type={'text'}
                            disabled={isTypeBike}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikCheckBox
                            name={'vehicleOwner'}
                            label={Translate.vehicleOwner}
                            type={'checkbox'}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </VehicleFormInputsContainer>
            <RegisterButton type={'submit'}>
                <Translation use={Translate.save} />
            </RegisterButton>
        </VehicleFormikContainer>
    );

};


const DefineVehicleForm = ({ setRefetch, closeModal }: any) => {
    const { t } = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const createVehicleMutation = useCreateVehicleMutation();

    /**
     *
     * DEV COMMENT
     *
     * @author Aleksandar Djordjevic
     * @date 04.04.2024
     *
     * TODO: Make function for submit
     *
     */
    const onSubmit = useCallback(async (values: any) => {
        if (!values?.name) return;
        try {
            setLoading();
            const result = await createVehicleMutation.mutateAsync({
                vehicleType: values.vehicleType,
                name: values.name,
                year: values?.year || undefined,
                registrationPlate: values?.registrationPlate || undefined,
                vehicleOwner: values?.vehicleOwner || undefined,
                vehicleNumber: values?.vehicleNumber || undefined,
                file: values?.file || undefined
            });
            if (result.status === 200) {
                successToast(t(result.message));
                setRefetch();
                closeModal();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
    }, [closeModal, resetLoading, setLoading, setRefetch, t, createVehicleMutation]);

    return (
        <DefineVehicleContainer>
            <VehicleFormContainer>
                <VehicleFormTitleContainer>
                    <Translation
                        use={Translate.vehicleAddModalTitle}
                        as={VehicleFormTitle}
                    />
                </VehicleFormTitleContainer>
                <Formik
                    initialValues={{
                        ...initalValuesVehicleAddForm
                    }}
                    validationSchema={validationVehicleAddForm}
                    onSubmit={onSubmit}
                >
                    <VehicleForm />
                </Formik>
            </VehicleFormContainer>
        </DefineVehicleContainer>
    );
};

export default DefineVehicleForm;