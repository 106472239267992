import React, { useCallback, useContext, useMemo } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ButtonIcon from 'components/button/ButtonIcon';
import { ListItemText } from '@mui/material';
import { UserActionMenu, UserActionMenuItem, UserActionMenuItemIcon } from 'views/Users/TableComponents/Actions/style';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TableDataContext from 'components/Table/context/context';
import { useModal } from 'hooks/useModal';
import Translation from 'components/Translation';
import { Translate } from 'internationalization/translate';
import DriverDialogActiveInActive from 'views/Drivers/components/Dialogs/ActiveInActive';
import DriverDialogBlock from 'views/Drivers/components/Dialogs/Block';
import DriverEditForm from 'views/Drivers/components/Dialogs/Edit';
import ConditionalRendering from 'components/Utils/ConditionalRendering';
import AutorenewIcon from '@mui/icons-material/Autorenew';

/**
 *
 * DEV COMMENT
 *
 * @author Aleksandar Djordjevic
 * @date 04.04.2024
 *
 * TODO: Make this like reusable component
 *
 */
const DriverActions = ({ row }: { row: any }) => {


  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);


  const { setRefetch } = useContext(TableDataContext);

  const { openModal: openDialogBlockDriver, closeModal: closeModalBlock } = useModal({
    modal: DriverDialogBlock,
    isActionsButtonsVisible: true,
    isCloseAction: false
  });

  const { openModal: openDialogDeleteDriver, closeModal: closeModalDelete } = useModal({
    modal: DriverDialogActiveInActive,
    isActionsButtonsVisible: true,
    isCloseAction: false
  });

  const { openModal: openDialogEditDriver, closeModal: closeModalEdit } = useModal({
    modal: DriverEditForm,
    isActionsButtonsVisible: false,
    isCloseAction: true
  });

  const [isBlocked, isActive] = useMemo(() => [
    row.isBlocked,
    row.active
  ], [row]);

  const handlerOpenDialogBlockDriver = useCallback(() => {
    openDialogBlockDriver({
      row,
      setRefetch,
      closeModal: closeModalBlock
    });
    handleClose();
  }, [row, setRefetch, openDialogBlockDriver, handleClose, closeModalBlock]);

  const handlerOpenDialogDeleteDriver = useCallback(() => {
    openDialogDeleteDriver({
      row,
      setRefetch,
      closeModal: closeModalDelete
    });
    handleClose();
  }, [row, setRefetch, openDialogDeleteDriver, handleClose, closeModalDelete]);

  const handlerOpenDialogEditDriver = useCallback(() => {
    openDialogEditDriver({
      row,
      setRefetch,
      closeModal: closeModalEdit
    });
    handleClose();
  }, [openDialogEditDriver, row, setRefetch, closeModalEdit, handleClose]);


  return (
    <>
      <ButtonIcon
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon/>
      </ButtonIcon>
      <UserActionMenu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ConditionalRendering condition={isActive}>
          <UserActionMenuItem
            disableRipple
            onClick={handlerOpenDialogBlockDriver}
            divider
          >
            <UserActionMenuItemIcon>
              <BlockIcon/>
            </UserActionMenuItemIcon>
            <Translation use={!isBlocked ? Translate.blockText : Translate.unblockText} as={ListItemText}/>
          </UserActionMenuItem>
        </ConditionalRendering>
        <ConditionalRendering condition={isActive}>
          <UserActionMenuItem
            disableRipple
            onClick={handlerOpenDialogEditDriver}
            divider
          >
            <UserActionMenuItemIcon>
              <EditIcon/>
            </UserActionMenuItemIcon>
            <Translation use={Translate.editText} as={ListItemText}/>
          </UserActionMenuItem>
        </ConditionalRendering>
        <UserActionMenuItem
          disableRipple
          onClick={handlerOpenDialogDeleteDriver}
        >
          <UserActionMenuItemIcon>
            {isActive ? <DeleteIcon/> : <AutorenewIcon/>}
          </UserActionMenuItemIcon>
          <Translation use={isActive ? Translate.deleteText : Translate.activateText} as={ListItemText}/>
        </UserActionMenuItem>
      </UserActionMenu>
    </>
  );
};

export default DriverActions;
