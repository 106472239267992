import React, { useCallback, useContext, useMemo } from 'react';
import useModalFormData from 'hooks/useModalFormData';
import UiDataContext from 'context/UiContext/context';
import Translation from 'components/Translation';
import { useTranslation } from 'react-i18next';
import { errorToast, successToast } from 'components/Utils/Toast';
import { useSelector } from 'react-redux';
import { selectGetDriver, selectGetVehicle } from '../../../../../store/app/appSlice';
import { Translate } from '../../../../../internationalization/translate';
import { SHIFT_SCHEDULING_STATUS } from '../../../view/SchedulingBoard/d';
import useTemporaryCanResumeShiftsMutation
    from '../../../../../core/api/hooks/mutations/shiftScheduling/useTemporaryCanResumeShiftsMutation';
import {
    ShiftSchedulingTempStopResumeDialogContainer,
    ShiftSchedulingTempStopResumeDialogHeader,
    ShiftSchedulingTempStopResumeDialogSubText,
    ShiftSchedulingTempStopResumeDialogText
} from '../TemporaryStop/style';

const ShiftSchedulingTemporaryCanResumeDialog = ({ id, status, vehicleId, driverId, setRefetch, closeModal }: any) => {

    const { t } = useTranslation();
    const shiftTemporaryCanResumeMutation = useTemporaryCanResumeShiftsMutation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const driver = useSelector(selectGetDriver(driverId));
    const vehicle = useSelector(selectGetVehicle(vehicleId));

    const [driverName, vehicleName, isTempCanResume] = useMemo(() => [
        driver?.fullName,
        vehicle?.name,
        status === SHIFT_SCHEDULING_STATUS.TEMP_STOP
    ], [driver, vehicle, status]);

    const handlerOnSubmit = useCallback(async () => {
        if (!id || !isTempCanResume) return false;
        try {
            setLoading();
            const result = await shiftTemporaryCanResumeMutation.mutateAsync({
                id
            });
            if (result?.status === 200) {
                setRefetch();
                successToast(t(result.message));
                closeModal();
            }
        } catch (e: any) {
            console.log(e);
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
        return true;
    }, [id, setLoading, shiftTemporaryCanResumeMutation, t, isTempCanResume, setRefetch, closeModal, resetLoading]);

    useModalFormData({
        handlerOnSubmit
    });


    return (
        <ShiftSchedulingTempStopResumeDialogContainer>
            <Translation use={Translate.shiftSchedulingTempCanResumeTitle}
                         as={ShiftSchedulingTempStopResumeDialogHeader} />
            <Translation use={Translate.shiftSchedulingTempCanResumeText}
                         as={ShiftSchedulingTempStopResumeDialogText} />
            <Translation use={Translate.shiftSchedulingTempCanResumeDriverText}
                         as={ShiftSchedulingTempStopResumeDialogSubText} params={{ name: driverName }} />
            <Translation use={Translate.shiftSchedulingTempCanResumeVehicleText}
                         as={ShiftSchedulingTempStopResumeDialogSubText} params={{ name: vehicleName }} />
        </ShiftSchedulingTempStopResumeDialogContainer>
    );
};


export default ShiftSchedulingTemporaryCanResumeDialog;
