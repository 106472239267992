import React, { useMemo } from 'react';
import { RestaurantItemImageContainer,  StyledAvatar, StyleLazyLoadImage } from './style';


const RestaurantItemImage = ({ row }: { row: any }) => {
    const [url, name] = useMemo(() => [
        row?.imageUrl,
        row?.name
    ], [row]);


    return (
        <RestaurantItemImageContainer>
            <StyledAvatar>
                {url ?
                    <StyleLazyLoadImage
                        alt={name}
                        effect="blur"
                        src={url} />
                    : null}
            </StyledAvatar>
        </RestaurantItemImageContainer>
    );
};

export default RestaurantItemImage;
