import React, { useCallback, useEffect } from 'react';
import { ApprovalRequestButtonBadge, ApprovalRequestButtonIcon, ApprovalRequestIcon } from './style';
import { Colors } from '../../../../theme/palette';
import { Menu } from '@mui/material';
import ApprovalsList from './ApprovalsList';
import useGetApprovalsCount from '../../../../core/api/hooks/query/approvals/useGetApprovalsCount';


const ApprovalRequests = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);


    const { data, refetch } = useGetApprovalsCount();

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        if(!data) return;
        setAnchorEl(event.currentTarget);
    }, [setAnchorEl, data]);
    const handleClose = useCallback((_: any, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        setAnchorEl(null);
    }, [setAnchorEl]);


    useEffect(() => {
        if(!data) {
            setAnchorEl(null);
            return;
        }
    }, [data,setAnchorEl]);

    return (
        <>
            <ApprovalRequestButtonIcon
                aria-controls={open ? 'approvals' : undefined}
                onClick={handleClick}
                disabled={!data}
            >
                { data ? <ApprovalRequestButtonBadge badgeContent={<>{data}</>}>
                    <ApprovalRequestIcon />
                </ApprovalRequestButtonBadge> : <ApprovalRequestIcon /> }
            </ApprovalRequestButtonIcon>
            <Menu
                id="basic-menu1"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: {
                        padding: 0
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        minWidth: 520,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        border: `1px solid ${Colors.gradient.yellowLight}`,
                        borderRadius: '8px'
                    }
                }}
            >
                <ApprovalsList onClose={handleClose} setRefetchCount={refetch} />
            </Menu>
        </>
    );
};

export default ApprovalRequests;