import React, { useCallback, useMemo, useReducer, useRef } from 'react';
import TableDataContext from 'components/Table/context/context';
import { cloneDeep, get, isEqual, merge, omit } from 'lodash';
import { TABLE_EVENTS, TFilerPayload, TReducer, TReducerAction } from 'components/Table/context/d';
import { useFilterNumber } from 'components/Table/context/helpers';
import { endOfDay, startOfDay } from 'date-fns';
import { emptyArray, emptyObject } from 'utils/index';

export const MAX_PER_PAGE = 50000;

const reducer = (state: TReducer, action: TReducerAction) => {
    switch (action.type) {
        default:
            return state;


        case TABLE_EVENTS.clearAllFilters: {
            return {
                ...state,
                filter: {},
                isNeedRefetch: Math.floor(new Date().getTime())
            };
        }

        case TABLE_EVENTS.addFilter: {
            const field = action.payload.field;
            const data = action.payload.data;
            if (isEqual(get(state.filter, field), data)) return state;
            let filter = { ...state.filter };
            if (`${data}`.length === 0) {
                filter = omit({ ...state.filter }, [action.payload]);
            } else {
                filter = {
                    ...filter,
                    [field]: data
                };
            }
            return {
                ...state,
                filter: filterByOrder(filter),
                isNeedRefetch: Math.floor(new Date().getTime())
            };
        }

        case TABLE_EVENTS.addMultiFilter: {
            const data = action.payload as any;
            const _filter = Object.keys(data).filter(key => {
                if (isEqual(get(state.filter, key), data[key])) return false;
                return true;
            });
            let filter = { ...state.filter };

            if (!_filter.length) {
                filter = omit({ ...state.filter }, [action.payload]);
            } else {
                const _data = _filter.reduce((acc, f) => {
                    return {
                        ...acc,
                        [f]: data[f]
                    };
                }, {});
                filter = {
                    ...filter,
                    ..._data
                };
            }
            return {
                ...state,
                filter: filterByOrder(filter),
                isNeedRefetch: Math.floor(new Date().getTime())
            };
        }

        case TABLE_EVENTS.clearFilter: {
            let filter = { ...state.filter };
            filter = omit(filter, [action.payload]);
            return {
                ...state,
                filter: filterByOrder(filter),
                isNeedRefetch: Math.floor(new Date().getTime())
            };
        }
        case TABLE_EVENTS.setPage: {
            const page = action.payload;
            if (state.page === page) return state;
            return {
                ...state,
                page,
                isNeedRefetch: Math.floor(new Date().getTime())
            };
        }
        case TABLE_EVENTS.setPerPage: {
            const perPage = action.payload;
            if (perPage === state.perPage) return state;
            return {
                ...state,
                page: 0,
                perPage,
                isNeedRefetch: Math.floor(new Date().getTime())
            };
        }
        case TABLE_EVENTS.clearNeedRefetch: {
            return {
                ...state,
                isNeedRefetch: 0
            };
        }

        case TABLE_EVENTS.setRefetch: {
            return {
                ...state,
                isNeedRefetch: Math.floor(new Date().getTime())
            };
        }
    }
};
const filterByOrder = (filter: any) => {
    const keys = Object.keys(filter);
    keys.sort();
    return keys.reduce((acc, x) => {
        const val = filter[x];
        if (Array.isArray(val)) val.sort();
        return {
            ...acc,
            [x]: val
        };
    }, {});

};


const useTableContext = (dataEntry: any) => {
    const {
        columns: entryColumns,
        extendedColumns = emptyArray,
        fnFilters,
        requestOptions: optionsEntry = emptyObject,
        includeModelMap,
        hideColumnVisibility = false,
        filter: _filter = emptyObject,
        page = 0,
        sort,
        perPage,
        isExportCsv = false,
        isGlobalSearch = false,
        tableSearchTooltip = 'Search by asin / mpn',
        additional: entryAdditional = emptyObject,
        tableSystemID
    } = dataEntry;
    const { filterNumberValue } = useFilterNumber(true);

    const dataReducerStartState = useRef({
        columns: entryColumns,
        savedFilters: {},
        page,
        perPage:  window.innerWidth >= 2560 ? 17 : perPage || 10,
        isNeedRefetch: 1,
        filter: filterByOrder(_filter),
        sort,
        additional: entryAdditional,
        hideColumnVisibility,
        isExportCsv,
        isGlobalSearch,
        tableSearchTooltip,
        tableSystemID
    });
    const [state, dispatch] = useReducer(reducer, dataReducerStartState.current as TReducer);
    const options = useRef(optionsEntry);

    const { columns, filter, additional } = state as any;

    const getFilterByType = useCallback((fieldType: string, key: string, data: any) => {
        switch (fieldType) {
            default:
                return {
                    [key]: { $like: `%${data}%` }
                };

            case 'value':
            case 'multiselection':
                return {
                    [key]: data
                };
            case 'selection':
                return {
                    [key]: data.value
                };

            case 'number':
                return {
                    [key]: filterNumberValue(data)
                };
            case 'date':
                return {
                    [key]: {
                        $gte: startOfDay(data),
                        $lte: endOfDay(data)
                    }
                };

            case 'fn': {
                return merge({}, fnFilters)?.[key]?.(data) || undefined;
            }

        }
    }, [fnFilters, filterNumberValue]);

    const getAllColumns = useCallback((cols: any) => {
        return cols.map((col: any) => {
            if (col?.accessorKey) return col;
            return getAllColumns(col.columns);
        });
    }, []);

    const getAllColumnsWithIncludedModels = useCallback((cols: any) => {
        const _cols = getAllColumns(cols).flat();
        return _cols.map((x: any) => {
            let model = '' as any;
            if (x?.includeModel) {
                const arr = x?.includeModel.split('.');
                model = arr.map((x: any) => includeModelMap?.[x]).join('.') as any;
            }
            return {
                ...x,
                model: model ? model : undefined
            };
        });
    }, [getAllColumns, includeModelMap]);

    /*const _filtersFetchData = useCallback(() => {
        const array = Object.keys(filter).map(key => {
                const data = filter[key] || '';
                if (columns) {
                    const _cols = getAllColumns(columns).flat();
                    const column = _cols.find((f: any) => f.accessorKey === key || f.fieldName === key) || extendedColumns.find((f: any) => f.accessorKey === key || f.fieldName === key);
                    if (column) {
                        if (column.includeModel) return undefined;
                        if (column.accessorKey) key = column.fieldName ? column.fieldName : column.accessorKey;
                        if (column?.fieldType !== 'selection') {
                            if (!`${data}`.length) return undefined;
                        }
                        return getFilterByType(column?.fieldType, key, data);
                    }
                }
                return merge({}, fnFilters)?.[key]?.(data) || undefined;
            }
        )
            .filter(x => !!x);
        if (!array.length) return {};
        return {
            filter: {
                $and: array
            }
        };
    }, [filter, getFilterByType, fnFilters, getAllColumns, columns, extendedColumns]);
*/
    const filtersFetchData = useCallback(() => {
        return Object.keys(filter).reduce((acc, key) => {
            const data = filter[key] || '';
            return {
                ...acc,
                [key]: data
            };
        }, {});
    }, [filter]);


    const filterIncludeModelsData = useCallback(() => {
        const { include } = options.current;
        if (!include) return {};
        const array = Object.keys(filter).map(key => {
            if (!columns) return {};
            const column = columns.find((f: any) => f.accessorKey === key || f.fieldName === key) || extendedColumns.find((f: any) => f.accessorKey === key || f.fieldName === key);
            if (!column || !column.includeModel) return undefined;
            const data = filter[key];
            return {
                model: column.includeModel,
                filter: getFilterByType(column.fieldType || 'string', column.fieldName || key, data)
            };
        }).filter(x => !!x) as any;

        const _array = array.reduce((acc: any, d: any) => {
            const oldIndex = acc.findIndex((x: any) => x.model === d.model);
            if (oldIndex === -1) {
                return [
                    ...acc,
                    {
                        model: d.model,
                        filter: [d.filter]
                    }
                ];
            }
            acc.splice(oldIndex, 1, {
                model: d.model,
                filter: [...acc[oldIndex].filter, d.filter]
            });
            return [...acc];
        }, []).map((x: any) => ({
            model: x.model.split('.'),
            filter: {
                $and: x.filter
            }
        }));

        const _include = _array.reduce((acc: any, oneModel: any) => {
            let stringFind = 'include';
            const mapIndex = oneModel.model.map((p: any) => {
                const pointer = get(acc, stringFind);
                if (!pointer) return -1;
                const f = pointer.findIndex((hh: any) => hh.model === p || hh.as === p);
                if (f === -1) return -1;
                stringFind = stringFind + `[${f}].include`;
                return f;
            }).filter((x: any) => x !== -1);

            if (mapIndex.length !== oneModel.model.length) return acc;

            stringFind = `include[${mapIndex[0]}]`;
            let obj = get(acc, stringFind);
            obj.required = true;

            mapIndex.slice(1).forEach((x: any) => {
                stringFind = stringFind + `.include[${x}]`;
                obj = get(acc, stringFind);
                obj.required = true;
            });

            obj.filter = oneModel.filter;
            return { ...acc };
        }, cloneDeep(options.current));
        return _include;
    }, [filter, options, columns, getFilterByType, extendedColumns]);

    const requestOptions = useMemo(() => {
        const { page, perPage } = state;
        const filterData = filtersFetchData();
        const filterDataInclude = filterIncludeModelsData();

        return merge({}, {
                perPage: perPage === -1 ? MAX_PER_PAGE : perPage || 25,
                page: perPage === -1 ? 1 : (page + 1) || 1
            },
            { ...optionsEntry },
            filterData,
            filterDataInclude,
            additional
        ) as any;
    }, [state, additional, optionsEntry, filtersFetchData, filterIncludeModelsData]);

    const setPage = useCallback((payload: number) => {
        dispatch({
            type: TABLE_EVENTS.setPage,
            payload
        });
    }, [dispatch]);

    const setPerPage = useCallback((payload: number) => {
        dispatch({
            type: TABLE_EVENTS.setPerPage,
            payload
        });
    }, [dispatch]);

    const visibleColumns = useMemo(() => columns, [columns]);

    const clearNeedRefetch = useCallback(() => {
        dispatch({
            type: TABLE_EVENTS.clearNeedRefetch
        });
    }, [dispatch]);

    const setFilter = useCallback((payload: TFilerPayload) => {
        dispatch({
            type: TABLE_EVENTS.addFilter,
            payload
        });
    }, [dispatch]);

    const setMultiFilter = useCallback((payload: any) => {
        dispatch({
            type: TABLE_EVENTS.addMultiFilter,
            payload
        });
    }, [dispatch]);

    const clearFilter = useCallback((field: string) => {
        dispatch({
            type: TABLE_EVENTS.clearFilter,
            payload: field
        });
    }, [dispatch]);

    const refCurrentFilter = useRef({} as any);
    refCurrentFilter.current = {
        filter: filter,
        tableSystemID
    };


    const clearAllFilters = useCallback(() => {
        dispatch({
            type: TABLE_EVENTS.clearAllFilters
        });
    }, [dispatch]);

    const setRefetch = useCallback(() => {
        dispatch({
            type: TABLE_EVENTS.setRefetch
        });
    }, [dispatch]);


    return useMemo(() => {
        return {
            ...state,
            visibleColumns,
            setPage,
            setPerPage,
            clearNeedRefetch,
            setFilter,
            clearFilter,
            requestOptions,
            hideColumnVisibility,
            getAllColumns,
            getAllColumnsWithIncludedModels,
            clearAllFilters,
            setRefetch,
            setMultiFilter
        };
    }, [
        state,
        visibleColumns,
        setPage,
        setPerPage,
        clearNeedRefetch,
        setFilter,
        clearFilter,
        requestOptions,
        hideColumnVisibility,
        getAllColumns,
        getAllColumnsWithIncludedModels,
        clearAllFilters,
        setRefetch,
        setMultiFilter
    ]);
};

export type TTableDataContextType = ReturnType<typeof useTableContext>;

const TableDataContextContainer = ({
                                       children,
                                       columns,
                                       fnFilters,
                                       ...rest
                                   }: React.PropsWithChildren<{
    columns: any[],
    fnFilters?: any,
}>) => {

    const _columns = useMemo(() => [...columns], [columns]);

    const providerData = useTableContext({
        columns: _columns,
        fnFilters,
        ...rest
    });

    return (
        <TableDataContext.Provider value={providerData}>
            {children}
        </TableDataContext.Provider>
    );
};

export default TableDataContextContainer;
