import React, {
    PropsWithChildren,
    useMemo
}                   from 'react';
import {
    RenderLabelValueContainer,
    LabelPart,
    ValuePart,
    EmptyValue
} from './style';
import {
    isString
} from 'lodash';
import { useTranslation } from 'react-i18next';

type TRenderLabelValueProps = {
    label?: string
    value?: string|number
    isUpperCase?: boolean
    children?: any
    format?: any
    align?:  'center' | 'flex-start' | 'flex-end'
    valueColor?: string
    labelCenter?: boolean
    placeholder?: string
    className?: string
    isRow?: boolean
    isDivider?: boolean
} & PropsWithChildren<any>

const RenderLabelValue = ({ label, value, children, format, isUpperCase, align, valueColor, labelCenter, isRow, placeholder= '###.##', className }: TRenderLabelValueProps) => {

    const {t} = useTranslation();
    const _value = useMemo(()=> {
        if(isString(value) && !value) return;
        if(format) return format(value, false);
        return value;
    },[value,format]);


    const isNotValue = useMemo(()=> !value && !children , [value,children]);

    return (
        <RenderLabelValueContainer className={className} isRow={isRow}>
            {label && <LabelPart isCenter={labelCenter} isUpperCase={isUpperCase}>{t(label)}</LabelPart>}
            {/*isDivider && <AppDivider />*/}
            <ValuePart align={align} valueColor={valueColor} isNotValue={isNotValue}>
                {
                  _value ? _value : children ? children : <EmptyValue>{placeholder}</EmptyValue>
                }
            </ValuePart>
        </RenderLabelValueContainer>
    );
};

export default RenderLabelValue;
