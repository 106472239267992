import * as yup from 'yup';
import { Translate } from '../../../../../internationalization/translate';

export const initalValuesVehicleEditForm = {
    name: '',
    year: '',
    registrationPlate: '',
    vehicleOwner: '',
    vehicleType: '',
    vehicleNumber: '',
    imageUrl: ''
};

/**
 *
 * DEV COMMENT
 *
 * @author Aleksandar Djordjevic
 * @date 05.04.2024
 *
 * TODO: In email validation add to user can type space
 * TODO: Add validations for picture and vehicle ownership
 *
 */
export const validationVehicleEditForm = yup.object({
    name: yup.string()
        .required(Translate.vehicleNameRequired)
        .min(1, Translate.vehicleNameMinLength)
        .max(40, Translate.vehicleNameMaxLength),
    year: yup.lazy((_, options) => options.parent.vehicleType === '4'
        ? yup.string().notRequired()
        : yup.string().required(Translate.businessNameRequired)
    ),
    registrationPlate: yup.lazy((_, options) =>
        options.parent.vehicleType === '4'
            ? yup.string().notRequired()
            : yup.string().required(Translate.vehicleRegistrationPlateRequired)
                .min(7, Translate.vehicleRegistrationPlateMinLength)
                .matches(/^[a-zA-ZČĆĐŠŽ0-9-]+$/, Translate.vehicleRegistrationPlateValidChar)
    ),
    vehicleOwner: yup.string()
        .required(Translate.vehicleOwnerRequired),
    vehicleType: yup.string()
        .required(Translate.vehicleTypeRequired),
    vehicleNumber: yup.string()
        .required(Translate.vehicleNumberRequired)
        .min(1, Translate.vehicleNumberMinLength)
        .matches(/^\d+$/, Translate.vehicleNumberOnlyNumber),
    imageUrl: yup.string().optional()
    // .required(Translate.businessNameRequired),
});