import axiosClient from 'core/api/config/axios';
import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'core/api/utils/createUrl';


const useGetApprovalsCount = () => {
  const { data, isLoading, isFetched, isFetching, refetch } = useQuery({
    queryKey: ['get-approvals-count'],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await axiosClient.get(createUrl({
        url: '/dispatcher/approval-requests-count',
        version: 'v1'
      }));
      return res.data?.data || 0;
    },
    refetchInterval: 120000,
    refetchIntervalInBackground: true
  });
  return { data, isLoading, isFetched, isFetching, refetch };
};

export default useGetApprovalsCount;
