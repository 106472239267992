import styled from 'styled-components';
import { Flex } from 'assets/style';


export const TableCustomHeaderContainer = styled(Flex)`
    width: 100%;
    color: black;
    padding: 10px 25px 10px 15px;
    justify-content: space-between;
    align-items: center;
`;
