import { ChangeShiftStartAmountTitle } from '../ChangeShiftStartAmount/style';
import { ChangeSettingsShiftPickersContainer, ChangeSettingsShiftsContainer, ChangeSettingsShiftsForm } from './style';
import Translation from '../../../../../components/Translation';
import { Translate } from '../../../../../internationalization/translate';
import { Formik } from 'formik';
import * as yup from 'yup';
import { SettingsDialogButton, SettingsDialogButtonsForm } from '../../../view/style';
import { Colors } from '../../../../../theme/palette';
import useUpdateSettingsMutation from '../../../../../core/api/hooks/mutations/settings/useUpdateSettingsMutation';
import { useSelector } from 'react-redux';
import { selectSettings, setSettings } from '../../../../../store/app/appSlice';
import { useCallback, useContext, useMemo } from 'react';
import { SETTINGS_KEYS } from '../../../../../store/app/d';
import UiDataContext from '../../../../../context/UiContext/context';
import { useAppDispatch } from '../../../../../store/hooks';
import FormikTimepicker from '../../../../../components/Formik/FormikTimepicker';
import { SHIFT_TYPES } from '../../../../Schedule/components/Dialogs/DefineShiftsForDay/context/d';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';

const validationSchema = yup.object({
    from: yup.string().required('required'),
    to: yup.string().required('required')
});

const ChangeSettingsShift = ({ closeModal, shiftType = SHIFT_TYPES.FIRST_SHIFT }: any) => {

    const settings = useSelector(selectSettings);
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const [id, data] = useMemo(() => {
        const sett = settings?.[SETTINGS_KEYS.SHIFT_SCHEDULING];
        const data = sett?.valueJson?.[shiftType];
        const currDate = moment().format('YYYY-MM-DD');
        return [
            sett.id,
            { from: moment(`${currDate} ${data.from}:00`).toDate(), to: moment(`${currDate} ${data.to}:00`).toDate() }
        ];
    }, [settings]);
    const updateSettingsMutation = useUpdateSettingsMutation(id);


    const onSubmit = useCallback(async (values: any) => {
        if (!values?.from || !values?.to) return;
         try {
              setLoading();
              const result = await updateSettingsMutation.mutateAsync({
                  key: SETTINGS_KEYS.SHIFT_SCHEDULING,
                  valueJson: {
                      ...settings?.[SETTINGS_KEYS.SHIFT_SCHEDULING]?.valueJson,
                      [shiftType]: {
                          from: moment(values.from).format('HH:mm'),
                          to:  moment(values.to).format('HH:mm')
                      }
                  }
              });
              if (result?.status === 200) {
                  if(result?.data) {
                      dispatch(setSettings({
                          [SETTINGS_KEYS.SHIFT_SCHEDULING]: {...(result?.data || {})}
                      }));
                  }
                  successToast(t(result.message));
                  closeModal();
              }
          }catch (e: any) {
              errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
              return false;
          }finally {
              resetLoading();
          }
    }, [resetLoading, setLoading,t, settings, updateSettingsMutation, closeModal, shiftType, dispatch]);


    const onCancel = () => {
        closeModal();
    };

    return (
        <ChangeSettingsShiftsContainer>
            <Formik
                initialValues={{
                    ...data
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                <ChangeSettingsShiftsForm>
                    <Translation use={Translate.settingsShiftChangeShiftStartTitle} as={ChangeShiftStartAmountTitle} />
                    <ChangeSettingsShiftPickersContainer>
                        <FormikTimepicker
                            name={'from'}
                            variant={'outlined'}
                            label={Translate.settingsShiftStartLabel}
                        />
                        <FormikTimepicker
                            name={'to'}
                            variant={'outlined'}
                            label={Translate.settingsShiftEndLabel}
                        />
                    </ChangeSettingsShiftPickersContainer>
                    <SettingsDialogButtonsForm isColumn={1}>
                        <SettingsDialogButton type={'submit'}>
                            <Translation use={Translate.save} />
                        </SettingsDialogButton>
                        <SettingsDialogButton background={Colors.white} onClick={onCancel}>
                            <Translation use={Translate.cancel} />
                        </SettingsDialogButton>
                    </SettingsDialogButtonsForm>
                </ChangeSettingsShiftsForm>
            </Formik>

        </ChangeSettingsShiftsContainer>
    );
};

export default ChangeSettingsShift;