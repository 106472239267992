import styled from 'styled-components';
import {Flex} from '../../assets/style';
import {Box} from '@mui/material';


export const OnlyDesktopContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    background: #000000;
`;

export const OnlyDesktopHeader = styled(Flex)`
    padding: 55px 0;
    justify-content: center;
    align-content: center;

    @media (max-width: 767px) {
        padding: 34px 0;
    }
    
`;

export const OnlyDesktopTitle = styled(Flex)`
    font: normal normal 700 56px/70px "Readex Pro";
    background: linear-gradient(95.57deg, #FFF200 -4.33%, #FFD700 47.64%, rgba(255, 255, 255, 0.2) 105.08%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 767px) {
        font-size: 28px;
        line-height: 36px;
    }
    
`;

export const OnlyDesktopContent = styled(Flex)`
    flex: 2;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const OnlyDesktopContentContainer = styled(Flex)`
    flex: 2;
    width: 60%;
    max-width: 930px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    @media (max-width: 767px) {
        width: 100%;
        justify-content: center;
        padding: 15px;
    }

`;

export const OnlyDesktopText = styled(Flex)`
    font: normal normal 700 32px/48px "Readex Pro";
    color: white;
    text-align: center;
    white-space: break-spaces;
    word-break: break-word;

    @media (max-width: 767px) {
       font-size: 22px;
       line-height: 26px;
    }
    
`;

export const OnlyDesktopContainerImage = styled(Flex)`
    width: 500px;
    height: 500px;


    @media (max-width: 767px) {
        width: auto;
        height:auto;
        justify-content: center;
        align-items: center;  
        padding: 20px 0;
    }

`;

export const OnlyDesktopImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;

    @media (max-width: 767px) {
        width: 320px;
        height: 306px;
    }
    
    
`;
