import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';
import { TCreateSchedulingByDay } from '../d';
import { SHIFT_TYPES } from '../../../../../views/Schedule/components/Dialogs/DefineShiftsForDay/context/d';


const useUpdateShiftsMutation = () => {
    const mutation = useMutation({
        mutationFn: async (data: { shiftDate: string, shiftType: SHIFT_TYPES,  data: TCreateSchedulingByDay[] }) => {
            const response = await axiosClient.put(createUrl({
                url: '/dispatcher/shift-scheduling',
                version: 'v1',
            }), data, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        }
    });
    return mutation;
};

export default useUpdateShiftsMutation;
