import React, { FC, useCallback, useContext } from 'react';
import { OrderAbortButton, OrderAbortContainer, OrderAbortFooter, OrderAbortForm } from './style';
import useModalFormData from '../../../../../hooks/useModalFormData';
import { Translate } from '../../../../../internationalization/translate';
import { Formik } from 'formik';
import Translation from '../../../../../components/Translation';
import * as yup from 'yup';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import { queryClient } from '../../../../../core/root/ReactQueryProvider';
import UiDataContext from '../../../../../context/UiContext/context';
import { useTranslation } from 'react-i18next';
import FormikTextField from '../../../../../components/Formik/FormikTextField';
import useAbortOrderMutation from '../../../../../core/api/hooks/mutations/order/useAbortOrderMutation';

type Props = {
    id: number|string
    closeModal: () => void,
    order: any
}

const schema = yup.object().shape({
    abortNote: yup.string().required(Translate.required),
});

const OrderAbort: FC<Props> = ({
   id,
   closeModal,
   order
}) => {

    const { setLoading, resetLoading } = useContext(UiDataContext);
    const {t} = useTranslation();
    const abortOrderMutation = useAbortOrderMutation();
    
    const onSubmit = useCallback(async (values: any) => {
        if (!values || !id) return;
        try {
            setLoading();
            const result = await abortOrderMutation.mutateAsync({
                id,
                abortNote: values.abortNote
            });
            if (result?.status === 200) {
                successToast(t(result.message));
                await queryClient.invalidateQueries({queryKey: ['get-orders']});
                await queryClient.invalidateQueries({queryKey: ['get-current-drivers']});
                closeModal();
            }
        }catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        }finally {
            resetLoading();
        }
    },[id, setLoading, resetLoading, abortOrderMutation]);


    const handleCancel = useCallback(()=> {
        closeModal();
    },[closeModal]);

    useModalFormData({
        modalTitle: t(Translate.orderViewAbortDialogTitle, {dailyNumber: order?.dailyNumber})
    });



    return (
        <OrderAbortContainer>
            <Formik
                initialValues={{
                    abortNote: ''
                }}
                validationSchema={schema}
                onSubmit={onSubmit}
            >
                <OrderAbortForm>
                    <FormikTextField
                        fullWidth
                        variant={'outlined'}
                        label={'orderViewAbortNoteLabel'}
                        name={'abortNote'}
                        rows={4}
                        multiline
                    />
                    <OrderAbortFooter>
                        <OrderAbortButton isWhite onClick={handleCancel}>
                            <Translation use={Translate.cancel} />
                        </OrderAbortButton>
                        <OrderAbortButton type="submit">
                            <Translation use={Translate.orderViewAbortDialogButtonLabel} />
                        </OrderAbortButton>
                    </OrderAbortFooter>
                </OrderAbortForm>
            </Formik>
        </OrderAbortContainer>
    );
};

export default OrderAbort;