import React, { useMemo } from 'react';
import { UserStatusBadge, UserStatusContainer } from 'views/Users/TableComponents/UserStatus/style';
import Translation from 'components/Translation';
import { Translate } from 'internationalization/translate';

const UserStatus = ({ row }: { row: any }) => {

    const [statusText, isActive] = useMemo(() => [
        row.active !== 1 ? Translate.inactive : row.isBlocked === 1 ? Translate.blocked : (typeof row.isPhoneVerified !== 'undefined' && !row.isPhoneVerified) ? Translate.notVerified : Translate.active,
        row.active && !row?.isBlocked ? (typeof row.isPhoneVerified !== 'undefined' && !row.isPhoneVerified) ? 2 : 1 : 0
    ], [row]);

    return (
        <UserStatusContainer>
            <UserStatusBadge active={isActive}>
                <Translation use={statusText} />
            </UserStatusBadge>
        </UserStatusContainer>
    );
};

export default UserStatus;
