import { useLocation } from 'react-router-dom';
import {
    RestaurantMenuContainer,
    RestaurantMenuHeaderAddButton,
    RestaurantMenuHeaderButtonsPart,
    RestaurantMenuTableContainer
} from './style';
import Translation from '../../components/Translation';
import { Translate } from '../../internationalization/translate';
import { DriverHeaderType, DriversHeader } from '../Drivers/style';
import AddIcon from '@mui/icons-material/Add';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useModal } from '../../hooks/useModal';
import DefineRestaurantMenu from './component/DefineRestaurantMenu';
import Panel from '../../components/Panel';
import { RestaurantMenuColumns } from './def';
import Table from '../../components/Table';
import useRestaurantMenuQuery from '../../core/api/hooks/query/restaurantMenu/useRestaurantMenuQuery';
import ConditionalRendering from '../../components/Utils/ConditionalRendering';
import AddMenuItemDialog from './component/Dialogs/AddMenuItemDialog';
import TableHeaderMenu from './component/TableComponents/Header';
import CategorySectionDialog from './component/Dialogs/CategorySectionDialog';
import { queryClient } from '../../core/root/ReactQueryProvider';

const RestaurantMenu = () => {
    const location = useLocation();
    const [needRefetch, setNeedRefetch] = useState<number>(0);
    const row = useMemo(()=> location?.state && location?.state?.rowData, [location]);

    const [name, restaurantId] = useMemo(() => [
        row?.businessName || '',
        row?.id
    ], [row]);

    const handlerSetRefetch = useCallback(() => {
        setNeedRefetch(new Date().getTime());
        queryClient.refetchQueries({
            queryKey: ['restaurant-categories-by-type', {id: `${restaurantId}`}]
        });
        queryClient.refetchQueries({
            queryKey: ['restaurant-menu-items']
        });
    }, [setNeedRefetch]);
    const refetchDone = useCallback(() => setNeedRefetch(0), [setNeedRefetch]);


    const data = useRestaurantMenuQuery(row?.id);

    const [isMenuCreated, lastPosition] = useMemo(() => [
        !!(row?.id && data?.id),
        (data?.lastPosition || 0) + 1
    ], [row, data]);

    const { openModal: openDialogDefineDriver, closeModal } = useModal({
        modal: DefineRestaurantMenu,
        isActionsButtonsVisible: false,
        isCloseAction: true
    });

    const { openModal: openDialogAddMenuItem, closeModal: closeModalAddItem } = useModal({
        modal: AddMenuItemDialog,
        isActionsButtonsVisible: false,
        isCloseAction: true
    });

    const { openModal: openDialogCategorySection, closeModal: closeModalCategorySection } = useModal({
        modal: CategorySectionDialog,
        isActionsButtonsVisible: false,
        isCloseAction: true
    });

    const handlerOpenDialogDefineDriver = useCallback(() => {
        if (isMenuCreated) return;
        openDialogDefineDriver({
            row,
            setRefetch: handlerSetRefetch,
            closeModal
        });
    }, [closeModal, openDialogDefineDriver, isMenuCreated]);


    const handlerOpenDialogAddMenuItem = useCallback(() => {
        if (!isMenuCreated) return;
        openDialogAddMenuItem({
            restaurantId: data?.restaurantId,
            menuId: data.id,
            setRefetch: handlerSetRefetch,
            closeModal: closeModalAddItem,
            lastPosition
        });
    }, [openDialogAddMenuItem, closeModalAddItem, data, row, handlerSetRefetch, lastPosition]);

    const handlerOpenDialogCategrySection = useCallback(() => {
        openDialogCategorySection({
            restaurantId: row?.id,
            setRefetch: handlerSetRefetch,
            closeModal: closeModalCategorySection
        });
    }, [openDialogCategorySection, closeModalCategorySection, row, handlerSetRefetch]);




    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: ['restaurant-menu-items'] }).then();
    }, []);

    return (
        <RestaurantMenuContainer>
            <DriversHeader>
                <Translation use={Translate.restaurantMenuTitle} as={DriverHeaderType} params={{ name }} />
                <RestaurantMenuHeaderButtonsPart>
                    <RestaurantMenuHeaderAddButton
                        onClick={handlerOpenDialogCategrySection}
                        startIcon={<AddIcon />}
                    >
                        <Translation use={Translate.restaurantCategorySectionLabel} />
                    </RestaurantMenuHeaderAddButton>
                    <ConditionalRendering condition={!isMenuCreated}>
                        <RestaurantMenuHeaderAddButton
                            onClick={handlerOpenDialogDefineDriver}
                            startIcon={<AddIcon />}
                        >
                            <Translation use={Translate.driverAddButtonTitle} />
                        </RestaurantMenuHeaderAddButton>
                    </ConditionalRendering>
                    <ConditionalRendering condition={isMenuCreated}>
                        <RestaurantMenuHeaderAddButton
                            onClick={handlerOpenDialogAddMenuItem}
                            startIcon={<AddIcon />}
                        >
                            <Translation use={Translate.restaurantMenuItemAddButtonTitle} />
                        </RestaurantMenuHeaderAddButton>
                    </ConditionalRendering>
                </RestaurantMenuHeaderButtonsPart>
            </DriversHeader>
            <RestaurantMenuTableContainer>
                <Panel>
                        <Table
                            columns={RestaurantMenuColumns}
                            url={`/dispatcher/restaurant/menu-items/${data?.id}`}
                            queryKey={`restaurant-menu-items-${data?.id}`}
                            useHeader
                            needRefetch={needRefetch}
                            refetchDone={refetchDone}
                            preventFetch={!!data?.id}
                        >
                            <TableHeaderMenu restaurantId={restaurantId}/>
                        </Table>
                </Panel>
            </RestaurantMenuTableContainer>
        </RestaurantMenuContainer>
    );
};

export default RestaurantMenu;
