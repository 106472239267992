import { useImageCropContext } from '../context';
import { Add, Remove } from '@mui/icons-material';
import { SliderButton, SliderInput } from './style';

export const ZoomSlider = () => {
    const { zoom, setZoom, handleZoomIn, handleZoomOut, max_zoom, min_zoom, zoom_step } = useImageCropContext();

    return (
        <div className={'flex items-center justify-center gap-2'}>
            <SliderButton onClick={handleZoomOut}>
                <Remove />
            </SliderButton>
            <SliderInput
                type="range"
                name="range"
                min={min_zoom}
                max={max_zoom}
                step={zoom_step}
                value={zoom}
                onChange={e => {
                    setZoom(Number(e.target.value));
                }}
            />
            <SliderButton onClick={handleZoomIn}>
                <Add />
            </SliderButton>
        </div>
    );
};
