import React from 'react';
import { TabsContainer } from 'components/Tabs/style';
import TabHeader from 'components/Tabs/TabHeader';
import TabContent from 'components/Tabs/TabContent';
import withTabs from 'hooks/useTabs';
import { useStateContext } from '../../context/ContextProvider';


const Tabs = (props: any) => {
    const { open } = useStateContext();
    const { activeTab, tabs, handleChange, isAbsolute } = props;
    const isTopHeader = props?.tabHeaderPosition === 'top' ? 1 : 0;

    return (
        <TabsContainer istopheader={isTopHeader} isabsolute={isAbsolute ? 1 : 0} isopen={open ? 1 : 0}>
            <TabHeader tabs={tabs} value={activeTab} onChange={handleChange} />
            <TabContent tabs={tabs} activeTab={activeTab} />
        </TabsContainer>
    );
};


export default withTabs(Tabs);
