import React, { useMemo } from 'react';
import { UserStatusBadge, UserStatusContainer } from 'views/Users/TableComponents/UserStatus/style';
import Translation from 'components/Translation';
import { Translate } from 'internationalization/translate';

const VehicleStatus = ({ row }: { row: any }) => {

    const [statusText, isActive] = useMemo(() => [
        row.active !== 1 ? Translate.vehicleInactive : !row?.enabled ? Translate.unavailable : Translate.vehicleActive,
        row.active && !row?.enabled ? 2 : row?.active ? 1 : 0
    ], [row]);

    return (
        <UserStatusContainer>
            <UserStatusBadge active={isActive}>
                <Translation use={statusText} />
            </UserStatusBadge>
        </UserStatusContainer>
    );
};

export default VehicleStatus;
