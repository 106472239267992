import axiosClient from 'core/api/config/axios';
import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'core/api/utils/createUrl';


const useGetDriversQuery = () => {
  const { data, isLoading, isFetched, isFetching } = useQuery({
    queryKey: ['get-drivers'],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await axiosClient.get(createUrl({
        url: '/dispatcher/driver',
        query: {
          active: true,
          perPage: 1000
        },
        version: 'v1'
      }));
      return res.data?.data || [];
    }
  });
  return { data, isLoading, isFetched, isFetching };
};

export default useGetDriversQuery;
