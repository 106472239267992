import React from 'react';
import { IMaskInput } from 'react-imask';
import { useField } from 'formik';
import InputTime from '../input/InputTime';

export const MaskedInput = React.forwardRef(function MaskedInput(props: any, ref) {
    const { onChange, ...other } = props as any;
    return (
        <IMaskInput
            {...other}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});


const FormikInputTimeMask = ({ name, mask, ...rest }: any) => {

    const [field, meta] = useField(name);

    const newProps = {
        ...field,
        ...rest,
        error: Boolean(meta.error) && meta.touched ? meta.error : false,
        helperText: Boolean(meta.touched) && meta.error ? meta.error : ''
    };

    const onBlur = (e: any) => {
        field.onBlur && field.onBlur(e);
    };

    return <InputTime
        type={'text'}
        {...newProps}
        value={newProps.value || ''}
        onBlur={onBlur}
        InputProps={{
            ...(newProps?.InputProps || {}),
            inputComponent: MaskedInput,
            inputProps: { mask: mask },
        }}
    />;
};

export default FormikInputTimeMask;