import React from 'react';
import { MaterialTabs, TabHeaderContainer, TabHeaderTab } from 'components/Tabs/style';
import { TTabHeaderProps } from 'components/Tabs/d';
import { useTranslation } from 'react-i18next';


const TabHeader = ({ tabs, value, onChange }: TTabHeaderProps) => {

    const { t } = useTranslation();

    if (!tabs?.length) return <></>;

    return (
        <TabHeaderContainer>
            <MaterialTabs value={value} onChange={onChange}>
                {tabs.map((tab, index) => {
                        const id = `${tab.id}-${index}`;
                        return <TabHeaderTab
                            key={id}
                            label={t(tab.label) || tab.label}
                            id={id}
                            aria-controls={`${tab.id}-tabpanel-${index}`}
                        />;
                    }
                )}
            </MaterialTabs>
        </TabHeaderContainer>
    );
};

export default TabHeader;
