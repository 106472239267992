import axiosClient from 'core/api/config/axios';
import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'core/api/utils/createUrl';


const useGetApprovals = () => {
  const { data, isLoading, isFetched, isFetching, refetch } = useQuery({
    queryKey: ['get-approvals'],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await axiosClient.get(createUrl({
        url: '/dispatcher/approval-requests',
        version: 'v1'
      }));
      return res.data?.data || {};
    }
  });
  return { data, isLoading, isFetched, isFetching, refetch };
};

export default useGetApprovals;
