
export enum MENU_EVENTS {
    MENU_ADD_ITEM = 'MENU_ADD_ITEM',
    MENU_REMOVE_ITEM = 'MENU_REMOVE_ITEM',
    MENU_EDIT_ITEM = 'MENU_EDIT_ITEM',
    MENU_RESET_STATE = 'MENU_RESET_STATE',
    MENU_ADD_CATEGORIES = 'MENU_ADD_CATEGORIES',
    MENU_CHANGE_ITEM = 'MENU_CHANGE_ITEM',
    MENU_REMOVE_EDIT_ITEM = 'MENU_REMOVE_EDIT_ITEM',
    MENU_SET_SEARCH = 'MENU_SET_SEARCH',
    MENU_CLEAR_SEARCH = 'MENU_CLEAR_SEARCH'
}

export type TRestaurantMenuActions = {
    type: MENU_EVENTS,
    payload?: any
}

export type TRestaurantMenuReducer = {
    tableList: any[],
    editItem?: any
    categories: any[]
    categoryMap: any
    tableListFiltered: any[]
    search: string
}

export const TEST_MENU_LIST_DATA = [
    {
        'type': '1',
        'restaurantCategoryId': 5,
        'name': 'test 1',
        'description': 'test 1',
        'quantity': 'kom',
        'price': '123',
        'free': false,
        'file': null,
        'position': '1',
        'id': '42279-41951',
        'category': {
            'id': 5,
            'restaurantId': 13,
            'name': 'Test 1',
            'type': 1,
            'active': 1,
            'createdAt': '2024-04-27T06:48:12.000Z',
            'updatedAt': '2024-04-27T06:48:12.000Z'
        },
        'categoryName': 'Test 1',
        'typeName': 'Jelo',
        'formattedPrice': '123'
    },
    {
        'type': '1',
        'restaurantCategoryId': 5,
        'name': 'test 2',
        'description': 'test 2',
        'quantity': 'kom',
        'price': '456',
        'free': false,
        'file': null,
        'position': '2',
        'id': '16483-48465',
        'category': {
            'id': 5,
            'restaurantId': 13,
            'name': 'Test 1',
            'type': 1,
            'active': 1,
            'createdAt': '2024-04-27T06:48:12.000Z',
            'updatedAt': '2024-04-27T06:48:12.000Z'
        },
        'categoryName': 'Test 1',
        'typeName': 'Jelo',
        'formattedPrice': '456'
    },
    {
        'type': '1',
        'restaurantCategoryId': 5,
        'name': 'test 3',
        'description': 'test ',
        'quantity': 'kom',
        'price': '789',
        'free': false,
        'file': null,
        'position': '3',
        'id': '80064-34748',
        'category': {
            'id': 5,
            'restaurantId': 13,
            'name': 'Test 1',
            'type': 1,
            'active': 1,
            'createdAt': '2024-04-27T06:48:12.000Z',
            'updatedAt': '2024-04-27T06:48:12.000Z'
        },
        'categoryName': 'Test 1',
        'typeName': 'Jelo',
        'formattedPrice': '789'
    },
    {
        'type': '2',
        'restaurantCategoryId': 6,
        'name': 'pice 1',
        'description': '1',
        'quantity': 'kom',
        'price': '987',
        'free': false,
        'file': null,
        'position': '4',
        'id': '59637-66191',
        'category': {
            'id': 6,
            'restaurantId': 13,
            'name': 'Test 2',
            'type': 2,
            'active': 1,
            'createdAt': '2024-04-27T06:48:15.000Z',
            'updatedAt': '2024-04-27T06:48:15.000Z'
        },
        'categoryName': 'Test 2',
        'typeName': 'Pice',
        'formattedPrice': '987'
    },
    {
        'type': '2',
        'restaurantCategoryId': 6,
        'name': 'pice 2',
        'description': '2',
        'quantity': 'kom',
        'price': '654',
        'free': false,
        'file': null,
        'position': '5',
        'id': '84216-92339',
        'category': {
            'id': 6,
            'restaurantId': 13,
            'name': 'Test 2',
            'type': 2,
            'active': 1,
            'createdAt': '2024-04-27T06:48:15.000Z',
            'updatedAt': '2024-04-27T06:48:15.000Z'
        },
        'categoryName': 'Test 2',
        'typeName': 'Pice',
        'formattedPrice': '654'
    },
    {
        'type': '2',
        'restaurantCategoryId': 6,
        'name': 'pice 3',
        'description': '3',
        'quantity': 'kom',
        'price': '321',
        'free': false,
        'file': null,
        'position': '6',
        'id': '80929-87903',
        'category': {
            'id': 6,
            'restaurantId': 13,
            'name': 'Test 2',
            'type': 2,
            'active': 1,
            'createdAt': '2024-04-27T06:48:15.000Z',
            'updatedAt': '2024-04-27T06:48:15.000Z'
        },
        'categoryName': 'Test 2',
        'typeName': 'Pice',
        'formattedPrice': '321'
    }
];