export enum TABLE_EVENTS {
    setPage = 'setTablePage',
    setPerPage = 'setTablePerPage',
    clearNeedRefetch = 'clearNeedRefetch',
    addFilter='addFilter',
    addMultiFilter = 'addMultiFilter',
    clearFilter='clearFilter',
    setRefetch = 'setRefetch',
    clearAllFilters ='clearAllFilters'
}


export type TReducerAction = {
    type: TABLE_EVENTS;
    payload?: number | string | null | any;
}


export type TTableColumn = {
    accessor: string
    fieldType?: string
    required?: boolean
}


export type TReducer = {
    columns: TTableColumn[],
    tableId?: string,
    page?: number
    perPage?: number
    isNeedRefetch?: number
    filter?: any,
    isExportCsv?: boolean
    isGlobalSearch?: boolean
    tableSearchTooltip?: string,
}

export type TFilerPayload = {
    field: string
    data: any
}
