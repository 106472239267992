import styled from 'styled-components';
import {Box} from '@mui/material';
import {Flex} from 'assets/style';
import {Colors} from 'theme/palette';
import {drawerWidth, miniDrawerWidth} from 'components/appbar/style';


export const GuestLayoutContainer = styled(Box)`
  &&& {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100vh;
  }
`;


export const GuestLayoutHeader = styled(Flex)`
  &&& {
    font-family: "Readex Pro";
    padding: 55px 0;
    justify-content: center;
    align-items: center;
    font-size: 56px;
    font-weight: 400;
    line-height: 70px;
    letter-spacing: 0.5px;
    background: ${() => `-webkit-linear-gradient(180deg, ${Colors.gradient.yellowLight} 0%, ${Colors.gradient.yellowDark} 100%)`};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const GuestLayoutContent = styled(Flex)`
    flex: 2;
    flex-direction: column;
`;

export const GuestLayoutImage = styled.img`
  position: fixed;
  left: -10%;
  bottom: -33%;
  width: 80%;
  height: 80%;
  transform: rotate(15deg);
  flex-shrink: 0;

    @media (max-width: 767px) {
        transform: rotate(0deg);
        width: 125%;
        height: 100%;
    } 
    
`;


export const DefaultLayoutContainer = styled(Box)`
  &&& {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
  }
`;

export const DefaultLayoutContent = styled(Flex)<{open?: boolean}>`
    flex: 2;
    flex-direction: column;
    padding-top: 60px;
    width: ${props => `calc(100% - ${props.open ? drawerWidth : miniDrawerWidth}px)`};
    margin-left: ${props => props.open ? drawerWidth : miniDrawerWidth}px;
`;
