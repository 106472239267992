import React, { useContext, useCallback, useMemo } from 'react';
import {
    OrderDriverItemAssigned,
    OrderDriverItemColumn,
    OrderDriverItemContainer, OrderDriverItemRightActionButton,
    OrderDriverItemRow
} from './style';
import { formatPriceSymbol } from '../../../../../../utils';
import { Translate } from '../../../../../../internationalization/translate';
import Translation from '../../../../../../components/Translation';
import OrderViewAssignContext from '../../context/context';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { OrderDriverViewActionsListEvents } from '../../OrdersViewPart/d';
import { useTranslation } from 'react-i18next';

const OrderDriverItem = ({driver}: any) => {
    const {t} = useTranslation();
    const { setDriver, selectedOrders, selectedDriver} = useContext(OrderViewAssignContext);
    
    const [isAssignable, isVisible, id, dataDriver] = useMemo(()=>[
        selectedOrders && selectedOrders?.length > 0,
        selectedDriver ? 1 : 0,
        driver?.driver?.id,
        driver?.driver
    ],[selectedOrders, selectedDriver, driver]);
    
    const selectDriver = useCallback(()=> {
        if(!driver?.driver?.id) return;
        setDriver(driver?.driver?.id);
    }, [setDriver, driver]);
    
    return (
        <OrderDriverItemContainer selected={Number(selectedDriver === driver?.driver?.id)}  status={driver.status} free={driver?.currentOrders ? 1 : 0} >
            <OrderDriverItemColumn flex={2} onClick={selectDriver}>
                <OrderDriverItemRow>
                    <div>{`${driver?.driver?.firstName || ''} ${driver?.driver?.lastName || ''}`}</div>
                    <div>{`#${driver?.vehicle?.vehicleNumber} ${(driver?.vehicle?.vehicleTypeName || '').toLowerCase()}`}</div>
                </OrderDriverItemRow>
                <OrderDriverItemRow>
                    <Translation use={Translate.orderDriverItemViewCurrentAmount} params={{amount: formatPriceSymbol(driver?.ordersAmount)}}/>
                    <Translation use={Translate.orderDriverItemViewCurrentCompleted} params={{count: driver?.completedOrders}}/>
                </OrderDriverItemRow>
                <OrderDriverItemRow>
                    <Translation use={Translate.orderDriverItemViewCurrentOrders} params={{currentOrders: driver?.currentOrders}}/>
                    <Translation use={Translate.orderDriverItemViewCurrentBided} params={{currentBided: ''}}/>
                </OrderDriverItemRow>
            </OrderDriverItemColumn>
            <OrderDriverItemAssigned>
                 <OrderDriverItemRightActionButton
                    title={t(Translate.orderDriverListActionAssigned)}
                    data-action={OrderDriverViewActionsListEvents.ASSIGNED_DRIVER}
                    data-action-id={id}
                    data-action-param={JSON.stringify(dataDriver)}
                    disabled={!isAssignable}
                    visibility={isVisible}
                >
                    <DirectionsCarIcon />
                </OrderDriverItemRightActionButton>
            </OrderDriverItemAssigned>
        </OrderDriverItemContainer>
    );
};

export  default OrderDriverItem;