import styled from 'styled-components';
import { Flex } from 'assets/style';
import { Box } from '@mui/material';
import Button from 'components/button';
import {Form} from 'formik';
import ButtonIcon from '../../../../components/button/ButtonIcon';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const DefineDriverContainer = styled(Flex)`
  min-width: 700px;
  max-width: 700px;
  min-height: 680px;
  max-height: 700px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DefineDriverStepsContainer = styled(Flex)`
  &&& {
    width: 320px;
    justify-content: space-between;
    padding: 25px 0;
  }
`;

export const BackButton = styled(ButtonIcon)`
    &&& {
        display: flex;
        justify-content: center;
        align-items: center;
        font: normal 400 18px/22.5px 'Readex Pro';
        font-stretch: normal;
        color: #000000;
        position: absolute;
        top: 10px;
        left: 10px;
    }
`;

export const BackIcon = styled(ArrowBackIcon)`
    color: #FFFFFF;
`;

export const DefineDriverFormsContent = styled(Box)`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 135px 15px;
  width: 100%;
`;


export const RegForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 2;
  width: 100%;
`;

export const RegisterFormContainer = styled(Flex)`
  flex-direction: column;
  flex: 2;
  width: 100%;
`;

export const RegisterButton = styled(Button)`
  &&& {   
    min-width: 370px;
    max-width: 100%;
    font-size: 18px;
    line-height: 22.5px;
  }
`;
