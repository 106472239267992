import React, { useCallback, useContext, useMemo } from 'react';
import TableDataContext from '../../../../../components/Table/context/context';
import TableFilter from '../../../../../components/Table/TableFilter';
import TableSearchInput from '../../../../../components/Table/TableSearchInput';
import { TableCustomMenuHeaderContainer, TableCustomMenuHeaderFilterPart } from './style';
import { Translate } from '../../../../../internationalization/translate';


import useRestaurantCategoriesByTypeQuery
    from '../../../../../core/api/hooks/query/restaurantMenu/useRestaurantCategoriesByTypeQuery';
import TableFilterCategory from './TableFilterCategory';

const typeOptions = [
    { value: 1, label: 'dishes', filterKey: 'type' },
    { value: 2, label: 'drink', filterKey: 'type' },
    { value: 3, label: 'addition', filterKey: 'type' }
];

const statusesOpts = [
    { value: true, label: 'Neaktivni', filterKey: 'inactive' },
    { value: true, label: 'Aktivini', filterKey: 'active' }
];


const TableHeaderMenu = ({ restaurantId }: any) => {
    const { setFilter, filter, clearAllFilters } = useContext(TableDataContext);
    const handlerSearch = useCallback((value: string) => {
        setFilter({
            field: 'search',
            data: value
        });
    }, [setFilter]);

    const handlerClearSearch = useCallback(() => setFilter({ field: 'search', data: undefined }), [setFilter]);


    const data = useRestaurantCategoriesByTypeQuery(restaurantId);

    const categoriesOpts = useMemo(() => {
        if (!filter['type']) return [];
        return (data[filter['type']] || []).map((x: any)=> ({
            label: x.name,
            value: `${x.id}`,
            filterKey: 'restaurantCategoryId'
        }));
    }, [data, filter]);


    return (
        <TableCustomMenuHeaderContainer>
            <TableCustomMenuHeaderFilterPart>
                <TableFilter isClearable={true} isSingle fieldFilter={['type']}
                             label={Translate.restaurantMenuTypeFilterLabel} setFilter={setFilter}
                             clearAllFilters={clearAllFilters} tableFilterOptions={typeOptions} filter={filter} />
                <TableFilterCategory isClearable
                             fieldFilter={['restaurantCategoryId']}
                             setFilter={setFilter}
                             isCategory={1}
                             clearAllFilters={clearAllFilters}
                             tableFilterOptions={categoriesOpts}
                             filter={filter} />
                <TableFilter fieldFilter={['active', 'inactive']} label={Translate.restaurantMenuStatusFilterLabel}
                             setFilter={setFilter} clearAllFilters={clearAllFilters} tableFilterOptions={statusesOpts}
                             filter={filter} />
            </TableCustomMenuHeaderFilterPart>
            <TableSearchInput
                handlerSearch={handlerSearch}
                clearSearch={handlerClearSearch}
            />
        </TableCustomMenuHeaderContainer>
    );
};

export default TableHeaderMenu;