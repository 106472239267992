import styled from 'styled-components';
import { Flex } from '../../../../../../assets/style';
import Button from '../../../../../../components/button';
import { ImageCropPreviewContainer } from '../../../../../../components/Image/Crop/style';

export const RestaurantMenuAddManuallyFormContainer = styled(Flex)`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //padding: 40px 0;
`;

export const RestaurantMenuAddManuallyFormTitle = styled(Flex)`
    font-family: 'Readex Pro', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    color: black;
`;

export const RestaurantMenuAddManuallyFormColumn = styled(Flex)`
    display: flex;
    flex-direction: column;
    //flex: 2;
    width: 100%;
    padding: 0 50px;
    justify-content: center;
    
    .pt-0 {
        padding-top: 0 !important;
    }
    
    ${ImageCropPreviewContainer} {
        margin-bottom: 0;
    }
`;

export const RestaurantMenuAddManuallyFormButtonsColumn = styled(Flex)`
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 15px 0;
`;

export const RestaurantMenuManualButton = styled(Button)`
  &&& {   
    min-width: 370px;
    max-width: 100%;
    font-size: 18px;
    line-height: 22.5px;
  }
`;


export const AddItemManuallyButton = styled(Button)`
    &&& {
        min-width: 370px;
        max-width: 100%;
        font-size: 18px;
        line-height: 22.5px;
        background: white;
    }
`;