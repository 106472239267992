import styled, { css } from 'styled-components';
import { Flex } from '../../../../../assets/style';
import {Avatar} from '@mui/material';
import ButtonIcon from '../../../../button/ButtonIcon';


export const ApprovalListItemContainer  = styled(Flex)`
        padding: 10px 17px;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        border-bottom: 1px solid #FFFFFF;
        min-height: 70px;

        &:first-child {
                border-top: 1px solid #FFFFFF;
        }
`;

export const ApprovalListItemAvatar = styled(Avatar)`
    &&& {
        width: 40px;
        height: 40px;
        color: white;
    }
`;

export const ApprovalListItemContent = styled(Flex)`
    flex: 2;
    flex-direction: column;
    justify-content: flex-start;
`;


export const ApprovalListItemContentTitle = styled(Flex)`
    color: #FFFFFF;
    font: normal 400 16px/24px 'Readex Pro';
`;

export const ApprovalListItemContentSubText = styled(Flex)`
    font: normal 400 12px/15px 'Readex Pro';
    color: #FFFFFF;
    letter-spacing: 1px;
`;



export const ApprovalListItemRightPart = styled(Flex)`
    flex-direction: column;
    height: 100%;
    min-height: 54px;
    justify-content: flex-end;
`;

export const ApprovalListItemActions = styled(Flex)`
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    flex: 2;
`;

export const ApprovalListRequestTime = styled(Flex)`
        text-align: right;
        font: normal normal 10px/14px 'Readex Pro';
        letter-spacing: 1px;
        justify-content: flex-end;
        color: #e3e3e3;
`;

export const ApprovalListActionButton = styled(ButtonIcon)<{color?: string}>`
    &&& {
        width: 26px;
        height: 26px;    
        color: #DD3533CC;

        ${props => props.color === 'success' && css`
            color: #11D600;
        `}
    }
`;