import { CustomColumnDef } from '../../../../../components/Table/d';
import Translation from '../../../../../components/Translation';
import React from 'react';
import { dishesTypeOpts } from '../../../view/DefineRestaurantMenuManually/component/RestaurantMenuAddManuallyForm';
import RestaurantCategoryListActions from './views/components/TableActions';
import CategoryStatus from './views/components/TableStatus';

export const RestaurantCategoryColumns: CustomColumnDef[] = [
    {
        accessorKey: 'position',
        header: 'restaurantMenuItemPosition',
        align: 'center',
        style: {
            minWidth: 45,
            width: '45px'
        },
        cell: (props) => props.row.original.position
    },
    {
        accessorKey: 'name',
        header: 'name',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '50%',
            width: '50%'
        },
        cell:  (props: any)  => `${props.row.original?.name || ''}`
    },
    {
        accessorKey: 'type',
        header: 'restaurantMenuItemType',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '25%',
            width: '25%'
        },
        cell: (props: any) => <Translation use={dishesTypeOpts.find(x => x.value === `${props.row.original?.type}`)?.label || ''} />
    },
    {
        accessorKey: 'is18Plus',
        header: 'is18Plus',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '60px',
            width: '60px'
        },
        cell: (props) => <Translation use={props.row?.original?.is18Plus ? 'yes' : 'no'} />
    },
    {
        header: 'Status',
        style: {
            minWidth: 90,
            width: 90
        },
        cell: (user: any) => <CategoryStatus row={user.row.original}/>
    },
    {
        accessorKey: 'actions',
        header: '',
        align: 'left',
        alignHeader: 'center',
        style: {
            minWidth: '90px',
            width: '90px',
        },
        cell: (props) => <RestaurantCategoryListActions row={props.row.original}/>
    },
];