import styled from 'styled-components';
import { Flex } from '../../assets/style';
import Button from '../button';


export const VersionCheckerFormContainer = styled(Flex)`
    width: 500px;
    height: 300px;
    padding: 0 30px 30px 30px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const VersionCheckerText = styled(Flex)`
    flex: 2;
    text-align: center;
    justify-content: center;
    align-items: center;
    font: normal 400 22px/32px 'Readex Pro';
`;

export const VersionCheckerButton = styled(Button)`
    &&& {
        min-width: 80%;
    }
`;