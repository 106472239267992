import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';
import Button from '../../../../../components/button';
import UploadFileIcon from '@mui/icons-material/UploadFile';

export const RestaurantMenuImportExcelFormContainer = styled(Flex)`
    width: 100%;
    flex: 1;
    align-items: center;
`;

export const RestaurantMenuChooseExcelButtonContainer = styled(Flex)`
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const RestaurantMenuChooseExcelButton = styled(Button)`
    &&& {
        background: #7B7B7B;
        border: 1px white solid;
        min-width: 370px;
        max-width: 100%;
        font-size: 18px;
        line-height: 22.5px;
        color: white;
    }
`;

export const RestaurantMenuUploadExcelIcon = styled(UploadFileIcon)`
    &&& {
        color: white;
        margin-right: 15px;
    }
`;