import {
    AddItemManuallyButton,
    RestaurantMenuAddManuallyFormButtonsColumn,
    RestaurantMenuAddManuallyFormColumn,
    RestaurantMenuAddManuallyFormContainer,
    RestaurantMenuAddManuallyFormTitle,
    RestaurantMenuManualButton
} from './style';
import { Grid } from '@mui/material';
import { Form, Formik, useFormikContext } from 'formik';
import CropImage from '../../../../../../components/Image/Crop';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { initialValuesMenuManuallyInsertForm, restaurantMenuValidation } from './validation';
import FormikSelectField from '../../../../../../components/Formik/FormikSelectField';
import { Translate } from '../../../../../../internationalization/translate';
import FormikTextField from '../../../../../../components/Formik/FormikTextField';
import FormikCheckBox from '../../../../../../components/Formik/FormikCheckBox';
import Translation from '../../../../../../components/Translation';

import FormikInputCurrency from '../../../../../../components/Formik/FormikInputCurrency';
import RestaurantMenuContext from '../../../context/context';
import FormikInputNumber from '../../../../../../components/Formik/FormikInputNumber';
import useRestaurantCategoriesByTypeQuery
    from '../../../../../../core/api/hooks/query/restaurantMenu/useRestaurantCategoriesByTypeQuery';
import useRestaurantWholeCategoriesQuery
    from '../../../../../../core/api/hooks/query/restaurantCategory/useRestaurantWholeCategoriesQuery';
import { getFileBase64 } from '../../../../../../utils';

export const dishesTypeOpts = [
    { label: 'dishes', value: '1' },
    { label: 'drink', value: '2' },
    { label: 'addition', value: '3' }
];


const RestaurantMenuAddForm = ({ categoriesByType, makeResetImage, isNeedReset }: any) => {
    const { values, resetForm, setFieldValue, setValues } = useFormikContext() as any;
    const { editItem, removeEditItem, nextPosition } = useContext(RestaurantMenuContext);
    
    const [imageUrl] = useMemo(()=> [(values as any)?.imageUrl] ,[values]);

    const categoriesOptions = useMemo(() => {
        const type = (values as any)?.type;
        if (!categoriesByType) return [];
        const categs = categoriesByType[`${type}`];
        if (!categs) return [];
        return (categs || []).map((category: any) => ({
            label: category.name,
            value: category.id
        }));
    }, [categoriesByType, values]);

    const handlerSaveImage = (data: any) => {
        setFieldValue('file', data, false).then();
    };


    const handlerResetForm = useCallback(() => {
        makeResetImage();
        removeEditItem();
        resetForm();
    }, [resetForm, removeEditItem, makeResetImage]);
    
    const handlerFreeChange = useCallback((free: boolean)=> {
        if(free) {
            setFieldValue('lastPrice', values.price, false);
            setFieldValue('price', '0', false);
        } else {
            setFieldValue('lastPrice', '', false);
            setFieldValue('price', values.lastPrice, true);
        }
    },[setFieldValue, values]);

    useEffect(() => {
        if (!editItem || !editItem?.id) {
            return;
        }
        setValues({
            ...editItem
        }).then();
    }, [editItem]);

    useEffect(() => {
        if (!nextPosition || editItem) return;
        setFieldValue('position', `${nextPosition}`, false).then();
    }, [nextPosition, editItem]);

    return (
        <Form>
            <RestaurantMenuAddManuallyFormColumn>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <CropImage imageUrl={imageUrl} handlerSave={handlerSaveImage} isNeedReset={isNeedReset} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikSelectField
                            options={dishesTypeOpts}
                            variant="outlined"
                            name={'type'}
                            label={Translate.restaurantMenuItemType}
                            fullWidth
                            isDark={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikSelectField
                            options={categoriesOptions}
                            variant="outlined"
                            disabled={!categoriesOptions.length}
                            name={'restaurantCategoryId'}
                            label={Translate.restaurantMenuItemCategory}
                            fullWidth
                            isDark={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormikTextField
                            variant="outlined"
                            label={Translate.restaurantMenuItemName}
                            name={'name'}
                            fullWidth
                            type={'text'}
                            isDark={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormikTextField
                            variant="outlined"
                            label={Translate.restaurantMenuItemDescription}
                            name={'description'}
                            fullWidth
                            type={'text'}
                            isDark={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikTextField
                            variant="outlined"
                            label={Translate.restaurantMenuItemAmount}
                            name={'quantity'}
                            fullWidth
                            type={'text'}
                            isDark={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikInputCurrency
                            variant="outlined"
                            label={Translate.restaurantMenuItemPrice}
                            name={'price'}
                            fullWidth
                            type={'text'}
                            isDark={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikInputNumber
                            name={'position'}
                            variant="outlined"
                            label={Translate.restaurantMenuItemPosition}
                            fullWidth
                            isDark={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikCheckBox
                            name={'free'}
                            label={Translate.restaurantMenuItemIsFree}
                            type={'checkbox'}
                            handlerAfterChange={handlerFreeChange}
                            fullWidth
                            isDark={false}
                        />
                        <FormikCheckBox
                            name={'is18Plus'}
                            label={Translate.restaurantMenuItemIs18Plus}
                            type={'checkbox'}
                            fullWidth
                            isDark={false}
                        />
                    </Grid>
                </Grid>
            </RestaurantMenuAddManuallyFormColumn>
            <RestaurantMenuAddManuallyFormButtonsColumn>
                <AddItemManuallyButton onClick={handlerResetForm}>
                    <Translation use={Translate.reset} />
                </AddItemManuallyButton>
                <RestaurantMenuManualButton type={'submit'}>
                    <Translation use={Translate.next} />
                </RestaurantMenuManualButton>
            </RestaurantMenuAddManuallyFormButtonsColumn>
        </Form>
    );
};

const RestaurantMenuAddManuallyForm = ({ id }: any) => {

    const timeOut = useRef(null);
    const { categories, addCategories, addItem, changeItem, editItem } = useContext(RestaurantMenuContext);
    const [isNeedReset, setIsNeedReset] = useState<boolean>(false);

    const _categories = useRestaurantWholeCategoriesQuery(id);
    const categoriesByType = useRestaurantCategoriesByTypeQuery(id);


    const makeResetImage = useCallback(() => {
        setIsNeedReset(true);
        if (timeOut.current) clearTimeout(timeOut.current);
        timeOut.current = setTimeout(() => {
            setIsNeedReset(false);
        }, 2000) as any;
    }, [setIsNeedReset, timeOut]);


    const handlerAddChangeItem = useCallback((values: any) => editItem && editItem?.id ? changeItem(values) : addItem(values), [addItem, editItem, changeItem]);

    const onSubmit = useCallback(async (values: any, { resetForm }: any) => {
        if (!values.restaurantCategoryId) return;
        const imageUrl = values?.file ? await getFileBase64(values.file) : undefined;
        handlerAddChangeItem({
            ...values,
            imageUrl
        });
        makeResetImage();
        resetForm();
    }, [handlerAddChangeItem, makeResetImage]);

    useEffect(() => {
        if (categories.length) return;
        addCategories(_categories);
    }, [categories, _categories]);

    useEffect(() => {
        return () => {
            if (timeOut.current) clearTimeout(timeOut.current);
        };
    }, []);

    return (
        <RestaurantMenuAddManuallyFormContainer>
            <RestaurantMenuAddManuallyFormTitle>
                Rucno dodavanje Jela
            </RestaurantMenuAddManuallyFormTitle>
            <Formik
                initialValues={{
                    ...initialValuesMenuManuallyInsertForm
                }}
                validationSchema={restaurantMenuValidation}
                onSubmit={onSubmit}
            >
                <RestaurantMenuAddForm isNeedReset={isNeedReset} makeResetImage={makeResetImage}
                                       categoriesByType={categoriesByType} />
            </Formik>
        </RestaurantMenuAddManuallyFormContainer>
    );
};

export default RestaurantMenuAddManuallyForm;