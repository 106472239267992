import React, { useCallback } from 'react';
import {
    ShiftSchedulingContainer,
    ShiftSchedulingHeader,
    ShiftSchedulingHeaderType,
    ShiftSchedulingTableContainer
} from './style';
import Panel from '../../components/Panel';
import Translation from '../../components/Translation';
import { Translate } from '../../internationalization/translate';
import { VehicleHeaderAddButton } from '../Vehicles/style';
import AddIcon from '@mui/icons-material/Add';
import { useModal } from '../../hooks/useModal';
import DefineShiftsForDay from './components/Dialogs/DefineShiftsForDay';
import SchedulingBoard from './view/SchedulingBoard';

const Schedule = () => {
    const { openModal: openDialogDefineShiftsByDate, closeModal } = useModal({
        modal: DefineShiftsForDay,
        isActionsButtonsVisible: false,
        isCloseAction: false,
        noPadding: false
    });
    
    const handlerOpenDialogDefineShiftByDate = useCallback(()=> {
        openDialogDefineShiftsByDate({
            closeModal
        });
    },[openDialogDefineShiftsByDate, closeModal]);
    
    return (
        <ShiftSchedulingContainer>
            <ShiftSchedulingHeader>
                <Translation  use={Translate.schedulingPageTitle} as={ShiftSchedulingHeaderType}/>
                <VehicleHeaderAddButton
                    onClick={handlerOpenDialogDefineShiftByDate}
                    startIcon={<AddIcon />}
                >
                    <Translation use={Translate.addText} />
                </VehicleHeaderAddButton>
            </ShiftSchedulingHeader>
            <ShiftSchedulingTableContainer>
                <Panel>
                    <SchedulingBoard />
                </Panel>
            </ShiftSchedulingTableContainer>
        </ShiftSchedulingContainer>
    );
};


export default Schedule;
