import { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePickerContainer } from './style';


const Datepicker = ({
    format = 'dd.MM.yyyy',
    ...rest
}: DatePickerProps<any>)=> {
    return (
        <DatePickerContainer>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker format={format} {...rest} />
            </LocalizationProvider>
        </DatePickerContainer>

    );
};


export default Datepicker;