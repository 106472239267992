import styled from 'styled-components';
import { Flex } from '../../../../../../../assets/style';
import { Colors } from '../../../../../../../theme/palette';
import { Typography } from '@mui/material';

export const DefineShiftsForDayHeader = styled(Flex)`
    padding: 10px 15px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid ${Colors.gradient.yellowDark};
`;


export const DefineShiftForDayHeaderTitle = styled(Typography).attrs({
    variant: 'h4'
})`
    color: #FFF;
    text-align: center;
    font: normal 400 40px/normal 'Readex Pro';
`;


export const DefineShiftDateHeader = styled(Typography).attrs({
    variant: 'h4'
})`
    color: #FFF;
    text-align: center;
    font: normal 400 24px/30px 'Readex Pro';
`;
