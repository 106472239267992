import { MENU_EVENTS, TEST_MENU_LIST_DATA, TRestaurantMenuActions, TRestaurantMenuReducer } from './d';
import { formatPriceSymbol, guid } from '../../../../utils';
import { useCallback, useMemo, useReducer, useRef } from 'react';
import RestaurantMenuContext from './context';
import { dishesTypeOpts } from '../DefineRestaurantMenuManually/component/RestaurantMenuAddManuallyForm';
import i18n from 'internationalization/i18';
import { IS_TEST } from '../../../../config';


const initState = {
    tableList: IS_TEST ? TEST_MENU_LIST_DATA: [],
    categories: [],
    tableListFiltered: IS_TEST ? TEST_MENU_LIST_DATA :[],
    categoryMap: new Map(),
    search: ''
};


const calculatePosition = ({
                               data,
                               item,
                               currentItem
                           }: any) => {
    // eslint-disable-next-line no-debugger
    debugger;
    const sortData = data.sort((a: any, b: any,) => +b.position - +a.position);
    const defPosition = Number( sortData?.[0]?.position || 1);
    const itemPosition =  +item.position; //> defPosition + 1 ? defPosition + 1 : +item.position;
    if(!itemPosition) return sortData;
    if (currentItem && currentItem?.position === itemPosition) return sortData;
    if(!sortData.length) return [];
    if(currentItem) {
        if(+currentItem?.position < +itemPosition) {
            const _data = [...sortData];
            const arr = sortData.filter((x: any) => +x.position > +currentItem.position && +x.position <= itemPosition);
            if (!arr.length) return sortData;
            for (const i of arr) {
                const index = _data.findIndex((d: any) => d.id === i.id);
                if (index === -1) return;
                _data.splice(index, 1, {
                    ...i,
                    position: +i.position - 1
                });
            }
            return _data;
        } else {
            const _data = [...sortData];
            const arr = sortData.filter((x: any) => +x.position >= itemPosition && +x.position < +currentItem.position);
            if (!arr.length) return sortData;
            for (const i of arr) {
                const index = _data.findIndex((d: any) => d.id === i.id);
                if (index === -1) return;
                _data.splice(index, 1, {
                    ...i,
                    position: +i.position + 1
                });
            }
            return _data;
        }

    }
    if(itemPosition >= defPosition + 1 && !currentItem) return sortData;
    if (itemPosition < defPosition) {
        const _data = [...sortData];
        const arr = sortData.filter((x: any) => +x.position >= itemPosition && +x.position <= defPosition);
        if (!arr.length) return sortData;
        for (const i of arr) {
            const index = _data.findIndex((d: any) => d.id === i.id);
            if (index === -1) return;
            _data.splice(index, 1, {
                ...i,
                position: +i.position + 1
            });
        }
        return _data;
    }else {
        const _data = [...sortData];
        const arr = sortData.filter((x: any) =>+x.position > defPosition && +x.position <= itemPosition);
        if (!arr.length) return sortData;
        for (const i of arr) {
            const index = _data.findIndex((d: any) => d.id === i.id);
            if (index === -1) return;
            _data.splice(index, 1, {
                ...i,
                position: +i.position - 1
            });
        }
        return _data;
    }
};

const reducer = (state: TRestaurantMenuReducer, action: TRestaurantMenuActions) => {
    switch (action.type) {
        default:
            return state;

        case MENU_EVENTS.MENU_ADD_ITEM: {
            let id = guid();
            while (state.tableList.length > 0) {
                const index = state.tableList.findIndex((x: any) => x.id === id);
                if (index === -1) break;
                id = guid();
            }
            const price = action.payload.price.replace(/\./gm, '').replace(/,/gm, '.');
            const category = state.categories.find(x => x.id === action.payload.restaurantCategoryId);
            const typeName = i18n.t(dishesTypeOpts.find(x => x.value === action.payload.type)?.label || '');
            const item  ={
                ...action.payload,
                id,
                price,
                category,
                categoryName: category?.name,
                typeName,
                formattedPrice: action.payload.price
            };

            const tableListCalculated = calculatePosition({
                data: state.tableList,
                item
            });
            const tableList =  [...tableListCalculated, item];
            return {
                ...state,
                tableList,
                tableListFiltered: [...tableList]
            };
        }
        case MENU_EVENTS.MENU_REMOVE_ITEM: {
            const arr = [...state.tableList];
            const index = arr.findIndex((x: any) => x.id === action.payload);
            if (index === -1) return state;
            const currentItem = arr[index];
            if(currentItem.position) {
                const defPosition = Number(arr[arr.length - 1]?.position || 1);
                const _arr = arr.filter((x: any) => +x.position >= +currentItem.position && +x.position <= defPosition);
                if (_arr.length) {
                    for (const i of _arr) {
                        const index = arr.findIndex((d: any) => d.id === i.id);
                        if (index !== -1) {
                            arr.splice(index, 1, {
                                ...i,
                                position: +i.position - 1
                            });
                        }
                    }
                }
            }
            arr.splice(index, 1);
            return {
                ...state,
                tableList: [...arr],
                tableListFiltered: [...arr]
            };
        }

        case MENU_EVENTS.MENU_EDIT_ITEM: {
            const index = [...state.tableList].findIndex((x: any) => x.id === action.payload);
            if (index === -1) return state;
            const item = state.tableList[index];
            return {
                ...state,
                editItem: {
                    ...item,
                    price: formatPriceSymbol(item.price)
                }
            };
        }

        case MENU_EVENTS.MENU_CHANGE_ITEM: {
            const arr = [...state.tableList];
            let index = arr.findIndex((x: any) => x.id === action.payload?.id);
            if (index === -1) return state;
            const price = action.payload.price.replace(/\./gm, '').replace(/,/gm, '.');
            const category = state.categories.find(x => x.id === action.payload.restaurantCategoryId);
            const typeName = i18n.t(dishesTypeOpts.find(x => x.value === action.payload.type)?.label || '');
            const item = {
                ...(state.tableList[index]),
                ...action.payload,
                price,
                category,
                categoryName: category?.name,
                typeName,
                formattedPrice: action.payload.price
            };
            const tableListCalculated = calculatePosition({
                data: state.tableList,
                item,
                currentItem: state.tableList[index]
            });
            index = tableListCalculated.findIndex((x: any) => x.id === action.payload?.id);
            if (index === -1) return state;
            tableListCalculated.splice(index, 1, item);
            return {
                ...state,
                tableList: [...tableListCalculated],
                tableListFiltered: [...tableListCalculated],
                editItem: undefined
            };
        }

        case MENU_EVENTS.MENU_ADD_CATEGORIES: {
            const categories = [...(action.payload || [])];
            const categoryMap = new Map(categories.map(x => [x.id, x]));
            return {
                ...state,
                categories,
                categoryMap
            };
        }

        case MENU_EVENTS.MENU_REMOVE_EDIT_ITEM: {
            return {
                ...state,
                editItem: undefined
            };
        }

        case MENU_EVENTS.MENU_RESET_STATE: {
            return {
                ...initState
            };
        }

        case MENU_EVENTS.MENU_SET_SEARCH: {
            const regex = new RegExp(action.payload.replace(/\./, '\\.'), 'i');
            const data = [...state.tableList].filter(x => {
                return regex.test(x.name) ||
                    regex.test(x.formattedPrice) ||
                    regex.test(x.description) ||
                    regex.test(x.categoryName) ||
                    regex.test(x.typeName) ||
                    regex.test(x.quantity) ||
                    regex.test(x.free);
            });


            return {
                ...state,
                search: action.payload,
                tableListFiltered: [...data]
            };
        }

        case MENU_EVENTS.MENU_CLEAR_SEARCH: {
            return {
                ...state,
                search: '',
                tableListFiltered: [...state.tableList]
            };
        }
    }
};


export const useRestaurantMenuContext = () => {


    const dataReducerState = useRef({
        ...initState
    });

    const [state, dispatch] = useReducer(reducer, dataReducerState.current as TRestaurantMenuReducer);


    const addItem = useCallback((item: any) => dispatch({
        type: MENU_EVENTS.MENU_ADD_ITEM,
        payload: item
    }), [dispatch]);

    const removeItem = useCallback((itemId: string) => dispatch({
        type: MENU_EVENTS.MENU_REMOVE_ITEM,
        payload: itemId
    }), [dispatch]);

    const setEditItem = useCallback((itemId: string) => dispatch({
        type: MENU_EVENTS.MENU_EDIT_ITEM,
        payload: itemId
    }), [dispatch]);

    const addCategories = useCallback((categories: any[]) => dispatch({
        type: MENU_EVENTS.MENU_ADD_CATEGORIES,
        payload: categories
    }), [dispatch]);

    const changeItem = useCallback((item: any) => dispatch({
        type: MENU_EVENTS.MENU_CHANGE_ITEM,
        payload: item
    }), [dispatch]);


    const removeEditItem = useCallback(() => dispatch({
        type: MENU_EVENTS.MENU_REMOVE_EDIT_ITEM
    }), [dispatch]);


    const resetState = useCallback(() => dispatch({
        type: MENU_EVENTS.MENU_RESET_STATE
    }), [dispatch]);


    const setSearch = useCallback((payload: string) => dispatch({
        type: MENU_EVENTS.MENU_SET_SEARCH,
        payload
    }), [dispatch]);

    const clearSearch = useCallback(() => dispatch({
        type: MENU_EVENTS.MENU_CLEAR_SEARCH
    }), [dispatch]);


    return useMemo(() => {
        const tableListFiltered = state.tableListFiltered.sort((a, b) => b.position - a.position);
        const nextPosition = Number(tableListFiltered[0]?.position || 0) + 1;
        return {
            ...state,
            tableListFiltered,
            nextPosition,
            addItem,
            removeItem,
            setEditItem,
            addCategories,
            changeItem,
            removeEditItem,
            resetState,
            setSearch,
            clearSearch
        };
    }, [
        state,
        addItem,
        removeItem,
        setEditItem,
        addCategories,
        changeItem,
        removeEditItem,
        resetState,
        setSearch,
        clearSearch
    ]);
};


export type TRestaurantMenuContextType = ReturnType<typeof useRestaurantMenuContext>;

const RestaurantContextContainer = ({
                                        children
                                    }: any) => {
    const providerData = useRestaurantMenuContext();

    return (
        <RestaurantMenuContext.Provider value={providerData}>
            {children}
        </RestaurantMenuContext.Provider>
    );
};


export default RestaurantContextContainer;