import { TUiReducer, TUiReducerAction, UI_EVENTS } from 'context/UiContext/d';
import { useCallback, useMemo, useReducer, useRef } from 'react';
import UiDataContext from 'context/UiContext/context';


const reducer = (state: TUiReducer, action: TUiReducerAction) => {

  switch (action.type) {
    default:
      return state;


    case UI_EVENTS.UI_SET_LOADING: {
      const { timer, isSkeleton, text, isMini } = action.payload || {};
      if (typeof isMini !== 'undefined') return {
        ...state,
        loading: {
          isLoading: false,
          isMini,
        }
      };
      if (typeof isSkeleton !== 'undefined') {
        return {
          ...state,
          loading: {
            isLoading: false,
            isSkeleton,
          }
        };
      }
      return {
        ...state,
        isLoading: true,
        timer: timer ? Number(timer) : void (0),
        isSkeleton: void (0),
        isMini: void (0),
        text
      };
    }


    case UI_EVENTS.UI_RESET_LOADING: {
      if (!state?.isLoading && !state?.timer && !state?.isSkeleton && !state?.isMini) return state;
      return {
        ...state,
        isLoading: false,
        timer: void (0),
        isSkeleton: false,
        isMini: false,
        text: void (0)
      };
    }

  }

};


const useUiContext = () => {

  const dataReducerStartState = useRef({
    isLoading: false,
    timer: void (0),
    isSkeleton: false,
    isMini: false,
    text: void (0)
  });

  const [state, dispatch] = useReducer(reducer, dataReducerStartState.current as TUiReducer);

  const setLoading = useCallback((payload?: TUiReducer) => {
    dispatch({
      type: UI_EVENTS.UI_SET_LOADING,
      payload
    });
  }, [dispatch]);

  const resetLoading = useCallback(() => {
    dispatch({
      type: UI_EVENTS.UI_RESET_LOADING
    });
  }, [dispatch]);


  return useMemo(()=> ({
    ...state,
    setLoading,
    resetLoading
  }),[
    state,
    setLoading,
    resetLoading
  ]);
};


export type TUiContextType = ReturnType<typeof useUiContext>;
const UiContextContainer = ({
                              children
                            }: any) => {

  const providerData = useUiContext();

  return (
    <UiDataContext.Provider value={providerData}>
      {children}
    </UiDataContext.Provider>
  );
};

export default UiContextContainer;
