import {
  useContext,
  useMemo,
  useEffect,
  useImperativeHandle
} from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import ModalContext from 'components/Dialog/context';

const defaultSubmit = async ()=>  true;

const useModalFormData =  ({
     modalTitle,
     handlerOnSubmit = defaultSubmit ,
                           } : {
  modalTitle?: string,
  handlerOnSubmit?: () => Promise<boolean>
}) => {
  const {setModalTitle, refSubmit, onReject} = useContext(ModalContext);

  useImperativeHandle(refSubmit, () => {
    return {
      submit: handlerOnSubmit
    };
  });

  useEffect(()=> {
    if(!modalTitle) return;
    setModalTitle(modalTitle);
  },[modalTitle, setModalTitle]);

  return useMemo(()=> ({
    setModalTitle,
    onReject
  }),[setModalTitle, onReject]);
};


export default useModalFormData;
