import styled from 'styled-components';
import { Flex } from 'assets/style';
import { Colors } from '../../../../../../../theme/palette';
import ButtonIcon from '../../../../../../../components/button/ButtonIcon';

export const OrderFreeItemContainer = styled(Flex)`
    min-height: 370px;
    min-width: 320px;
    max-width: 320px;
    padding: 25px 20px 0px 20px;
    flex-direction: column;
    position: relative;
    border-radius: 20px;
    border: 1px solid ${Colors.gradient.yellowDark};
    
    
    .MuiFormControl-root {
        margin-bottom: 5px;
    }
`;

export const OrderFreeItemRow = styled(Flex)`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;


export const ClearItemButton = styled(ButtonIcon)`
    &&& {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 5px;
        right: 5px;
        color: white;
    }
`;