import styled from 'styled-components';
import { Flex } from 'assets/style';
import Button from 'components/button';
import { Form } from 'formik';

export const EditDriverContainer = styled(Flex)`
  min-width: 700px;
  max-width: 700px;
  min-height: 680px;
  max-height: 700px;
  flex-direction: column;
  align-items: center;
  padding: 25px 135px 15px;
`;


export const EditDriverForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 2;
  width: 100%;
`;

export const EditDriverFormTitle = styled(Flex)`
  font: normal 400 36px/46px 'Readex Pro';
  color: #FFFFFF;
`;

export const EditDriverFormContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  flex: 2;
  width: 100%;
`;
export const EditDriverFormContent = styled(Flex)`
  flex: 2;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  &&& {
    
    .MuiGrid-item {
      padding-left: 0;
    }
  }
`;


export const EditDriverRow = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding-top: 16px;
`;

export const EidDriverSubmitButton = styled(Button)`
  &&& {
    min-width: 370px;
    max-width: 100%;
    font-size: 18px;
    line-height: 22.5px;
  }
`;
