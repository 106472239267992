import { PeriodsOptions } from './context/d';
import {   useContext, useCallback, useMemo } from 'react';
import moment from 'moment';
import PeriodPickerContext from './context/context';
import {
    PeriodPickerPreviewDates,
    PeriodPickerDatesValueBox,
    PeriodPickerDatesValue
}                             from 'components/PeriodPicker/style';
import ButtonPrevNext         from 'components/PeriodPicker/ButtonPrevNext';
import KeyboardArrowLeftIcon  from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const PeriodPicker = ()=> {

    const { dateRange, movePeriodNextPrev, period } = useContext(PeriodPickerContext);

    const { startDate, endDate, isEndDate, isNextVisible } = useMemo(() => {
        const currentDateTime = moment().add(14, 'days').toDate().getTime();
        const data = {
            startDate: moment(dateRange?.startDate).format('DD.MM.YYYY'),
            endDate: moment(dateRange?.endDate).format('DD.MM.YYYY'),
            isNextVisible: currentDateTime > (dateRange?.endDate.getTime() || 0)
        };

        return {
            ...data,
            isEndDate: data.startDate !== data.endDate
        };
    }, [dateRange]);

    const movePrev = useCallback(() => {
        movePeriodNextPrev();
    }, [movePeriodNextPrev]);

    const moveNext = useCallback(() => {
        movePeriodNextPrev(true);
    }, [movePeriodNextPrev]);

    const isButtonDisabled = period === PeriodsOptions.RANGE;


    return (
        <PeriodPickerPreviewDates>
            <PeriodPickerDatesValueBox isDisabled={true}>
                <ButtonPrevNext onClick={movePrev} tooltip={''} disabled={isButtonDisabled}>
                    <KeyboardArrowLeftIcon />
                </ButtonPrevNext>
                <PeriodPickerDatesValue>
                    {startDate}
                    {isEndDate &&
                        <>
                            &nbsp;
                            -
                            &nbsp;
                            {endDate}
                        </>}
                </PeriodPickerDatesValue>
                <ButtonPrevNext onClick={moveNext} tooltip={''} disabled={!isNextVisible || isButtonDisabled}>
                    <KeyboardArrowRightIcon />
                </ButtonPrevNext>
            </PeriodPickerDatesValueBox>
        </PeriodPickerPreviewDates>
    );
};


export default PeriodPicker;