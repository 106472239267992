import styled from 'styled-components';
import { Box } from '@mui/material';
import { Flex } from '../../../../assets/style';


export const SchedulingBoardContainer = styled(Box)`
   display: flex; 
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 35px 30px; 
   height: 100%;
   width: 100%;
`;

export const SchedulingBoardHeader = styled(Flex)`
    width: 100%;
    min-height: 30px;
    justify-content: center;
    align-items: center;
`;

export const SchedulingBoardViewContainer = styled(Flex)`
    flex: 2;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`;