import React from 'react';
import {ButtonContainer} from './style';


const Button = ({children, isOpen, ...rest}: any) => {


    return (
        <ButtonContainer
            isOpen={isOpen}
            {...rest}
        >
            {children}
        </ButtonContainer>
    );
};

export default Button;
