import React, { PropsWithChildren } from 'react';
import { PanelContainer } from 'components/Panel/style';


const Panel = ({ children }: PropsWithChildren<any>) => {


    return (
        <PanelContainer>
            {children}
        </PanelContainer>
    );
};

export default Panel;
