import React from 'react';
import { EmptyListContainer, EmptyListIconFilter, EmptyListText } from './style';
import Translation from 'components/Translation';
import { Translate } from 'internationalization/translate';


const EmptyList = ({ isFilter }: any) => {

    return (
        <EmptyListContainer>
            <EmptyListIconFilter />
            <Translation use={isFilter ? Translate.emptyTableFilter : Translate.emptyTable}
                         as={EmptyListText} />
        </EmptyListContainer>
    );
};

export default EmptyList;