import { SettingShiftsContainer } from './style';
import SettingsShiftsRow from '../../components/SettingsShifs/SettingsShiftsRow';
import { Translate } from '../../../../internationalization/translate';
import { useModal } from '../../../../hooks/useModal';
import { useCallback, useMemo } from 'react';
import ChangeShiftStartAmount from '../../components/Dialogs/ChangeShiftStartAmount';
import { useSelector } from 'react-redux';
import { selectSettingsShifts, selectSettingsVehicleStartAmount } from '../../../../store/app/appSlice';
import { SHIFT_TYPES } from '../../../Schedule/components/Dialogs/DefineShiftsForDay/context/d';
import ChangeSettingsShift from '../../components/Dialogs/ChangeSettingsShift';


const SettingsShifts = () => {

    const vehicleStartAmount = useSelector(selectSettingsVehicleStartAmount);
    const settingsShifts = useSelector(selectSettingsShifts);

    const [firstShiftText, secondShiftText] = useMemo(() => [
        settingsShifts?.[SHIFT_TYPES.FIRST_SHIFT] ? `${settingsShifts?.[SHIFT_TYPES.FIRST_SHIFT]?.from} - ${settingsShifts?.[SHIFT_TYPES.FIRST_SHIFT]?.to}` : '',
        settingsShifts?.[SHIFT_TYPES.SECOND_SHIFT] ? `${settingsShifts?.[SHIFT_TYPES.SECOND_SHIFT]?.from} - ${settingsShifts?.[SHIFT_TYPES.SECOND_SHIFT]?.to}` : '',
        settingsShifts?.[SHIFT_TYPES.THIRD_SHIFT] ? `${settingsShifts?.[SHIFT_TYPES.THIRD_SHIFT]?.from} - ${settingsShifts?.[SHIFT_TYPES.THIRD_SHIFT]?.to}` : ''
    ], [settingsShifts]);

    const { openModal: openModalChangeShiftStartAmount, closeModal: closeChangeShiftStartAmountModal } = useModal({
        modal: ChangeShiftStartAmount,
        isActionsButtonsVisible: false,
        isCloseAction: false,
        noPadding: true
    });

    const { openModal: openModalChangeSettingsShift, closeModal: closeChangeSettingsShiftModal } = useModal({
        modal: ChangeSettingsShift,
        isActionsButtonsVisible: false,
        isCloseAction: false,
        noPadding: true
    });

    const handleActionStartAmount = useCallback(() => {
        openModalChangeShiftStartAmount({
            closeModal: closeChangeShiftStartAmountModal
        });
    }, [openModalChangeShiftStartAmount, closeChangeShiftStartAmountModal]);


    const handleActionChangeSettingsShift = useCallback((shiftType: SHIFT_TYPES) => {
        openModalChangeSettingsShift({
            closeModal: closeChangeSettingsShiftModal,
            shiftType
        });
    }, [openModalChangeSettingsShift, closeChangeSettingsShiftModal]);
    
    const handleActionChangeFirstShift = () => handleActionChangeSettingsShift(SHIFT_TYPES.FIRST_SHIFT);
    const handleActionChangeSecondShift = () => handleActionChangeSettingsShift(SHIFT_TYPES.SECOND_SHIFT);

    return (
        <SettingShiftsContainer>
            <SettingsShiftsRow label={Translate.settingsShiftVehicleStartAmountLabel} value={vehicleStartAmount}
                               handleAction={handleActionStartAmount} />
            <SettingsShiftsRow label={Translate.settingsShiftFirstShiftLabel} value={firstShiftText}
                               handleAction={handleActionChangeFirstShift} />
            <SettingsShiftsRow label={Translate.settingsShiftSecondShiftLabel} value={secondShiftText}
                               handleAction={handleActionChangeSecondShift} />
        </SettingShiftsContainer>
    );
};

export default SettingsShifts;