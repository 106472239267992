import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { deepmerge } from '@mui/utils';
import {
  createTheme,
  CssBaseline,
  PaletteMode,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider,
  useMediaQuery
} from '@mui/material';
import { getDesignTokens } from 'theme/designToken';
import { getThemedComponents } from 'theme/designComponent';
import { ContextProvider } from 'context/ContextProvider';
import router from 'router';
import { ColorModeContext } from 'theme/colorModeContext';
import { ReactQueryProvider } from 'core/root';
import UiContextContainer from 'context/UiContext';
import SpinnerCenter from 'components/Spinner';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import store from './store/index';
import './internationalization/i18';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import VersionChecker from './components/VersionChecker';

function App() {

  const prefersDarkMode: boolean = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = React.useState<PaletteMode>('dark');


  useEffect(() => {
    setMode(prefersDarkMode ? 'dark' : 'light');
  }, [prefersDarkMode]);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === 'light' ? 'dark' : 'light'
        );
      }
    }),
    []
  );

  let theme = React.useMemo(
    () =>
      createTheme(deepmerge(getDesignTokens(mode), getThemedComponents(mode))),
    [mode]
  );
  theme = responsiveFontSizes(theme);


  return (
    <>
      <Provider store={store}>
      <UiContextContainer>
        <ColorModeContext.Provider value={colorMode}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            <ReactQueryProvider>
              <ContextProvider>
                <RouterProvider router={router}/>
                {/*<ModalContainer/>*/}
                <SpinnerCenter/>
                <ToastContainer
                  position='bottom-center'
                  autoClose={3000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick={false}
                  rtl={false}
                  pauseOnFocusLoss={false}
                  draggable={false}
                  pauseOnHover
                />
                <VersionChecker />
              </ContextProvider>
            </ReactQueryProvider>
          </MuiThemeProvider>
        </ColorModeContext.Provider>
      </UiContextContainer>
      </Provider>
    </>
  );
}

export default App;
