import {useMutation} from '@tanstack/react-query';
import axiosClient, {transformRequest} from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';
import { TCreateRestaurantMenuItem } from '../d';


const useCreateRestaurantMenuItemMutation = (menuId: number) => {
    const mutation = useMutation({
        mutationFn: async (data: TCreateRestaurantMenuItem) => {
            const response = await axiosClient.post(createUrl({
                url: `/dispatcher/restaurant/menu/item/${menuId}`,
                version: 'v1',
            }), data, {
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                transformRequest
            });
            return response.data;
        }
    });
    return mutation;
};

export default useCreateRestaurantMenuItemMutation;
