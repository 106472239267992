import { SchedulingBoardWeekPickerContainer } from './style';
import PeriodPicker from '../../../../../../components/PeriodPicker';


const SchedulingBoardWeekPicker = () => {
    
    
    return (
        <SchedulingBoardWeekPickerContainer>
            <PeriodPicker />
        </SchedulingBoardWeekPickerContainer>
    );
};

export default SchedulingBoardWeekPicker;