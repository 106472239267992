import styled, { css } from 'styled-components';
import { Flex } from 'assets/style';
import { Badge, Menu, MenuItem } from '@mui/material';
import Button from 'components/button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';

export const TableFilterContainer = styled(Flex)`
  min-width: 140px;
`;
export const FilterArrowCaret = styled(ArrowDropDownIcon)`
   
`;

export const FilterButton = styled(Button)<{opened: number}>`
 &&& {
   width: 100%;
   padding: 8px;
   color: #000000;
   border-color: black;
   border-radius: 0;
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
   font: normal normal 400 18px/30px 'Readex Pro';
   justify-content: space-around;
   
   ${props => !props.opened && css`
     background: transparent;
   `}
    
    .MuiButton-endIcon {
      padding: 0;
      margin-left: 0;
    }
    
 }
`;

export const FilterBadge = styled(Badge)`
  &&& {
     .MuiBadge-badge {
       background-color: #11D600;
       color: #FFFFFF;
     }
  }
`;


export const FilterMenu = styled(Menu)<{isCategory ?: number}>`
  &&& {
    .MuiPaper-root {
      max-width: ${props => props.isCategory ? '152': '140'}px;
      background-color: #FFFFFF;
      border: 1px solid #000;
      border-top: none;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
       
       .MuiList-root{
          min-width: ${props => props.isCategory ? '152': '140'}px;
          padding: 0;
       }
    }
  }
`;

export const FilterMenuItem = styled(MenuItem)`
   &&& {
      position: relative;
      color: #000000;

      &.MuiMenuItem-divider {
         border-bottom-color: #000000;
      }
   }
`;

export const FilterMenuItemSelected = styled(CheckIcon)`
  &&& {
     position: absolute;
     right: 7px;
     font-size:16px;
     color: #11D600;
  }
`;
