
export enum CROP_EVENTS {
    setFile = 'setFile',
    setImage = 'setImage',
    setCrop = 'setCrop',
    setRotation = 'setRotation',
    setZoom='setZoom',
    setCroppedAreaPixels = 'setCroppedAreaPixels',
    resetState = 'resetState'
}


export type TReducerAction = {
    type: CROP_EVENTS;
    payload?: number | string | null | any;
}

export type TReducer = {
    image: any
    crop: any
    rotation: any
    zoom: number
    croppedAreaPixels: any
    file?: any
}