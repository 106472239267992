import {
    SettingsShiftRowPreviewContainer,
    SettingsShiftsButtonIconAction,
    SettingsShiftsPreviewLabel,
    SettingsShiftsPreviewValue,
    SettingsShiftsRowActionContainer,
    SettingsShiftsRowContainer
} from './style';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';


const SettingsShiftsRow = ({
    label,
    value,
    handleAction,
    icon = EditIcon
}: any) => {
    const {t} = useTranslation();
    const Icon = icon;
    
    return (
        <SettingsShiftsRowContainer>
            <SettingsShiftRowPreviewContainer>
                <SettingsShiftsPreviewLabel>{t(label)}</SettingsShiftsPreviewLabel>
                <SettingsShiftsPreviewValue>{value}</SettingsShiftsPreviewValue>
            </SettingsShiftRowPreviewContainer>
            <SettingsShiftsRowActionContainer>
                <SettingsShiftsButtonIconAction onClick={handleAction}>
                    <Icon />
                </SettingsShiftsButtonIconAction>
            </SettingsShiftsRowActionContainer>
        </SettingsShiftsRowContainer>
    );
};

export default SettingsShiftsRow;