import axiosClient from 'core/api/config/axios';
import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'core/api/utils/createUrl';


const useGetOrdersQuery = (requestParams= {} as any, refetchDone?: any) => {
  const { data, isLoading, isFetched, isFetching, error, refetch } = useQuery({
    queryKey: ['get-orders'],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await axiosClient.get(createUrl({
        url: '/dispatcher/order-free',
        query: {
          perPage: 1000,
          ...(requestParams || {})
        },
        version: 'v1'
      }));
      refetchDone && refetchDone();
      return res?.data?.data || [];
    }
  });
  return { data, isLoading, isFetched, isFetching, error, refetch };
};

export default useGetOrdersQuery;
