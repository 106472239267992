import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';
import { TCreateRestaurantMenu } from '../d';


const useCreateRestaurantMenuMutation = () => {
    const mutation = useMutation({
        mutationFn: async (data: TCreateRestaurantMenu) => {
            const response = await axiosClient.post(createUrl({
                url: '/dispatcher/restaurant/menu',
                version: 'v1',
            }), data, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        }
    });
    return mutation;
};

export default useCreateRestaurantMenuMutation;
