import styled from 'styled-components';
import {Card} from '@mui/material';


export const PanelContainer = styled(Card)`
  &&& {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: white;
    display: flex;
    flex-direction: column;
  }
`;
