import React, {
    useState,
    useCallback,
    useMemo
} from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {Input} from 'components/input/index';


const InputPassword = (props: any) => {

    const [typeState, setTypeState] = useState<boolean>(false);

    const handlerChangeType = useCallback(() => {
        setTypeState(v => {
            return !v;

        });
    }, [setTypeState]);

    const iconAction = React.useMemo(() => {
        return {
            handler: handlerChangeType,
            icon: typeState ? VisibilityIcon : VisibilityOffIcon
        } as any;
    }, [handlerChangeType, typeState]);


    const type = useMemo(() => typeState ? 'text' : 'password', [typeState]);


    return (
        <Input
            {...props}
            autoComplete='off'
            type={type}
            iconRight={iconAction}
        />
    );
};


export default InputPassword;
