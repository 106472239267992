import React, { useContext } from 'react';
import RestaurantMenuContext from '../../../context/context';


const RestaurantMenuViewListCategory = ({id}:any) => {
    
    const { categoryMap } = useContext(RestaurantMenuContext);

    return <>{categoryMap.get(id)?.name || ''}</>;
};

export default RestaurantMenuViewListCategory;