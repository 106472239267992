
export enum SHIFT_TYPES {
    FIRST_SHIFT = 1,
    SECOND_SHIFT = 2,
    THIRD_SHIFT= 3
}


export enum SHIFT_SCHEDULING_FOR_DAY_EVENTS {
    SET_ACTIVE_SHIFT = 'SET_ACTIVE_SHIFT',
    ADD_NEW_ITEM_BY_SHIFT = 'ADD_NEW_ITEM_BY_SHIFT',
    REMOVE_ITEM_BY_SHIFT = 'REMOVE_ITEM_BY_SHIFT',
    CHANGE_FIELD_VALUE_OF_ITEM = 'CHANGE_FIELD_VALUE_OF_ITEM',
    SET_SHIFT_DATE = 'SET_SHIFT_DATE',
    SET_SHIFTS = 'SET_SHIFTS',
    REST_SHIFTS = 'REST_SHIFTS',
    RESET_NEED_REFETCH = 'RESET_NEED_REFETCH',
    NEED_REFETCH = 'NEED_REFETCH'
}


export type TShiftSchedulingActions = {
    type: SHIFT_SCHEDULING_FOR_DAY_EVENTS,
    payload?: any
}

export type TShiftSchedulingReducer = {
    activeShift: number;
    shiftDate?: string|number|Date,
    shifts?: any
    needRefetch?: boolean
}


export const initialDataShiftsForDay = {
    activeShift: 1,
    shifts: {
        [SHIFT_TYPES.FIRST_SHIFT]: [],
        [SHIFT_TYPES.SECOND_SHIFT]: [],
        [SHIFT_TYPES.THIRD_SHIFT]: []
    },
    needRefetch: false
};