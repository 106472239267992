import styled, { css } from 'styled-components';
import {Button, IconButton} from '@mui/material';
import {Colors} from '../../theme/palette';


export const ButtonContainer = styled(Button)<{isOpen?: boolean, background?: string, isWhite?: number}>`
  &&& {
    background: linear-gradient(180deg, ${Colors.gradient.yellowLight} 0%, ${Colors.gradient.yellowDark} 100%);
    border: 1px solid #000;
    border-radius: 20px;
    color: #000;
    padding: 20px 0;
    text-transform: none;
    font-family: "Readex Pro";
    font-size: 24px;
    line-height: 30px;
    font-style: normal;
    font-weight: 400;
      
      ${props => props.background && css`
          background: ${props.background};
      `}
      
      ${props => props.isWhite && css`
            background: ${Colors.white};
            color: ${Colors.black};
      `}

    &:hover {
      border-width: 1px;
    }
  }
`;


export const ButtonIconContainer = styled(IconButton)`
  &&& {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    color: #000000;
    
      &.Mui-disabled {
          opacity: 0.5;
          cursor: not-allowed;
      }  
  }
`;
