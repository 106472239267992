import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';


export const ShiftSchedulingTempStopResumeDialogContainer = styled(Flex)`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;


export const ShiftSchedulingTempStopResumeDialogHeader = styled(Flex)`
    text-align: center;
    font: normal normal 400 40px/50px 'Readex Pro';
`;

export const ShiftSchedulingTempStopResumeDialogText = styled(Flex)`
    width: 80%;
    padding: 25px 0;
    text-align: center;
    font: normal normal normal 24px/30px 'Readex Pro';
`;

export const ShiftSchedulingTempStopResumeDialogSubText = styled(Flex)`
    max-width: 80%;
    padding-top: 10px;
    text-align: center;
    font: normal normal normal 24px/30px 'Readex Pro';
`;