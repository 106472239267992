import React, { useCallback, useState } from 'react';
import {
    CategorySectionContainer,
    CategorySectionDefineUpdateForm,
    CategorySectionTablePanel,
    CategorySectionTableView,
    CategorySectionTitle
} from './style';
import Translation from '../../../../../components/Translation';
import { Translate } from '../../../../../internationalization/translate';
import DefineUpdateForm from './views/DefineUpdateForm';
import Table from '../../../../../components/Table';
import { RestaurantCategoryColumns } from './column';
import { TUseOptimizeEventData, useOptimizeEventClick } from '../../../../../hooks/useOptimizeEventClick';
import { useModal } from '../../../../../hooks/useModal';
import RestaurantCategoryDialogActiveInActive from './views/components/Dialogs/ActiveInActive';
import { RESTAURANT_CATEGORY_TABLE_EVENTS } from './d';
import TableHeaderMenu from '../../TableComponents/Header';


const CategorySectionDialog = ({ restaurantId }: any) => {
    const [needRefetch, setNeedRefetch] = useState<number>(0);
    const handlerSetRefetch = useCallback(() => setNeedRefetch(new Date().getTime()), [setNeedRefetch]);
    const refetchDone = useCallback(() => setNeedRefetch(0), [setNeedRefetch]);
    const [editId, setEditId] = useState<number|string| null>(null);


    const clearEdit = useCallback(()=> setEditId(null), []);
    
    const { openModal: openDialogActiveInactiveCategory, closeModal: closeModal } = useModal({
        modal: RestaurantCategoryDialogActiveInActive,
        isActionsButtonsVisible: true,
        isCloseAction: true
    });

    const handlerOpenDialogActiveInactiveCategory = useCallback((row: string)=> {
        openDialogActiveInactiveCategory({
            setRefetch: handlerSetRefetch,
            closeModal,
            row: typeof row === 'string' ? JSON.parse(row) : row
        });
    },[closeModal, openDialogActiveInactiveCategory, handlerSetRefetch]);



    const { onClickHandler } = useOptimizeEventClick({
        eventHandler(data: TUseOptimizeEventData) {
            if(data.action === RESTAURANT_CATEGORY_TABLE_EVENTS.EDIT_ITEM) {
                if(data.id) {
                    setEditId(data.id);
                    return;
                }
            }

            if(data.action === RESTAURANT_CATEGORY_TABLE_EVENTS.REMOVE_ITEM){
                if(data.param) {
                    handlerOpenDialogActiveInactiveCategory(data.param);
                    return;
                }
            }
        }
    });

    return (
        <CategorySectionContainer>
            <CategorySectionDefineUpdateForm>
                <Translation use={Translate.restaurantCategorySectionDefineTitle} as={CategorySectionTitle} />
                <DefineUpdateForm  restaurantId={restaurantId} id={editId} setRefetch={handlerSetRefetch} clearEdit={clearEdit} />
            </CategorySectionDefineUpdateForm>
            <CategorySectionTableView  onClick={onClickHandler} data-action-root>
                <CategorySectionTablePanel>
                    {restaurantId ?
                        <Table
                            columns={RestaurantCategoryColumns}
                            url={`/dispatcher/restaurant-category/${restaurantId}`}
                            queryKey={'restaurant-categories'}
                            useHeader
                            needRefetch={needRefetch}
                            refetchDone={refetchDone}
                            preventFetch
                        >
                            <TableHeaderMenu />
                        </Table>
                        : <></>}
                </CategorySectionTablePanel>
            </CategorySectionTableView>
        </CategorySectionContainer>
    );
};

export default CategorySectionDialog;