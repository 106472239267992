import { DefineShiftForDayTabButton, DefineShiftForDayTabsContainer } from './style';
import { useCallback, useContext } from 'react';
import ShiftSchedulingForDayContext from '../../context/context';


const ShiftsForDateTabs = () => {

    const {activeShift, setActiveShift} = useContext(ShiftSchedulingForDayContext);

    const handlerChangeActiveShift = useCallback((payload: number)=> {
        setActiveShift(payload);
    },[setActiveShift]);
    
    const handlerFirstShift = useCallback(()=> handlerChangeActiveShift(1), [handlerChangeActiveShift]);
    const handlerSecondShift = useCallback(()=> handlerChangeActiveShift(2), [handlerChangeActiveShift]);
    const handlerThirdShift = useCallback(()=> handlerChangeActiveShift(3), [handlerChangeActiveShift]);

    return (
        <DefineShiftForDayTabsContainer>
            <DefineShiftForDayTabButton onClick={handlerFirstShift} isActive={activeShift === 1 ? 1 : 0}>Prva smena</DefineShiftForDayTabButton>
            <DefineShiftForDayTabButton onClick={handlerSecondShift} isActive={activeShift === 2 ? 1 : 0}>Druga smena</DefineShiftForDayTabButton>
            <DefineShiftForDayTabButton disabled onClick={handlerThirdShift} isActive={activeShift === 3 ? 1 : 0}>Treca smena</DefineShiftForDayTabButton>
        </DefineShiftForDayTabsContainer>
    );
};

export default ShiftsForDateTabs;