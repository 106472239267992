import { css } from 'styled-components';
import { Colors } from './palette';


export const backgroundYellowGradient = css`
   background: linear-gradient(180deg, ${Colors.gradient.yellowLight} 0%, ${Colors.gradient.yellowDark} 100%);
`;

export const colorBlack = css`
    color: ${Colors.black} !important;
`;
