import { useCallback, useContext, useMemo, useReducer, useRef } from 'react';
import getCroppedImg from '../helpers/cropImage';
import { CROP_EVENTS, TReducer, TReducerAction } from './d';
import CropDataContext from './context';


const initState = {
    image:  null,
    crop: { x: 0, y: 0 },
    rotation:  0,
    zoom: 1,
    croppedAreaPixels: null
} as any;

const reducer = (state: TReducer, action: TReducerAction) => {
    switch (action.type) {
        default:
            return state;
        case CROP_EVENTS.setFile: {
            return {
                ...state,
                file: action.payload
            };
        }    
        case CROP_EVENTS.setImage: {
            return {
                ...state,
                image: action.payload
            };
        }
        case CROP_EVENTS.setCrop: {
            return {
                ...state,
                crop: action.payload
            };
        }
        case CROP_EVENTS.setRotation: {
            return {
                ...state,
                rotation: action.payload
            };
        }

        case CROP_EVENTS.setCroppedAreaPixels: {
            return {
                ...state,
                croppedAreaPixels: action.payload
            };
        }

        case CROP_EVENTS.setZoom: {
            return {
                ...state,
                zoom: action.payload
            };
        }
        
        case CROP_EVENTS.resetState: {
            return {
                ...initState
            };
        }
    }
};

const useCropContext = (dataEntry: any) => {
    const {
        max_zoom = 3,
        min_zoom = 1,
        zoom_step = 0.1,
        max_rotation = 360,
        min_rotation = 0,
        rotation_step = 5
    } = dataEntry;
    const dataReducerStartState = useRef({
        image: dataEntry.image || null,
        crop: dataEntry?.crop || { x: 0, y: 0 },
        rotation: dataEntry?.rotation || 0,
        zoom: dataEntry?.zoom || 1,
        croppedAreaPixels: dataEntry?.croppedAreaPixels || null
    });
    const [state, dispatch] = useReducer(reducer, dataReducerStartState.current as TReducer);

    const onCropComplete = useCallback((_croppedArea: any, croppedAreaPixels: any) => {
        dispatch({
            type: CROP_EVENTS.setCroppedAreaPixels,
            payload: croppedAreaPixels
        });
    }, []);

    const handleZoomIn = () => {
        if (state.zoom < max_zoom) {
            dispatch({
                type: CROP_EVENTS.setZoom,
                payload: state.zoom + zoom_step * 2
            });
        }
    };

    const handleZoomOut = () => {
        if (state.zoom > min_zoom) {
            dispatch({
                type: CROP_EVENTS.setZoom,
                payload: state.zoom - zoom_step * 2
            });
        }
    };
    
    const handleRotateCw = () => {
        dispatch({
            type: CROP_EVENTS.setRotation,
            payload: state.rotation + rotation_step
        });
    };

    const handleRotateAntiCw = () => {
        dispatch({
            type: CROP_EVENTS.setRotation,
            payload: state.rotation - rotation_step
        });
    };

    const getProcessedImage = async () => {
        const {image, croppedAreaPixels, rotation} = state;
        if (image && croppedAreaPixels) {
            const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation) as any;
            const imageFile = new File([croppedImage.file], `img-${Date.now()}.png`, {
                type: 'image/png'
            });
            return imageFile;
        }
    };

    const setFile = useCallback((payload: any)=> dispatch({type: CROP_EVENTS.setFile, payload}), [dispatch]);

    const setImage = useCallback((payload: any) => {
        dispatch({
            type: CROP_EVENTS.setImage,
            payload
        });
    },[dispatch]);

    const setRotation = useCallback((payload: any) => dispatch({type: CROP_EVENTS.setRotation, payload}),[dispatch]);
    const setZoom = useCallback((payload: any) => dispatch({type: CROP_EVENTS.setZoom, payload}),[dispatch]);
    const setCroppedAreaPixels = useCallback((payload: any) => dispatch({type: CROP_EVENTS.setCroppedAreaPixels, payload}),[dispatch]);
    const setCrop = useCallback((payload: any) => dispatch({type: CROP_EVENTS.setCrop, payload}),[dispatch]);

    const resetStates = useCallback(() => dispatch({ type: CROP_EVENTS.resetState }),[dispatch]);
    
    return useMemo(()=> ({
        ...state,
        max_zoom,
        min_zoom,
        zoom_step,
        max_rotation,
        min_rotation,
        rotation_step,
        setImage,
        resetStates,
        setZoom,
        setRotation,
        setCrop,
        setCroppedAreaPixels,
        onCropComplete,
        setFile,
        getProcessedImage,
        handleZoomIn,
        handleZoomOut,
        handleRotateAntiCw,
        handleRotateCw,
    }),[
        state,
        max_zoom,
        min_zoom,
        zoom_step,
        max_rotation,
        min_rotation,
        rotation_step,
        setImage,
        resetStates,
        setZoom,
        setRotation,
        setCrop,
        setCroppedAreaPixels,
        onCropComplete,
        getProcessedImage,
        handleZoomIn,
        handleZoomOut,
        handleRotateAntiCw,
        handleRotateCw,
        setFile,
    ]);

};


export type TCropDataContextType = ReturnType<typeof useCropContext>;
const ImageCropProvider = ({
                               children,
                               max_zoom = 3,
                               min_zoom = 1,
                               zoom_step = 0.1,
                               max_rotation = 360,
                               min_rotation = 0,
                               rotation_step = 5
                           }: any) => {
    
    
    const providerData = useCropContext({
        max_zoom,
        min_zoom,
        zoom_step,
        max_rotation,
        min_rotation,
        rotation_step
    });

    return (
        <CropDataContext.Provider
            value={{
                ...providerData
            }}
        >
            {children}
        </CropDataContext.Provider>
    );
};

export const useImageCropContext = () => useContext(CropDataContext);

export default ImageCropProvider;