import React, { useMemo } from 'react';
import Tabs from 'components/Tabs';
import { Translate } from 'internationalization/translate';


const Users = React.lazy(() => import('views/Users'));
const Drivers = React.lazy(() => import('views/Drivers'));
const Restaurants = React.lazy(() => import('views/Restaurants'));
const Accounts = () => {

    const tabs = useMemo(() => [
        {
            id: 'driver',
            label: Translate.tabDrivers,
            component: Drivers,
            value: 0
        },
        {
            id: 'restaurant',
            label: Translate.tabRestaurants,
            component: Restaurants,
            value: 1
        },
        {
            id: 'user',
            label: Translate.tabUsers,
            component: Users,
            value: 2
        }
    ], []);

    return <Tabs tabs={tabs} tabHeaderPosition={'top'} isAbsolute={true} />;

};

export default Accounts;
