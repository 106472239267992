import React, { useMemo } from 'react';
import SelectText from 'components/select/index';
import countries from './countryOpts.json';
import { Translate } from 'internationalization/translate';
import { SelectCountryImage } from 'components/select/style';

const SelectPhoneCode = ({...rest}: any)=> {

  const options = useMemo(()=>  countries.map(x=> ({
    ...x,
    component: <SelectCountryImage effect="blur" src={x.flag} alt={x.name}/>,
  })),[]);

  return (
    <SelectText
      options={options}
      label={Translate.country}
      variant={'outlined'}
      {...rest}
    />
  );
};

export default SelectPhoneCode;
