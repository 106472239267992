import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';
import MuiAppBar from '@mui/material/AppBar';
import { styled as _styled } from '@mui/material/styles';
import {Toolbar} from '@mui/material';
import styled from 'styled-components';
import {Flex} from 'assets/style';
export const drawerWidth = 240;
export const miniDrawerWidth = 100;

export type AppBarProps = {
    open: boolean;
    toggleDrawer?: () => void
} & MuiAppBarProps

export const AppBarComponent = _styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    width: open ?  `calc(100% - ${drawerWidth}px)` :  `calc(100% - ${miniDrawerWidth}px)`,
    zIndex: theme.zIndex.drawer + 2,
    background: 'transparent',
    boxShadow: 'none',
    transition: 'ease-in-out 0.3s',
}));

export const AppToolbar = styled(Toolbar)`
  &&& {
    width: 100%;
    padding: 20px 30px;
    min-height: 50px;
    justify-content: space-between;
    position: relative;
  }
`;


export const AppToolBarRightPart = styled(Flex)`
   gap: 30px;
   justify-content: center;
   align-items: center;
   height: 100%; 
  //flex-direction: row-reverse;
`;
