/* eslint-disable @typescript-eslint/no-explicit-any */

import { BASE_URL } from 'core/constants';

const queryString = (params: any) => {
    return Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
};
export const createUrl = ({
  url,
  query,
  version
}: {url: string, query?: any, version?: 'v1'}) => {
    let _url = `${BASE_URL}`;
    if(version) _url = `${_url}/${version}`;
    _url = `${_url}${url}`;
    if (query) {
        return `${_url}?${queryString(query)}`;
    }
    return `${_url}`;
};
