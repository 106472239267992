


export enum ORDER_FREE_EVENTS {
    ADD_NEW_ITEM = 'ADD_NEW_ITEM',
    REMOVE_ITEM = 'REMOVE_ITEM',
    RESET_ITEMS = 'RESET_ITEMS',
    RESET_STATE = 'RESET_STATE'
}

export type TOrderFreeActions = {
    type: ORDER_FREE_EVENTS,
    payload?: any
}
export type TOrderItemRecord = {
    id?: number
    description: string,
    address: string,
    pickUpValue: number|string,
    amount?: number|string
}


export type TOrderFreeReducer = {
    items: TOrderItemRecord[] | any[]
}

export const initialOrderItemRecord = {
    description: '',
    address: '',
    pickUpValue: '',
    amount: ''
};

export const initialOrderFree = {
    items: [
        {
            id: 1720895846915,
            ...initialOrderItemRecord
        },
    ] as TOrderItemRecord[]
};

export type TCreateOrder = {
    customerName: string
    customerPhone: string
    customerAddress: string
    customerAddressJson?: JSON
    orderDate?: string
    orderTime?: string
    orderDeliveryAmount?: number
    orderAmount?: number
    driverId?: number
    items: TOrderItemRecord[]
}