import { styled } from '@mui/material/styles';
import { Flex } from '../../../../../../assets/style';
import SearchOffIcon from '@mui/icons-material/SearchOff';


export const EmptyListContainer = styled(Flex)`
    flex: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 25px;
    padding: 20px;
`;

export const EmptyListIconFilter = styled(SearchOffIcon)`
  &&& {
    height: 80px;
    width: 80px;
    color: #666666;
  }
`;

export const EmptyListText = styled(Flex)`
    font: normal normal 20px/30px 'Readex Pro';
    color: #666666;
    justify-content: center;
    text-align: center;
`;