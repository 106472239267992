import {
    UserBlockDialogHeader,
    UserBlockDialogText
} from '../../../../Users/Dialogs/Block/style';
import Translation from '../../../../../components/Translation';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useVehicleDeleteMutation from '../../../../../core/api/hooks/mutations/vehicle/useVehicleDeleteMutation';
import UiDataContext from '../../../../../context/UiContext/context';
import { Translate } from 'internationalization/translate';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import useModalFormData from '../../../../../hooks/useModalFormData';
import { VehicleActiveInactiveDialogContainer } from './style';
import useVehicleReactivateMutation from '../../../../../core/api/hooks/mutations/vehicle/useVehicleReactiveMutation';

const VehicleDialogActiveInActive = ({ row, setRefetch, closeModal }: any) => {
    const { t } = useTranslation();
    const useVehicleDelete = useVehicleDeleteMutation();
    const useVehicleReactivate = useVehicleReactivateMutation();
    const { setLoading, resetLoading } = useContext(UiDataContext);

    const [id, name, isActive, title, text] = useMemo(() => [
        row.id,
        `#${row.vehicleNumber} - ${row.name} - ${row.registrationPlate}`,
        row.active,
        row.active ? Translate.vehicleInactiveModalTitle : Translate.vehicleActiveModalTitle,
        row.active ? Translate.vehicleInactiveModalText : Translate.vehicleActiveModalText
    ], [row]);

    const handlerOnSubmit = useCallback(async () => {
        if (!id) return false;
        try {
            setLoading();
            const result = isActive ? await useVehicleDelete.mutateAsync({ id }) : await useVehicleReactivate.mutateAsync({ id });
            if (result?.status === 200) {
                successToast(t(result.message));
                setRefetch();
                closeModal();
            }
        } catch (e: any) {
            console.log(e);
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
            return false;
        } finally {
            resetLoading();
        }
        return true;
    }, [id, setLoading, isActive, useVehicleDelete, useVehicleReactivate, t, setRefetch, closeModal, resetLoading]);

    useModalFormData({
        handlerOnSubmit
    });

    return (
        <VehicleActiveInactiveDialogContainer>
            <Translation use={title} as={UserBlockDialogHeader} />
            <Translation use={text} as={UserBlockDialogText} />
            <UserBlockDialogText>
                {name} ?
            </UserBlockDialogText>
        </VehicleActiveInactiveDialogContainer>
    );
};

export default VehicleDialogActiveInActive;