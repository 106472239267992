import styled, { css } from 'styled-components';
import { CircularProgress } from '@mui/material';
import { Flex } from 'assets/style';

export const TableFooterContainer = styled(Flex)<{ isEnd?: boolean }>`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 2px;
    ${props => props.isEnd && css`
        justify-content: flex-end;
    `}
`;

export const LeftPart = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
`;

export const RightPart = styled(Flex)`
  position: relative;
  justify-content: flex-end;
  align-items: center;
  padding-left: 40px;
`;

export const TableMiniProgress = styled(CircularProgress).attrs({
  size: '20px'
})`
  position: absolute;
  left: 0;
`;
