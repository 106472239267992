import {
    useState,
    useMemo,
    useCallback
} from 'react';
import {
    isNumber,
    toNumber
} from 'lodash';

export const useFilterNumber = (dataBase = false) => {
    const [inputError,setInputError] = useState(false);
    const filterNumberValue = useCallback((filter: string) => {
        if (filter === null || filter === undefined) return !dataBase ? true : undefined;
        const data = /^((<=)|(>=)|(!=)|(<)|(>)|(=))/.exec(filter);
        if (!data || data.length === 0) {
            if (filter.length === 0 || filter === '-') return () => !dataBase ? true : undefined;
            const number = Number(filter);
            if (!isNumber(number)) {
                setInputError(true);
                return !dataBase ? true : undefined;
            }
            return !dataBase ? (value: any) => +value === number : { $eq: number };
        }
        const cmp = data[0];
        filter = filter.substring(cmp.length);
        if (filter.length === 0 || filter === '-') return () => !dataBase ? true : undefined;
        const VAL = toNumber(filter);
        if (!isNumber(VAL) || isNaN(VAL)) {
            setInputError(true);
            return () => !dataBase ? true : undefined;
        }
        switch (cmp) {
            case '<=':
                return !dataBase ? (value: any) => +value <= VAL : { $lte: VAL };
            case '>=':
                return !dataBase ? (value: any) => +value >= VAL : { $gte: VAL };
            case '!=':
                return !dataBase ? (value: any) => +value !== VAL : { $neq: VAL };
            case '>':
                return !dataBase ? (value: any) => +value > VAL : { $gt: VAL };
            case '<':
                return !dataBase ? (value: any) => +value < VAL : { $lt: VAL };
        }
        return !dataBase ? (value: any) => +value === VAL : { $eq: VAL };
    },[dataBase, setInputError]);
    return useMemo(()=> ({
        inputError,
        filterNumberValue
    }),[inputError,filterNumberValue]);
};
