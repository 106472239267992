import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { Flex } from '../../assets/style';
import Button from '../../components/button';

export const RestaurantMenuContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const RestaurantMenuTitle = styled(Typography).attrs({
    variant: 'h4'
})`
  color: #FFF;
  text-align: center;
  font: normal 400 40px/normal 'Readex Pro';
`;


export const RestaurantMenuHeaderButtonsPart = styled(Flex)`
    gap: 20px;
    justify-content: start;
    align-items: center;
`;

export const RestaurantMenuHeaderAddButton = styled(Button)`
    &&& {
        display: flex;
        justify-content: space-between;
        min-width: 120px;
        max-width: 200px;
        gap: 15px;
        padding: 10px 25px;
        color: black;
        border-radius: 10px;
        font-family: Readex Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        .MuiButton-startIcon {
            margin-right: 0;
            width: 24px;
            height: 24px;
        }
    }
`;



export const RestaurantMenuTableContainer = styled(Flex)`
    flex: 2;
    width: 100%;
    padding: 15px 30px;
`;


export const RestaurantMenuTableEmpty = styled(Flex)`
    flex: 2;
    justify-content: center;
    align-items: center;
    font: normal normal 30px/42px "Readex Pro";
    color: #666666;
`;