import axiosClient from 'core/api/config/axios';
import {useQuery} from '@tanstack/react-query';
import {createUrl} from 'core/api/utils/createUrl';


const useGetScheduledDatesQuery = ()=> {
    const { data, isFetched, isLoading } = useQuery({
        queryKey: ['shift-scheduled-dates'],
        queryFn: async () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const res: any = await axiosClient.get(createUrl({
                url: '/dispatcher/shift-scheduling-get-dates',
                version: 'v1'
            }));
            return res.data?.data  || {};
        },
        retry: false
    });
    return { data, isFetched, isLoading };
};

export default useGetScheduledDatesQuery;
