import React, { useCallback, useContext, useMemo } from 'react';
import {
    ApprovalListActionButton,
    ApprovalListItemActions,
    ApprovalListItemAvatar,
    ApprovalListItemContainer,
    ApprovalListItemContent,
    ApprovalListItemContentSubText,
    ApprovalListItemContentTitle, ApprovalListItemRightPart, ApprovalListRequestTime
} from './style';
import Translation from '../../../../Translation';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useTranslation } from 'react-i18next';
import { Translate } from '../../../../../internationalization/translate';
import useApprovalRequestMutation from '../../../../../core/api/hooks/mutations/approval/useApprovalRequestMutation';
import UiDataContext from '../../../../../context/UiContext/context';
import { errorToast, successToast } from '../../../../Utils/Toast';
import { formatDateTime } from '../../../../../utils';

const ApprovalListItem = ({ item, setRefetch }: any) => {
    const {t} = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const approvalRequestMutation = useApprovalRequestMutation();

    const [firstLetter, title, text, id, createdAt] = useMemo(() => [
        (() => {
            switch (item.Account.type) {
                case 2:
                    return (item.Account?.restaurants as any)?.[0]?.businessName?.charAt(0)?.toUpperCase();
                case 4:
                    return (item.Account?.driver as any)?.firstName?.charAt(0)?.toUpperCase();
            }
        })(),
        (() => {
            switch (item.approvalType) {
                case 2:
                case 4:
                    return Translate.approvalTypeResetPin;
            }
        })(),
        (() => {
            const type = t(item?.Account?.type === 2 ? Translate.restaurant : Translate.driver);
            const name = (() => {
                switch (item.Account.type) {
                    case 2:
                        return (item.Account?.restaurants as any)?.[0]?.businessName;
                    case 4:
                        return `${(item.Account?.driver as any)?.firstName} ${(item.Account?.driver as any)?.lastName}`;
                }
            })();
            const subText = (() => {
                let val = '';
                switch (item.approvalType) {
                    case 2:
                    case 4:
                        val = Translate.approvalTypeResetPinText;
                        break;
                }
                return val ? t(val) : '';
            })();
            return <span><b>{type} {name}</b> {subText}</span>;
        })(),
        item.id,
        formatDateTime(new Date(item.createdAt))
    ], [item, t]);


    const cancelApprovalRequest = useCallback(async ()=> {
        if(!id) return; if(!id) return;
        try {
            setLoading();
            const result = await approvalRequestMutation.mutateAsync({
                id,
                isApproved: 0
            });
            if (result.status === 200) {
                successToast(t(result.message));
                setRefetch();
            }
        }catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        }finally {
            resetLoading();
        }
    },[id, resetLoading, setLoading, setRefetch, t, approvalRequestMutation]);

    const approveApprovalRequest = useCallback(async ()=> {
        if(!id) return;
        try {
            setLoading();
            const result = await approvalRequestMutation.mutateAsync({
                id,
                isApproved: 1
            });
            if (result.status === 200) {
                successToast(t(result.message));
                setRefetch();
            }
        }catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        }finally {
            resetLoading();
        }
    },[id, resetLoading, setLoading, setRefetch, t, approvalRequestMutation]);

    return (
        <ApprovalListItemContainer>
            <ApprovalListItemAvatar>{firstLetter}</ApprovalListItemAvatar>
            <ApprovalListItemContent>
                <Translation use={title} as={ApprovalListItemContentTitle} />
                <ApprovalListItemContentSubText>{text}</ApprovalListItemContentSubText>
            </ApprovalListItemContent>
            <ApprovalListItemRightPart>
                <ApprovalListRequestTime>{createdAt}</ApprovalListRequestTime>
                <ApprovalListItemActions>
                    <ApprovalListActionButton  title={'Odbi'} onClick={cancelApprovalRequest}>
                        <CancelOutlinedIcon />
                    </ApprovalListActionButton>
                    <ApprovalListActionButton color={'success'} title={'Potvrdi'} onClick={approveApprovalRequest}>
                        <CheckCircleOutlineOutlinedIcon />
                    </ApprovalListActionButton>
                </ApprovalListItemActions>
            </ApprovalListItemRightPart>

        </ApprovalListItemContainer>
    );
};

export default ApprovalListItem;