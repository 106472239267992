import React from 'react';
import { DriverRegSendToFormContainer } from 'views/Drivers/components/DefineDriverForm/DriverRegSendToForm/style';
import Translation from '../../../../../components/Translation';
import { Translate } from '../../../../../internationalization/translate';
import { RestaurantMenuInsertTypeFormTitle } from '../RestaurantMenuInsertTypeForm/style';
import { Grid } from '@mui/material';
import FormikSelectField from '../../../../../components/Formik/FormikSelectField';
import {
    RestaurantMenuChooseExcelButton,
    RestaurantMenuChooseExcelButtonContainer,
    RestaurantMenuImportExcelFormContainer, RestaurantMenuUploadExcelIcon
} from './style';

export const programsOpts = [
    { label: 'Oktopus', value: '1' },
    { label: 'E-bar', value: '2' },
    { label: 'Garson', value: '4' }
];

const RestaurantMenuImportExcelForm = ({ changeStepper, setExcel }: any) => {

    console.log(changeStepper, setExcel);

    return (
        <DriverRegSendToFormContainer>
            <Translation use={Translate.restaurantMenuInsertTypeExcelTitle} as={RestaurantMenuInsertTypeFormTitle} />
            <RestaurantMenuImportExcelFormContainer>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <FormikSelectField
                            options={programsOpts}
                            variant="outlined"
                            name={'programType'}
                            label={Translate.restaurantMenuInsertProgramTypeLabel}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <RestaurantMenuChooseExcelButtonContainer>
                            <RestaurantMenuChooseExcelButton type={'submit'}>
                                <RestaurantMenuUploadExcelIcon />
                                <Translation use={Translate.restaurantMenuUploadExcelButtonLabel} />
                            </RestaurantMenuChooseExcelButton>
                        </RestaurantMenuChooseExcelButtonContainer>
                    </Grid>
                </Grid>
            </RestaurantMenuImportExcelFormContainer>
        </DriverRegSendToFormContainer>
    );
};

export default RestaurantMenuImportExcelForm;