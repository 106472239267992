import React, { useCallback, useContext, useMemo } from 'react';
import useModalFormData from 'hooks/useModalFormData';
import { UserBlockDialogContainer, UserBlockDialogHeader, UserBlockDialogText } from 'views/Users/Dialogs/Block/style';
import UiDataContext from 'context/UiContext/context';
import Translation from 'components/Translation';
import { Translate } from 'internationalization/translate';
import useDriverBlockMutation from 'core/api/hooks/mutations/driver/useDriverBlockMutation';
import { useTranslation } from 'react-i18next';
import { errorToast, successToast } from 'components/Utils/Toast';

const DriverDialogBlock = ({ row, setRefetch, closeModal }: any) => {

  const {t} = useTranslation();
  const driverBlockMutation = useDriverBlockMutation();
  const { setLoading, resetLoading } = useContext(UiDataContext);
  const [id, nickname, isBlocked, title, text] = useMemo(() => [
    row.id,
    `${row?.firstName} ${row?.lastName}`,
    row.isBlocked,
    row.isBlocked ? Translate.driverUnblockModalTitle  : Translate.driverBlockModalTitle,
    row.isBlocked ? Translate.driverUnblockModalText  : Translate.driverBlockModalText
  ], [row]);
  const handlerOnSubmit = useCallback(async () => {
    if (!id) return false;
    try {
      setLoading();
      const result = await driverBlockMutation.mutateAsync({
        id,
        isBlocked: isBlocked === 1 ? false : true
      });
      if (result?.status === 200) {
        successToast(t(result.message));
        setRefetch();
        closeModal();
      }
    } catch (e: any) {
      errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
    } finally {
      resetLoading();
    }
    return true;
  }, [id, setLoading, driverBlockMutation, isBlocked, t, setRefetch, closeModal, resetLoading]);

  useModalFormData({
    handlerOnSubmit
  });


  return (
    <UserBlockDialogContainer>
      <Translation use={title} as={UserBlockDialogHeader} />
      <Translation use={text} as={UserBlockDialogText} params={{nickname}} />
    </UserBlockDialogContainer>
  );
};


export default DriverDialogBlock;
