import { useMutation } from '@tanstack/react-query';
import axiosClient from '../../../config/axios';
import { createUrl } from '../../../utils/createUrl';

const useApprovalRequestMutation  = () => {
    const mutation = useMutation({
        mutationFn: async (data: {id: number,isApproved: number}) => {
            const response = await axiosClient.post(createUrl({
                url: '/dispatcher/approval-reset-pin',
                version: 'v1',
            }), data, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        }
    });
    return mutation;
};

export default useApprovalRequestMutation;