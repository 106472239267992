import React, { useMemo } from 'react';
import {
    RestaurantMenuListAutorenewIcon,
    RestaurantMenuListButton,
    RestaurantMenuListDeleteIcon,
    RestaurantMenuListEditIcon,
    RestaurantMenuListRow
} from './style';
import { RESTAURANT_CATEGORY_TABLE_EVENTS } from '../../../d';
import ConditionalRendering from '../../../../../../../../components/Utils/ConditionalRendering';

const RestaurantCategoryListActions = ({ row }: any) => {

    const [isActive] = useMemo(() => [
        row?.active
    ], [row]);

    return (
        <RestaurantMenuListRow>
            <ConditionalRendering condition={isActive}>
                <RestaurantMenuListButton
                    data-action={RESTAURANT_CATEGORY_TABLE_EVENTS.EDIT_ITEM}
                    data-action-id={row.id}
                >
                    <RestaurantMenuListEditIcon />
                </RestaurantMenuListButton>
            </ConditionalRendering>
            <RestaurantMenuListButton
                data-action={RESTAURANT_CATEGORY_TABLE_EVENTS.REMOVE_ITEM}
                data-action-id={row.id}
                data-action-param={JSON.stringify(row)}
            >
                {isActive ? <RestaurantMenuListDeleteIcon /> : <RestaurantMenuListAutorenewIcon />}
            </RestaurantMenuListButton>
        </RestaurantMenuListRow>
    );
};
export default RestaurantCategoryListActions;