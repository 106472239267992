import GroupIcon from '@mui/icons-material/Group';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SettingsIcon from '@mui/icons-material/Settings';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import HomeIcon from '@mui/icons-material/Home';

export const sidebarMenu = [
    {
        text: 'Početna',
        Icon: HomeIcon,
        path: '/home'
    },
    {
        text: 'Korisnici',
        Icon: GroupIcon,
        path: '/accounts',
        queryStr: '?tab=driver',
        childrenPaths: ['/restaurant-menu']
    },
    {
        text: 'Vozila',
        Icon: DirectionsCarIcon,
        path: '/vehicles',
        disabled: false
    },
    {
        text: 'Raspored',
        Icon: EditCalendarIcon,
        path: '/schedule',
        disabled: false
    },
    {
        text: 'Podešavanja',
        Icon: SettingsIcon,
        path: '/settings/shifts',
        disabled: false
    }
];
