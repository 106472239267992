import styled from 'styled-components';
import { Flex } from 'assets/style';
import { Avatar } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';


export const AvatarFirstLetterContainer = styled(Flex)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const StyledAvatar = styled(Avatar)`
  &&& {
    color: white;
  }
`;


export const StyleLazyLoadImage = styled(LazyLoadImage)`
  &&& {
    max-width: 100%;
    max-height: 100%;
  }
`;
