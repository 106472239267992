import axiosClient from 'core/api/config/axios';
import {useQuery} from '@tanstack/react-query';
import {createUrl} from 'core/api/utils/createUrl';


const useRestaurantMenuItemQuery = (id: number)=> {
    const { data, isFetched, isLoading } = useQuery({
        queryKey: [`restaurant-category-${id}`],
        queryFn: async () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const res: any = await axiosClient.get(createUrl({url: `/dispatcher/restaurant/category/${id}`, version: 'v1'}));
            return res.data?.data  || {};
        },
        enabled: !!id
    });
    return { data, isFetched, isLoading };
};

export default useRestaurantMenuItemQuery;
