import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Table, TableBody } from '@mui/material';
import {
    EmptyTableContainer,
    EmptyTableIconFilter,
    EmptyTableRows,
    EmptyTableText,
    TableContainer,
    TableDataContainer,
    TBodyRow
} from 'components/Table/style';
import TableDataContext from 'components/Table/context/context';
import TDCell from 'components/Table/TDCell';
import TableFooter from 'components/Table/TableFooter';
import THead from 'components/Table/THead';
import { deepEqual } from 'fast-equals';
import { NOOP } from 'utils';
import { Translate } from 'internationalization/translate';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import useTableQuery from 'core/api/hooks/query/useTableQuery';
import UiDataContext from 'context/UiContext/context';
import Translation from 'components/Translation';

const TableRows = ({ hideHeader, rows, tableBordered }: any) => {
    let selected = 0;
    return rows.map((row: any, rInd: number) => {
        if (row.depth === 0) selected = (selected + 1) % 2;

        return (
            <TBodyRow key={rInd} depth={row.depth} selected={!!selected}>
                {(row.getVisibleCells() || []).map((cell: any, index: number) => {
                    return <TDCell key={`cell-${index}`} hideHeader={hideHeader}
                                   tableBordered={tableBordered} cell={cell} />;
                })}
            </TBodyRow>
        );
    });
};

const Component = ({
                       hideHeader,
                       visibleColumns,
                       tableData,
                       totalRecords,
                       tableBordered,
                       isVisibilityHeader = true,
                       className,
                       filter
                   }: any) => {
    const { getHeaderGroups, getRowModel } = useReactTable({
        data: tableData,
        columns: visibleColumns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        pageCount: totalRecords
    });

    const isFilter = useMemo(() => filter && Object.keys(filter).filter(x => !!filter[x])?.length, [filter]);

    return (
        <>
            {<Table stickyHeader className={className}>
                {!hideHeader && <THead headerGroups={getHeaderGroups()} isVisibilityHeader={isVisibilityHeader} />}
                <TableBody>
                    {tableData?.length ?
                        <TableRows
                            rows={(getRowModel()?.rows || [])}
                            hideHeader={hideHeader}
                            tableBordered={tableBordered}
                        /> :
                        <TBodyRow>
                            <EmptyTableRows
                                colSpan={visibleColumns.length}>
                                <EmptyTableContainer>
                                    <EmptyTableIconFilter />
                                    <Translation use={isFilter ? Translate.emptyTableFilter : Translate.emptyTable}
                                                 as={EmptyTableText} />
                                </EmptyTableContainer>
                            </EmptyTableRows>
                        </TBodyRow>
                    }
                </TableBody>
            </Table>}
        </>

    );
};

export const StaticTable = (props: any) => <Component {...props} />;

const TableComponent = ({
                            csvFileName,
                            title,
                            modelName,
                            url,
                            queryKey,
                            needRefetch,
                            refetchDone = NOOP,
                            tableBordered,
                            usePagination,
                            className,
                            hideLoading,
                            preventFetch,
                            isResetPagination,
                            rowsPerPageOptions,
                            children,
                            useHeader,
                            FooterChildren
                        }: any) => {
    const [tableData, setTableData] = useState([] as any);
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const {
        isNeedRefetch,
        clearNeedRefetch,
        requestOptions,
        setPage,
        visibleColumns,
        filter
    } = useContext(TableDataContext);
    const refReqOptions = useRef({});

    const _requestOptions = useMemo(() => {
        if (!deepEqual(requestOptions, refReqOptions.current)) refReqOptions.current = requestOptions;
        return refReqOptions.current;
    }, [requestOptions, refReqOptions]);

    const _clearNeedRefetch = useCallback(() => {
        clearNeedRefetch();
        refetchDone && refetchDone();
    }, [clearNeedRefetch, refetchDone]);

    const _isNeedRefetch = useMemo(() => !!isNeedRefetch || !!needRefetch, [isNeedRefetch, needRefetch]);

    const refForFetch = useRef({
        hideLoading,
        preventFetch
    });
    refForFetch.current = {
        hideLoading,
        preventFetch
    };

    

    const { data: tableState, error, isLoading, isFetching, isFetched, refetch } = useTableQuery({
        url,
        queryKey,
        isNeedFetch: !!_isNeedRefetch,
        refetchDone: _clearNeedRefetch,
        requestOptions: _requestOptions,
        isVisible: !!refForFetch.current.preventFetch
    }) as any;


    useEffect(() => {
        if (!isLoading || !isFetched) {
            resetLoading();
            return;
        }
        setLoading();
        return () => {
            resetLoading();
        };
    }, [setLoading, resetLoading, isLoading, isFetched]);


    useEffect(() => {
        if (!_isNeedRefetch || isFetching || error) return;
        refetch();
    }, [_isNeedRefetch, refetch, isFetching, error]);

    /**
     * Make refetch and emit done
     */
        // emitFetchSuccess && emitFetchSuccess(result);
        // refetchDone();


    useEffect(() => {
        if(!tableState) return;
        setTableData([...(tableState?.rows || [])].map((x: any, index: number) => ({
            ...x,
            index: index + 1
        })));
    }, [tableState]);    


    useEffect(() => {
        if (!isResetPagination) return;
        setPage(0);
    }, [isResetPagination, setPage]);

    return (
        <TableDataContainer className={className}>
            {useHeader ? children : null}
            <TableContainer>
                <Component
                    tableBordered={tableBordered}
                    visibleColumns={visibleColumns}
                    tableData={tableData}
                    totalRecords={tableState?.count || 0}
                    filter={filter}
                />
            </TableContainer>
            {usePagination &&
                <TableFooter title={title} csvFileName={csvFileName} modelName={modelName} totalRecords={tableState?.count || 0}
                             rowsPerPageOptions={rowsPerPageOptions} FooterChildren={FooterChildren} />}
        </TableDataContainer>
    );
};

export default TableComponent;
