import styled, { css } from 'styled-components';
import { Box } from '@mui/material';
import { Flex } from '../../../assets/style';
import Button from '../../../components/button';


export const SettingsLayoutContainer = styled(Box)`
    &&& {
        display: flex;
        width: 100%;
        flex-direction: row;
        height: 100%;
        overflow: hidden;
    } 
`;


export const SettingsLayoutContent = styled(Flex)`
    flex: 2;
    flex-direction: column;
`;


export const SettingsDialogButton = styled(Button)`
    &&& {
        padding: 15px;
        flex: 1;
        font: normal normal 400 18px/22.5px "Readex Pro" !important;
    }
`;

export const SettingsDialogButtonsForm = styled(Flex)<{isColumn?: number}>`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    
    ${SettingsDialogButton} {
        max-width: 45%;
        min-width: 45%;
    }
    
    ${props => props?.isColumn && css`
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        align-items: center;
        
        ${SettingsDialogButton} {
            max-width: 100%;
            min-width: 100%;
        }
    `}
`;

