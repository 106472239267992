import React, { useCallback, useContext, useMemo } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ButtonIcon from 'components/button/ButtonIcon';
import { ListItemText } from '@mui/material';
import { UserActionMenu, UserActionMenuItem, UserActionMenuItemIcon } from 'views/Users/TableComponents/Actions/style';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TableDataContext from 'components/Table/context/context';
import { useModal } from 'hooks/useModal';
import Translation from 'components/Translation';
import { Translate } from 'internationalization/translate';
import ConditionalRendering from 'components/Utils/ConditionalRendering';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddMenuItemDialog from '../../Dialogs/AddMenuItemDialog';
import RestaurantMenuItemDialogActiveInActive from '../../Dialogs/ActiveInActive';

const RestaurantMenuItemActions = ({ row }: { row: any }) => {

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);


  const { setRefetch } = useContext(TableDataContext);
  
  const { openModal: openDialogDelete, closeModal: closeModalDelete } = useModal({
    modal: RestaurantMenuItemDialogActiveInActive,
    isActionsButtonsVisible: true,
    isCloseAction: false
  });

  const { openModal: openDialogEdit, closeModal: closeModalEdit } = useModal({
    modal: AddMenuItemDialog,
    isActionsButtonsVisible: false,
    isCloseAction: true
  });

  const  isActive = useMemo(() => row.active, [row]);

  const handlerOpenDialogDeleteRestaurantItem = useCallback(() => {
      openDialogDelete({
      row,
      setRefetch,
      closeModal: closeModalDelete
    });
    handleClose();
  }, [row, setRefetch, openDialogDelete, handleClose, closeModalDelete]);

  const handlerOpenDialogEditRestaurantMenuItem = useCallback(() => {
      openDialogEdit({
      restaurantId: row?.restaurantId,
      menuId: row?.restaurantMenuId,
      id: row?.id,
      setRefetch,
      closeModal: closeModalEdit
    });
    handleClose();
  }, [openDialogEdit, row, setRefetch, closeModalEdit, handleClose]);

  return (
    <>
      <ButtonIcon
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon/>
      </ButtonIcon>
      <UserActionMenu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ConditionalRendering condition={isActive}>
          <UserActionMenuItem
            disableRipple
            onClick={handlerOpenDialogEditRestaurantMenuItem}
            divider
          >
            <UserActionMenuItemIcon>
              <EditIcon/>
            </UserActionMenuItemIcon>
            <Translation use={Translate.editText} as={ListItemText}/>
          </UserActionMenuItem>
        </ConditionalRendering>
        <UserActionMenuItem
          disableRipple
          onClick={handlerOpenDialogDeleteRestaurantItem}
        >
          <UserActionMenuItemIcon>
            {isActive ? <DeleteIcon/> : <AutorenewIcon/>}
          </UserActionMenuItemIcon>
          <Translation use={isActive ? Translate.deleteText : Translate.activateText} as={ListItemText}/>
        </UserActionMenuItem>
      </UserActionMenu>
    </>
  );
};

export default RestaurantMenuItemActions;
