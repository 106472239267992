import styled, { css } from 'styled-components';
import { Flex } from '../../../../../../assets/style';
import Button from '../../../../../../components/button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Colors } from '../../../../../../theme/palette';

export const RestaurantMenuViewManuallyContainer = styled(Flex)`
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 20px 0;
`;

export const RestaurantMenuViewHeaderContainer = styled(Flex)`
    align-items: center;
    flex-direction: row;
    padding: 20px 30px;
    justify-content: space-between;
    width: 100%;
`;

export const RestaurantMenuViewContentContainer = styled(Flex)`
    flex-direction: column;
    justify-content: start;
    flex: 2;
    width: 100%;
    padding: 15px 0 0;

    .MuiTable-root {
        max-height: 100%;
        
        .MuiTableHead-root {
            position: sticky;
        }
        
        .MuiTableBody-root {
            max-height: 100%;
            overflow-y: scroll;
        }
    }
`;


export const RestaurantMenuViewHeaderButtonBack = styled(Button)`
    &&& {
        min-width: 110px;
        max-width: 100%;
        font-size: 16px;
        line-height: 20px;
        background: black;
        color: white;
        padding: 10px 0;
        border-radius: 10px;
        max-height: 40px;        
    }
`;

export const RestaurantMenuViewHeaderButtonReset = styled(Button)<{disabled?: boolean}>`
    &&& {
        min-width: 110px;
        max-width: 100%;
        font-size: 16px;
        line-height: 20px;
        background: #666666;
        color: white;
        padding: 10px 0;
        border-radius: 10px;
        
        ${props => props.disabled && css`
            pointer-events: auto;
            cursor: not-allowed;
            opacity: 0.7;
            background: #DADADA;
            border-color: transparent;
        `}
    }
`;

export const RestaurantMenuViewHeaderButtonSave = styled(Button)<{disabled?: boolean}>`
    &&& {
        min-width: 110px;
        max-width: 100%;
        font-size: 16px;
        line-height: 20px;
        padding: 10px 0;
        border-radius: 10px;

        ${props => props.disabled && css`
            pointer-events: auto;
            opacity: 0.7;
            cursor: not-allowed;
            background: #DADADA;
            color: white;
            border-color: transparent;
        `}
    }
`;

export const RestaurantMenuBackIcon = styled(ArrowBackIcon)`
    &&& {
        color: white;
        margin-right: 15px;
    }
`;

export const RestaurantMenuViewHeaderSearchPart = styled(Flex)`
  &&& {
      flex: 2;
      color: black;
      justify-content: center;
      align-items: center;

      ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
          color:   ${Colors.grey} !important;
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color:   ${Colors.grey} !important;
          opacity:  1;
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
          color:    ${Colors.grey} !important;
          opacity:  1;
      }
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color:    ${Colors.grey} !important;
      }
      ::-ms-input-placeholder { /* Microsoft Edge */
          color:   ${Colors.grey} !important;
      }

      ::placeholder { /* Most modern browsers support this now. */
          color:    ${Colors.grey} !important;
      }

      .MuiOutlinedInput-notchedOutline {
          border-color: ${Colors.grey} !important;
      }
      
      .MuiInputAdornment-root {
          color: ${Colors.grey} !important;
          min-height: 40px;
          max-height: 40px;
      }

      .MuiOutlinedInput-root {
          min-height: 40px;
          max-height: 40px;
          padding: 10px;
      }
  }  
`;

export const RestaurantMenuViewHeaderRightPart = styled(Flex)`
    align-items: center;
    gap: 25px;
`;