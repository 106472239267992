import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';
import { TCreateRestaurantCategory } from '../d';
import { omit } from 'lodash';


const useUpdateRestaurantCategoryMutation = () => {
    const mutation = useMutation({
        mutationFn: async (data: TCreateRestaurantCategory) => {
            const response = await axiosClient.put(createUrl({
                url: `/dispatcher/restaurant/category/${data.id}`,
                version: 'v1',
            }), {
                ...omit(data, ['id'])
            }, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
        }
    });
    return mutation;
};

export default useUpdateRestaurantCategoryMutation;
