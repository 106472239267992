import Translation from '../../../../../components/Translation';
import { Translate } from '../../../../../internationalization/translate';
import {
    DriverRegFormContent,
    DriverRegOptionButton,
    DriverRegOptionsRow,
    DriverRegOptionText, DriverRegSendToFormContainer, DriverRegSendTypeError
} from '../../../../Drivers/components/DefineDriverForm/DriverRegSendToForm/style';
import React, { useCallback, useMemo } from 'react';
import {
    RestaurantMenuInsertTypeExcelIcon,
    RestaurantMenuInsertTypeFormTitle,
    RestaurantMenuInsertTypeManuallyIcon
} from './style';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { MENU_IMPORT_TYPE } from '../validation';

const RestaurantMenuInsertTypeForm = ({setExcel}: any) => {

    const { errors, values, setFieldValue } = useFormikContext();
    const { t } = useTranslation();

    const error = useMemo(() => (errors as any)?.menuImportType || '', [errors]);

    const [isActiveExcel, isActiveManual] = useMemo(() => [
        Number((values as any)?.menuImportType === MENU_IMPORT_TYPE.EXCEL),
        Number((values as any)?.menuImportType === MENU_IMPORT_TYPE.MANUAL),
    ], [values]);

    const handlerExcel = useCallback(async () => {
        await setFieldValue('menuImportType', MENU_IMPORT_TYPE.EXCEL, true);
        setExcel(true);
    }, [setExcel, setFieldValue]);

    const handlerManual = useCallback(async () => {
        await setFieldValue('menuImportType', MENU_IMPORT_TYPE.MANUAL, true);
        setExcel(false);
    }, [setExcel, setFieldValue]);


    return(
        <DriverRegSendToFormContainer>
            <Translation use={Translate.restaurantMenuInsertTypeTitle} as={RestaurantMenuInsertTypeFormTitle} />
            <DriverRegFormContent>
                <DriverRegOptionsRow>
                    <DriverRegOptionButton onClick={handlerExcel} active={isActiveExcel}>
                        <RestaurantMenuInsertTypeExcelIcon />
                        <Translation use={Translate.restaurantMenuInsertTypeExcel} as={DriverRegOptionText} />
                    </DriverRegOptionButton>
                    <DriverRegOptionButton onClick={handlerManual} active={isActiveManual}>
                        <RestaurantMenuInsertTypeManuallyIcon />
                        <Translation use={Translate.restaurantMenuInsertTypeManually} as={DriverRegOptionText} />
                    </DriverRegOptionButton>
                </DriverRegOptionsRow>
                <DriverRegSendTypeError>
                    {error ? t(error) : <>&nbsp;</>}
                </DriverRegSendTypeError>
            </DriverRegFormContent>
        </DriverRegSendToFormContainer>
    );
};

export default RestaurantMenuInsertTypeForm;