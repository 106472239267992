import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';

const useOrderCheckPhoneMutation = () => {
    const mutation = useMutation({
        mutationFn: async (data: {customerPhone: string}) => {
            const response = await axiosClient.post(createUrl({
                url: '/dispatcher/order-free/check-phone',
                version: 'v1',
            }), { customerPhone: data.customerPhone }, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        }
    });
    return mutation;
};

export default useOrderCheckPhoneMutation;
