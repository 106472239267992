import { Flex } from '../../../../../../../../assets/style';
import styled from 'styled-components';
import { Colors } from '../../../../../../../../theme/palette';
import ButtonIcon from '../../../../../../../../components/button/ButtonIcon';

export const ShiftItemContainer = styled(Flex)`
    flex-direction: column;
    padding: 40px 10px 10px;
    border: 1px solid ${Colors.gradient.yellowDark};
    border-radius: 20px;
    background: initial;
    aspect-ratio: 3/3;
    position: relative;
    gap: 10px;
`;

export const ClearItemButton = styled(ButtonIcon)`
    &&& {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 5px;
        right: 5px;
        color: white;
    }
`;