import styled from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Colors } from '../../theme/palette';


export const CollapseContainer = styled(Accordion)`
    && {
        background: transparent;
        
        &:before {
          background: transparent;
        }
    }
`;

export const CollapseSummary = styled(AccordionSummary)`
    && {
        border-bottom: 1px solid ${Colors.white};
    }
`;

export const CollapseDetails = styled(AccordionDetails)`
    && {
        padding-top: 15px;
    }
`;