import React, { PropsWithChildren, useEffect, useState } from 'react';


export type TConditionalRendering = PropsWithChildren<any> & {
    condition: boolean
    timeout?: number,
}
const ConditionalRendering = ({ condition, children, timeout = 0 }: TConditionalRendering) => {

    const [state, setState] = useState(condition);

    useEffect(() => {
        let refTh = 0;
        if (condition) {
            setState(true);
            return;
        }
        refTh = setTimeout(() => {
            setState(false);
        }, timeout) as any;
        return () => {
            clearTimeout(refTh);
        };
    }, [condition, setState, timeout]);

    if (!state) return <></>;
    return (
        <>
            {children}
        </>
    );
};

export default ConditionalRendering;
