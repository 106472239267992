import styled from 'styled-components';
import { Flex } from 'assets/style';
import { Form } from 'formik';
import Button from '../../../../../../components/button';


export const OrderAddItemContainer = styled(Flex)`
    min-width: 450px;
    min-height: 250px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;


export const OrderAddItemFooter = styled(Flex)`
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-height: 40px;
    padding: 5px 0 5px;
`;

export const OrderAddItemForm = styled(Form)`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;


export const OrderAddItemButton = styled(Button)`
  &&& {   
    min-width: 150px;
    max-width: 150px;
    font-size: 16px;
    line-height: 20px;
    padding: 15px 0;
  }
`;