import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';
import { Form } from 'formik';
import Button from '../../../../../components/button';


export const UpdateOrderInformationContainer = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 450px;
    max-width: 350px;
    min-height: 350px;
    padding: 20px;
`;




export const UpdateOrderInformationForm = styled(Form)`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;


export const UpdateOrderInformationRow = styled(Flex)`
    width: 100%;
    gap: 25px;
    justify-content: space-between;
    align-items: center;

 
    
    .MuiFormControl-root {
        min-width: 30%;
    }
`;

export const UpdateOrderInformationFooter = styled(Flex)`
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-height: 40px;
    padding: 5px 0 5px;
`;


export const UpdateOrderInformationButton = styled(Button)`
  &&& {   
    min-width: 150px;
    max-width: 150px;
    font-size: 16px;
    line-height: 20px;
    padding: 15px 0;
  }
`;


