import styled, { css } from 'styled-components';
import { Flex } from '../../../../../../../assets/style';
import { Colors } from '../../../../../../../theme/palette';
import Button from '../../../../../../../components/button';


export const DefineShiftForDayTabsContainer = styled(Flex)`
    flex-direction: column;
    padding: 25px;
    border-right: 2px solid ${Colors.gradient.yellowDark};
`;

export const DefineShiftForDayTabButton = styled(Button)<{isActive?: number, disabled?: boolean}>`
    &&& {
        min-width: 170px;
        min-height: 47px;
        background: transparent !important;
        color: white;
        justify-content: flex-start;
        padding: 10px 15px;
        margin-bottom: 10px;
        
        &:active {
            background: initial;
        }
        
        ${props => props.disabled && css`
            opacity: 0.5;
        `}    
            
        ${props => props.isActive && css`
            background: linear-gradient(180deg, ${Colors.gradient.yellowLight} 0%, ${Colors.gradient.yellowDark} 100%) !important;
            color: black;
        `}
    }
`;
