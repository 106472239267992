import styled from 'styled-components';
import {Avatar} from '@mui/material';
import {Flex} from 'assets/style';
import Button from 'components/button';


export const AppBarUserAvatar = styled(Avatar)`
  &&& {
    color: white;
  }
`;

export const AvatarMenuContainer = styled(Flex)`
  flex-direction: column;
  padding: 18px 21px;
  gap: 30px;
`;
export const AvatarMenuRow = styled(Flex)`
    gap: 16px;
`;

export const AvatarMenuImage = styled(Avatar)`
  &&& {
    width: 43px;
    height: 43px;
    color: white;
  }
`;

export const MenuUserInfo = styled(Flex)`
  flex-direction: column;
`;

export const MenuUserInfoOneRow = styled(Flex)`
  color: #FFF;
  font-family: Readex Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const MenuUserInfoUserName = styled(Flex)`
  color: #FFF;
  font-family: Readex Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const MenuLogoutButton = styled(Button)`
  &&& {
    width: 100%;
    border-radius: 10px;
    background: #fff;
    padding: 5px 40px;
    color: #000;
    font-family: Readex Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
