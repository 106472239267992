import React, { memo } from 'react';
import { TTabContentProps } from 'components/Tabs/d';
import TabPanel from 'components/Tabs/TabPanel';
import { TabContentContainer } from 'components/Tabs/style';

const TabContent = memo(({ tabs, activeTab }: TTabContentProps) => {
    return (
        <TabContentContainer>
            {!tabs?.length ? <>Empty Page</> :
                tabs.map((tab, index) => {
                    const Component = tab.component;
                    return (
                        <TabPanel key={tab.guid} active={activeTab} index={index} value={tab.value}>
                            <Component {...(tab.componentProps || {})} isVisible={tab.value === activeTab} />
                        </TabPanel>
                    );
                })
            }
        </TabContentContainer>
    );
});

export default TabContent;
