import styled, { css } from 'styled-components';
import { IconButton } from '@mui/material';

export const ButtonPrevNextContainer = styled(IconButton)<{disabled?: boolean}>`
 
  &&& {
    width: 26px;
    height: 26px;
    padding: 0px;
    color: black;
    ${({disabled})=> disabled && css`
       opacity: 0.3;
    `}
  }
`;
