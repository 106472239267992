import styled, { css } from 'styled-components';
import { Flex } from 'assets/style';
import { Colors } from 'theme/palette';
import ButtonIcon from '../../../../../../components/button/ButtonIcon';



export const OrderDriverItemColumn = styled(Flex)<{flex?: number}>`
    flex-direction: column;
    ${props => !!props?.flex && css`
        flex: ${props.flex};
    `} 
`;

export const OrderDriverItemAssigned = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;

export const OrderDriverItemRightActionButton = styled(ButtonIcon)<{visibility?: number}>`
   &&& {
       width: 35px;
       height: 35px;
       padding: 5px;
       align-items: center;
       border-radius: 100%;
       color: ${Colors.white};
       background: ${Colors.black};
       z-index: 100;
       
       svg {
           font-size: 26px;
       }
       
       ${props => !props.visibility && css`
           visibility: hidden;
       `}
   }
`;


export const OrderDriverItemRow = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    font: normal 400 125%/20px "Readex Pro";
`;


export const OrderDriverItemContainer = styled(Flex)<{status?: number, free?: number, selected?: number}>`
    flex-direction: row;
    gap: 8px;
    padding: 15px 0 15px 15px;
    border-radius: 20px;
    border: 1px solid ${Colors.gradient.yellowDark};
    cursor: pointer;
    background: ${Colors.green70};
    color: ${Colors.white};
    
    ${props => !!props.selected && css`
        background: linear-gradient(180deg, ${Colors.gradient.yellowLight} 100%, ${Colors.gradient.yellowLighter} 100%, ${Colors.gradient.yellowLight} 100%);
        color: ${Colors.black};
    `}
    
    ${props => !props.selected && props.status === 3 && css`
        background: ${Colors.gradient.yellowDark};
        color: ${Colors.white};
    `}
    
    ${props => !props.selected &&  !!props.free && css`
        background: ${Colors.red};
        color: ${Colors.white};
    `}
`;