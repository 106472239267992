import React, { useCallback, useContext } from 'react';
import TableDataContext from 'components/Table/context/context';
import { TableCustomHeaderContainer } from 'views/Users/TableHeader/style';
import TableSearchInput from 'components/Table/TableSearchInput';
import TableFilter from 'components/Table/TableFilter';

const TableHeader = ({ tableFilterOptions }: any) => {
    const { setFilter, filter, clearAllFilters } = useContext(TableDataContext);


    const handlerSearch = useCallback((value: string) => {
        setFilter({
            field: 'search',
            data: value
        });
    }, [setFilter]);

    const handlerClearSearch = useCallback(() => setFilter({ field: 'search', data: undefined }), [setFilter]);

    return (
        <TableCustomHeaderContainer>
            <TableFilter setFilter={setFilter} clearAllFilters={clearAllFilters} tableFilterOptions={tableFilterOptions} filter={filter} />
            <TableSearchInput
                handlerSearch={handlerSearch}
                clearSearch={handlerClearSearch}
            />
        </TableCustomHeaderContainer>
    );
};

export default TableHeader;
