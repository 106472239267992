import { SchedulingBoardContainer, SchedulingBoardHeader, SchedulingBoardViewContainer } from './style';
import SchedulingBoardWeekPicker from './components/SchedulingBoardWeekPicker';
import withPeriodPickerContext from 'components/PeriodPicker/context';
import SchedulingBoardView from './components/SchedulingBoardView';
const SchedulingBoard = () => {

    
    return (
        <SchedulingBoardContainer>
            <SchedulingBoardHeader>
                <SchedulingBoardWeekPicker />
            </SchedulingBoardHeader>
            <SchedulingBoardViewContainer>
                <SchedulingBoardView />
            </SchedulingBoardViewContainer>
        </SchedulingBoardContainer>
    );
};

export default withPeriodPickerContext(SchedulingBoard);