import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';


const useDriverBlockMutation = () => {
    const mutation = useMutation({
        mutationFn: async (data: {id: string|number, isBlocked?: boolean}) => {
            const {id, isBlocked} = data;
            const response = await axiosClient.patch(createUrl({
                url: `/dispatcher/driver/block-unblock/${id}`,
                version: 'v1',
                query: {
                    isBlocked
                }
            }), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        }
    });
    return mutation as any;
};

export default useDriverBlockMutation;
