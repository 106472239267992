import React, { useMemo } from 'react';
import moment from 'moment';
import {
    OrderItemPickUpLateTime,
    OrderItemPickUpTimeContainer,
    OrderItemPickUpTimeText,
    OrderItemTitle
} from './style';


const PickUpTimeView = ({
    itemAddress,
    isCompleted,
    pickUpTime,
    completedTime
}: any) => {
    
    const  [time, isOrange, isRed, completedTimeText] = useMemo(()=> {
       const diff = moment.duration(moment(pickUpTime).diff(moment()))?.asMinutes();
       let diffCompleted = completedTime ? moment.duration(moment(pickUpTime).diff(moment(completedTime)))?.asMinutes() : 0;
       if(diffCompleted && diffCompleted < 0) {
           diffCompleted *= - 1;
       }
       return [
            pickUpTime ? moment(pickUpTime).format('HH:mm') : '',
            pickUpTime && (diff >= 15 || (diff <= 15 && diff >= 11)),
            pickUpTime && diff <= 10,
            completedTime ? `zakasnio ${Math.ceil(diffCompleted)} min` : ''
        ];
    },[pickUpTime, completedTime]);

    return (
        <OrderItemPickUpTimeContainer>
            <OrderItemTitle isCompleted={isCompleted}>{itemAddress ? itemAddress : '#####'}</OrderItemTitle>
            <OrderItemPickUpTimeText isOrange={+isOrange} isRed={+isRed}>
                {time}
            </OrderItemPickUpTimeText>
            <OrderItemPickUpLateTime>{completedTimeText}</OrderItemPickUpLateTime>
        </OrderItemPickUpTimeContainer>
    );
};

export default PickUpTimeView;