import * as yup from 'yup';
import { IS_TEST } from '../../../../../../config';

export const initialValuesMenuManuallyInsertForm =  IS_TEST ? {
    type: '',
    restaurantCategoryId: 0,
    name: '',
    description: '',
    quantity: '',
    price: '',
    free: false,
    file: null,
    position: '1',
    imageUrl: null
} : {
    type: '',
    restaurantCategoryId: 0,
    name: '',
    description: '',
    quantity: '',
    price: '',
    free: false,
    file: null,
    position: '1',
    imageUrl: null
};

export const restaurantMenuValidation = yup.object({
    type: yup.string().required('requiredField'),
    restaurantCategoryId: yup.number().required('requiredField').min(1, 'requiredField'),
    name: yup.string().required('requiredField'),
    description: yup.string().test('required-description', 'requiredField', function(value) {
        return !!(value && value.replace(/\s/gmi, '')?.length);
    }),
    quantity: yup.string().required('requiredField'),
    price: yup.string().test('required-price', 'requiredField', function(value) {
        if(this.parent.free) return true;
        return !!value;
    }),
    position: yup.number().required('requiredField')
});