import styled, { css } from 'styled-components';
import { Flex } from 'assets/style';
import Button from 'components/button';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { Colors } from 'theme/palette';
export const DriverRegSendToFormContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 2;
`;
export const DriverRegFormTitle = styled(Flex)`
  font: normal 400 36px/46px 'Readex Pro';
  color: #FFFFFF;
`;
export const DriverRegFormContent = styled(Flex)`
  flex: 2;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const DriverRegOptionsRow = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  gap: 50px;
`;

export const DriverRegOptionButton = styled(Button)<{active?: number}>`
  &&& {
    min-width: 180px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    align-items: center;
    
    ${props => props.active && css`
      border: 3px solid ${Colors.lightGrey};
    `}
  }
`;
export const DriverRegSendTypeError = styled(Flex)`
  font-family: Readex Pro;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  margin-top: 6px;
  color: #f44336;
`;

export const DriverRegOptionEmailIcon = styled(MailOutlineIcon)`
  &&& {
    font-size: 48px;
    color: #000000;
  }
`;

export const DriverRegOptionPhoneIcon = styled(PhoneIphoneIcon)`
  &&& {
    font-size: 48px;
    color: #000000;
  }
`;

export const DriverRegOptionText = styled(Flex)`
  font: normal 700 30px/37.5px 'Readex Pro';
  color: #000000;
`;
