import PreviewShiftRowHeader from '../PreviewShiftRowHeader';
import PreviewShiftRow from '../PreviewShiftRow';

export type TPreviewCurrentShiftRow = {
    id: string|number
    driver: string
    status: number
}

export type TPreviewCurrentShiftProps = {
    dataPreview: TPreviewCurrentShiftRow[]
}

const PreviewCurrentShift = ({dataPreview}: TPreviewCurrentShiftProps) => {


    return (
        <>
            <PreviewShiftRowHeader />
            {dataPreview.map((x: any)=> <PreviewShiftRow key={x.id} {...x} />)}
        </>
    );
};

export default PreviewCurrentShift;