import React from 'react';
import {isMobile} from 'react-device-detect';
import {Navigate} from 'react-router-dom';
import {
    OnlyDesktopContainer,
    OnlyDesktopContainerImage,
    OnlyDesktopContent, OnlyDesktopContentContainer,
    OnlyDesktopHeader,
    OnlyDesktopImage,
    OnlyDesktopText,
    OnlyDesktopTitle
} from './style';
import notAllowed from 'assets/images/mobile-not-allowed.png';
import {GuestLayoutImage} from '../../layouts/style';
import pattern from '../../assets/images/pattern.png';


const OnlyDesktop = () => {

    if(!isMobile) {
        return <Navigate to="/"/>;
    }

    return (
        <OnlyDesktopContainer>
            <GuestLayoutImage src={pattern}/>
            <OnlyDesktopHeader>
                <OnlyDesktopTitle>Obaveštenje</OnlyDesktopTitle>
            </OnlyDesktopHeader>
            <OnlyDesktopContent>
                <OnlyDesktopContentContainer>
                    <OnlyDesktopText>Aplikacija Dispečer <br/> radi isključivo na desktopu.</OnlyDesktopText>
                    <OnlyDesktopContainerImage>
                        <OnlyDesktopImage src={notAllowed} alt={'DingDont Not allowed'} />
                    </OnlyDesktopContainerImage>
                    <OnlyDesktopText>Molimo vas da koristite aplikaciju na desktop računarima radi najboljeg iskustva.</OnlyDesktopText>
                </OnlyDesktopContentContainer>
               </OnlyDesktopContent>
        </OnlyDesktopContainer>
    );
};

export default OnlyDesktop;