import axiosClient from 'core/api/config/axios';
import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'core/api/utils/createUrl';


const useGetSettingsQuery = () => {
  const { data, isLoading, isFetched, isFetching } = useQuery({
    queryKey: ['get-settings'],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await axiosClient.get(createUrl({
        url: '/settings',
        version: 'v1'
      }));
      return res.data?.data;
    }
  });
  return { data, isLoading, isFetched, isFetching };
};

export default useGetSettingsQuery;
