
export enum PERIOD_PICKER_EVENTS {
    setToday= 'day',
    setMonth = 'month',
    setWeek = 'isoWeek',
    setYear = 'year',
    setPrevPeriod = 'setPrevPeriod',
    setNextPeriod = 'setNextPeriod',
    setCustomDateByPeriod = 'setCustomDateByPeriod',
    setDateRange= 'range'
}

export type TPeriodDateRange = {
    startDate:Date,
    endDate:Date,
}


export type TReducerAction = {
    type: PERIOD_PICKER_EVENTS;
    payload?: TPeriodDateRange
}

export enum PeriodsOptions {
    DAY = 'day',
    WEEK = 'isoWeek',
    MONTH = 'month',
    YEAR = 'year',
    RANGE = 'range'
}


export type TReducer = {
    period: PeriodsOptions,
    dateRange?: TPeriodDateRange
}


