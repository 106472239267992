import React, { useEffect } from 'react';
import { useModal } from '../../hooks/useModal';
import VersionCheckerForm from './DialogForm';
import { APP_VERSION } from '../../config';
import { useSelector } from 'react-redux';
import { selectVersion } from '../../store/app/appSlice';

const VersionChecker = () => {
    const appVersion = useSelector(selectVersion);
    const { openModal: openDialogChecker } = useModal({
        modal: VersionCheckerForm,
        isActionsButtonsVisible: false,
        isCloseAction: false
    });

    useEffect(() => {
        const appVer = (APP_VERSION as string || '').replace(/\s+/gmi, '');
        const appVer1 = (appVersion as string || '').replace(/\s+/gmi, '');
        if(!appVer1 || !appVer || appVer1 === appVer) return;
        if(appVer1 !== appVer) {
            openDialogChecker();
        }
    }, [appVersion, openDialogChecker]);


    return (
        <></>
    );
};

export default VersionChecker;