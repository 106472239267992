import {
    DefineShiftForDayTabsContent, DefineShiftsButton, DefineShiftsTabsContentContainer, DefineShiftsTabsFooter,
    DefineShiftTabsContentAddNewRecordButton,
    DefineShiftTabsContentHeader, DefineShiftTabsContentHeaderText,
    DefineShiftTabsContentItems
} from './style';
import AddIcon from '@mui/icons-material/Add';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import Translation from '../../../../../../../components/Translation';
import { Translate } from '../../../../../../../internationalization/translate';
import ShiftSchedulingForDayContext from '../../context/context';
import ShiftItem from './ShiftItem';
import EmptyShiftsContent from './EmptyShiftsContent';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { selectSettingsShifts, selectSettingsVehicleStartAmount } from '../../../../../../../store/app/appSlice';
import { useTranslation } from 'react-i18next';
import ConditionalRendering from '../../../../../../../components/Utils/ConditionalRendering';
import { TUseOptimizeEventData, useOptimizeEventClick } from '../../../../../../../hooks/useOptimizeEventClick';
import { SHIFT_SCHEDULING_PREVIEW_EVENTS } from '../../../../../view/SchedulingBoard/d';
import { useModal } from '../../../../../../../hooks/useModal';
import ShiftSchedulingTemporaryStopDialog from '../../../TemporaryStop';
import ShiftSchedulingTemporaryCanResumeDialog from '../../../TemporaryCanResume';


const ShiftForDayShiftsContent = ({closeModal, isNeedMaxWidth = 1, isUpdate = false,  isShiftStarted}: any) => {
    const {t} = useTranslation();
    const { activeShift, activeShiftItems, addNewItemByShift, setActiveShift, shifts, setNeedRefetch } = useContext(ShiftSchedulingForDayContext);
    const {values, resetForm, errors, isSubmitting, setValues} = useFormikContext() as any;

    const settingsShift = useSelector(selectSettingsShifts);
    const settingStartAmount = useSelector(selectSettingsVehicleStartAmount);

    const headerText = useMemo(()=> {
        const _settingsShift = settingsShift?.[`${activeShift}`];
        const tKey= (()=> {
            if(activeShift === 1) return Translate.schedulingFirstShift;
            if(activeShift === 2) return Translate.schedulingSecondShift;
            return Translate.schedulingThirdShift;
        })();
        return `${t(tKey)}: ${_settingsShift?.from} - ${_settingsShift?.to}`;
        },[t, activeShift, settingsShift]);

    const handlerCancel = useCallback(()=> {
        closeModal();
        resetForm({});
    },[closeModal]);

    const handlerAddNewItem = useCallback(() => {
        if(isShiftStarted) return;
        const id = new Date().getTime() + 1;
        addNewItemByShift(id, settingStartAmount);
    },[addNewItemByShift, isShiftStarted, settingStartAmount]);

    useEffect(() => {
        if(!isSubmitting) return;
        const firstKey = Object.keys(errors)?.sort()?.[0];
        if(!firstKey) return;
        const tabKey = (()=> {
           if(firstKey.startsWith('2')) return 2;
           if(firstKey.startsWith('3')) return 3;
           return 1;
        })();
        setActiveShift(tabKey);
    }, [errors, isSubmitting]);

    useEffect(() => {
        if(!shifts) return;
        let obj = {};
        const dd = Object.values(shifts).map(x => x).flat();

        dd.forEach((x: any)=> {
            obj = {
                ...obj,
                [`${x.shiftId}_vehicleId_${x.id}`]: values?.[`${x.shiftId}_vehicleId_${x.id}`] || x?.[`${x.shiftId}_vehicleId_${x.id}`] || x?.['vehicleId'],
                [`${x.shiftId}_driverId_${x.id}`]: values?.[`${x.shiftId}_driverId_${x.id}`] || x?.[`${x.shiftId}_driverId_${x.id}`] || x?.['driverId'],
                [`${x.shiftId}_amount_${x.id}`]: values?.[`${x.shiftId}_amount_${x.id}`] || x?.[`${x.shiftId}_amount_${x.id}`] || x?.['amount']
            };
        });
        setValues({
            ...(values || {}),
            ...obj
        });
    }, [shifts, isUpdate]);


    const { openModal: openDialogShiftTempStop, closeModal: closeShiftTempStopModal } = useModal({
        modal: ShiftSchedulingTemporaryStopDialog,
        isActionsButtonsVisible: true,
        isCloseAction: false
    });

    const { openModal: openDialogShiftTempCanResume, closeModal: closeShiftTempCanResumeModal } = useModal({
        modal: ShiftSchedulingTemporaryCanResumeDialog,
        isActionsButtonsVisible: true,
        isCloseAction: false
    });

    const handlerOpenDialogShiftTempStop = useCallback((data: any) => {
        openDialogShiftTempStop({
            ...data,
            setRefetch: setNeedRefetch,
            closeModal: closeShiftTempStopModal
        });
    }, [closeShiftTempStopModal, setNeedRefetch, openDialogShiftTempStop]);



    const handlerOpenDialogShiftTempCanResume = useCallback((data: any) => {
        openDialogShiftTempCanResume({
            ...data,
            setRefetch: setNeedRefetch,
            closeModal: closeShiftTempCanResumeModal
        });
    }, [closeShiftTempCanResumeModal, setNeedRefetch, openDialogShiftTempCanResume]);


    const { onClickHandler } = useOptimizeEventClick({
        eventHandler(data: TUseOptimizeEventData) {
            if(data.action === SHIFT_SCHEDULING_PREVIEW_EVENTS.TEMP_STOP) {
                if(data.param) handlerOpenDialogShiftTempStop(JSON.parse(data.param));
                return;
            }

            if(data.action === SHIFT_SCHEDULING_PREVIEW_EVENTS.TEMP_CAN_RESUME) {
                if(data.param) handlerOpenDialogShiftTempCanResume(JSON.parse(data.param));
                return;
            }
        }
    });

    
    return (
        <DefineShiftForDayTabsContent isNeedMaxWidth={isNeedMaxWidth}>
            <DefineShiftTabsContentHeader>
                <DefineShiftTabsContentHeaderText>{headerText}</DefineShiftTabsContentHeaderText>
                <ConditionalRendering condition={!isShiftStarted}>
                    <DefineShiftTabsContentAddNewRecordButton
                        onClick={handlerAddNewItem}
                        startIcon={<AddIcon />}
                    >
                        <Translation use={Translate.addText} />
                    </DefineShiftTabsContentAddNewRecordButton>
                </ConditionalRendering>
            </DefineShiftTabsContentHeader>
            <DefineShiftsTabsContentContainer onClick={onClickHandler} data-action-root>
                {activeShiftItems?.length ? (<DefineShiftTabsContentItems>
                        {activeShiftItems.map((item: any, index: number) => <ShiftItem item={item} key={index}  />)}
                    </DefineShiftTabsContentItems>)
                    : <EmptyShiftsContent />}
            </DefineShiftsTabsContentContainer>
            <DefineShiftsTabsFooter>
                <DefineShiftsButton onClick={handlerCancel} isWhite={1}>
                    <Translation use={Translate.cancel} />
                </DefineShiftsButton>
                <DefineShiftsButton type="submit">
                    <Translation use={Translate.save} />
                </DefineShiftsButton>
            </DefineShiftsTabsFooter>
        </DefineShiftForDayTabsContent>
    );
};

export default ShiftForDayShiftsContent;