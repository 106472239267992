import { CustomColumnDef } from 'components/Table/d';
import Avatar from 'components/Avatar';
import UserStatus from 'views/Users/TableComponents/UserStatus';
import DriverActions from 'views/Drivers/components/TableComponents/Actions';

export const extendedDriverColumns = [
  { accessorKey: 'search' },
  { accessorKey: 'blocked' },
  { accessorKey: 'active' },
  { accessorKey: 'start' },
  { accessorKey: 'stop' }
];

export const DriversColumns: CustomColumnDef[] = [
  {
    accessorKey: 'avatar',
    header: '',
    align: 'center',
    style: {
      minWidth: 65,
      width: '65px'
    },
    cell: (props) => <Avatar row={props.row.original}/>
  },
  {
    accessorKey: 'firstLastName',
    header: 'Ime i Prezime',
    align: 'left',
    style: {
      minWidth: '25%',
      width: '25%'
    },
    cell: (props) => `${props.row.original?.firstName} ${props.row?.original?.lastName}`
  },
  {
    accessorKey: 'email',
    header: 'Email',
    align: 'left',
    style: {
      minWidth: '560px',
      width: '35%'
    },
  },
  {
    accessorKey: 'phoneNumber',
    header: 'Telefon',
    align: 'left',
    style: {
      minWidth: 180,
      width: 180
    },
    cell: (user: any) => {
      let phone = user.row.original?.phone;
      if (!phone) return '';
      phone = `+${phone}`;
      return <span>{phone}</span>;
    },
  },
  {
    header: 'Status',
    style: {
      minWidth: 120,
      width: 120
    },
    cell: (user: any) => <UserStatus row={user.row.original}/>
  },
  {
    accessorKey: 'earnings',
    header: 'Zarada',
    style: {
      minWidth: 100,
      width: 100
    }
  },
  {
    accessorKey: 'actions',
    header: '',
    style: {
      minWidth: 90,
      width: 90
    },
    cell: (user: any) => <DriverActions row={user.row.original}/>
  }
];
