import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';

const useAbortOrderMutation = () => {
    const mutation = useMutation({
        mutationFn: async (data: {id: number|string, abortNote: string}) => {
            const response = await axiosClient.patch(createUrl({
                url: `/dispatcher/order-free/abort/${data.id}`,
                version: 'v1',
            }), { note: data.abortNote }, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        }
    });
    return mutation;
};

export default useAbortOrderMutation;
