import { readFile } from './helpers/cropImage';
import { useImageCropContext } from './context';
import Cropper from './cropper/Cropper';
import { ZoomSlider } from './cropper/Sliders';
import {
    CropImageActionButtons,
    CropImageModalButtonsPart,
    ImageCropContainer,
    ImageCropModalContainer,
    InputHidden
} from './style';

const ImageCropModalContent = ({ handleDone, handleClose }: any) => {
    const { setImage } = useImageCropContext();

    const handleFileChange = async ({ target: { files } }: any) => {
        const file = files && files[0];
        const imageDataUrl = await readFile(file);
        setImage(imageDataUrl);
    };

    return (
        <ImageCropModalContainer>
            <div className="border border-dashed border-gray-200 p-6 rounded-lg">
                <div className="flex justify-center">
                    <ImageCropContainer>
                        <Cropper />
                    </ImageCropContainer>
                </div>
                <ZoomSlider />
                <InputHidden
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    id="avatarInput"
                    accept="image/*"
                />
                <CropImageModalButtonsPart>
                    <CropImageActionButtons onClick={handleClose}>
                        Cancel
                    </CropImageActionButtons>
                    <CropImageActionButtons onClick={handleDone}>
                        Save
                    </CropImageActionButtons>
                </CropImageModalButtonsPart>
            </div>
        </ImageCropModalContainer>
    );
};

export default ImageCropModalContent;