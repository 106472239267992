import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import { APP_VERSION } from '../../config';
import { SETTINGS_KEYS } from './d';

// Define a type for the slice state
interface AppState {
    version: string
    settings?: any,
    drivers?: any,
    vehicles?: any
}

// Define the initial state using that type
const initialState: AppState = {
    version: APP_VERSION || ''
};

export const appSlice = createSlice({
    name: 'app',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setVersion: (state, action: PayloadAction<string>) => {
            state.version = action.payload;
        },
        setSettings: (state, action: PayloadAction<any>) => {
            state.settings = {
                ...(state?.settings || {}),
                ...action.payload
            };
        },
        setDrivers: (state, action: PayloadAction<any>) => {
            state.drivers = [...(action.payload || [])];
        },
        setVehicles: (state, action: PayloadAction<any>) => {
            state.vehicles = [...(action.payload || [])];
        }
    }
});

export const { setVersion, setSettings, setVehicles, setDrivers } = appSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectVersion = (state: RootState) => state.app.version;
export const selectDrivers = (state: RootState) => state.app.drivers || [];
export const selectVehicles = (state: RootState) => state.app?.vehicles || [];
export const selectSettings = (state: RootState) => state.app.settings || {};
export const selectSettingsVehicleStartAmount = (state: RootState) => state.app.settings?.[SETTINGS_KEYS.SHIFT_START_AMOUNT]?.value || '';
export const selectSettingsShifts = (state: RootState) => state.app.settings?.[SETTINGS_KEYS.SHIFT_SCHEDULING]?.valueJson || {};
export const selectGetVehicle = (id: number) => (state: RootState) => state.app.vehicles.find((x: any)=> x.id === id) || {};
export const selectGetDriver = (id: number) => (state: RootState) => state.app.drivers.find((x: any)=> x.id === id) || {};
export default appSlice.reducer;