import React, { useCallback, useEffect } from 'react';
import { SearchAddressAutoComplete } from './style';
import { Input } from '../input';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import useOpenStreetSearch from '../../hooks/useOpenStreetSearch';


const AddressSearchOpenStreet = ({dataEntry, onChange, label, useHelperText = false, ...rest}: any) => {
    const [address, setAddress] = React.useState(dataEntry?.address || '');
    const [addressObj, setAddressObj] = React.useState<any>(dataEntry?.addressObject || null);

    const {
        value,
        suggestions,
        setValue
    } = useOpenStreetSearch();


    const handlerClearData = useCallback(() => {
        setAddressObj(null);
        setAddress('');
    }, [setAddressObj, setAddress]);


    React.useEffect(() => {
        if (!dataEntry || addressObj || address) return;
        setAddressObj(dataEntry?.addressObject || null);
        setAddress(dataEntry?.address  || dataEntry?.addressObject?.description || '');
    }, [dataEntry]);

    useEffect(() => {
        return () => {
            setAddress('');
            setAddressObj(null);
        };
    }, []);

    return (
        <SearchAddressAutoComplete
            sx={{ width: 300 }}
            freeSolo
            disableClearable={false}
            getOptionLabel={(option: any) => {
                return typeof option === 'string' ? option : option?.description;
            }}
            options={suggestions}
            includeInputInList
            filterSelectedOptions
            noOptionsText="No locations"
            inputValue={address}
            value={value || addressObj}
            onInputChange={(_event, newInputValue, reason: any) => {
                if (reason === 'clear') {
                    handlerClearData();
                } else {
                    setAddress(newInputValue);
                }
            }}
            onChange={(_event, newValue: any) => {
                onChange(newValue);
            }}
            renderInput={(params: any) => (
                <Input {...params}
                       {...rest}
                       variant="outlined"
                       label={label || 'Pretraga'}
                       fullWidth
                       useHelperText={useHelperText}
                       icon={{
                           icon: SearchIcon
                       }}
                       onChange={(e: any) => {
                           setValue(e.target.value);
                           setAddress(e.target.value);
                       }}
                />
            )}
            renderOption={(props, option: any) => {
                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                <Box
                                    component="span"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    {option.main_text}
                                </Box>
                                <Typography variant="body2" color="text.secondary">
                                    {option.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
};

export default AddressSearchOpenStreet;