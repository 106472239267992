import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';


const useUpdateSettingsMutation = (id: number) => {
    const mutation = useMutation({
        mutationFn: async (data: { key: string; value?: string, valueJson?: string }) => {
            const response = await axiosClient.put(createUrl({
                url: `/settings/${id}`,
                version: 'v1',
            }), data, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        }
    });
    return mutation;
};

export default useUpdateSettingsMutation;
