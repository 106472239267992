import React, { useCallback, useMemo } from 'react';
import {
    FilterArrowCaret,
    FilterBadge,
    FilterButton,
    FilterMenu,
    FilterMenuItem,
    FilterMenuItemSelected,
    TableFilterContainer
} from 'components/Table/TableFilter/style';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';

const TableFilter = ({ label = 'Filter', tableFilterOptions, setFilter, filter, clearAllFilters, fieldFilter = [], isSingle, isClearable = false, isCategory }: {
    label?: string
    tableFilterOptions: any[],
    setFilter: (payload: any) => void,
    filter: any
    clearAllFilters: () => void,
    fieldFilter?: string[],
    isSingle?: boolean
    isClearable?: boolean
    isCategory?: number
}) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, [setAnchorEl]);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const setClearFilter = useCallback((key: string, value?: string | number | boolean) => {
        clearAllFilters();
        setFilter({
            field: key,
            data: !isSingle ? filter[key] ? undefined : value : isClearable ? filter[key] == value ? undefined : value : value
        });
        handleClose();
    }, [setFilter, filter, handleClose, clearAllFilters, isSingle, isClearable]);

    const filterCount = useMemo(() => {
        const filterKeys = Object.keys(filter).filter(x=> x !== 'search' && (fieldFilter?.length  &&  fieldFilter.includes(x)));
        return filterKeys.map(x => filter[x]).filter(x => !!x)?.length || 0;
    }, [filter]);

    const filterClick = (_filter: any) => {
        if (_filter.disabled) return;
        setClearFilter(_filter.filterKey, _filter.value);
    };

    return (
        <TableFilterContainer>
            <FilterButton
                variant={'outlined'}
                opened={open ? 1 : 0}
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                startIcon={(<FilterBadge badgeContent={filterCount}>
                    <FilterAltIcon />
                </FilterBadge>)}
                onClick={handleClick}
                endIcon={<FilterArrowCaret />}
            >
                {t(label)}
            </FilterButton>
            <FilterMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                    sx: {
                        minWidth: 140
                    }
                }}
                isCategory={isCategory}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                {tableFilterOptions.map((_filter, index) => {
                    const selected = !!(_filter.value === filter?.[_filter.filterKey]);
                    return (
                        <FilterMenuItem divider={tableFilterOptions.length - 1 !== index} key={index}
                                        onClick={() => filterClick(_filter)}
                                        disableRipple disabled={_filter.disabled}>
                            {t(_filter.label) || _filter.label}
                            {selected ? <FilterMenuItemSelected /> : <></>}
                        </FilterMenuItem>
                    );
                })}
            </FilterMenu>
        </TableFilterContainer>
    );
};

export default TableFilter;
