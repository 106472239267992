import React, { useCallback, useContext, useEffect } from 'react';
import { Formik, useFormikContext } from 'formik';
import { defineCategoryValidation, initialValuesDefineCategoryForm } from '../../d';
import {
    DefineUpdateCategoryFormButton,
    DefineUpdateCategoryFormButtonsColumn,
    DefineUpdateCategoryFormContainer,
    DefineUpdateCategoryFormFormContainer,
    DefineUpdateCategoryFormGrid
} from './style';
import { Grid } from '@mui/material';
import FormikSelectField from '../../../../../../../components/Formik/FormikSelectField';
import {
    dishesTypeOpts
} from '../../../../../view/DefineRestaurantMenuManually/component/RestaurantMenuAddManuallyForm';
import { Translate } from '../../../../../../../internationalization/translate';
import FormikTextField from '../../../../../../../components/Formik/FormikTextField';
import Translation from '../../../../../../../components/Translation';
import { useTranslation } from 'react-i18next';
import UiDataContext from '../../../../../../../context/UiContext/context';
import useCreateRestaurantCategoryMutation
    from '../../../../../../../core/api/hooks/mutations/restaurantCategory/useCreateRestaurantCategoryMutation';
import useUpdateRestaurantCategoryMutation
    from '../../../../../../../core/api/hooks/mutations/restaurantCategory/useUpdateRestaurantCategoryMutation';
import { errorToast, successToast } from '../../../../../../../components/Utils/Toast';
import { pick } from 'lodash';
import useRestaurantCategoryQuery
    from '../../../../../../../core/api/hooks/query/restaurantCategory/useRestaurantCategoryQuery';
import FormikCheckBox from '../../../../../../../components/Formik/FormikCheckBox';
import FormikInputNumber from '../../../../../../../components/Formik/FormikInputNumber';


const CategoryForm = ({ id, clearEdit }: any) => {

    const { values, setValues, resetForm } = useFormikContext();

    const handlerResetForm = useCallback(() => {
        resetForm();
        clearEdit();
    }, [id, clearEdit, resetForm]);


    const { data, isLoading, isFetched } = useRestaurantCategoryQuery(id);

    useEffect(() => {
        if (!data || isLoading || !isFetched) return;
        setValues({
            ...(values as any),
            name: data.name,
            type: data.type,
            position: data.position ? `${data.position}` : '',
            is18Plus: data.is18Plus
        }).then();
    }, [data, isLoading, isFetched]);


    return (
        <DefineUpdateCategoryFormFormContainer>
            <DefineUpdateCategoryFormGrid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <FormikSelectField
                        options={dishesTypeOpts}
                        variant="outlined"
                        name={'type'}
                        label={Translate.restaurantMenuItemType}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormikTextField
                        variant="outlined"
                        label={Translate.restaurantMenuItemName}
                        name={'name'}
                        fullWidth
                        type={'text'}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormikInputNumber
                        name={'position'}
                        variant="outlined"
                        label={Translate.restaurantMenuItemPosition}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormikCheckBox
                        name={'is18Plus'}
                        label={Translate.restaurantMenuItemIs18Plus}
                        type={'checkbox'}
                        fullWidth
                    />
                </Grid>
            </DefineUpdateCategoryFormGrid>
            <DefineUpdateCategoryFormButtonsColumn>
                <DefineUpdateCategoryFormButton white onClick={handlerResetForm}>
                    <Translation use={Translate.reset} />
                </DefineUpdateCategoryFormButton>
                <DefineUpdateCategoryFormButton type={'submit'}>
                    <Translation use={Translate.save} />
                </DefineUpdateCategoryFormButton>
            </DefineUpdateCategoryFormButtonsColumn>
        </DefineUpdateCategoryFormFormContainer>
    );
};

const DefineUpdateForm = ({ restaurantId, id, setRefetch, clearEdit }: any) => {
    const { t } = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const createRestaurantCategory = useCreateRestaurantCategoryMutation();
    const updateRestaurantCategory = useUpdateRestaurantCategoryMutation();

    const onSubmit = useCallback(async (values: any, { resetForm }: any) => {
        if (!values.name) return;
        try {
            setLoading();
            const obj = {
                name: values.name.replace(/^\s+|\s+$/g, '').trim(),
                ...(pick(values, [ 'type', 'position'])),
                id,
                restaurantId,
                is18Plus: values?.is18Plus ? 1 : 0
            };
            const result = id ? await updateRestaurantCategory.mutateAsync(obj) : await createRestaurantCategory.mutateAsync(obj);
            if (result?.status === 200) {
                successToast(t(result.message));
                setRefetch();
                resetForm();
                clearEdit();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
    }, [id, setRefetch, t, createRestaurantCategory, updateRestaurantCategory, setLoading, resetLoading, clearEdit]);


    return (
        <DefineUpdateCategoryFormContainer>
            <Formik
                initialValues={initialValuesDefineCategoryForm}
                validationSchema={defineCategoryValidation}
                onSubmit={onSubmit}
            >
                <CategoryForm id={id} clearEdit={clearEdit} />
            </Formik>
        </DefineUpdateCategoryFormContainer>
    );
};


export default DefineUpdateForm;

