import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import {
    EditDriverContainer,
    EditDriverForm,
    EditDriverFormContainer,
    EditDriverFormContent,
    EditDriverFormTitle,
    EditDriverRow,
    EidDriverSubmitButton
} from 'views/Drivers/components/Dialogs/Edit/style';
import { Formik, useFormikContext } from 'formik';
import { initialValuesEditForm, validationEditDriverForm } from 'views/Drivers/components/Dialogs/Edit/validation';
import Translation from 'components/Translation';
import { Translate } from 'internationalization/translate';
import { Grid } from '@mui/material';
import FormikTextField from 'components/Formik/FormikTextField';
import FormikSelectPhoneCode from 'components/Formik/FormikSelectPhoneCode';
import FormikInputNumber from 'components/Formik/FormikInputNumber';
import useGetDriverQuery from 'core/api/hooks/query/useGetDriverQuery';
import { errorToast, successToast } from 'components/Utils/Toast';
import { useTranslation } from 'react-i18next';
import UiDataContext from 'context/UiContext/context';
import useUpdateDriverMutation from 'core/api/hooks/mutations/driver/useUpdateDriverMutation';


const EditForm = ({ id }: any) => {

    const { setValues, registerField } = useFormikContext();

    const { data: driver } = useGetDriverQuery(id);

    useEffect(() => {
        if (!driver || !driver?.id) return;
        const cCode = (driver?.account?.phoneCode || '').replace(/^\+/gi, '');
        setValues({
            firstName: driver.firstName,
            lastName: driver.lastName,
            email: driver?.account?.email,
            phoneCode: driver?.account?.phoneCode || '',
            phone: (driver?.account?.phoneNumber || '').slice(cCode.length)
        }, false).then();
    }, [driver, setValues, registerField]);


    useEffect(() => {
        return () => {
            (async () => {
                await setValues(initialValuesEditForm, false);
            })();
        };
    }, []);

    return (
        <EditDriverForm>
            <EditDriverFormContainer>
                <Translation use={Translate.driverEditModalTitle} as={EditDriverFormTitle} />
                <EditDriverFormContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <FormikTextField
                                variant="outlined"
                                name={'firstName'}
                                label={Translate.firstNameLabel}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormikTextField
                                variant="outlined"
                                label={Translate.lastNameLabel}
                                name={'lastName'}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormikTextField
                                variant="outlined"
                                label={Translate.emailLabel}
                                name={'email'}
                                type={'email'}
                                fullWidth
                            />
                        </Grid>
                        <EditDriverRow>
                            <Grid item xs={6} md={4}>
                                <FormikSelectPhoneCode
                                    fullWidth
                                    name={'phoneCode'}
                                />
                            </Grid>
                            <Grid item xs={6} md={8}>
                                <FormikInputNumber
                                    fullWidth
                                    name={'phone'}
                                    variant={'outlined'}
                                    label={Translate.phoneLabel}
                                    maxLength={12}
                                />
                            </Grid>
                        </EditDriverRow>
                    </Grid>
                </EditDriverFormContent>
            </EditDriverFormContainer>
            <EidDriverSubmitButton type={'submit'}>
                <Translation use={Translate.save} />
            </EidDriverSubmitButton>
        </EditDriverForm>
    );

};

const DriverEditForm = ({ row, setRefetch, closeModal }: any) => {
    const { t } = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const [id] = useMemo(() => [row.id], [row]);
    const updateDriverMutation = useUpdateDriverMutation(id);

    const onSubmit = useCallback(async (values: any) => {
        if (!values?.email) return;
        try {
            setLoading();
            const result = await updateDriverMutation.mutateAsync({
                ...values,
                phoneCode: values?.phoneCode || undefined,
                phoneNumber: values?.phone ? `${values.phoneCode}${values.phone.replace(values.phoneCode, '')}` : undefined
            });
            if (result.status === 200) {
                successToast(t(result.message));
                setRefetch();
                closeModal();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
    }, [closeModal, resetLoading, setLoading, setRefetch, t, updateDriverMutation]);


    return (
        <EditDriverContainer>
            <Formik
                initialValues={{ ...initialValuesEditForm }}
                validationSchema={validationEditDriverForm}
                onSubmit={onSubmit}
            >
                <EditForm id={id} />
            </Formik>
        </EditDriverContainer>
    );
};

export default DriverEditForm;
