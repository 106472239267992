import React from 'react';
import SuspenseHoc from 'components/Utils/SuspenseHoc';
import { useModalRender } from 'hooks/useModal';
import ModalContext from 'components/Dialog/context';
import Dialog from 'components/Dialog/index';

export const ModalContainer = () => {

  const modals = useModalRender();
  return (
    <>
      {modals.map((m: any) => {
        const { ModalComponent, guid } = m;
        return (
          <SuspenseHoc key={guid}>
            <ModalContext.Provider value={m}>
              <Dialog
                Context={ModalComponent}
              />
            </ModalContext.Provider>
          </SuspenseHoc>
        );
      })}
    </>
  );
};

export default ModalContainer;
