import React, { useEffect, useRef } from 'react';
import {Input} from 'components/input';
import {useField} from 'formik';


const FormikTextField = ({name, pattern,label, fullWidth, variant, withCbFn, cbFn, ...rest}: any) => {
    const timer = useRef(null);
    const [field, meta] = useField({
        name,
        ...rest
    });

    const newProps = {
        ...field,
        ...rest,
        error: Boolean(meta.error) && meta.touched,
        helperText: Boolean(meta.touched) && meta.error
    };

    const onBlur = (e: any) => {
        field.onBlur(e);
    };
    
    const onChange = (e: any) => {
        if(pattern) {
            const cc = e.target.value.replace(new RegExp(pattern,'gm'), '');
            e.target.value = cc;
        }
        field.onChange(e);
        if(withCbFn && cbFn) {
            timer.current && clearTimeout(timer.current as any);
            timer.current = setTimeout(() => {
                if(!withCbFn || !cbFn) return;
                if (e.target.value?.length >= 2) {
                    cbFn(e.target.value);
                }
            }, 500)as any;
        }
    };


    useEffect(() => {
        return () => {
            timer.current && clearTimeout(timer.current as any);
            timer.current = null;
        };
    }, []);

    return (
        <Input
            {...newProps}
            value={newProps.value || ''}
            onBlur={onBlur}
            onChange={onChange}
            variant={variant}
            label={label}
            fullWidth={fullWidth}
            disabled={rest.disabled}
        />
    );
};


export default FormikTextField;
