import React, { useMemo } from 'react';
import Translation from 'components/Translation';
import { Translate } from 'internationalization/translate';
import { RestaurantItemStatusBadge, RestaurantItemStatusContainer } from './style';

const RestaurantItemStatus = ({ row }: { row: any }) => {

    const [statusText, isActive] = useMemo(() => [
        row.active !== 1 ?  Translate.inactive : Translate.active,
        row.active ? 1 : 0
    ], [row]);

    return (
        <RestaurantItemStatusContainer>
            <RestaurantItemStatusBadge active={isActive}>
                <Translation use={statusText} />
            </RestaurantItemStatusBadge>
        </RestaurantItemStatusContainer>
    );
};

export default RestaurantItemStatus;
