import Datepicker from '../Datepicker';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';


const FormikDatepicker = ({ name, ...rest }: any) => {
    const {t} = useTranslation();
    const [field, meta, helper] = useField({
        name,
        ...rest
    });

    const newProps = {
        ...field,
        ...rest,
        error: Boolean(meta.error) && meta.touched,
        helperText: Boolean(meta.touched) && meta.error
    };
    const onChange = (value: any) => {
        helper.setValue(value, true);
    };

    return (
        <Datepicker
            {...newProps}
            slotProps={{
                textField: {
                    label: t(newProps.label),
                    error: newProps.error
                }
            }}
            value={newProps.value || ''}
            onChange={onChange}
        />
    );


};

export default FormikDatepicker;