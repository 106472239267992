import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';

const useChangeOrderDeliveryAmountMutation = () => {
    const mutation = useMutation({
        mutationFn: async ({id, amount}: {id: number, amount: number}) => {
            const response = await axiosClient.patch(createUrl({
                url: `/dispatcher/order-free/change-amount/${id}`,
                version: 'v1',
            }), { amount }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        }
    });
    return mutation;
};

export default useChangeOrderDeliveryAmountMutation;
