import { useQuery } from '@tanstack/react-query';
import axiosClient from '../../config/axios';
import { createUrl } from '../../utils/createUrl';

const useGetVehicleQuery = (id: number) => {
    const { data, isLoading, isFetched, isFetching } = useQuery({
        queryKey: ['get-driver'],
        queryFn: async () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const res: any = await axiosClient.get(createUrl({
                url: `/dispatcher/vehicles/${id}`,
                version: 'v1'
            }));
            return res.data?.data || {};
        },
        enabled: !!id
    });
    return { data, isLoading, isFetched, isFetching };
};

export default useGetVehicleQuery;