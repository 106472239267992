import React, { memo } from 'react';
import { TableHead } from '@mui/material';
import ThCell from 'components/Table/TableHeader/ThCell';
import { THeadRow } from 'components/Table/style';


const THead = memo(({ headerGroups, isVisibilityHeader = true }: any) => {
    return (
        <TableHead>
            {headerGroups.map((headerGroup: any) => (
                <THeadRow key={headerGroup.id}>
                    {headerGroup.headers.map((column: any, index: number) => {
                        return <ThCell key={column.id} column={column} index={index}
                                       isVisibilityHeader={isVisibilityHeader && !column?.placeholderOf && index === 0} />;

                    })
                    }
                </THeadRow>
            ))}
        </TableHead>
    );
});


export default THead;
