import { DefineRestaurantMenuContainer, DefineRestaurantMenuStepsContainer } from './style';
import React, { useCallback, useMemo, useState } from 'react';
import {
    BackButton,
    BackIcon,
    DefineDriverFormsContent,
    RegForm, RegisterButton, RegisterFormContainer
} from '../../../Drivers/components/DefineDriverForm/style';
import Steps from '../../../../components/Steps';
import { Formik } from 'formik';

import Translation from '../../../../components/Translation';
import { Translate } from '../../../../internationalization/translate';
import RestaurantMenuInsertTypeForm from './RestaurantMenuInsertTypeForm';
import RestaurantMenuImportExcelForm from './RestaurantMenuImportExcelForm';
import { initialValuesMenuInsertForm, MENU_IMPORT_TYPE, validationRestaurantMenuInsertTypeForm } from './validation';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

const steps = [
    { value: 0, label: '1' },
    { value: 1, label: '2' }
];
//const DefineRestaurantMenu = ({ setRefetch, closeModal }: any) => {
const DefineRestaurantMenu = ({row}: any) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [isExcel, setIsExcel] = useState<boolean>(false);
    const _steps = useMemo(() => isExcel ? steps : steps.slice(-1), [isExcel]);
    const maxSteps = useMemo(() => _steps.length - 1, [_steps]);
    const isLast = useMemo(() => activeStep === maxSteps, [maxSteps, activeStep]);
    const isFirst = useMemo(() => activeStep === 0, [activeStep]);
    const navigate = useNavigate();

    const changeStepper = useCallback(() => {
        if (isLast) return;
        setActiveStep(s => maxSteps === s + 1 ? maxSteps : s + 1);
    }, [maxSteps, setActiveStep, isLast]);

    const prevStepper = useCallback(() => {
        if (isFirst) return;
        setActiveStep(s => s - 1 <= 0 ? 0 : s - 1);
    }, [maxSteps, setActiveStep, isFirst]);

    const Component = useMemo(() => {
        switch (activeStep) {
            case 1:
                return RestaurantMenuImportExcelForm;
            default:
                return RestaurantMenuInsertTypeForm;
        }
    }, [activeStep]);

    const validationSchema = useMemo(() => {
        switch (activeStep) {
            case 1:
                return yup.object({});
            default:
                return validationRestaurantMenuInsertTypeForm;
        }
    }, [activeStep]);

    const handlerOpenRestaurantAddMenuManuallyPage = useCallback(() => {
        navigate(`/restaurant-menu-add-manually/${row?.id}`, {
            replace: false
        });
    }, [navigate]);

    const onSubmit = useCallback(async (values: any) => {
        const isExcel = values?.menuImportType === MENU_IMPORT_TYPE.EXCEL;

        if (!isLast && isExcel) {
            changeStepper();
            return;
        } else {
            handlerOpenRestaurantAddMenuManuallyPage();
        }
    }, [isLast, changeStepper]);

    return (
        <DefineRestaurantMenuContainer>
            <DefineRestaurantMenuStepsContainer>
                {activeStep > 0 ? <BackButton onClick={prevStepper}><BackIcon /></BackButton> : <></>}
                <Steps steps={_steps} activeStep={activeStep} />
            </DefineRestaurantMenuStepsContainer>
            <DefineDriverFormsContent>
                <Formik
                    initialValues={{
                        ...initialValuesMenuInsertForm
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {() => {
                        return (
                            <RegForm>
                                <RegisterFormContainer>
                                    <Component changeStepper={changeStepper} setExcel={setIsExcel} />
                                </RegisterFormContainer>
                                <RegisterButton type={'submit'}>
                                    <Translation use={Translate.next} />
                                </RegisterButton>
                            </RegForm>
                        );
                    }}
                </Formik>
            </DefineDriverFormsContent>
        </DefineRestaurantMenuContainer>
    );
};

export default DefineRestaurantMenu;