import React, { useCallback, useContext } from 'react';
import { OrderViewListHeaderContainer, OrderViewListHeaderSearch, OrderViewListHeaderTitle } from './style';
import Translation from 'components/Translation';
import OrderViewContext from '../context/context';
import { Translate } from 'internationalization/translate';



const OrderViewListHeader = () => {
    
    const {setFilter, clearFilter} = useContext(OrderViewContext);
    
    
    const handleSearch = useCallback((value: string)=> {
        if(!value) return;
        setFilter({
            field: 'search',
            data: value
        });
    },[setFilter]);
    
    const handleClear = useCallback(()=> clearFilter('search'),[clearFilter]);
    
    return (
        <OrderViewListHeaderContainer>
            <Translation use={Translate.orderViewTitle} as={OrderViewListHeaderTitle} />
            <OrderViewListHeaderSearch 
                handlerSearch={handleSearch}
                clearSearch={handleClear}
                placeholder={'search'}
            />
        </OrderViewListHeaderContainer>
    );
};

export default OrderViewListHeader;