import React, {useCallback, useContext, useMemo} from 'react';
import useModalFormData from 'hooks/useModalFormData';
import {UserBlockDialogContainer, UserBlockDialogHeader, UserBlockDialogText} from 'views/Users/Dialogs/Block/style';
import UiDataContext from 'context/UiContext/context';
import Translation from 'components/Translation';
import {Translate} from 'internationalization/translate';
import {useTranslation} from 'react-i18next';
import {errorToast, successToast} from 'components/Utils/Toast';
import useVehicleDisableEnableMutation
    from '../../../../../core/api/hooks/mutations/vehicle/useVehicleDisableEnableMutation';

const VehiclesDialogEnableDisable = ({row, setRefetch, closeModal}: any) => {

    const {t} = useTranslation();
    const vehicleDisableEnableMutation = useVehicleDisableEnableMutation();
    const {setLoading, resetLoading} = useContext(UiDataContext);
    const [id, name, isEnabled, title, text] = useMemo(() => [
        row.id,
        `#${row.vehicleNumber} - ${row.name} - ${row.registrationPlate}`,
        row.enabled,
        row.enabled ? Translate.vehicleDisabledModalTitle : Translate.vehicleEnableModalTitle,
        row.enabled ? Translate.vehicleDisabledModalText : Translate.vehicleEnabledModalText
    ], [row]);

    const handlerOnSubmit = useCallback(async () => {
        if (!id) return false;
        try {
            setLoading();
            const result = await vehicleDisableEnableMutation.mutateAsync({
                id,
                enabled: isEnabled === 1 ? false : true
            });
            if (result?.status === 200) {
                successToast(t(result.message));
                setRefetch();
                closeModal();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
        return true;
    }, [id, setLoading, vehicleDisableEnableMutation, isEnabled, t, setRefetch, closeModal, resetLoading]);

    useModalFormData({
        handlerOnSubmit
    });


    return (
        <UserBlockDialogContainer>
            <Translation use={title} as={UserBlockDialogHeader}/>
            <Translation use={text} as={UserBlockDialogText} params={{name}}/>
        </UserBlockDialogContainer>
    );
};


export default VehiclesDialogEnableDisable;
