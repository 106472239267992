import React, { FC, useContext } from 'react';
import {
  DialogButton,
  DialogButtonsPart
} from 'components/Dialog/style';
import ModalContext from 'components/Dialog/context';
import Translation from 'components/Translation';

type Props = {
    onSubmitAction:(e: any) => void,
    buttonSubmitLabel?: string
    buttonCancelLabel?: string
}

const Buttons:FC<Props> = ({ onSubmitAction, buttonSubmitLabel = 'Potvrdi', buttonCancelLabel = 'Otkaži' }) => {

  const { onReject } = useContext(ModalContext);

  return (
    <DialogButtonsPart>
      <DialogButton
        variant={'outlined'}
        onClick={onReject}
      >
        <Translation use={buttonCancelLabel} />
      </DialogButton>

      <DialogButton
        variant={'outlined'}
        onClick={onSubmitAction}
        primary={1}
      >
        <Translation use={buttonSubmitLabel} />
      </DialogButton>
    </DialogButtonsPart>
  );
};

export default Buttons;
