import PopoverButton from '../../../../../../../../components/Popover/PopoverButton';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
    ShiftFinishPopoverButton,
    ShiftFinishPopoverButtons,
    ShiftFinishPopoverContainer,
    ShiftFinishPopoverText
} from './style';
import Translation from '../../../../../../../../components/Translation';
import { Translate } from '../../../../../../../../internationalization/translate';
import React, { useCallback, useContext } from 'react';

import useHandoverShiftsMutation
    from '../../../../../../../../core/api/hooks/mutations/shiftScheduling/useHandoverShiftsMutation';
import { errorToast, successToast } from '../../../../../../../../components/Utils/Toast';
import { useTranslation } from 'react-i18next';
import UiDataContext from '../../../../../../../../context/UiContext/context';
import { queryClient } from '../../../../../../../../core/root/ReactQueryProvider';


const PreviewShiftFinishPopoverButton = ({ id, shiftDate, shiftType }: { id: number | string, shiftDate: string, shiftType: any}) => {


    const { t } = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const handoverShiftsMutation = useHandoverShiftsMutation();

    const handleOnSubmit = useCallback(async (handleClose: any)=> {
        if(!id) return;
        setLoading();
        try {
            const results =  await handoverShiftsMutation.mutateAsync({ id: Number(id) });
            if (results?.status === 200) {
                successToast(t(results.message));
                await queryClient.invalidateQueries({ queryKey: ['shift-scheduling'] });
                await queryClient.invalidateQueries({ queryKey: [`shift-scheduling-by-date-type-${shiftDate}-${shiftType}`] });
                handleClose();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
    },[handoverShiftsMutation, id, setLoading, resetLoading, t, shiftDate, shiftType]);


    return (
        <PopoverButton Icon={AttachMoneyIcon}>
            {(handleClose: any) => {
                const onSubmit = async () => {
                    await handleOnSubmit(handleClose);
                };
                return (
                    <ShiftFinishPopoverContainer>
                        <ShiftFinishPopoverText>{t(Translate.shiftSchedulingFinishText)}</ShiftFinishPopoverText>
                        <ShiftFinishPopoverButtons>
                            <ShiftFinishPopoverButton isWhite={1} onClick={handleClose}>
                                <Translation use={Translate.cancel} />
                            </ShiftFinishPopoverButton>
                            <ShiftFinishPopoverButton onClick={onSubmit}>
                                <Translation use={Translate.save} />
                            </ShiftFinishPopoverButton>
                        </ShiftFinishPopoverButtons>
                    </ShiftFinishPopoverContainer>
                );
            }}
        </PopoverButton>
    );
};

export default PreviewShiftFinishPopoverButton;