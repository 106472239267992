import {
    initOrderDriverViewState,
    ORDER_DRIVER_VIEW_EVENTS,
    TOrderDriverViewActions,
    TOrderDriverViewReducer
} from './d';
import { get, isEqual, merge, omit, pick } from 'lodash';
import { PropsWithChildren, useCallback, useMemo, useReducer, useRef } from 'react';
import { TFilerPayload } from '../../../../../../components/Table/context/d';
import OrderDriverViewContext from './context';

export const MAX_PER_PAGE = 50000;
const filterByOrder = (filter: any) => {
    const keys = Object.keys(filter);
    keys.sort();
    return keys.reduce((acc, x) => {
        const val = filter[x] as any;
        if (Array.isArray(val)) val.sort();
        return {
            ...acc,
            [x]: val
        };
    }, {});

};
const reducer = (state: TOrderDriverViewReducer, action: TOrderDriverViewActions) => {
    switch (action.type) {
        default:
            return state;

        case ORDER_DRIVER_VIEW_EVENTS.CLEAR_ALL_FILTER: {
            return {
                ...state,
                filter: {},
                isNeedRefetch: Math.floor(new Date().getTime())
            };
        }

        case ORDER_DRIVER_VIEW_EVENTS.CLEAR_NEED_REFETCH: {
            return {
                ...state,
                isNeedRefetch: 0
            };
        }


        case ORDER_DRIVER_VIEW_EVENTS.ADD_FILTER: {
            const field = action.payload.field;
            const data = action.payload.data;
            if (isEqual(get(state.filter, field), data)) return state;
            let filter = { ...pick(state.filter, ['search']) } as any;
            if (`${data}`.length === 0) {
                filter = omit({ ...state.filter }, [action.payload]);
            } else {
                filter = {
                    ...filter,
                    [field]: data
                };
            }
            return {
                ...state,
                filter: filterByOrder(filter),
                isNeedRefetch: Math.floor(new Date().getTime())
            };
        }



        case ORDER_DRIVER_VIEW_EVENTS.CLEAR_FILTER: {
            let filter = { ...state.filter };
            filter = omit(filter, [action.payload]);
            return {
                ...state,
                filter: filterByOrder(filter),
                isNeedRefetch: Math.floor(new Date().getTime())
            };
        }


    }
};

export const useOrderDriverViewContext = (entryData = {}) => {

    const dataReduceState = useRef({
        ...initOrderDriverViewState,
        ...entryData
    });

    const [state, dispatch] = useReducer(reducer, dataReduceState.current as TOrderDriverViewReducer);

    const clearNeedRefetch = useCallback(() => {
        dispatch({
            type: ORDER_DRIVER_VIEW_EVENTS.CLEAR_NEED_REFETCH
        });
    }, [dispatch]);

    const setFilter = useCallback((payload: TFilerPayload) => {
        dispatch({
            type: ORDER_DRIVER_VIEW_EVENTS.ADD_FILTER,
            payload
        });
    }, [dispatch]);

    const clearFilter = useCallback((field: string) => {
        dispatch({
            type: ORDER_DRIVER_VIEW_EVENTS.CLEAR_FILTER,
            payload: field
        });
    }, [dispatch]);

    const clearAllFilters = useCallback(() => {
        dispatch({
            type: ORDER_DRIVER_VIEW_EVENTS.CLEAR_ALL_FILTER
        });
    }, [dispatch]);

    const setRefetch = useCallback(() => {
        dispatch({
            type: ORDER_DRIVER_VIEW_EVENTS.SET_NEED_REFETCH
        });
    }, [dispatch]);

    const filtersFetchData = useCallback(() => {
        return Object.keys(state.filter).reduce((acc, key) => {
            const data = state.filter[key] || '';
            return {
                ...acc,
                [key]: data
            };
        }, {});
    }, [state]);

    const requestOptions = useMemo(() => {
        const filterData = filtersFetchData();
        return merge({}, {
                perPage: MAX_PER_PAGE,
                page: 1
            },
            filterData
        ) as any;
    }, [state, filtersFetchData]);


    return useMemo(() => ({
        ...state,
        clearFilter,
        clearAllFilters,
        clearNeedRefetch,
        setFilter,
        setRefetch,
        requestOptions
    }), [state, clearFilter, clearAllFilters, clearNeedRefetch, setFilter, setRefetch, requestOptions]);
};

export type TOrderDriverViewContextType = ReturnType<typeof useOrderDriverViewContext>;

const OrderDriverViewContextContainer = ({
                                             children,
                                             ...rest
                                         }: PropsWithChildren<any>) => {

    const providerData = useOrderDriverViewContext(rest);


    return (
        <OrderDriverViewContext.Provider value={providerData}>
            {children}
        </OrderDriverViewContext.Provider>
    );
};

export default OrderDriverViewContextContainer;