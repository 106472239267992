import React, { useMemo } from 'react';
import { TDCellContainer, TDExpandedContainer } from 'components/Table/style';
import { flexRender } from '@tanstack/react-table';

const TDCell = ({
                    cell,
                    style = {},
                    expand
                }: any) => {

    const notRenderInExpand = useMemo(() => cell?.row?.depth > 0 && cell?.column?.columnDef?.hideInExpand, [cell]);

    return (
        <TDCellContainer align={cell?.column?.columnDef?.align}
                         style={{ ...cell?.column?.columnDef?.style, ...style }}>
            {!expand ? !notRenderInExpand ? flexRender(
                cell.column.columnDef.cell,
                cell.getContext()
            ) : <></> : (
                <TDExpandedContainer>
                    {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                    )}
                </TDExpandedContainer>
            )}
        </TDCellContainer>
    );
};

export default TDCell;
