import {useMutation} from '@tanstack/react-query';
import axiosClient, { transformRequest } from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';


export type TCreateVehicle = {
    name: string
    year?: string
    registrationPlate?: string
    vehicleType?: string
    vehicleOwner?: number
    vehicleNumber?: number
    imageUrl?: string
    enabled?: number
    active?: number
    file?: any
}

const useCreateVehicleMutation = () => {
    const mutation = useMutation({
        mutationFn: async (data: TCreateVehicle) => {
            const response = await axiosClient.post(createUrl({
                url: '/dispatcher/vehicles',
                version: 'v1',
            }), data, {
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                transformRequest
            });
            return response.data;
        }
    });
    return mutation;
};

export default useCreateVehicleMutation;
