import { OpenStreetMapProvider as LeafletOpenStreetMapProvider } from 'leaflet-geosearch';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDebounce } from 'use-debounce';


const useOpenStreetSearch = () => {
    const [search, setSearch] = useState<string>('');
    const [debounceValue] = useDebounce(search, 500);
    const [suggestions, setSuggestions] = useState<any>([]);
    
    const provider = new LeafletOpenStreetMapProvider({
        params: {
            //countrycodes: 'rs', // Limit results to Serbia
            'accept-language': 'sr-Latn', // Use Serbian Latin
            extratags: 1,
            namedetails: 1,
            addressdetails: 1, // include additional address detail parts
            //polygon_text: 1
            bounded: true, // Enable bounding box
            viewbox: '21.2335,43.4789,21.4335,43.6789'
            //layer: 'address, poi, railway, natural, manmade'
        }
    });


    useEffect(() => {
        if(!debounceValue) return;
        (async ()=> {
            const results = await provider.search({ query: debounceValue });
            const data = results.map((x)=> {
                const address = (x?.raw as any)?.address;
                return {
                    lat: x.raw?.lat,
                    lng: x.raw?.lon,
                    main_text: `${address?.road}${address?.house_number ? ` ${address?.house_number}` : ''}`,
                    secondary_text: `${address?.quarter ? address?.quarter : address?.city_district}, ${address?.city}, ${address?.country}`,
                    description: `${address?.road}${address?.house_number ? ` ${address?.house_number}` : ''}, ${address?.quarter ? address?.quarter : address?.city_district}, ${address?.city}`,
                    place_id: x.raw?.place_id,
                    display_name: x.raw?.display_name
                };
            });
            setSuggestions(data);
        })();
    }, [debounceValue]);
        
    
    const clearSuggestions = useCallback(() => setSuggestions([]),[setSuggestions]);

    const setValue = useCallback((value: string)=> setSearch(value),[setSearch]);
    
    return useMemo(()=> ({
        value: search,
        clearSuggestions,
        suggestions,
        setValue,
    }), [
        clearSuggestions,
        suggestions,
        setValue,
        search
    ]);
};

export default useOpenStreetSearch;