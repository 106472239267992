import styled from 'styled-components';
import { Flex } from 'assets/style';


export const PeriodPickerPreviewDates = styled(Flex)`
    flex-direction: column;
    color: black;
    line-height: 120%;
`;

export const PeriodPickerDatesValueBox = styled(Flex)<{ isDisabled?: boolean }>`
    justify-content: space-between;
    align-items: center;
`;


export const PeriodPickerDatesValue = styled(Flex)`
    color: #000;
    min-width: 240px;
    margin: 0 10px;
    font: normal normal 400 24px/30px 'Readex Pro', serif;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
