import { Flex } from '../../../../../assets/style';
import styled, { css } from 'styled-components';
import { Box } from '@mui/material';


export const DefineShiftsForDayContainer = styled(Box)<{isNeedMaxWidth?: number}>`
    display: flex;
    flex-direction: column;
    min-width: 1180px;
    min-height: 820px;
    max-height: 820px;
    
    ${props => props.isNeedMaxWidth && css`
        max-width: 1180px;
    `}
    
`;


export const  DefineShiftForDayContent = styled(Flex)`
    flex: 2;
    width: 100%;
`;
