import styled, { css } from 'styled-components';
import { Flex } from '../../../../../assets/style';
import { Typography } from '@mui/material';


export const PreviewShiftContainer = styled(Flex)<{isEnded?: number}>`
    flex-direction: column;
    padding: 0 45px 30px;
    width: 700px;
    
    ${props => props?.isEnded && css`
        width: 1200px;
    `}
`;

export const PreviewShiftHeader = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    margin-bottom: 24px;
    width: 100%;
`;

export const PreviewShiftHeaderText = styled(Typography).attrs({
    variant: 'h4'
})`
    width: 100%;
    text-align: center;
`;


export const PreviewShiftBody = styled(Flex)`
    flex: 2;
    flex-direction: column;
    gap: 15px;
    min-height: 450px;
    max-height: 450px;
    overflow-x: scroll;
`;