import React, {
  forwardRef, useMemo
} from 'react';
import {
  TSelectProps,
  selectDropdownMaxHeight
} from 'components/select/d';
import {
  SelectContainer,
  SelectMenuItem,
  SelectMenuItemIcon,
  SelectMenuItemText,
  SelectClearButton,
  SelectHelperText,
  SelectFormControl,
  SelectInputLabel
} from 'components/select/style';
import {
  Check,
  Delete
} from '@mui/icons-material';
import { noop as NOOP } from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import Translation from 'components/Translation';

// eslint-disable-next-line react/display-name
const SelectText = forwardRef(({
  options,
  label,
  onChange = NOOP,
  onClear,
  fullWidth = false,
  variant = 'standard',
  value,
  MenuProps,
  error,
  helperText,
  useHelperText = true,
  multiple,
  required,
  isDark = true,
  ...rest
}: TSelectProps, ref) => {
  const {t} = useTranslation();

  const _helperText = useMemo(()=> useHelperText ? error && typeof error === 'string' ? t(error) :  helperText ? t(helperText) :  <>&nbsp;</> : null,[t, error, helperText, useHelperText ]);
  const isValue = !!(multiple ? (value as any)?.length : value);
  const values = ((multiple ? value : [value]) || []) as (string | number)[];

  const labelStr = required ? `${t(label as string)} *` : t(label as string);

  return (
      <SelectFormControl fullWidth={fullWidth} isDark={isDark} disabled={rest.disabled} >
        {labelStr && <SelectInputLabel isDark={isDark} error={!!error} value={value} id="select-label" shrink>{labelStr}</SelectInputLabel>}
        {isValue && onClear ? <>
          <SelectClearButton size="small" onClick={onClear}>
            <Delete fontSize="small" />
          </SelectClearButton>
        </> : null}
        <SelectContainer
          ref={ref}
          labelId="select-label"
          label={labelStr}
          value={value || ''}
          variant={variant}
          multiple={multiple}
          onChange={onChange}
          error={!!error}
          IconComponent={ExpandMoreIcon}
          MenuProps={{
            ...MenuProps,
            classes: {
              paper: 'select-popover-paper'
            },
            sx: {
              ...(MenuProps?.sx || {}),
              maxHeight: selectDropdownMaxHeight,
              zIndex: 25000,
            }
          }}
          {...rest}
        >
            {options.map((option, i) => {
              const selected = values.includes(option.value);
              const icon = option?.icon;
              const Component = option?.component;
              return (
                <SelectMenuItem key={i} value={option.value} disabled={option.disabled}>
                  {Component && Component}
                  {option.label && <Translation use={option.label} as={SelectMenuItemText} /> }
                  {icon ?
                    !icon?.isComponent ?
                      <icon.icon htmlColor={icon.color || 'inherit'} />
                      : <icon.icon />
                    : selected &&
                      <SelectMenuItemIcon>
                          <Check fontSize="small" />
                      </SelectMenuItemIcon>
                  }
                </SelectMenuItem>
              );
            })}
        </SelectContainer>
        {useHelperText && <SelectHelperText error={!!error}>{_helperText}</SelectHelperText>}
      </SelectFormControl>
  );
});

export default SelectText;
