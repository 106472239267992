import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { Flex } from '../../assets/style';

export const ShiftSchedulingContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 5px 10px;
`;

export const ShiftSchedulingHeader = styled(Flex)`
    padding: 15px 25px;
    justify-content: space-between;
    align-items: center;
    min-height: 76px;
`;

export const ShiftSchedulingHeaderType = styled(Typography).attrs({
    variant: 'h4'
})`
    color: #FFF;
    text-align: center;
    font: normal 400 40px/normal 'Readex Pro';
`;



export const ShiftSchedulingTableContainer = styled(Flex)`
    flex: 2;
    width: 100%;
    padding: 15px 20px;


    @media (max-width: 1921px) {
        min-width: 1800px;
        max-width: 1800px;
    }

    @media (max-width: 1800px) {
        min-width: 1500px;
        max-width: 1700px;
    }
`;
