import { Formik, useFormikContext } from 'formik';
import useGetVehicleQuery from '../../../../../core/api/hooks/query/useGetVehicleQuery';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';


import {
    EditDriverContainer,
    EditDriverForm,
    EditDriverFormTitle
} from '../../../../Drivers/components/Dialogs/Edit/style';
import Translation from '../../../../../components/Translation';
import { Translate } from '../../../../../internationalization/translate';
import { Grid } from '@mui/material';
import FormikTextField from '../../../../../components/Formik/FormikTextField';
import { useTranslation } from 'react-i18next';
import UiDataContext from '../../../../../context/UiContext/context';
import useUpdateVehicleMutation from '../../../../../core/api/hooks/mutations/vehicle/useUpdateVehicleMutation';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import { initalValuesVehicleEditForm, validationVehicleEditForm } from './validation';
import FormikSelectField from '../../../../../components/Formik/FormikSelectField';
import { vehicleTypesOpts } from '../../DefineVehicleForm';
import { RegisterButton } from '../../../../Drivers/components/DefineDriverForm/style';
import { VehicleEditFormInputsContainer } from './style';
import FormikCheckBox from '../../../../../components/Formik/FormikCheckBox';
import CropImage from '../../../../../components/Image/Crop';
import FormikSelectYear from '../../../../../components/Formik/FormikSelectYear';

const EditForm = ({ id }: any) => {
    const { values, setValues, resetForm, setFieldValue, isSubmitting } = useFormikContext() as any;

    const { data: vehicle } = useGetVehicleQuery(id);

    useEffect(() => {
        if (!vehicle || !vehicle?.id) return;
        setValues({
            name: vehicle.name,
            year: vehicle.year,
            registrationPlate: vehicle.registrationPlate,
            vehicleOwner: !!vehicle.vehicleOwner,
            vehicleType: vehicle.vehicleType,
            vehicleNumber: vehicle.vehicleNumber,
            imageUrl: vehicle.imageUrl
        }, false).then();
    }, [vehicle, setValues]);

    useEffect(() => {
        return () => {
            resetForm();
        };
    }, []);

    useEffect(() => {
        if (values.vehicleType == 4) {
            setFieldValue('year', '', false);
            setFieldValue('registrationPlate', '', false);
        }
    }, [values]);

    const imageUrl = useMemo(() => values?.imageUrl, [values]);


    const handlerSaveImage = useCallback((data: any) => {
        setFieldValue('file', data, false);
    }, [setFieldValue]);

    const isTypeBike = useMemo(() => values?.vehicleType == '4', [values]);

    useEffect(() => {
        console.log('submitting ', isSubmitting);
    }, [isSubmitting]);


    return (
        <EditDriverForm>
            <Translation use={Translate.vehicleEditModalTitle} as={EditDriverFormTitle} />
            <VehicleEditFormInputsContainer>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <CropImage imageUrl={imageUrl} handlerSave={handlerSaveImage} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikSelectField
                            options={vehicleTypesOpts}
                            variant="outlined"
                            name={'vehicleType'}
                            label={Translate.vehicleType}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikTextField
                            variant="outlined"
                            name={'vehicleNumber'}
                            label={Translate.vehicleNumber}
                            fullWidth
                            type={'text'}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormikTextField
                            variant="outlined"
                            name={'name'}
                            label={Translate.vehicleName}
                            fullWidth
                            type={'text'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikSelectYear
                            variant="outlined"
                            name={'year'}
                            label={Translate.vehicleYear}
                            fullWidth
                            disabled={isTypeBike}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikTextField
                            variant="outlined"
                            name={'registrationPlate'}
                            label={Translate.vehicleRegistrationPlate}
                            fullWidth
                            type={'text'}
                            disabled={isTypeBike}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormikCheckBox
                            name={'vehicleOwner'}
                            label={Translate.vehicleOwner}
                            type={'checkbox'}
                        />
                    </Grid>
                </Grid>
            </VehicleEditFormInputsContainer>
            <RegisterButton type={'submit'} disabled={!!isSubmitting}>
                <Translation use={Translate.save} />
            </RegisterButton>
        </EditDriverForm>
    );
};

const VehicleEditForm = ({ row, setRefetch, closeModal }: any) => {
    const { t } = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const [id] = useMemo(() => [row.id], [row]);
    const updateVehicleMutation = useUpdateVehicleMutation(id);

    const onSubmit = useCallback(async (values: any, { setSubmitting }: any) => {
        if (!values?.name) return;
        try {
            setLoading();
            const result = await updateVehicleMutation.mutateAsync({
                vehicleType: values.vehicleType,
                name: values.name,
                year: values?.year || undefined,
                registrationPlate: values?.registrationPlate || undefined,
                vehicleOwner: values?.vehicleOwner || undefined,
                vehicleNumber: values?.vehicleNumber || undefined,
                file: values?.file || undefined
            });
            if (result.status === 200) {
                successToast(t(result.message));
                setRefetch();
                closeModal();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
            setSubmitting(false);
        }
    }, [closeModal, resetLoading, setLoading, setRefetch, t, updateVehicleMutation]);

    return (
        <EditDriverContainer>
            <Formik
                initialValues={{ ...initalValuesVehicleEditForm }}
                validationSchema={validationVehicleEditForm}
                onSubmit={onSubmit}
            >
                <EditForm id={id} />
            </Formik>
        </EditDriverContainer>
    );
};

export default VehicleEditForm;