import React, {
  useRef,
  useReducer,
  useMemo,
  useCallback
} from 'react';
import PeriodPickerContext from 'components/PeriodPicker/context/context';
import {
  TReducerAction,
  TReducer,
  PERIOD_PICKER_EVENTS,
  TPeriodDateRange,
  PeriodsOptions
} from 'components/PeriodPicker/context/d';
import moment from 'moment';


const reducer = (state: TReducer, action: TReducerAction): TReducer => {
  switch (action.type) {
    default:
      return state;

    case PERIOD_PICKER_EVENTS.setDateRange: {

      return action.payload ? {
        ...state,
        period: PeriodsOptions.RANGE,
        dateRange: {
          startDate: moment(new Date(action.payload?.startDate as any)).startOf('day').toDate(),
          endDate: moment(new Date(action.payload?.endDate as any)).endOf('day').toDate()
        }
      } : {
        ...state,
        period: PeriodsOptions.RANGE
      };

    }

    case PERIOD_PICKER_EVENTS.setCustomDateByPeriod: {
      return action.payload ? {
        ...state,
        dateRange: {
          startDate: moment(new Date(action.payload?.startDate as any)).startOf('day').toDate(),
          endDate: moment(new Date(action.payload?.endDate as any)).endOf('day').toDate()
        }
      } : {
        ...state,
      };
    }

    case PERIOD_PICKER_EVENTS.setWeek:
    case PERIOD_PICKER_EVENTS.setYear:
    case PERIOD_PICKER_EVENTS.setMonth:
    case PERIOD_PICKER_EVENTS.setToday: {
      return {
        ...state,
        period: action.type as any as PeriodsOptions,
        dateRange: {
          startDate: moment().startOf(action.type as any).toDate(),
          endDate: moment().endOf(action.type as any).toDate()
        }
      };
    }

    case PERIOD_PICKER_EVENTS.setPrevPeriod:
    case PERIOD_PICKER_EVENTS.setNextPeriod: {
      if (state.period === PeriodsOptions.RANGE) return state;
      const dateMoment = action.type === PERIOD_PICKER_EVENTS.setNextPeriod ? moment(state.dateRange?.startDate).add(1, state.period as any) : moment(state.dateRange?.startDate).subtract(1, state.period as any);

      return {
        ...state,
        dateRange: {
          startDate: dateMoment.startOf(state.period).toDate(),
          endDate: dateMoment.endOf(state.period).toDate()
        }
      };
    }

  }
};

const usePeriodPickerState = () => {

  const startDataPeriod = useMemo(()=> {

    return {
      period: PeriodsOptions.WEEK,
      dateRange: {
        startDate:  moment().startOf(PeriodsOptions.WEEK).toDate(),
        endDate:  moment().endOf(PeriodsOptions.WEEK).toDate()
      }
    };
  },[]);

  const dataReducerStartState = useRef<TReducer>(startDataPeriod as any);

  const [state, dispatch] = useReducer(reducer, dataReducerStartState.current as TReducer);
  
  const triggerPeriod = useCallback((type: PeriodsOptions) => {
    dispatch({
      type: type as any as PERIOD_PICKER_EVENTS
    });
  }, [dispatch]);

  const movePeriodNextPrev = useCallback((forward?: boolean) => {
    dispatch({
      type: forward ? PERIOD_PICKER_EVENTS.setNextPeriod : PERIOD_PICKER_EVENTS.setPrevPeriod
    });
  }, [dispatch]);

  const setDateRange = useCallback((payload: TPeriodDateRange) => {
    dispatch({
      type: PERIOD_PICKER_EVENTS.setDateRange,
      payload
    });
  }, [dispatch]);


  const setCustomDateRangeByPeriod = useCallback((payload: TPeriodDateRange) => {
    dispatch({
      type: PERIOD_PICKER_EVENTS.setCustomDateByPeriod,
      payload
    });
  }, [dispatch]);

  return useMemo(() => ({
    ...state,
    triggerPeriod,
    setDateRange,
    movePeriodNextPrev,
    setCustomDateRangeByPeriod
  }), [state, triggerPeriod, setDateRange, movePeriodNextPrev, setCustomDateRangeByPeriod]);
};

export type TPeriodPickerContextType = ReturnType<typeof usePeriodPickerState>

const withPeriodPickerContext = (Component: React.FC) => {
  const PeriodPickerContextContainer = (props: any) => {

    const providerData = usePeriodPickerState();

    return (
      <PeriodPickerContext.Provider value={providerData}>
        <Component  {...props} />
      </PeriodPickerContext.Provider>
    );
  };

  return PeriodPickerContextContainer;
};

export default withPeriodPickerContext;
