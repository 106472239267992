import { DefineShiftForDayHeaderTitle, DefineShiftsForDayHeader } from './style';
import Translation from '../../../../../../../components/Translation';
import { Translate } from '../../../../../../../internationalization/translate';
import FormikDatepicker from '../../../../../../../components/Formik/FormikDatepicker';
import useGetScheduledDatesQuery
    from '../../../../../../../core/api/hooks/query/shiftScheduling/useGetScheduledDatesQuery';
import { useMemo } from 'react';
import moment from 'moment';


const ShiftForDayHeader = () => {
    
    const {data} = useGetScheduledDatesQuery();
    
    const disabledDates = useMemo(()=> data || [], [data]);

    const shouldDisableDate = (date: any) => {
        return disabledDates.includes(moment(date).format('YYYY-MM-DD')) || date.getTime() > moment().add(1, 'months').toDate().getTime();
    };
    return (
        <DefineShiftsForDayHeader>
            <Translation use={Translate.defineShiftForDayTitle} as={DefineShiftForDayHeaderTitle} />
           <FormikDatepicker name={'shiftDate'} disablePast shouldDisableDate={shouldDisableDate}/>
        </DefineShiftsForDayHeader>
    );
};

export default ShiftForDayHeader;