import * as yup from 'yup';
export const initialValuesDefineCategoryForm = {
    name: '',
    type: ''
};



export const defineCategoryValidation = yup.object({
    name : yup.string().required('requiredField'),
    type: yup.string().required('requiredField'),
    position: yup.string().required('requiredField')
});


export const RESTAURANT_CATEGORY_TABLE_EVENTS = {
    REMOVE_ITEM: 'RESTAURANT_CATEGORY_TABLE_EVENTS_REMOVE_ITEM',
    EDIT_ITEM: 'RESTAURANT_CATEGORY_TABLE_EVENTS_EDIT_ITEM'
};
