import {IS_TEST} from '../../config';
import * as yup from 'yup';

export interface LoginValues {
    email: string;
    password: string
}

export const initialValueLogin = IS_TEST ? {
    email: 'stefan.milutinovic.rs@gmail.com',
    password: 'test123!',
} : {
    email: '',
    password: ''
};

export const validationLoginSchema = yup.object({
    email: yup.string()
        .email('invalidEmail')
        .required('emailRequired'),
    password: yup
        .string()
        .min(6, 'passwordMinLength')
        .required('passwordRequired'),
});
