

export enum ORDER_VIEW_EVENTS {
    CLEAR_ALL_FILTER = 'CLEAR_ALL_FILTER',
    ADD_FILTER = 'ADD_FILTER',
    CLEAR_FILTER = 'CLEAR_FILTER',
    CLEAR_NEED_REFETCH = 'CLEAR_NEED_REFETCH',
    SET_NEED_REFETCH = 'SET_NEED_REFETCH',
    SET_SELECTED_DRIVER = 'SET_SELECTED_DRIVER',
    SET_SELECTED_ITEM = 'SET_SELECTED_ITEM'
}

export type TOrderViewActions = {
    type: ORDER_VIEW_EVENTS,
    payload?: any
}

export const initOrderViewState = {
    filter: {}
};

export type TOrderViewReducer = {
    filter?: any
    isNeedRefetch?: number
    selectedDriver?: string
    selectedItems?: string[]
}