import styled from 'styled-components';
import { Flex } from '../../../../../../../assets/style';


export const PreviewShiftRowContainerHeader = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    font: normal normal 700 20px/26px "Readex Pro";
`;

export const PreviewShiftRowItem = styled(Flex)`
    flex: 2;
`;

export const PreviewShiftRowStatus = styled(Flex)`
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`;

export const PreviewShiftRowStatusText = styled(Flex)`
    flex: 1;
`;

