import React, { useCallback, useContext, useMemo } from 'react';
import {
    OrderViewHeaderContainer,
    OrderViewHeaderItem,
    OrderViewHeaderItemCircle,
    OrderViewHeaderItemText
} from './style';
import Translation from '../../../../../components/Translation';
import { Translate } from '../../../../../internationalization/translate';
import { Colors } from '../../../../../theme/palette';
import OrderViewContext from './context/context';

const OrderViewHeader = () => {
        
    const {filter, setFilter, clearFilter} = useContext(OrderViewContext);

    const handleFilter = useCallback((field: string)=>{
        if(filter?.[field]) clearFilter(field);
        else setFilter({field, data: true});
    } ,[setFilter, clearFilter, filter]);

    const [isActive, isScheduled, isPending, isRejected] = useMemo(()=> [
        +filter.active,
        +filter.scheduled,
        +filter.pending,
        +filter.rejected
    ], [filter]);

    const handleFilterActive = useCallback(()=> handleFilter('active') ,[handleFilter]);
    const handleFilterScheduled = useCallback(()=> handleFilter('scheduled') ,[handleFilter]);
    const handleFilterPending = useCallback(()=> handleFilter('pending') ,[handleFilter]);
    const handleFilterRejected = useCallback(()=> handleFilter('rejected') ,[handleFilter]);

    return (
        <OrderViewHeaderContainer>
            <OrderViewHeaderItem >
                <OrderViewHeaderItemCircle color={Colors.green70} onClick={handleFilterActive} active={isActive}/>
                <Translation use={Translate.orderViewFilterActiveText} as={OrderViewHeaderItemText} />
            </OrderViewHeaderItem>
            <OrderViewHeaderItem>
                <OrderViewHeaderItemCircle color={Colors.blue70} onClick={handleFilterPending} active={isPending}/>
                <Translation use={Translate.orderViewFilterPendingText} as={OrderViewHeaderItemText} />
            </OrderViewHeaderItem>
            <OrderViewHeaderItem>
                <OrderViewHeaderItemCircle color={Colors.grey70} onClick={handleFilterScheduled} active={isScheduled}/>
                <Translation use={Translate.orderViewFilterScheduledText} as={OrderViewHeaderItemText} />
            </OrderViewHeaderItem>
            <OrderViewHeaderItem>
                <OrderViewHeaderItemCircle color={Colors.red} onClick={handleFilterRejected} active={isRejected}/>
                <Translation use={Translate.orderViewFilterRejectedText} as={OrderViewHeaderItemText} />
            </OrderViewHeaderItem>
        </OrderViewHeaderContainer>
    );
};

export default OrderViewHeader;