import axiosClient from 'core/api/config/axios';
import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'core/api/utils/createUrl';


export type TTableQueryParams = {
  url: string
  queryKey: string,
  isNeedFetch?: boolean
  refetchDone: ()=> void,
  requestOptions?: any
  isVisible?: boolean
}
const useTableQuery = ({
  url,
  queryKey,
  isVisible,
  refetchDone,
  requestOptions = {}
}: TTableQueryParams)=> {
        const { data, error, isLoading, isFetched, isFetching, refetch } = useQuery({
            queryKey: [queryKey],
            cacheTime: 0,
            queryFn: async () => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                try {
                    console.log('data', queryKey, url);
                    const res: any = await axiosClient.get(createUrl({
                        url,
                        version: 'v1'
                    }), {
                        params: requestOptions
                    });
                    refetchDone();
                    return res.data?.data  || {rows: [], count: 0};
                } catch (e) {
                    return {rows: [], count: 0};
                }
            },
            refetchOnMount: false,
            retryOnMount: false,
            enabled: isVisible
        } as any);
    return { data, error, isLoading, isFetching, refetch, isFetched };
};

export default useTableQuery;
